import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import { push } from 'connected-react-router';
import { compose } from 'ramda';
import TabRoutes, { addHandlers } from '@/routes/tabs';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import Bar from './bar';
import Tabs from './tabs';
import Title from './title';
import Toggle from './toggle';
import UserMenu from './user-menu';
export const selectors = {
    isAuthenticated: Selectors.isAuthenticatedSelector,
};
export const actionCreators = {
    openDrawer: ActionCreators.openDrawer,
    changeRoute: (path) => ActionCreators.proxyAction(push(path)),
};
export const mergeProps = (_, { changeRoute }) => ({
    routes: addHandlers(TabRoutes, changeRoute),
    goHome: () => changeRoute('/'),
});
export const enhance = compose(connect(selectors, actionCreators, mergeProps));
const Header = enhance(function Header({ isAuthenticated, routes, openDrawer, goHome, }) {
    return (<Bar>
      <Toolbar>
        {isAuthenticated && <Toggle openDrawer={openDrawer}/>}
        <Title goHome={goHome}/>
        {isAuthenticated && (<React.Fragment>
            <Hidden smDown implementation="css">
              <Tabs id="desktop" routes={routes}/>
            </Hidden>
            <UserMenu />
          </React.Fragment>)}
      </Toolbar>
      {isAuthenticated && (<Hidden mdUp implementation="css">
          <Toolbar>
            <Tabs id="mobile" routes={routes}/>
          </Toolbar>
        </Hidden>)}
    </Bar>);
});
export default Header;
