import { __awaiter } from "tslib";
import { omit } from 'ramda';
import { cuid } from '@/utils';
import { ENRICH_API_PREFIX } from '@/config/global';
import DB, { AREAS, BULK_LOCK_STATUS, CLICKTHROUGHS, CLIENT_PRODUCT_REPORTS, COLLECTIONS, CUSTOMER, CUSTOMER_INFO, HEALTHCHECK, LOCK_STATUS, QUICKCHECK, STORE_COLLECTIONS, TOKEN, TRAFFIC, USERS, } from './db';
let db = DB;
let customer = CUSTOMER;
let areas = AREAS;
let users = USERS;
export function models() {
    return (area, name) => ({
        get: wrapResult(getModel(area, name)),
        find: wrapResult(findModels(area, name)),
        create: () => wrapResult(createModel(area, name)),
        update: () => wrapResult(updateModel(area, name)),
        patch: () => wrapResult(patchModel(area, name)),
        remove: wrapResult(removeModel(area, name)),
        bulkLockStatus: wrapResult(getBulkLockStatus),
        lockStatus: wrapResult(modelLockStatus),
        lock: wrapResult(lockModel),
        unlock: wrapResult(unlockModel),
        bulkUpdate: wrapResult(bulkUpdateModel(area, name)),
    });
}
export function area() {
    return {
        get: wrapResult(getArea),
        find: wrapResult(findAreas),
        create: () => wrapResult(createArea),
        update: () => wrapResult(updateArea),
        remove: wrapResult(removeArea),
        bulkLockStatus: wrapResult(getBulkLockStatus),
        lockStatus: wrapResult(modelLockStatus),
        lock: wrapResult(lockModel),
        unlock: wrapResult(unlockModel),
    };
}
export function user() {
    return {
        get: wrapResult(getUser),
        find: wrapResult(findUsers),
        create: () => wrapResult(createUser),
        update: () => wrapResult(updateUser),
        remove: wrapResult(removeUser),
        bulkLockStatus: wrapResult(getBulkLockStatus),
        lockStatus: wrapResult(modelLockStatus),
        lock: wrapResult(lockModel),
        unlock: wrapResult(unlockModel),
    };
}
/* eslint-disable @typescript-eslint/require-await */
export function getModel(area, name) {
    return (id) => __awaiter(this, void 0, void 0, function* () { return get((db[area] || {})[name] || [], id); });
}
export function findModels(area, name) {
    return () => __awaiter(this, void 0, void 0, function* () { return (db[area] || {})[name] || []; });
}
export function createModel(area, name) {
    return (model) => __awaiter(this, void 0, void 0, function* () {
        const newModel = Object.assign(Object.assign({}, model), { id: cuid() });
        db = Object.assign(Object.assign({}, db), { [area]: Object.assign(Object.assign({}, (db[area] || {})), { [name]: create((db[area] || {})[name] || [], newModel) }) });
        return newModel;
    });
}
export function updateModel(area, name) {
    return (id, model) => __awaiter(this, void 0, void 0, function* () {
        db = Object.assign(Object.assign({}, db), { [area]: Object.assign(Object.assign({}, (db[area] || {})), { [name]: update((db[area] || {})[name] || [], id, model) }) });
        return model;
    });
}
export function patchModel(area, name) {
    return (id, updateFields) => __awaiter(this, void 0, void 0, function* () {
        db = Object.assign(Object.assign({}, db), { [area]: Object.assign(Object.assign({}, (db[area] || {})), { [name]: patch((db[area] || {})[name] || [], id, updateFields) }) });
        const index = db[area][name].findIndex(({ id: modelId }) => modelId === id);
        return db[area][name][index];
    });
}
export function removeModel(area, name) {
    return (id) => __awaiter(this, void 0, void 0, function* () {
        db = Object.assign(Object.assign({}, db), { [area]: Object.assign(Object.assign({}, (db[area] || {})), { [name]: remove((db[area] || {})[name] || [], id) }) });
    });
}
export function getBulkLockStatus(_id) {
    return __awaiter(this, void 0, void 0, function* () {
        return BULK_LOCK_STATUS;
    });
}
export function getAreaBulkLockStatus(_id) {
    return __awaiter(this, void 0, void 0, function* () { });
}
export function getUserBulkLockStatus(_id) {
    return __awaiter(this, void 0, void 0, function* () { });
}
export function modelLockStatus(_id) {
    return __awaiter(this, void 0, void 0, function* () {
        return LOCK_STATUS;
    });
}
export function lockModel(_id) {
    return __awaiter(this, void 0, void 0, function* () { });
}
export function unlockModel(_id) {
    return __awaiter(this, void 0, void 0, function* () { });
}
export function bulkUpdateModel(area, name) {
    return (models) => __awaiter(this, void 0, void 0, function* () {
        db = Object.assign(Object.assign({}, db), { [area]: Object.assign(Object.assign({}, (db[area] || {})), { [name]: bulkUpdate((db[area] || {})[name] || [], models) }) });
    });
}
export function login(email, password) {
    return __awaiter(this, void 0, void 0, function* () {
        if (email !== 'olya@test.com' || password !== '12341234') {
            throw new Error(`unable to authenticate user with email "${email}"`);
        }
        return { areas: [AREAS.map(({ name }) => name)] };
    });
}
export function loginSSO(provider, queryString) {
    return __awaiter(this, void 0, void 0, function* () {
        return { areas: [AREAS.map(({ name }) => name)] };
    });
}
export function fetchExternalAuthenticationProviders() {
    return __awaiter(this, void 0, void 0, function* () {
        return [
            { name: 'Azure', loginUrl: '/oauth2/authorization/azure' },
            { name: 'Google', loginUrl: '/oauth2/authorization/google' },
        ];
    });
}
export function externalAuthLoginUrl(path) {
    return path;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function upload(area, file) {
    return __awaiter(this, void 0, void 0, function* () {
        return `/${area}/${cuid()}.png`;
    });
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function promote(source, target) {
    return __awaiter(this, void 0, void 0, function* () { });
}
export function validate() {
    return __awaiter(this, void 0, void 0, function* () {
        return { areas: [AREAS.map(({ name }) => name)] };
    });
}
export function productAttributes() {
    return __awaiter(this, void 0, void 0, function* () {
        return STORE_COLLECTIONS;
    });
}
export function productAttributeValues() {
    return __awaiter(this, void 0, void 0, function* () {
        return STORE_COLLECTIONS;
    });
}
export function collections() {
    return __awaiter(this, void 0, void 0, function* () {
        return COLLECTIONS;
    });
}
export function adminCollections() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function grove() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function searchPreview() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function getArea(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(areas, id);
    });
}
export function findAreas() {
    return __awaiter(this, void 0, void 0, function* () {
        return areas;
    });
}
export function createArea(area) {
    return __awaiter(this, void 0, void 0, function* () {
        areas = create(areas, area);
        return areas[areas.length - 1];
    });
}
export function updateArea(id, area) {
    return __awaiter(this, void 0, void 0, function* () {
        areas = update(areas, id, area);
        return area;
    });
}
export function removeArea(id) {
    return __awaiter(this, void 0, void 0, function* () {
        areas = remove(areas, id);
    });
}
export function bulkUpdateArea(updates) {
    return __awaiter(this, void 0, void 0, function* () {
        areas = bulkUpdate(areas, updates);
    });
}
export function getUser(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(users, id);
    });
}
export function findUsers() {
    return __awaiter(this, void 0, void 0, function* () {
        return users;
    });
}
export function createUser(user) {
    return __awaiter(this, void 0, void 0, function* () {
        users = create(users, user);
        return users[users.length - 1];
    });
}
export function updateUser(id, user) {
    return __awaiter(this, void 0, void 0, function* () {
        users = update(users, id, user);
        return user;
    });
}
export function removeUser(id) {
    return __awaiter(this, void 0, void 0, function* () {
        users = remove(users, id);
    });
}
export function bulkUpdateUser(updates) {
    return __awaiter(this, void 0, void 0, function* () {
        users = bulkUpdate(users, updates);
    });
}
export function getKeys() {
    return __awaiter(this, void 0, void 0, function* () {
        return [customer.activeKey1, ...(customer.activeKey2 ? [customer.activeKey2] : [])];
    });
}
export function addPrimaryKey() {
    return __awaiter(this, void 0, void 0, function* () {
        customer = Object.assign(Object.assign({}, customer), { activeKey1: cuid(), activeKey2: customer.activeKey1 });
    });
}
export function removeSecondaryKey() {
    return __awaiter(this, void 0, void 0, function* () {
        if (customer.activeKey2) {
            customer = omit(['activeKey2'], customer);
        }
    });
}
export function changeLogStatus() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function changeLog() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function topRefinements() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function records() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function recordsTimeseries() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function responseTime() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function clickthroughs() {
    return __awaiter(this, void 0, void 0, function* () {
        return CLICKTHROUGHS;
    });
}
export function traffic() {
    return __awaiter(this, void 0, void 0, function* () {
        return TRAFFIC;
    });
}
export function eventHealthcheck() {
    return __awaiter(this, void 0, void 0, function* () {
        return HEALTHCHECK;
    });
}
export function runQuickcheck() {
    return __awaiter(this, void 0, void 0, function* () {
        return QUICKCHECK;
    });
}
export function statusQuickcheck() {
    return __awaiter(this, void 0, void 0, function* () {
        return QUICKCHECK;
    });
}
export function services() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function timeseries() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function performance() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function nulls() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function products() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function navigations() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function glancerDashboards() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function glancerEmbedUrl(area, path) {
    return __awaiter(this, void 0, void 0, function* () {
        return `data:text/html,${encodeURIComponent(`<b>Area</b>: ${area}<br><b>Path</b>: ${path}`)}`;
    });
}
export function clientProductsReports() {
    return __awaiter(this, void 0, void 0, function* () {
        return CLIENT_PRODUCT_REPORTS;
    });
}
export function customersContract() {
    return __awaiter(this, void 0, void 0, function* () {
        return CUSTOMER_INFO;
    });
}
export function enrichTokens() {
    return __awaiter(this, void 0, void 0, function* () {
        return {};
    });
}
export function auth() {
    return {
        enrichTokens,
        models: models(),
        area: area(),
        user: user(),
        upload: wrapResult(upload),
        promote: wrapResult(promote),
        validate: wrapResult(validate),
        productAttributes: wrapResult(productAttributes),
        productAttributeValues: wrapResult(productAttributeValues),
        collections: wrapResult(collections),
        grove: wrapResult(grove),
        searchPreview: wrapResult(searchPreview),
        changeLog: wrapResult(changeLog),
        changeLogStatus: wrapResult(changeLogStatus),
        analyticsV2: {
            services,
            timeseries,
            records,
            recordsTimeseries,
            responseTime,
            performance,
            nulls,
            navigations,
            products,
            clickthroughs: wrapResult(clickthroughs),
            traffic: wrapResult(traffic),
            healthcheck: wrapResult(eventHealthcheck),
            runQuickcheck: wrapResult(runQuickcheck),
            statusQuickcheck: wrapResult(statusQuickcheck),
        },
        glancerAnalytics: {
            dashboards: glancerDashboards,
            embedUrl: glancerEmbedUrl,
        },
        key: {
            addPrimary: wrapResult(addPrimaryKey),
            removeSecondary: wrapResult(removeSecondaryKey),
            get: wrapResult(getKeys),
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        analytics: (clientKey) => ({
            topRefinements,
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        admin: (clientKey) => ({
            collections: wrapResult(adminCollections),
        }),
    };
}
export function enrichAuth() {
    return {
        clientProductsReports,
        customersContract,
    };
}
export function ccEnrichAuth(token) {
    return {
        url: ENRICH_API_PREFIX,
        token,
    };
}
export default {
    auth,
    enrichAuth,
    login: wrapResult(login),
    loginSSO: wrapResult(loginSSO),
    validatePassword: (_password) => __awaiter(void 0, void 0, void 0, function* () { return ({ result: { requirements: [] } }); }),
    ccEnrichAuth,
    fetchExternalAuthenticationProviders,
    externalAuthLoginUrl,
};
/* eslint-enable @typescript-eslint/require-await */
export function get(models, id) {
    return models.find(({ id: modelId }) => modelId === id);
}
export function create(models, model) {
    const newModel = Object.assign(Object.assign({}, model), { id: cuid() });
    return [...models, newModel];
}
export function update(models, id, model) {
    const index = models.findIndex(({ id: modelId }) => modelId === id);
    return [...models.slice(0, index), model, ...models.slice(index + 1)];
}
export function bulkUpdate(models, updates) {
    return models.map((model) => {
        const updated = updates.find(({ id }) => id === model.id);
        return updated ? Object.assign(Object.assign({}, model), updated) : model;
    });
}
export function patch(models, id, updateFields) {
    const index = models.findIndex(({ id: modelId }) => modelId === id);
    const modelsClone = [...models];
    modelsClone[index] = Object.assign(Object.assign({}, modelsClone[index]), updateFields);
    return modelsClone;
}
export function remove(models, id) {
    return models.filter(({ id: modelId }) => modelId !== id);
}
export function bulkLockStatus() { }
export function lockStatus() { }
export function lock() { }
export function unlock() { }
export function wrapResult(callback, statusCode = 200) {
    return (...args) => callback(...args).then((result) => ({
        result,
        statusCode,
        headers: {},
        token: TOKEN,
    }));
}
