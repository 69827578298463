import { call, fork, takeEvery } from 'redux-saga/effects';
import { PROMOTE_AREA } from '@/store/actions/types';
import * as Tasks from './tasks';
export function* promoteArea({ payload: { source, target } }) {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        yield call(Tasks.authCall, client.promote, source, target);
        yield Tasks.success(`Promoted from "${source}" to "${target}".`);
    }
    catch (e) {
        yield Tasks.error(`Failed to promote from "${source}" to "${target}".`, e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export default function* promotionSaga() {
    yield takeEvery(PROMOTE_AREA, promoteArea);
}
