import { __rest } from "tslib";
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = {
    root: {
        boxShadow: 'none',
        whiteSpace: 'nowrap',
    },
};
const ToggleButtons = withStyles(styles)(function ToggleButtons({ buttonsList, className, classes, }) {
    return (<ToggleButtonGroup classes={{ root: classNames(classes.root, className) }}>
      {buttonsList.map((_a, index) => {
        var { content } = _a, otherProps = __rest(_a, ["content"]);
        return (<ToggleButton disableRipple={true} key={index} {...otherProps}>
            {content}
          </ToggleButton>);
    })}
    </ToggleButtonGroup>);
});
export default ToggleButtons;
