import React from 'react';
import { push } from 'connected-react-router';
import { Routing } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import Route from '../route';
export const selectors = {
    activeRoute: Selectors.activeRouteSelector,
};
export const actionCreators = {
    goToHomeProxy: ActionCreators.proxyAction,
};
export const mergeProps = (_, { goToHomeProxy }) => ({
    goToHome: () => goToHomeProxy(push(Routing.MERCHANDISING_PATH)),
});
const HomeRoute = connect(selectors, actionCreators, mergeProps)(function HomeRoute({ activeRoute, goToHome }) {
    return <Route label="Home" onClick={goToHome} url={Routing.MERCHANDISING_PATH} active={activeRoute === Routing.MERCHANDISING_PATH}/>;
});
export default HomeRoute;
