import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var Phrase;
(function (Phrase) {
    Phrase.DEFAULT = { promote: true };
    Phrase.CONFIG = {
        type: ModelType.PHRASE,
        defaults: Phrase.DEFAULT,
        filter: filterByNameAndId,
        promotable: true,
    };
})(Phrase || (Phrase = {}));
export default Phrase;
