import * as ReduxForm from 'redux-form';
import { createSelector } from 'reselect';
import * as AuthSelectors from './auth';
import * as RouterSelectors from './router';
import * as SessionSelectors from './session';
export * from './analytics';
export * from './auth';
export * from './cache';
export * from './change-log';
export * from './collections';
export * from './enrich';
export * from './glancer-analytics';
export * from './merchandising';
export * from './router';
export * from './session';
export * from './ui';
export * from './composite';
export * from './query-param';
export const availableAreas = createSelector(SessionSelectors.activeAreaSelector, AuthSelectors.allowedAreasSelector, (activeArea, areas) => areas.filter((area) => area !== activeArea));
export const formDefaultsSelector = createSelector(RouterSelectors.locationStateSelector, ({ defaultValues } = {}) => defaultValues || {});
export const formActionSelector = createSelector(RouterSelectors.locationStateSelector, ({ action }) => action);
export function searchPreviewSelector({ searchPreview }) {
    return searchPreview;
}
export function copyToAreaSelector({ copyToArea }) {
    return copyToArea;
}
export const formValuesSelector = (state) => (formName) => ReduxForm.getFormValues(formName)(state);
export const isFormValidSelector = (state) => (formName) => ReduxForm.isValid(formName)(state);
export const formInitialValuesSelector = (state) => (formName) => ReduxForm.getFormInitialValues(formName)(state);
