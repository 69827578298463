import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { compose } from 'ramda';
import IconButton from '@/components/controls/icon-button';
import { disabling } from '@/components/disabling';
import { withStyles, } from '@/decorators';
export const styles = ({ palette }) => ({
    paper: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        '&$disabled': {
            color: palette.disabledFont,
        },
    },
    display: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    disabled: {},
});
export const enhance = compose(withStyles(styles), disabling);
const FormManagerDisplay = enhance(function FormManagerDisplay({ onEdit, onRemove, disabled, classes, children, }) {
    return (<div className={classNames(classes.paper, { [classes.disabled]: disabled })}>
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>
      <div className={classes.display}>{children}</div>
      <IconButton onClick={onRemove}>
        <DeleteIcon />
      </IconButton>
    </div>);
});
export default FormManagerDisplay;
