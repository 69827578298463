import React from 'react';
import AreaSwitcher from '@/components/drawer/area-switcher';
import * as Page from '@/components/page';
import * as Selectors from '@/store/selectors';
import { connect, denormalize } from '@/utils';
import RouteList from '../route-list';
import RouteSection from '../route-section';
import RouteLink from '../analytics/link';
import { Routing } from '@/constants';
import { GLANCER_ANALYTICS_ROUTES } from '@/routes/drawer';
export const selectors = {
    dashboards: Selectors.glancerDashboardsSelector,
};
const GlancerAnalyticsDrawer = connect(selectors)(function GlancerAnalyticsDrawer({ dashboards }) {
    const dashboardsSection = {
        label: 'Dashboards',
        path: Routing.Section.GlancerAnalytics.DASHBOARDS,
        routes: denormalize(dashboards).map(({ title, id }) => ({ label: title, path: id })),
    };
    return (<React.Fragment>
      <Page.Wrapper>
        <Page.Header><AreaSwitcher /></Page.Header>
      </Page.Wrapper>
      <RouteList>
        <RouteSection path={dashboardsSection.path} section={dashboardsSection} component={RouteLink} key={dashboardsSection.path}/>
        {GLANCER_ANALYTICS_ROUTES.sections.map((section) => (<RouteSection path={GLANCER_ANALYTICS_ROUTES.path} section={section} component={RouteLink} key={section.path}/>))}
      </RouteList>
    </React.Fragment>);
});
export default GlancerAnalyticsDrawer;
