import { __rest } from "tslib";
import { call, select } from 'redux-saga/effects';
// import Zone from '@/merchandising/models/zone'
import * as Tasks from '@/store/sagas/tasks';
import * as Selectors from '@/store/selectors';
export const TEMPLATE_IMAGE_FIELDS = ['image', 'thumbnailImage'];
export function* uploadImage(file) {
    try {
        const area = yield select(Selectors.activeAreaSelector);
        const client = yield call(Tasks.authClient);
        return yield call(Tasks.authCall, client.upload, area, file);
    }
    catch (e) {
        yield Tasks.simpleError('Upload Image');
    }
}
export default {
    *user(user) {
        const { customerId } = yield select(Selectors.activeUserSelector);
        return Object.assign(Object.assign({}, user), { customerId });
    },
    *template(_a) {
        var template = __rest(_a, []);
        for (const key of TEMPLATE_IMAGE_FIELDS) {
            if (template[key] instanceof File) {
                template[key] = yield uploadImage(template[key]);
            }
        }
        return template;
    },
};
