import React from 'react';
import { modelManagerRoute } from '@/routes';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import RouteLink from '../route-link';
export const selectors = {
    activeArea: Selectors.activeAreaSelector,
};
export const actionCreators = {
    goToManager: ActionCreators.proxyAction,
};
export const mergeProps = (_, { goToManager }, { route }) => ({
    goToManager: () => goToManager(ActionCreators.goToManager(route.name)),
});
const MerchandisingRoute = connect(selectors, actionCreators, mergeProps)(function MerchandisingRoute({ activeArea, goToManager, route }) {
    const path = modelManagerRoute(route.name, activeArea);
    return <RouteLink label={route.label} path={path} onClick={goToManager}/>;
});
export default MerchandisingRoute;
