import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var BiasingProfile;
(function (BiasingProfile) {
    let Bias;
    (function (Bias) {
        let Strength;
        (function (Strength) {
            Strength.ABSOLUTE_INCREASE = 'Absolute_Increase';
            Strength.STRONG_INCREASE = 'Strong_Increase';
            Strength.MEDIUM_INCREASE = 'Medium_Increase';
            Strength.WEAK_INCREASE = 'Weak_Increase';
            Strength.LEAVE_UNCHANGED = 'Leave_Unchanged';
            Strength.WEAK_DECREASE = 'Weak_Decrease';
            Strength.MEDIUM_DECREASE = 'Medium_Decrease';
            Strength.STRONG_DECREASE = 'Strong_Decrease';
            Strength.ABSOLUTE_DECREASE = 'Absolute_Decrease';
        })(Strength = Bias.Strength || (Bias.Strength = {}));
        Bias.STRENGTHS = [
            { value: Strength.ABSOLUTE_DECREASE, label: 'Absolute Decrease' },
            { value: Strength.STRONG_DECREASE, label: 'Strong Decrease' },
            { value: Strength.MEDIUM_DECREASE, label: 'Medium Decrease' },
            { value: Strength.WEAK_DECREASE, label: 'Weak Decrease' },
            { value: Strength.LEAVE_UNCHANGED, label: 'Leave Unchanged' },
            { value: Strength.WEAK_INCREASE, label: 'Weak Increase' },
            { value: Strength.MEDIUM_INCREASE, label: 'Medium Increase' },
            { value: Strength.STRONG_INCREASE, label: 'Strong Increase' },
            { value: Strength.ABSOLUTE_INCREASE, label: 'Absolute Increase' },
        ];
        Bias.DEFAULT = { strength: Strength.LEAVE_UNCHANGED };
    })(Bias = BiasingProfile.Bias || (BiasingProfile.Bias = {}));
    let NumericBoost;
    (function (NumericBoost) {
        NumericBoost.DEFAULT = { weight: 1 };
    })(NumericBoost = BiasingProfile.NumericBoost || (BiasingProfile.NumericBoost = {}));
    BiasingProfile.DEFAULT = {
        influence: 5,
        matchMultiple: true,
        biases: [],
        numericBoosts: [],
    };
    BiasingProfile.CONFIG = {
        type: ModelType.BIASING_PROFILE,
        defaults: BiasingProfile.DEFAULT,
        filter: filterByNameAndId,
    };
})(BiasingProfile || (BiasingProfile = {}));
export default BiasingProfile;
