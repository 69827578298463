import * as qs from 'qs';
import { Routing } from '@/constants';
import { buildPaginationPageSettingsShape, getPageNumber, getPaginationSettings, transformPaginationToPageSettings, } from '@/enrich/routing/utils/pagination';
import { getUpdatedQueryString, getFreshQueryString } from '@/enrich/routing/utils/query-string-gen';
class RuleLibraryPage {
    constructor() {
        this.getFreshQueryString = getFreshQueryString.bind(this);
        this.getUpdatedQueryString = getUpdatedQueryString.bind(this);
    }
    getMergedPageSettings({ overrides = {}, pageSettings: currentPageSettings, queryString = '', }) {
        const parsed = qs.parse(queryString);
        const urlPageSettings = this.transformToPageSettings(parsed);
        const defaults = Routing.Page.Enrich.DEFAULT_PAGE_SETTINGS.ruleLibrary;
        const newSettings = Object.keys(defaults).reduce((newSettings, key) => {
            var _a, _b, _c;
            if (key === 'pagination') {
                const { pageSize, offset } = getPaginationSettings({
                    overrides: { pageSize: overrides.pageSize, offset: overrides.offset },
                    urlPageSettings: urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.pagination,
                    pageSettings: currentPageSettings === null || currentPageSettings === void 0 ? void 0 : currentPageSettings.pagination,
                    defaults: defaults.pagination,
                });
                newSettings.pageSize = pageSize;
                newSettings.offset = offset;
            }
            else {
                newSettings[key] = (_c = (_b = (_a = overrides[key]) !== null && _a !== void 0 ? _a : urlPageSettings[key]) !== null && _b !== void 0 ? _b : currentPageSettings[key]) !== null && _c !== void 0 ? _c : defaults[key];
            }
            return newSettings;
        }, {});
        return this.buildPageSettingShape(newSettings);
    }
    transformToPageSettings(queryStringParams) {
        const { page, pageSize, q, } = queryStringParams;
        const pagination = transformPaginationToPageSettings({ page, pageSize });
        return this.buildPageSettingShape(Object.assign(Object.assign({}, pagination), { query: q }));
    }
    buildPageSettingShape(pageSettingsHash) {
        const { offset, pageSize, query } = pageSettingsHash;
        const pagination = buildPaginationPageSettingsShape({ pageSize, offset });
        return Object.assign(Object.assign({}, pagination), { query });
    }
    transformToUrlSettings(pageSettings) {
        const { pagination: { pageSize, offset }, query: q } = pageSettings;
        const page = getPageNumber(offset, pageSize);
        return {
            page,
            pageSize,
            q,
        };
    }
}
export default RuleLibraryPage;
