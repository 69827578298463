import React from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialModal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Button from '@/components/controls/button';
import LabeledInline from '@/components/labeled/inline';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, breakpoints, fontSize }) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 0,
        [breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    container: {
        height: `calc(100% -  ${spacing(8.5)}px)`,
        padding: `${spacing(2)}px ${spacing(2)}px 0 ${spacing(2)}px`,
    },
    content: {
        maxHeight: '50vh',
        height: '100%',
        padding: `0 ${spacing(1)}px ${spacing(1)}px ${spacing(1)}px`,
        overflowY: 'scroll',
        '&$withTitle': {
            height: `calc(100% -  ${spacing(2.75)}px)`,
        },
    },
    title: {
        fontSize: fontSize.itemSubHeader,
        fontWeight: 500,
        alignSelf: 'flex-start',
    },
    buttonContainer: {
        padding: spacing(2),
        alignSelf: 'flex-end',
        borderTop: '1px solid #eeeeee',
        '&>:first-child': {
            marginRight: spacing(2),
        },
    },
    tooltip: {
        maxWidth: spacing(55),
    },
    withTitle: {},
});
const Modal = withStyles(styles)(function Modal({ open, onClose, onCancel, onAccept, paperClassName, containerClassName, titleClassName, contentClassName, acceptLabel, titleLabel, tooltip, classes, children, showFooter = true, }) {
    return (<MaterialModal disableAutoFocus open={open} onClose={onClose}>
      <Paper className={classNames(classes.paper, paperClassName)} elevation={2}>
        <Grid container className={classNames(classes.container, containerClassName)}>
          {titleLabel && (<Grid item xs={12} className={classNames(classes.title, titleClassName)}>
              <LabeledInline label={titleLabel} tooltip={tooltip} tooltipClassName={classes.tooltip}/>
            </Grid>)}
          <Grid item xs={12} className={classNames(classes.content, { [classes.withTitle]: titleLabel }, contentClassName)}>
            {children}
          </Grid>
        </Grid>
        {showFooter && (<Grid item container justify="flex-end" xs={12} className={classes.buttonContainer}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onAccept} variant="primary">
              {acceptLabel || 'Save'}
            </Button>
          </Grid>)}
      </Paper>
    </MaterialModal>);
});
export default Modal;
