import React from 'react';
import Body from '@/analytics/dashboards/search-term/insights/products/body';
import Head from '@/analytics/dashboards/search-term/insights/products/head';
import NoDataError from '@/components/no-data-error';
import ReportTable from '@/components/reports/table-v2';
import { Align, Type } from '@/components/reports/table-v2/types';
import { commaSeparateNumber } from '@/components/reports/table-v2/utils';
import State from '@/store/state';
var ReportKey = State.Analytics.ResultKey;
export const COLUMNS = [
    {
        id: ReportKey.PRODUCT,
        label: 'ID',
        type: Type.MEDIUM,
        align: null,
        formatter: null,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.TITLE,
        label: 'Title',
        type: Type.LARGE,
        align: null,
        formatter: null,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.AVG_SEARCH_POSITION,
        label: 'Click Position',
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        type: Type.SMALL,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.VIEW_PRODUCTS,
        label: 'Clicks',
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        type: Type.SMALL,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.ADD_TO_CARTS,
        label: 'Add To Carts',
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        type: Type.SMALL,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.ORDERS,
        label: 'Orders',
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        type: Type.SMALL,
        tooltip: null,
        csvLabel: null,
    },
];
const Products = function Products({ data, defaultSort, filterSize }) {
    return data.length ? (<ReportTable body={Body} columns={COLUMNS} data={data} defaultSort={defaultSort} filterSize={filterSize} head={Head}/>) : (<NoDataError />);
};
export default Products;
