import React from 'react';
import PageHeader from '@/components/page/header';
import { withStyles } from '@/decorators';
export const styles = ({ pageHeader, breakpoints, appHeader }) => ({
    root: Object.assign(Object.assign({}, pageHeader), { [breakpoints.up('md')]: {
            top: appHeader.height,
        }, [breakpoints.down('sm')]: {
            top: appHeader.height * 2,
        } }),
});
const StaticPageHeader = withStyles(styles)(function StaticPageHeader({ classes, children }) {
    return <PageHeader className={classes.root}>{children}</PageHeader>;
});
export default StaticPageHeader;
