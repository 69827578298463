import { __decorate } from "tslib";
import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import ConfigButton from 'mdi-react/SettingsIcon';
import IconButton from '@/components/controls/icon-button';
import { ModelType } from '@/constants';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect, getModelType, withFormName, } from '@/utils';
import ConfigMenu from './config-menu';
import SearchQuery from './query';
import QueryToggle from './query-toggle';
import { initializeConfig, isConfigInitialized } from './utils';
export const styles = ({ spacing }) => ({
    icon: {
        marginRight: spacing(1),
    },
});
export const selectors = {
    searchPreview: Selectors.searchPreviewSelector,
    area: Selectors.activeAreaSelector,
};
export const actionCreators = {
    loadProducts: ActionCreators.loadSearchPreviewProducts,
    loadPushToTopProducts: ActionCreators.loadPushToTopProducts,
    updateQuery: ActionCreators.updateSearchPreviewQuery,
    resetQuery: ActionCreators.resetSearchPreviewQuery,
    updateQueryType: ActionCreators.updateSearchPreviewQueryType,
};
export const mergeProps = (_, { loadPushToTopProducts }, { formName }) => ({
    loadPushToTopProducts: () => formName && loadPushToTopProducts(formName),
});
let VisualSearch = class VisualSearch extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { configMenuOpen: false };
        this.configButtonRef = React.createRef();
        this.onClick = () => this.setState({ configMenuOpen: !this.state.configMenuOpen });
        this.onClose = () => {
            this.setState({ configMenuOpen: false });
            initializeConfig(this.props.area);
        };
        this.onQueryToggleClick = (value) => {
            const queryIsEmpty = this.props.searchPreview && this.props.searchPreview.query === '';
            this.props.updateQueryType(value);
            if (!queryIsEmpty) {
                this.props.resetQuery();
                this.props.loadProducts();
            }
        };
        this.onConfigMenuChange = () => {
            const { modelType, loadProducts, loadPushToTopProducts } = this.props;
            if (modelType === ModelType.RULE) {
                loadPushToTopProducts();
            }
            loadProducts();
        };
    }
    render() {
        const { controlsClassName, searchLimitError, placeholder, classes, children, searchPreview, area, enableQueryTypeToggle = false, } = this.props;
        const { configMenuOpen } = this.state;
        return (<React.Fragment>
        <Grid container item justify="space-between" alignItems="center" className={controlsClassName}>
          <Grid item xs={11}>
            {searchLimitError ? (<Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  <ErrorIcon color="error"/>
                </Grid>
                <Grid item xs={11}>
                  <Typography color="error" variant="caption">
                    {searchLimitError}
                  </Typography>
                </Grid>
              </Grid>) : (<Grid container>
                <Grid item xs={enableQueryTypeToggle ? 7 : 12} lg={enableQueryTypeToggle ? 9 : 12}>
                  <SearchQuery updateQuery={this.props.updateQuery} queryValue={searchPreview.query} placeholder={placeholder}/>
                </Grid>
                {enableQueryTypeToggle && (<Grid item xs={5} lg={3}>
                    <QueryToggle queryType={searchPreview.queryType} onSwitchQuery={this.onQueryToggleClick}/>
                  </Grid>)}
              </Grid>)}
          </Grid>
          <Grid container item xs={1} justify="flex-end" alignItems="center">
            <IconButton onClick={this.onClick} buttonRef={this.configButtonRef}>
              <ConfigButton />
            </IconButton>
            <ConfigMenu area={area} open={configMenuOpen} onClose={this.onClose} rootEl={this.configButtonRef.current} onChange={this.onConfigMenuChange}/>
          </Grid>
        </Grid>
        {children}
      </React.Fragment>);
    }
    componentDidMount() {
        if (isConfigInitialized(this.props.area)) {
            this.props.loadProducts();
        }
        else {
            this.setState({ configMenuOpen: true });
        }
    }
    componentWillUnmount() {
        this.props.resetQuery();
        this.props.updateQueryType(State.SearchPreview.QueryType.QUERY);
    }
};
VisualSearch = __decorate([
    withFormName,
    getModelType,
    connect(selectors, actionCreators, mergeProps),
    (withStyles(styles))
], VisualSearch);
export default VisualSearch;
