import { __decorate } from "tslib";
import React from 'react';
import FormSection from '@/components/form/section';
import LabeledSection from '@/components/labeled/section';
import Link from '@/components/link';
import { responsive } from '@/components/responsive';
import { StringNaturalCompare } from '@/utils';
import CheckBox from './checkbox';
let CheckBoxSet = class CheckBoxSet extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onChange = (index) => (checked) => {
            const { selected, onChange } = this.props;
            const { value } = this.options[index];
            if (checked) {
                onChange([...selected, value]);
            }
            else {
                onChange(selected.filter((option) => option !== value));
            }
        };
        this.selectAll = () => this.props.onChange(this.selectedAll ? [] : this.options.map(({ value }) => value));
    }
    get selectedAll() {
        return this.props.options.length === this.props.selected.length;
    }
    get options() {
        const expanded = expandOptions(this.props.options);
        return (this.props.sort ? sorted(expanded) : expanded);
    }
    render() {
        const { selected, toggleAll, label, tooltip, column, checkBoxProps, labelClassName, formSectionClassName, className, } = this.props;
        const gridSizing = column
            ? { xs: 12 }
            : {
                xs: 12,
                md: 3,
                sm: 4,
            };
        return (<LabeledSection label={label} labelDecorator={toggleAll && <Link onClick={this.selectAll}>{this.selectedAll ? 'Deselect All' : 'Select All'}</Link>} labelClassName={labelClassName} tooltip={tooltip}>
        <FormSection column={column} className={formSectionClassName}>
          {this.options.map(({ value, label }, index) => (<CheckBox checked={selected.includes(value)} onChange={this.onChange(index)} description={label} {...gridSizing} {...checkBoxProps} key={value} className={className}/>))}
        </FormSection>
      </LabeledSection>);
    }
};
CheckBoxSet = __decorate([
    responsive
], CheckBoxSet);
export default CheckBoxSet;
export function expandOptions(options) {
    return options.map((option) => (typeof option === 'string' ? { value: option, label: option } : option));
}
export function sorted(options) {
    return options.sort(customComparator);
}
export function customComparator(lhs, rhs) {
    return StringNaturalCompare.caseInsensitive(lhs.value, rhs.value);
}
