import { call, fork, takeEvery } from 'redux-saga/effects';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Tasks from './tasks';
export function* addPrimaryKey() {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        yield call(Tasks.authCall, client.key.addPrimary);
        yield call(Tasks.loadKeys, ActionCreators.loadKeys(true));
        yield Tasks.simpleSuccess('create primary key');
    }
    catch (e) {
        yield Tasks.simpleError('create primary key', e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* removeSecondaryKey() {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        yield call(Tasks.authCall, client.key.removeSecondary);
        yield call(Tasks.loadKeys, ActionCreators.loadKeys(true));
        yield Tasks.simpleSuccess('remove secondary key');
    }
    catch (e) {
        yield Tasks.simpleError('remove secondary key', e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export default function* customerSaga() {
    yield takeEvery(Actions.LOAD_KEYS, Tasks.loadKeys);
    yield takeEvery(Actions.ADD_PRIMARY_KEY, addPrimaryKey);
    yield takeEvery(Actions.REMOVE_SECONDARY_KEY, removeSecondaryKey);
}
