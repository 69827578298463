import { ModelType } from '@/constants';
import { safeIncludes } from '@/utils';
var Redirect;
(function (Redirect) {
    Redirect.DEFAULT = { global: true, promote: true };
    Redirect.CONFIG = {
        type: ModelType.REDIRECT,
        defaults: Redirect.DEFAULT,
        filter: filterPredicate,
        promotable: true,
    };
    // eslint-disable-next-line no-inner-declarations
    function filterPredicate(filter) {
        return ({ id, name, location, global: isGlobal, }) => id.includes(filter)
            || safeIncludes(name, filter)
            || safeIncludes(location, filter)
            || (isGlobal && safeIncludes('global', filter));
    }
    Redirect.filterPredicate = filterPredicate;
})(Redirect || (Redirect = {}));
export default Redirect;
