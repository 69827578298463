import React from 'react';
import ReportTable from '@/components/reports/table-v2';
import { Align, Type } from '@/components/reports/table-v2/types';
import { commaSeparateNumber } from '@/components/reports/table-v2/utils';
import * as ActionCreators from '@/store/actions/creators';
import Body from './body';
import Head from './head';
export const COLUMNS = [
    {
        id: 'rowNum',
        label: 'Rank',
        type: Type.SMALL,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'Rank',
        csvLabel: null,
    },
    {
        id: 'term',
        label: 'Term',
        csvLabel: 'Term',
        type: Type.LARGE,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'Term',
    },
    {
        id: 'uniqueSearches',
        label: '#',
        csvLabel: 'Unique Searches (#)',
        type: Type.SMALL,
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        tooltip: 'Unique Searches',
    },
];
export const actionCreators = {
    createModel: ActionCreators.goToCreate,
};
const TopNulls = function TopNulls({ data, defaultSort, filterSize }) {
    return (<ReportTable head={Head} body={Body} columns={COLUMNS} data={data} defaultSort={defaultSort} filterSize={filterSize}/>);
};
export default TopNulls;
