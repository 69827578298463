import React from 'react';
import { compose } from 'ramda';
import { FieldArray } from 'redux-form';
import { labeledSection } from '@/components/labeled';
import { responsive } from '@/components/responsive';
import FormSection from '../section';
import ConnectedManager from './connected';
export const enhance = compose(responsive, labeledSection);
export const FormManager = enhance(function FormManager({ name, reorderable, transform, table, columns, sort, limit, addOnComponent, defaults = null, formComponent: FormComponent, displayComponent: DisplayComponent, rerenderOnEveryChange, }) {
    return (<React.Fragment>
      {addOnComponent}
      <FieldArray name={name} component={ConnectedManager} rerenderOnEveryChange={rerenderOnEveryChange} props={{
        name,
        table,
        columns,
        sort,
        limit,
        transform,
        reorderable,
        defaultValues: defaults,
        formComponent: FormComponent,
        displayComponent: DisplayComponent,
    }}/>
    </React.Fragment>);
});
export default function createFormManager({ form, display, reorderable, transform, table, columns, addOnComponent, sort, limit, rerenderOnEveryChange, }) {
    return function WrappedFormManager(props) {
        return (<FormSection>
        <FormManager formComponent={form} displayComponent={display} reorderable={reorderable} table={table} columns={columns} transform={transform} sort={sort} limit={limit} rerenderOnEveryChange={rerenderOnEveryChange} addOnComponent={addOnComponent} {...props}/>
      </FormSection>);
    };
}
