import * as Actions from '../types/collections';
import { createAction } from '../utils';
export const fetchProductAttributes = () => createAction(Actions.FETCH_PRODUCT_ATTRIBUTES);
export const enableFetchAutocomplete = () => createAction(Actions.ENABLE_FETCH_AUTOCOMPLETE);
export const disableFetchAutocomplete = () => createAction(Actions.DISABLE_FETCH_AUTOCOMPLETE);
export const fetchProductAttributeValues = (field) => createAction(Actions.FETCH_PRODUCT_ATTRIBUTE_VALUES, field);
export const updateProductAttributeValues = (field, values) => createAction(Actions.REFRESH_PRODUCT_ATTRIBUTE_VALUES, { field, values });
export const fetchCollections = () => createAction(Actions.FETCH_COLLECTIONS);
export function refreshProductAttributes(productAttributes) {
    return createAction(Actions.REFRESH_PRODUCT_ATTRIBUTES, productAttributes);
}
export function refreshCollections(collections) {
    return createAction(Actions.REFRESH_COLLECTIONS, collections);
}
