import { Routing } from '@/constants';
import JobPage from '@/enrich/routing/page-types/job';
import ProductPage from '@/enrich/routing/page-types/product';
import RuleLibraryPage from '@/enrich/routing/page-types/rule-library';
import ValidationPage from '@/enrich/routing/page-types/validation';
const pathMap = Routing.Page.Enrich.PATH_TO_PAGE;
export function getPageNameFromPath(path) {
    for (const pagePath of Object.keys(pathMap)) {
        if (path === pagePath || path.startsWith(`${pagePath}/`)) {
            return pathMap[pagePath];
        }
    }
    return null;
}
export function updateQueryParams(path, queryString, browserState) {
    const newPath = `${path}?${queryString}`;
    window.history.replaceState(browserState, window.document.title, newPath);
}
export function onEnrichPath(pathname) {
    return pathname === Routing.ENRICH_PATH || pathname.startsWith(`${Routing.ENRICH_PATH}/`);
}
export const pageTypeProcessingClasses = {
    product: new ProductPage(),
    job: new JobPage(),
    validation: new ValidationPage(),
    ruleLibrary: new RuleLibraryPage(),
};
