import { __rest } from "tslib";
import moize from 'moize';
import { compose } from 'ramda';
import * as ReactDND from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { withStyles } from '@/decorators';
import * as DragEnhancer from './enhancer';
export const styles = ({ palette, spacing }) => ({
    root: {
        cursor: 'move',
    },
    hoverTop: {
        borderTop: `${spacing(1 / 2)}px solid ${palette.highlight}`,
    },
    hoverBottom: {
        borderBottom: `${spacing(1 / 2)}px solid ${palette.highlight}`,
    },
    hoverLeft: {
        borderLeft: `${spacing(1 / 2)}px solid ${palette.highlight}`,
        paddingLeft: spacing(1 / 2),
    },
    hoverRight: {
        borderRight: `${spacing(1 / 2)}px solid ${palette.highlight}`,
        paddingRight: spacing(1 / 2),
    },
});
export const dragSource = {
    beginDrag(_a) {
        var { component: _component, classes: _classes } = _a, props = __rest(_a, ["component", "classes"]);
        return Object.assign({}, props);
    },
};
export const calculateTargetIndex = (dropIndex, dragIndex, pastMiddle) => {
    if (dropIndex > dragIndex && !pastMiddle) {
        return dropIndex - 1;
    }
    if (dropIndex < dragIndex && pastMiddle) {
        return dropIndex + 1;
    }
    return dropIndex;
};
export const createDropTarget = moize((calculateDimensions, updateDragItem) => ({
    hover(_, monitor, component) {
        if (monitor.canDrop()) {
            const { offset, middle } = calculateDimensions(monitor, component);
            updateDragItem(component, offset > middle);
        }
    },
    drop({ index: dropIndex }, monitor, component) {
        const { index: dragIndex, reorder } = monitor.getItem();
        const { offset, middle } = calculateDimensions(monitor, component);
        const realOffset = Math.max(0, offset);
        const pastMiddle = realOffset > middle;
        const targetIndex = calculateTargetIndex(dropIndex, dragIndex, pastMiddle);
        if (targetIndex !== dragIndex) {
            return reorder(dragIndex, targetIndex);
        }
    },
    canDrop({ namespace: dropNamespace }, monitor) {
        const { namespace: dragNamespace } = monitor.getItem();
        return (!dropNamespace && !dragNamespace) || dropNamespace === dragNamespace;
    },
}));
export function verticalDropHandler(component, pastMiddle) {
    component.setState(pastMiddle ? { hoverTop: false, hoverBottom: true } : { hoverTop: true, hoverBottom: false });
}
export function horizontalDropHandler(component, pastMiddle) {
    component.setState(pastMiddle ? { hoverLeft: false, hoverRight: true } : { hoverLeft: true, hoverRight: false });
}
export default function enhanceDragItem(itemType, type) {
    return compose(withStyles(styles), ReactDND.DragSource(itemType, dragSource, (connector) => ({
        connectDragSource: connector.dragSource(),
        connectDragPreview: connector.dragPreview(),
    })), ReactDND.DropTarget(itemType, type === 'horizontal'
        ? createDropTarget(DragEnhancer.calculateHoverX, horizontalDropHandler)
        : createDropTarget(DragEnhancer.calculateHoverY, verticalDropHandler), (connector, monitor) => ({
        connectDropTarget: connector.dropTarget(),
        isOver: monitor.isOver(),
    })));
}
export function calculateHoverY(monitor, component) {
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const middle = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const offset = clientOffset.y - hoverBoundingRect.top;
    return { middle, offset };
}
export function calculateHoverX(monitor, component) {
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const middle = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const clientOffset = monitor.getClientOffset();
    const offset = clientOffset.x - hoverBoundingRect.left;
    return { middle, offset };
}
