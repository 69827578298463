export const SHOW_ALERT = 'ALERT:SHOW';
export const HIDE_ALERT = 'ALERT:HIDE';
export const UPDATE_LOCK_BANNER = 'LOCK_BANNER:UPDATE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const OPEN_DRAWER = 'DRAWER:OPEN';
export const CLOSE_DRAWER = 'DRAWER:CLOSE';
export const EXPAND_DRAWER = 'DRAWER:EXPAND';
export const COLLAPSE_DRAWER = 'DRAWER:COLLAPSE';
export const OPEN_PREVIEW_DRAWER = 'PREVIEW_DRAWER:OPEN';
export const CLOSE_PREVIEW_DRAWER = 'PREVIEW_DRAWER:CLOSE';
export const OPEN_POSITION_MODAL = 'POSITION_MODAL:OPEN';
export const CLOSE_POSITION_MODAL = 'POSITION_MODAL:CLOSE';
export const OPEN_COPY_TO_AREA_MODAL = 'COPY_TO_AREA_MODAL:OPEN';
export const CLOSE_COPY_TO_AREA_MODAL = 'COPY_TO_AREA_MODAL:CLOSE';
export const SET_COPY_TO_AREA_TARGET = 'SET_COPY_TO_AREA_TARGET';
export const SET_DUPLICATE_MODEL_ID = 'SET_DUPLICATE_DUPLICATE_MODEL_ID';
export const OPEN_CHANGELOG_MODAL = 'CHANGE_LOG_MODAL:OPEN';
export const CLOSE_CHANGELOG_MODAL = 'CHANGE_LOG_MODAL:CLOSE';
export const SHOW_ERROR = 'ERROR:SHOW';
export const CLEAR_ERROR = 'ERROR:CLEAR';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
