import React from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DonutIcon from '@material-ui/icons/DonutLarge';
import HistoryIcon from '@material-ui/icons/History';
import LabelIcon from '@material-ui/icons/Label';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PollIcon from '@material-ui/icons/Poll';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import classNames from 'classnames';
import RadioTowerIcon from 'mdi-react/RadioTowerIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import ShieldHalfFullIcon from 'mdi-react/ShieldHalfFullIcon';
import { compose } from 'ramda';
import { Routing } from '@/constants';
import { withStyles, } from '@/decorators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const ICONS = {
    [Routing.Section.Merchandising.SITE]: RoutesIcon,
    [Routing.Section.Merchandising.RECALL]: DonutIcon,
    [Routing.Section.Merchandising.CONFIG]: SettingsIcon,
    [Routing.Section.Merchandising.ADMIN]: ShieldHalfFullIcon,
    [Routing.Section.Merchandising.HISTORY]: HistoryIcon,
    [Routing.Section.Analytics.SEARCH]: SearchIcon,
    [Routing.Section.Analytics.MONITORING]: PollIcon,
    [Routing.Section.Analytics.CONTRACT_METRICS]: LibraryBooksIcon,
    [Routing.Section.Analytics.BEACON]: RadioTowerIcon,
    [Routing.Section.GlancerAnalytics.DASHBOARDS]: SearchIcon,
    // CUE-120: hide enrich dashboard
    // [Routing.Section.Enrich.REPORTING]: DashboardIcon,
    [Routing.Section.Enrich.LIBRARY]: DashboardIcon,
    [Routing.Section.Enrich.RULES]: LibraryBooksIcon,
    [Routing.Section.Enrich.TAXONOMY]: LabelIcon,
};
export const styles = ({ palette, spacing }) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        backgroundColor: palette.background.paper,
        whiteSpace: 'nowrap',
        paddingRight: spacing(2),
        paddingLeft: spacing(2),
    },
    icon: {
        marginRight: spacing(1),
        fill: 'currentColor',
    },
    darkMode: {
        background: '#37474F',
        letterSpacing: 0.5,
        color: '#e0e0e0',
    },
});
export const selectors = {
    activeUser: Selectors.activeUserSelector,
};
export const enhance = compose(withStyles(styles), connect(selectors));
const RouteSection = enhance(function RouteSection({ section, activeUser, component: RouteComponent, darkMode, classes, }) {
    const IconComponent = ICONS[section.path];
    const allowedRoutes = section.routes.filter(({ roles }) => activeUser && (!roles || roles.length === 0 || roles.some((role) => activeUser.allowedAccess.includes(role))));
    const header = (<ListSubheader classes={{ root: classNames(classes.header, { [classes.darkMode]: darkMode }) }}>
      <IconComponent className={classes.icon}/>
      {section.label.toUpperCase()}
    </ListSubheader>);
    return allowedRoutes.length > 0 ? (<List subheader={header} dense>
      {allowedRoutes.map((allowedRoute) => (<RouteComponent route={allowedRoute} key={allowedRoute.path}/>))}
    </List>) : null;
});
export default RouteSection;
