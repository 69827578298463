import { __decorate } from "tslib";
import React from 'react';
import Navigations from '@/analytics/dashboards/search-term/insights/navigations';
import Performance from '@/analytics/dashboards/search-term/insights/performance';
import Products from '@/analytics/dashboards/search-term/insights/products';
import { SimpleLoading } from '@/components/loading';
import NoDataError from '@/components/no-data-error';
import { sortTable } from '@/components/reports/table-v2';
import { Direction } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import Header from './header';
import Snapshot from './snapshot';
import Tabs from './tabs';
var InsightsType = State.Analytics.InsightsType;
export const INSIGHTS = [
    {
        id: InsightsType.METRICS,
        label: 'Performance',
        component: Performance,
    },
    {
        id: InsightsType.PRODUCTS,
        label: 'Products',
        component: Products,
        defaultSort: { orderBy: State.Analytics.ResultKey.VIEW_PRODUCTS, order: Direction.DESC },
    },
    {
        id: InsightsType.NAVIGATIONS,
        label: 'Navigations & Refinements',
        component: Navigations,
        defaultSort: { orderBy: State.Analytics.ResultKey.UNIQUE_NAVIGATIONS, order: Direction.DESC },
    },
];
export const styles = () => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'scroll',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
});
export const selectors = {
    insightsData: Selectors.insightsDataSelector,
    currentInsights: Selectors.currentInsightsSelector,
};
export const actionCreators = {
    updateCurrentInsights: ActionCreators.updateCurrentInsights,
    loadInsights: ActionCreators.loadInsights,
};
let Insights = class Insights extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleTabChange = (_, insightsType) => {
            const { updateCurrentInsights, loadInsights, snapshot } = this.props;
            updateCurrentInsights(insightsType);
            loadInsights(insightsType, snapshot[State.Analytics.ResultKey.TERM]);
        };
    }
    render() {
        const { classes, snapshot, insightsData, currentInsights, onClose, } = this.props;
        if (!currentInsights) {
            return null;
        }
        const { component: Component, defaultSort } = INSIGHTS.find(({ id }) => currentInsights === id);
        const componentData = insightsData
            ? defaultSort
                ? insightsData.sort(sortTable(defaultSort.order, defaultSort.orderBy))
                : insightsData
            : null;
        return (<div className={classes.root}>
        <Header title={snapshot.term} onClose={onClose}/>
        <Snapshot data={snapshot}/>
        <Tabs currentInsights={currentInsights} onChange={this.handleTabChange}/>
        <div className={classes.content}>
          {componentData ? (componentData.length ? (<Component data={componentData} defaultSort={defaultSort} filterSize={100}/>) : (<NoDataError />)) : (<SimpleLoading />)}
        </div>
      </div>);
    }
    componentWillUnmount() {
        this.props.updateCurrentInsights(InsightsType.METRICS);
    }
};
Insights = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators)
], Insights);
export default Insights;
