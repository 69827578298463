import pathMatcher from 'path-match';
import { Routing } from '@/constants';
const pathMatch = pathMatcher({ end: false });
export const getClassificationWildcard = (location) => {
    const match = pathMatch(Routing.Page.Enrich.CLASSIFICATION_PATH)(location);
    return (match && match[Routing.Page.Enrich.PRODUCTS_WILDCARD]) || '';
};
export const getSectionPath = (section) => (`${Routing.ENRICH_PATH}/${section}`);
export const getClassificationPath = (section, classification) => (`${getSectionPath(section)}/${classification}`);
export const getClassificationTypePath = (section, classification, classType) => (`${getClassificationPath(section, classification)}/${classType}`);
