import { __awaiter, __rest } from "tslib";
import Cache from './cache';
import * as FetchModule from './fetch';
export const DEFAULT_EXPIRY = 10000;
export const SERVER_FAILURE_ERROR = 'Server replied with an error.';
export const PARSE_ERROR_MESSAGE = 'Unable to parse response.';
export class NetworkException extends Error {
    constructor(msg, statusCode, errors, warnings, explanation) {
        super(msg);
        this.statusCode = statusCode;
        this.errors = errors;
        this.warnings = warnings;
        this.explanation = explanation;
    }
}
export function checkStatus(response) {
    return __awaiter(this, void 0, void 0, function* () {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        const result = yield FetchModule.parseJSON(response);
        let errors;
        let warnings = [];
        try {
            errors = result.status.additionalInfo.errors || [];
            warnings = result.status.additionalInfo.warnings || [];
        }
        catch (_a) {
            errors = response.status >= 400 && response.status < 500
                ? [`${result.message} (${response.status})`]
                : [SERVER_FAILURE_ERROR];
        }
        throw new FetchModule.NetworkException(response.statusText, response.status, errors, warnings, result.errors);
    });
}
export function parseJSON(response) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield response.json();
        }
        catch (_a) {
            throw Object.assign(new Error(response.statusText), { errors: [PARSE_ERROR_MESSAGE] });
        }
    });
}
export function extractHeaders(response) {
    const headers = {};
    // eslint-disable-next-line no-return-assign
    response.headers.forEach((value, key) => (headers[key] = value));
    return headers;
}
export function buildResponse(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield FetchModule.parseJSON(response);
        const headers = FetchModule.extractHeaders(response);
        return { result, headers, statusCode: response.status };
    });
}
export function cacheResponses(fetch) {
    const cache = new Cache();
    return (url, _a = {}) => {
        var { expiry = DEFAULT_EXPIRY, skipCache } = _a, config = __rest(_a, ["expiry", "skipCache"]);
        const key = Cache.generateKey(url, config);
        if (!skipCache && cache.has(key)) {
            return Promise.resolve(cache.get(key));
        }
        return fetch(url, config)
            .then(FetchModule.checkStatus)
            .then(FetchModule.buildResponse)
            .then((resp) => (expiry === false ? resp : cache.set(key, resp, expiry)));
    };
}
export default cacheResponses(window.fetch);
