import { __rest } from "tslib";
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { setDisplayName, wrapDisplayName } from 'recompose';
import { Field } from 'redux-form';
import ErrorBoundary from '@/components/error-boundary';
import { exposeWrapped } from '@/utils';
export const wrapControl = (type) => (exposeWrapped(((Component) => setDisplayName(wrapDisplayName(Component, 'formControl'))(function WrappedControls(_a) {
    var { name, onChange } = _a, props = __rest(_a, ["name", "onChange"]);
    return (<ErrorBoundary>
            <Field name={name} component={Component} onChange={onChange} props={Object.assign({}, props)}/>
          </ErrorBoundary>);
}))));
