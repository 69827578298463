import React from 'react';
import * as Page from '@/components/page';
import { ENRICH_ROUTES } from '@/routes/drawer';
import RouteList from '../route-list';
import RouteSection from '../route-section';
import RouteLink from './link';
function EnrichDrawer() {
    return (<React.Fragment>
      <Page.Wrapper>
        <Page.Header />
      </Page.Wrapper>
      <RouteList>
        {ENRICH_ROUTES.sections.map((section) => (<RouteSection path={ENRICH_ROUTES.path} section={section} component={RouteLink} key={section.path}/>))}
      </RouteList>
    </React.Fragment>);
}
export default EnrichDrawer;
