import { ModelType } from '@/constants';
import { safeIncludes, sortByPriority } from '@/utils';
import { Sort, } from '.';
import Navigation from './navigation';
var Rule;
(function (Rule) {
    let Trigger;
    (function (Trigger) {
        let Type;
        (function (Type) {
            Type.SEARCH_MATCHES = 'Search_Matches';
            Type.SEARCH_STARTS_WITH = 'Search_Starts_With';
            Type.SEARCH_ENDS_WITH = 'Search_Ends_With';
            Type.SEARCH_CONTAINS = 'Search_Contains';
            Type.SEARCH_REGEX = 'Search_Regex';
            Type.NAVIGATION_VALUE = 'Navigation_Value';
            Type.NAVIGATION_RANGE = 'Navigation_Range';
            Type.NAVIGATION_SELECTED = 'Navigation_Selected';
            Type.NO_NAVIGATION_EVENT = 'No_Navigation_Event';
            Type.NO_RESULTS_EVENT = 'No_Results_Event';
            Type.CUSTOM_PARAM = 'Custom_URL_Parameter';
            Type.LABELS = {
                [Type.SEARCH_MATCHES]: 'Search Matches',
                [Type.SEARCH_STARTS_WITH]: 'Search Starts With',
                [Type.SEARCH_ENDS_WITH]: 'Search Ends With',
                [Type.SEARCH_CONTAINS]: 'Search Contains',
                [Type.SEARCH_REGEX]: 'Search Regex',
                [Type.NAVIGATION_VALUE]: 'Navigation Value',
                [Type.NAVIGATION_RANGE]: 'Navigation Range',
                [Type.NAVIGATION_SELECTED]: 'Navigation Selected',
                [Type.NO_NAVIGATION_EVENT]: 'No Navigation Event',
                [Type.NO_RESULTS_EVENT]: 'No Results Event',
                [Type.CUSTOM_PARAM]: 'Custom Parameter',
            };
            Type.ORDERED = [
                Type.SEARCH_MATCHES,
                Type.SEARCH_STARTS_WITH,
                Type.SEARCH_ENDS_WITH,
                Type.SEARCH_CONTAINS,
                Type.SEARCH_REGEX,
                Type.NAVIGATION_VALUE,
                Type.NAVIGATION_RANGE,
                Type.NAVIGATION_SELECTED,
                Type.NO_NAVIGATION_EVENT,
                Type.NO_RESULTS_EVENT,
                Type.CUSTOM_PARAM,
            ];
        })(Type = Trigger.Type || (Trigger.Type = {}));
        Trigger.TYPES = Type.ORDERED.map((value) => ({
            value,
            label: Type.LABELS[value],
        }));
        Trigger.DEFAULT = { type: Type.SEARCH_CONTAINS };
    })(Trigger = Rule.Trigger || (Rule.Trigger = {}));
    let Filter;
    (function (Filter) {
        let Type;
        (function (Type) {
            Type.NAVIGATION_VALUE = 'Navigation_Value';
            Type.NAVIGATION_RANGE = 'Navigation_Range';
            Type.SEARCH = 'Search';
            Type.EXCLUDE_NAVIGATION_VALUE = 'Excluded_Navigation_Value';
        })(Type = Filter.Type || (Filter.Type = {}));
        Filter.TYPES = [
            { value: Type.NAVIGATION_VALUE, label: 'Filtered by' },
            { value: Type.NAVIGATION_RANGE, label: 'Range is between' },
            { value: Type.SEARCH, label: 'Search' },
            { value: Type.EXCLUDE_NAVIGATION_VALUE, label: 'Filter by exclusion' },
        ];
        Filter.DEFAULT = { type: Type.NAVIGATION_VALUE };
    })(Filter = Rule.Filter || (Rule.Filter = {}));
    let NavigationOverride;
    (function (NavigationOverride) {
        NavigationOverride.DEFAULT = {
            type: Navigation.Type.VALUE,
            pinnedRefinements: [],
        };
    })(NavigationOverride = Rule.NavigationOverride || (Rule.NavigationOverride = {}));
    Rule.DEFAULT = {
        sort: { order: Sort.Order.ASCENDING, field: null },
        triggerSets: [],
        includedNavigations: [],
        navigationOverrides: [],
        bringToTop: [],
        sortByIds: [],
        restrictToIds: [],
        filters: [],
        nested: true,
        zoneContext: false,
        promote: true,
        intelligentNavigationEnabled: false,
    };
    Rule.CONFIG = {
        type: ModelType.RULE,
        defaults: Rule.DEFAULT,
        filter: filterPredicate,
        sort: sortByPriority,
        promotable: true,
        prioritized: true,
    };
    // eslint-disable-next-line no-inner-declarations
    function filterPredicate(filter) {
        return ({ id, name, template, triggerSets, }) => id.includes(filter)
            || safeIncludes(name, filter)
            || (template && safeIncludes(template.name, filter))
            || triggerSets.some((triggerSet) => triggerSet.some(({ value, navigationName, customUrlParam }) => safeIncludes(value, filter)
                || safeIncludes(navigationName, filter)
                || (customUrlParam && (safeIncludes(customUrlParam.key, filter) || safeIncludes(customUrlParam.value, filter)))));
    }
    Rule.filterPredicate = filterPredicate;
})(Rule || (Rule = {}));
export default Rule;
