import React from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ fontSize, palette, spacing }) => ({
    root: {
        color: palette.mainFont,
        marginRight: spacing(1.5),
        fontSize: fontSize.itemHeader,
        fontWeight: 500,
        whiteSpace: 'nowrap',
    },
});
const PageTitle = withStyles(styles)(function PageTitle({ className, classes, children }) {
    return (<Typography className={classNames(classes.root, className)} variant="subtitle1">
      {children}
    </Typography>);
});
export default PageTitle;
