import { createSelector } from 'reselect';
import { Routing } from '@/constants';
import * as RouterSelectors from './router';
export function unsavedFormActionSelector({ ui: { unsavedFormModal: { attemptedAction }, }, }) {
    return attemptedAction;
}
export function alertSelector({ ui: { alert } }) {
    return alert;
}
export function drawerOpenSelector({ ui: { drawer: { open }, }, }) {
    return open;
}
export function drawerCollapsedSelector({ ui: { drawer: { collapsed }, }, }) {
    return collapsed;
}
export function previewDrawerOpenSelector({ ui: { previewDrawer: { open }, }, }) {
    return open;
}
export function isCopyToAreaModalOpenSelector({ ui: { copyToAreaModal: { open }, }, }) {
    return open;
}
export function changeLogModalOpenSelector({ ui: { changeLogModal: { open }, }, }) {
    return open;
}
export function isLockBannerOpenSelector({ ui: { lockBanner: { open }, }, }) {
    return open;
}
export function positionModalSelector({ ui: { positionModal } }) {
    return positionModal;
}
export const showMainDrawerSelector = createSelector(RouterSelectors.activePathSelector, (activePath) => !activePath.startsWith(Routing.RELEASE_NOTES_PATH));
