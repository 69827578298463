import React from 'react';
import classNames from 'classnames';
import { getFormat } from '@/analytics/dashboards/search-performance/snapshots';
import SnapshotCard from '@/components/controls/snapshot-card';
import NoDataError from '@/components/no-data-error';
import { withStyles, } from '@/decorators';
import State from '@/store/state';
var IDs = State.Analytics.ResultKey;
const LARGE_CIRCLE_SIZE = 30;
const SMALL_CIRCLE_SIZE = 20;
export const styles = ({ spacing, palette, breakpoints }) => ({
    root: {
        display: 'flex',
        paddingTop: spacing(2),
    },
    labelFontSize: {
        [breakpoints.down('md')]: {
            fontSize: 11,
        },
    },
    numberFontSize: {
        fontSize: spacing(3),
        [breakpoints.down('md')]: {
            fontSize: spacing(2.5),
        },
    },
    circle: {
        borderRadius: '50%',
        color: palette.primary.contrastText,
        fontSize: '1.4em',
        width: LARGE_CIRCLE_SIZE,
        height: LARGE_CIRCLE_SIZE,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [breakpoints.down('md')]: {
            width: SMALL_CIRCLE_SIZE,
            height: SMALL_CIRCLE_SIZE,
            fontSize: '1.1em',
        },
    },
    [IDs.UNIQUE_SEARCHES]: {
        color: palette.analytics[IDs.UNIQUE_SEARCHES],
    },
    [IDs.UNIQUE_VIEW_PRODUCT_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_VIEW_PRODUCT_RATIO],
    },
    [IDs.UNIQUE_ADD_TO_CART_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_ADD_TO_CART_RATIO],
    },
    [IDs.UNIQUE_ORDER_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_ORDER_RATIO],
    },
    [IDs.CONVERSION_SCORE]: {
        color: palette.analytics[IDs.CONVERSION_SCORE],
    },
    [IDs.EXPLORATION_SCORE]: {
        color: palette.analytics[IDs.EXPLORATION_SCORE],
    },
});
const SNAPSHOT_CARDS = [
    {
        label: 'Unique Searches',
        id: IDs.UNIQUE_SEARCHES,
    },
    {
        label: 'Clickthrough Rate',
        id: IDs.UNIQUE_VIEW_PRODUCT_RATIO,
    },
    {
        label: 'Add To Cart Rate',
        id: IDs.UNIQUE_ADD_TO_CART_RATIO,
    },
    {
        label: 'Conversion Rate',
        id: IDs.UNIQUE_ORDER_RATIO,
    },
    {
        label: 'Conversion Score',
        isCircle: true,
        id: IDs.CONVERSION_SCORE,
    },
    {
        label: 'Exploration Score',
        isCircle: true,
        id: IDs.EXPLORATION_SCORE,
    },
];
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const Snapshot = withStyles(styles)(function Snapshot({ classes, data, }) {
    if (!data)
        return <NoDataError />;
    return (<div className={classes.root}>
      {SNAPSHOT_CARDS.map(({ id, label, isCircle }, index) => (<SnapshotCard value={getFormat(data, id).value} formatter={getFormat(data, id).formatter} label={label} key={index} numberClassName={classNames(classes.numberFontSize, { [classes.circle]: isCircle })} labelClassName={classNames(classes[id], classes.labelFontSize)} isCircle={isCircle}/>))}
    </div>);
});
export default Snapshot;
