import { __decorate } from "tslib";
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { FormSection } from 'redux-form';
import { disabling } from '@/components/disabling';
import { StringNaturalCompare } from '@/utils';
import FormManagerTableDisplay from './item/display';
import FormItem from './item/form';
import TableHeader from './item/table-head';
import TableDisplayWrapper from './item/wrapper';
let FormManagerTable = class FormManagerTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            ascending: null,
            sortBy: null,
        };
        this.handleSortClick = (sortBy) => () => {
            const ascending = this.state.sortBy === sortBy ? !this.state.ascending : true;
            this.setState({ ascending, sortBy });
        };
        this.handleRemove = (index) => () => this.props.fields.remove(index);
        this.handleEdit = (index) => () => this.props.onEdit(index);
    }
    render() {
        const { columns, disabled, fields, editingIndex, onSave, onReorder, formComponent: FormComponent, displayComponent: DisplayComponent, reorderable, } = this.props;
        const { ascending, sortBy } = this.state;
        if (fields.length === 0) {
            return null;
        }
        const fieldValues = fields.getAll().map((value, index) => ({ index, value }));
        const selectedColumn = columns.find((item) => item.key === sortBy) || {};
        return (<Table>
        <TableHeader columns={columns} sortable={!reorderable} ascending={ascending} sortBy={sortBy} handleClick={this.handleSortClick}/>
        <TableBody>
          {(reorderable && !disabled
            ? fieldValues
            : (selectedColumn.sort || defaultSort)(fieldValues, ascending, sortBy)).map(({ index }) => {
            const section = `${fields.name}[${index}]`;
            return (<TableDisplayWrapper reorderable={reorderable && !disabled} editingIndex={editingIndex} reorder={onReorder} index={index} key={index}>
                {editingIndex === index ? (<FormItem onSave={onSave}>
                    <FormComponent member={section}/>
                  </FormItem>) : (<FormManagerTableDisplay onEdit={this.handleEdit(index)} onRemove={this.handleRemove(index)} key={index}>
                    <FormSection name={section}>
                      <DisplayComponent value={fields.get(index)}/>
                    </FormSection>
                  </FormManagerTableDisplay>)}
              </TableDisplayWrapper>);
        })}
        </TableBody>
      </Table>);
    }
};
FormManagerTable = __decorate([
    disabling
], FormManagerTable);
export default FormManagerTable;
export function defaultSort(array, ascending, sortBy) {
    return sortBy
        ? array.sort((lhs, rhs) => StringNaturalCompare(lhs.value[sortBy], rhs.value[sortBy]) * (ascending ? 1 : -1))
        : array;
}
