import { __rest } from "tslib";
import * as Actions from '../types/auth';
import { createAction, createErrorAction, createThunkAction } from '../utils';
export const clearAuthentication = () => createAction(Actions.CLEAR_AUTHENTICATION);
export const acknowledgeAuthentication = (token, _a) => {
    var { areas, commandCenterAccess } = _a, user = __rest(_a, ["areas", "commandCenterAccess"]);
    return createAction(Actions.ACKNOWLEDGE_AUTHENTICATION, {
        token,
        user: Object.assign(Object.assign({}, user), { allowedAreas: areas, allowedAccess: commandCenterAccess }),
    });
};
export const authenticateUser = (email, password) => createAction(Actions.AUTHENTICATE_USER, { email, password });
export const initiateSSOAuthentication = (provider) => createAction(Actions.INITIATE_SSO_AUTHENTICATION, provider);
export const authenticateSSOUser = (token, user) => createAction(Actions.AUTHENTICATE_SSO_USER, { token, user });
export const validateToken = (action) => createAction(Actions.VALIDATE_TOKEN, action);
export const updateToken = (token) => createAction(Actions.UPDATE_TOKEN, token);
export const expireToken = () => createAction(Actions.EXPIRE_TOKEN);
export const exchangeSSOCode = (provider) => createAction(Actions.EXCHANGE_SSO_CODE, provider);
export const exchangeSSOCodeError = (message, details) => createAction(Actions.EXCHANGE_SSO_CODE_ERROR, { message, details });
export const validatePasswordFormat = (password) => createThunkAction(Actions.VALIDATE_PASSWORD_FORMAT, password);
export const validatePasswordFormatReply = (requirements, meta) => createAction(Actions.VALIDATE_PASSWORD_FORMAT_REPLY, requirements, meta);
export const validatePasswordFormatError = (requirements, meta) => createErrorAction(Actions.VALIDATE_PASSWORD_FORMAT_REPLY, requirements, meta);
export const getExternalAuthenticationProviders = () => createAction(Actions.GET_EXTERNAL_AUTHENTICATION_PROVIDERS);
export const updateExternalAuthenticationProvidersList = (providers) => createAction(Actions.UPDATE_EXTERNAL_AUTHENTICATION_PROVIDERS_LIST, providers);
