import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import ErrorBoundary from '@/components/error-boundary';
import { SimpleLoading } from '@/components/loading';
import NoDataError from '@/components/no-data-error';
import Tooltip from '@/components/tooltip';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, fontSize, palette }) => ({
    container: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        '&$accentBorder': {
            borderTop: `3px solid ${palette.primary.dark}`,
        },
    },
    header: {
        display: 'flex',
        height: spacing(5),
        padding: spacing(1.25),
        '&>*': {
            margin: 0,
        },
        '&>$title': {
            fontSize: fontSize.formControl,
            whiteSpace: 'nowrap',
            marginRight: spacing(1),
            fontWeight: 500,
            color: palette.secondary.main,
        },
    },
    content: {
        height: '100%',
        display: 'flex',
        padding: spacing(1.5),
        '&$title': {
            height: `calc(100% - ${spacing(5)}px)`,
        },
    },
    accentBorder: {},
    title: {},
});
const Card = withStyles(styles)(function Card({ tooltip, title, flat, accentBorder, headerClassName, contentClassName, classes, data, children, }) {
    return (<Paper className={classNames(classes.container, { [classes.accentBorder]: accentBorder })} elevation={flat ? 0 : 2}>
      <ErrorBoundary>
        {(title || tooltip) && (<Grid container item xs={12} justify="space-between" alignItems="center" wrap="nowrap" className={classNames(classes.header, headerClassName)}>
            {title && <p className={classes.title}>{title}</p>}
            {tooltip && <Tooltip title={tooltip} placement="bottom-start"/>}
          </Grid>)}
        <Grid container item xs={12} justify={data ? null : 'center'} alignContent={data ? null : 'center'} className={classNames(classes.content, { [classes.title]: title }, contentClassName)}>
          {!data ? <SimpleLoading /> : data.length === 0 ? <NoDataError /> : children}
        </Grid>
      </ErrorBoundary>
    </Paper>);
});
export default Card;
