import React from 'react';
import MaterialButton from '@material-ui/core/Button';
import classNames from 'classnames';
import { compose } from 'ramda';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';
import { PAGE_SIZE } from '@/constants';
import { withStyles, } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import { connect, totalPages as utilsTotalPages } from '@/utils';
import { withLinkStyles, FirstPageLink, LastPageLink, NextPageLink, PreviousPageLink, } from './link';
import Page from './page';
export const styles = ({ palette, spacing }) => ({
    root: {
        textAlign: 'center',
        position: 'relative',
        padding: `${spacing(1)}px ${spacing(3)}px`,
        borderBottom: `1px solid ${palette.grey[300]}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        minHeight: spacing(5),
        flex: '2 0 0',
        '&$sticky': {
            position: 'sticky',
            background: palette.primary.contrastText,
            bottom: 0,
        },
        '&>div': {
            flex: '1 0 0',
            flexDirection: 'row',
        },
    },
    sticky: {},
});
export const updateModelPage = (page, filter) => ActionCreators.goToManager(null, { page, filter });
export const actionCreators = {
    updateModelPage,
    scrollToTop: ActionCreators.scrollToTop,
};
export const mergeProps = (_, { updateModelPage, scrollToTop }, { filterValue }) => ({
    updateModelPage: (page) => {
        updateModelPage(page, filterValue);
        scrollToTop();
    },
});
const enhance = compose(withStyles(styles), connect(null, actionCreators, mergeProps));
const Pagination = enhance(function Pagination({ currentPage, totalResults, updatePage, updateModelPage, stickyPosition, classes, children, }) {
    const totalPages = utilsTotalPages(totalResults);
    const showPagination = totalResults > PAGE_SIZE;
    if (!children && (currentPage > totalPages || !showPagination)) {
        return null;
    }
    return (<div id="pagination" className={classNames(classes.root, { [classes.sticky]: stickyPosition })}>
      {showPagination && (<MaterialPagination currentPage={currentPage} totalPages={totalPages} onChange={updatePage || updateModelPage}/>)}
      {children}
    </div>);
});
export default Pagination;
export const Ellipsis = withLinkStyles(function Ellipsis({ onClick, classes }) {
    return (<MaterialButton onClick={onClick} aria-label="Load More Pages" classes={{ root: classes.root, label: classes.inactive }}>
      ...
    </MaterialButton>);
});
export const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};
export const MaterialPagination = createUltimatePagination({ itemTypeToComponent });
