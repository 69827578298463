import React from 'react';
import { compose } from 'ramda';
import Graph from '@/analytics/dashboards/search-performance/charts';
import Chart from '@/components/chart';
import { withStyles, } from '@/decorators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
var DashboardType = State.Analytics.DashboardType;
export const styles = ({ spacing }) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    chart: {
        display: 'flex',
        flex: 1,
        padding: `${spacing(1 / 2)}px ${spacing(1.5)}px`,
    },
});
export const searchTermFilter = Selectors.filterSelector(DashboardType.SEARCH_TERM);
export const selectors = {
    filters: searchTermFilter,
};
export const enhance = compose(withStyles(styles), connect(selectors));
const Performance = enhance(function Performance({ data, filters: { dateRange }, classes, }) {
    return (<div className={classes.root}>
      <div className={classes.chart}>
        <Chart.Card flat title="Unique Searches" data={data}>
          <Graph type="top" data={data} dateRange={dateRange}/>
        </Chart.Card>
      </div>
      <div className={classes.chart}>
        <Chart.Card flat title="Conversion Funnel" data={data}>
          <Graph type="bottom" data={data} dateRange={dateRange}/>
        </Chart.Card>
      </div>
    </div>);
});
export default Performance;
