import React from 'react';
import { compose } from 'ramda';
import Product from '@/components/product';
import ProductList from '@/components/product/list';
import VisualSearch from '@/components/visual-search';
import PreviewError from '@/components/visual-search/error';
import { withStyles, } from '@/decorators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const styles = ({ spacing }) => ({
    root: {
        padding: `${spacing(2)}px ${spacing(1)}px ${spacing(3)}px ${spacing(3)}px `,
        height: spacing(12),
    },
    productList: {
        padding: `0 ${spacing(7)}px ${spacing(12)}px ${spacing(3)}px `,
        width: '100%',
    },
});
export const selectors = {
    searchPreview: Selectors.searchPreviewSelector,
};
export const enhance = compose(withStyles(styles), connect(selectors));
const PreviewComponent = enhance(function PreviewComponent({ searchPreview: { error, products }, classes, }) {
    return (<VisualSearch controlsClassName={classes.root}>
      {error
        ? (<PreviewError error={error}/>) : (<ProductList className={classes.productList}>
            {products.map((product, index) => (<Product product={product} key={product.id || index}/>))}
          </ProductList>)}
    </VisualSearch>);
});
export default PreviewComponent;
