import { __decorate } from "tslib";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as PopularSearches from '@/analytics/dashboards/search-term/reports/popular-searches';
import * as TopNulls from '@/analytics/dashboards/search-term/reports/top-nulls';
import DeviceTypeFilter from '@/analytics/dashboards/shared/components/device-type-filter';
import ExportButton from '@/components/export-button';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import SearchQuery from './search-query';
var ReportType = State.Analytics.ReportType;
var Device = State.Analytics.ReportFilters.Device;
var MatchType = State.Analytics.MatchType;
export const DEVICE_OPTIONS = [Device.MOBILE, Device.TABLET, Device.DESKTOP];
const HEADERS = {
    [ReportType.PERFORMANCE]: PopularSearches.COLUMNS && PopularSearches.COLUMNS.map(({ id, csvLabel }) => ({ label: csvLabel, key: id })),
    [ReportType.NULLS]: TopNulls.COLUMNS
        && TopNulls.COLUMNS.filter((header) => header.csvLabel).map(({ id, csvLabel }) => ({ label: csvLabel, key: id })),
};
export const styles = ({ spacing, breakpoints, palette }) => ({
    root: {
        flex: 3,
        '&>:first-child': {
            marginLeft: 0,
        },
    },
    search: {
        flex: 1.5,
        [breakpoints.down('lg')]: {
            flex: 2.5,
        },
    },
    devices: {
        flex: 3.5,
        marginLeft: spacing(6),
    },
    filterLabel: {
        fontSize: spacing(1.5),
        color: palette.mainFont,
    },
    deviceSection: {
        padding: 0,
    },
    deviceType: {
        margin: 0,
    },
    deviceFilter: {
        marginLeft: spacing(1),
    },
});
export const selectors = {
    filters: Selectors.reportFiltersSelectorV2,
    query: Selectors.reportQuerySelectorV2,
    matchType: Selectors.reportQueryMatchTypeSelector,
    reportData: Selectors.reportDataSelectorV2,
};
export const actionCreators = {
    loadReport: ActionCreators.loadReport,
    updateReportFilters: ActionCreators.updateReportFilters,
    updateReportQuery: ActionCreators.updateReportQuery,
};
export const mergeProps = (_, { loadReport }, { reportType }) => ({
    loadReport: () => loadReport(reportType),
});
let TabFilters = class TabFilters extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onDeviceChange = (devices) => {
            const { filters, updateReportFilters, reportType } = this.props;
            updateReportFilters(reportType, Object.assign(Object.assign({}, filters), { devices }));
        };
    }
    render() {
        const { query, matchType, loadReport, reportType, updateReportQuery, classes, filters: { devices }, reportName, reportData, } = this.props;
        return (<Grid container item className={classes.root} alignItems="center">
        <Grid container item className={classes.search} justify="flex-start" alignItems="center">
          <SearchQuery query={query} matchType={matchType} reportType={reportType} loadReport={loadReport} updateReportQuery={updateReportQuery}/>
        </Grid>
        <Grid container item className={classes.devices} justify="flex-start" alignItems="center">
          <Typography className={classes.filterLabel}>Filter By:</Typography>
          <div className={classes.deviceFilter}>
            <DeviceTypeFilter devices={devices} onSave={this.onDeviceChange}/>
          </div>
        </Grid>
        <ExportButton data={reportData} reportName={reportName} headers={HEADERS[reportType]}/>
      </Grid>);
    }
    componentDidMount() {
        const { filters, query = '', updateReportFilters, updateReportQuery, reportType, } = this.props;
        if (!(filters && filters.devices)) {
            updateReportFilters(reportType, { devices: DEVICE_OPTIONS });
            updateReportQuery(reportType, query, MatchType.CONTAINS);
        }
    }
    componentDidUpdate(prevProps) {
        const { filters, loadReport } = this.props;
        if (prevProps.filters !== filters) {
            loadReport();
        }
    }
};
TabFilters = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators, mergeProps)
], TabFilters);
export default TabFilters;
