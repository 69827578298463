import { ModelType } from '@/constants';
import { safeIncludes } from '@/utils';
var Synonym;
(function (Synonym) {
    let Type;
    (function (Type) {
        Type["ONE_WAY"] = "One_Way";
        Type["TWO_WAY"] = "Two_Way";
    })(Type = Synonym.Type || (Synonym.Type = {}));
    Synonym.DEFAULT = { synonyms: [], promote: true };
    Synonym.CONFIG = {
        type: ModelType.SYNONYM,
        defaults: Synonym.DEFAULT,
        filter: filterPredicate,
        sort: comparator,
        promotable: true,
    };
    Synonym.isOneWay = (type) => type === Type.ONE_WAY;
    /* eslint-disable no-inner-declarations */
    function filterPredicate(filter) {
        return ({ id, type, searchTerm, synonyms, }) => id.includes(filter)
            || (Synonym.isOneWay(type) && safeIncludes(searchTerm, filter))
            || synonyms.some((value) => safeIncludes(value, filter));
    }
    Synonym.filterPredicate = filterPredicate;
    function comparator(lhs, rhs) {
        return Synonym.stringify(lhs).localeCompare(Synonym.stringify(rhs));
    }
    Synonym.comparator = comparator;
    function stringify({ type, searchTerm, synonyms }) {
        return (Synonym.isOneWay(type) ? searchTerm : synonyms.join(',')) || '';
    }
    Synonym.stringify = stringify;
    /* eslint-enable no-inner-declarations */
})(Synonym || (Synonym = {}));
export default Synonym;
