import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { compose } from 'ramda';
import { setDisplayName } from 'recompose';
import { withStyles, } from '@/decorators';
export const styles = ({ palette, spacing }) => ({
    root: {
        minWidth: 'initial',
        width: spacing(5),
    },
    inactive: {
        color: palette.grey[600],
    },
    active: {
        color: palette.primary.main,
    },
});
export const withLinkStyles = withStyles(styles);
export const enhance = (label) => compose(setDisplayName(`${label.replace(' ', '')}Link`), withLinkStyles);
// eslint-disable-next-line max-len
export default function createNavigationLink(icon, label) {
    return enhance(label)(function NavigationLink({ onClick, classes }) {
        return (<Button classes={{ root: classes.root }} onClick={onClick} aria-label={label}>
        {icon}
      </Button>);
    });
}
export const FirstPageLink = createNavigationLink(<FirstPageIcon />, 'First Page');
export const PreviousPageLink = createNavigationLink(<ChevronLeftIcon />, 'Previous Page');
export const NextPageLink = createNavigationLink(<ChevronRightIcon />, 'Next Page');
export const LastPageLink = createNavigationLink(<LastPageIcon />, 'Last Page');
