import React from 'react';
import { SimpleLoading } from '@/components/loading';
import NoDataError from '@/components/no-data-error';
import { sortTable } from '@/components/reports/table-v2';
import { REPORTS } from '.';
function SearchTermContent({ currentReport, reportData, isChartView, filterSize, }) {
    if (!reportData) {
        return <SimpleLoading />;
    }
    if (!reportData.length) {
        return <NoDataError />;
    }
    const { reportComponent: ReportComponent, chartComponent: ChartComponent, defaultSort } = REPORTS.find(({ id }) => currentReport === id);
    const sortedData = reportData
        ? reportData.sort(sortTable(defaultSort.order, defaultSort.orderBy)).slice(0, filterSize)
        : null;
    return (<React.Fragment>
      {isChartView ? (<ChartComponent data={sortedData}/>) : (<ReportComponent data={sortedData} defaultSort={defaultSort} filterSize={filterSize} key={currentReport}/>)}
    </React.Fragment>);
}
export default SearchTermContent;
