import React from 'react';
import DocumentationIcon from '@material-ui/icons/ImportContacts';
import * as Selectors from '@/store/selectors';
import { connect, getDocsURL } from '@/utils';
import MenuItem from '../menu-item';
export const selectors = {
    activeUser: Selectors.activeUserSelector,
};
const DocumentationMenuItem = connect(selectors)(function DocumentationMenuItem({ activeUser, }) {
    const url = getDocsURL(activeUser.docsAutoLoginBlob, activeUser.customerId);
    return (<MenuItem onClick={() => window.open(url, '_blank')}>
      <DocumentationIcon />
      Documentation
    </MenuItem>);
});
export default DocumentationMenuItem;
