import { __rest } from "tslib";
import { clone } from 'ramda';
import { SortDirection } from '@groupby/enrich-sdk/sort';
import * as EnrichReducers from './enrich';
import { Routing } from '@/constants';
import { pageTypeProcessingClasses } from '@/enrich/routing/utils/index';
import * as Actions from '@/store/actions/types';
import { getEnrichDefaultSettingsPages } from './utils';
import { getValidConfidenceThreshold } from '@/utils';
export const DEFAULT = {
    customerId: null,
    data: null,
    dateRange: {},
    snapshots: null,
    tokens: [],
    byCategory: {
        productsSelected: {},
    },
    categories: {
        all: [],
        selectedId: null,
        expanded: [],
    },
    classes: {
        allIds: [],
        byId: {},
    },
    currentTotalItems: 0,
    resourceLoadingStatus: {
        categories: false,
        classificationRules: false,
        classPredictions: false,
        previewProducts: false,
        products: false,
        jobCreation: false,
        aiClassificationJobs: false,
        users: false,
        productClassIdUpdate: false,
        productDetailsPanel: false,
        organizations: false,
    },
    jobManagement: {
        users: {
            allIds: [],
            byId: {},
        },
        selectedModels: [],
    },
    machineLearning: {
        models: [],
        selectedModel: null,
        classPredictions: [],
        selectedClassPredictions: {},
        aiClassificationJobs: [],
        remainingPredictionsByJob: {},
    },
    organizations: {
        allIds: [],
        byId: {},
        selected: [],
    },
    pages: getEnrichDefaultSettingsPages(),
    pagination: {
        pageSize: 20,
        offset: 0,
        pageSizes: [20, 40, 60],
    },
    productCounts: {
        total: 0,
        classified: 0,
        unclassified: 0,
    },
    productDetailsPanel: null,
    products: {
        allIds: [],
        byId: {},
    },
    productsExpanded: false,
    productFields: {
        'id': { id: 'id', source: 'standard' },
        'title': { id: 'title', source: 'standard' },
        'proprietaryId': { id: 'proprietaryId', source: 'standard' },
        'description': { id: 'description', source: 'standard' },
        'url': { id: 'url', source: 'standard' },
    },
    sortDirection: SortDirection.Descending,
    sortField: 'createdAt',
    query: '',
    ruleLibrary: {
        classificationRules: {
            allIds: [],
            byId: {},
        },
        selectedOrganizations: [],
        previewProducts: {
            allIds: [],
            byId: {},
        },
    },
    taxonomyNodes: {
        allIds: [],
        byId: {},
        selectedId: null,
        expanded: [],
    },
    userSettings: {
        selectedProductFields: ['proprietaryId', 'title', 'description', 'id'],
    },
    currentPage: '',
    visited: false,
};
// eslint-disable-next-line complexity
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_ENRICH_DATA:
            return EnrichReducers.refreshDataReducer(state, action);
        case Actions.REFRESH_ENRICH_SNAPSHOT:
            return EnrichReducers.refreshSnapshotsReducer(state, action);
        case Actions.REFRESH_ENRICH_FILTERS:
            return EnrichReducers.refreshFiltersReducer(state, action);
        case Actions.REFRESH_ENRICH_CUSTOMER_ID:
            return EnrichReducers.refreshCustomerIdReducer(state, action);
        case Actions.REFRESH_ENRICH_TOKENS:
            return EnrichReducers.refreshTokensReducer(state, action);
        case Actions.UPDATE_ENRICH_CURRENT_PAGE_SIZE:
            return EnrichReducers.updateCurrentPageSize(state, action);
        case Actions.UPDATE_ENRICH_CURRENT_TOTAL_ITEMS:
            return EnrichReducers.updateCurrentTotalItems(state, action);
        case Actions.UPDATE_ENRICH_PAGE_OFFSET:
            return EnrichReducers.updatePageOffset(state, action);
        case Actions.UPDATE_ENRICH_PRODUCT_COUNTS:
            return EnrichReducers.updateProductCounts(state, action);
        case Actions.UPDATE_ENRICH_PRODUCTS_EXPANDED:
            return EnrichReducers.updateProductsExpanded(state, action);
        case Actions.UPDATE_ENRICH_PRODUCTS:
            return EnrichReducers.updateProducts(state, action);
        case Actions.UPDATE_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS:
            return EnrichReducers.updateRuleLibraryPreviewProducts(state, action);
        case Actions.UPDATE_ENRICH_PRODUCT_SELECTION:
            return EnrichReducers.updateProductSelected(state, action);
        case Actions.UPDATE_ENRICH_PRODUCT_DETAILS_PANEL:
            return EnrichReducers.updateProductDetailsPanel(state, action);
        case Actions.CLOSE_ENRICH_PRODUCT_DETAILS_PANEL:
            return EnrichReducers.closeProductDetailsPanel(state);
        case Actions.UPDATE_ENRICH_SELECTED_PRODUCT_FIELDS:
            return EnrichReducers.updateSelectedProductFields(state, action);
        case Actions.UPDATE_ENRICH_QUERY:
            return EnrichReducers.updateQuery(state, action);
        case Actions.TOGGLE_ENRICH_SORT:
            return EnrichReducers.toggleSort(state, action);
        case Actions.UPDATE_ENRICH_ML_MODELS:
            return EnrichReducers.updateMLModels(state, action);
        case Actions.UPDATE_ENRICH_ML_MODEL_SELECTION:
            return EnrichReducers.updateMLModelSelection(state, action);
        case Actions.UPDATE_ENRICH_JOB_MODELS_SELECTION:
            return EnrichReducers.updateJobModelsSelection(state, action);
        case Actions.UPDATE_ENRICH_JOB_MANAGEMENT_USERS:
            return EnrichReducers.updateJobManagementUsers(state, action);
        case Actions.UPDATE_ENRICH_ML_CLASS_PREDICTIONS:
            return EnrichReducers.updateMLClassPredictions(state, action);
        case Actions.UPDATE_ENRICH_ML_SELECTED_CLASS_PREDICTIONS:
            return EnrichReducers.updateMLSelectedClassPredictions(state, action);
        case Actions.CLEAR_ENRICH_ML_SELECTED_CLASS_PREDICTIONS:
            return EnrichReducers.clearMLSelectedClassPredictions(state);
        case Actions.REMOVE_ENRICH_ML_SELECTED_CLASS_PREDICTION:
            return EnrichReducers.removeMLSelectedClassPrediction(state, action);
        case Actions.UPDATE_ENRICH_CATEGORIES:
            return EnrichReducers.updateCategories(state, action);
        case Actions.UPDATE_ENRICH_SELECTED_CATEGORY:
            return EnrichReducers.updateSelectedCategory(state, action);
        case Actions.UPDATE_ENRICH_ORGANIZATIONS:
            return EnrichReducers.updateOrganizations(state, action);
        case Actions.UPDATE_SELECTED_ENRICH_ORGANIZATIONS:
            return EnrichReducers.updateSelectedOrganizations(state, action);
        case Actions.UPDATE_ENRICH_TAXONOMY_NODES:
            return EnrichReducers.updateTaxonomyNodes(state, action);
        case Actions.UPDATE_ENRICH_SELECTED_TAXONOMY_NODE_ID:
            return EnrichReducers.updateSelectedTaxonomyNodeId(state, action);
        case Actions.UPDATE_ENRICH_CLASSES:
            return EnrichReducers.updateClasses(state, action);
        case Actions.RECEIVE_ENRICH_PRODUCT_CLASS_ID:
            return EnrichReducers.updateProductClassId(state, action);
        case Actions.UPDATE_ENRICH_EXPANDED_CATEGORIES:
            return EnrichReducers.updateExpandedCategories(state, action);
        case Actions.UPDATE_ENRICH_EXPANDED_TAXONOMY_NODES:
            return EnrichReducers.updateExpandedTaxonomyNodes(state, action);
        case Actions.UPDATE_ENRICH_RESOURCE_LOADING_STATUS:
            return EnrichReducers.updateLoadingStatus(state, action);
        case Actions.UPDATE_ENRICH_PAGE_SETTINGS:
            return EnrichReducers.updatePageSettings(state, action);
        case Actions.UPDATE_ENRICH_VISITED:
            return EnrichReducers.updateVisited(state);
        case Actions.SWITCH_ENRICH_PAGE:
            return EnrichReducers.switchPage(state, action);
        case Actions.UPDATE_ENRICH_PAGE_NAME:
            return EnrichReducers.updatePageName(state, action);
        case Actions.CLEAR_ENRICH_TAXONOMY_NODE_SELECTIONS:
            return EnrichReducers.clearTaxonomyNodeSelections(state);
        case Actions.CLEAR_ENRICH_CATEGORY_SELECTIONS:
            return EnrichReducers.clearCategorySelections(state);
        case Actions.CLEAR_ENRICH_RESOURCE_LOADING_STATUS:
            return EnrichReducers.clearResourceLoadingStatus(state);
        case Actions.UPDATE_ENRICH_CONFIDENCE_THRESHOLD:
            return EnrichReducers.updateConfidenceThreshold(state, action);
        case Actions.UPDATE_ENRICH_AI_CLASSIFICATION_JOBS:
            return EnrichReducers.updateAiClassificationJobs(state, action);
        case Actions.UPDATE_ENRICH_DATE_RANGE:
            return EnrichReducers.updateDateRange(state, action);
        case Actions.UPDATE_ENRICH_REMAINING_PREDICTIONS_BY_JOB:
            return EnrichReducers.updateRemainingPredictionsByJob(state, action);
        case Actions.UPDATE_ENRICH_JOBS_PREDICTION_STATE:
            return EnrichReducers.updatePredictionState(state, action);
        case Actions.UPDATE_ENRICH_JOB_SUBMITTER_SELECTION:
            return EnrichReducers.updateSubmitterSelection(state, action);
        case Actions.UPDATE_ENRICH_CLASSIFICATION_RULES:
            return EnrichReducers.updateClassificationRules(state, action);
        case Actions.UPDATE_ENRICH_RULE_LIBRARY_SELECTED_ORGANIZATIONS:
            return EnrichReducers.updateRuleLibrarySelectedOrganizations(state, action);
        default:
            return state;
    }
};
export function refreshDataReducer(state, { payload: data }) {
    return Object.assign(Object.assign({}, state), { data });
}
export function refreshSnapshotsReducer(state, { payload: data }) {
    return Object.assign(Object.assign({}, state), { snapshots: data });
}
export function refreshFiltersReducer(state, { payload: dateRange }) {
    return Object.assign(Object.assign({}, state), { dateRange });
}
export function refreshCustomerIdReducer(state, { payload: customerId }) {
    return Object.assign(Object.assign({}, state), { customerId });
}
export function refreshTokensReducer(state, { payload: tokens }) {
    return Object.assign(Object.assign({}, state), { tokens });
}
export function updateCurrentPageSize(state, { payload: pageSize }) {
    const pageName = state.currentPage;
    const page = state.pages[pageName] || {};
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, page), { pagination: Object.assign(Object.assign({}, page.pagination), { pageSize, offset: 0 }) }) }) });
}
export function updatePageOffset(state, { payload: offset }) {
    const pageName = state.currentPage;
    const page = state.pages[pageName] || {};
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, page), { pagination: Object.assign(Object.assign({}, page.pagination), { offset }) }) }) });
}
export function updateProducts(state, { payload: products }) {
    return Object.assign(Object.assign({}, state), { products });
}
export function updateRuleLibraryPreviewProducts(state, { payload: previewProducts }) {
    return Object.assign(Object.assign({}, state), { ruleLibrary: Object.assign(Object.assign({}, state.ruleLibrary), { previewProducts }) });
}
export function updateProductsExpanded(state, { payload: productsExpanded }) {
    return Object.assign(Object.assign({}, state), { productsExpanded });
}
export function updateCurrentTotalItems(state, { payload: currentTotalItems }) {
    return Object.assign(Object.assign({}, state), { currentTotalItems });
}
export function updateProductCounts(state, { payload: { total, classified, unclassified } }) {
    return Object.assign(Object.assign({}, state), { productCounts: Object.assign(Object.assign({}, state.productCounts), { total,
            classified,
            unclassified }) });
}
export function updateProductSelected(state, { payload: { id, select } }) {
    if (select) {
        return Object.assign(Object.assign({}, state), { byCategory: {
                productsSelected: Object.assign(Object.assign({}, state.byCategory.productsSelected), { [id]: true }),
            } });
    }
    const _a = state.byCategory.productsSelected, _b = id, val = _a[_b], productsSelected = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    return Object.assign(Object.assign({}, state), { byCategory: Object.assign(Object.assign({}, state.byCategory), { productsSelected }) });
}
export function updateProductDetailsPanel(state, { payload: product }) {
    const pages = clone(state.pages);
    Object.keys(pages).forEach((page) => {
        if (Routing.Page.Enrich.PAGE_TO_TYPE[page] === Routing.Page.Enrich.PAGE_TYPES.PRODUCT) {
            pages[page].productId = product.id;
        }
    });
    return Object.assign(Object.assign({}, state), { pages, productDetailsPanel: product });
}
export function closeProductDetailsPanel(state) {
    const pages = clone(state.pages);
    Object.keys(pages).forEach((page) => {
        if (Routing.Page.Enrich.PAGE_TO_TYPE[page] === Routing.Page.Enrich.PAGE_TYPES.PRODUCT) {
            pages[page].productId = '';
        }
    });
    return Object.assign(Object.assign({}, state), { pages, productDetailsPanel: null });
}
export function updateSelectedProductFields(state, { payload: selectedProductFields }) {
    return Object.assign(Object.assign({}, state), { userSettings: Object.assign(Object.assign({}, state.userSettings), { selectedProductFields }) });
}
export function updateQuery(state, { payload: query }) {
    const pageName = state.currentPage;
    const page = state.pages[pageName] || {};
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, page), { query, 
                // Changing offset because of search as you type
                pagination: Object.assign(Object.assign({}, page.pagination), { offset: 0 }) }) }) });
}
export function toggleSort(state, { payload: sortField }) {
    const sortDirection = sortField === state.sortField
        ? (state.sortDirection === SortDirection.Descending ? SortDirection.Ascending : SortDirection.Descending)
        : SortDirection.Descending;
    return Object.assign(Object.assign({}, state), { sortDirection,
        sortField });
}
export function updateMLModels(state, { payload: { models } }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { models }) });
}
export function updateMLModelSelection(state, { payload: { model } }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { selectedModel: model }) });
}
export function updateJobModelsSelection(state, { payload: selectedModels }) {
    return Object.assign(Object.assign({}, state), { jobManagement: Object.assign(Object.assign({}, state.jobManagement), { selectedModels }) });
}
export function updateJobManagementUsers(state, { payload: users }) {
    return Object.assign(Object.assign({}, state), { jobManagement: Object.assign(Object.assign({}, state.jobManagement), { users }) });
}
export function updateMLClassPredictions(state, { payload: classPredictions }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { classPredictions }) });
}
export function updateMLSelectedClassPredictions(state, { payload: selectedClassPrediction }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { selectedClassPredictions: Object.assign(Object.assign({}, state.machineLearning.selectedClassPredictions), { [selectedClassPrediction.id]: selectedClassPrediction }) }) });
}
export function clearMLSelectedClassPredictions(state) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { selectedClassPredictions: {} }) });
}
export function removeMLSelectedClassPrediction(state, { payload: selectedClassPredictionId }) {
    const _a = state.machineLearning.selectedClassPredictions, _b = selectedClassPredictionId, removedSelection = _a[_b], selectedClassPredictions = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { selectedClassPredictions }) });
}
export function updateCategories(state, { payload: categoriesList }) {
    return Object.assign(Object.assign({}, state), { categories: Object.assign(Object.assign({}, state.categories), { all: categoriesList }) });
}
export function updateSelectedCategory(state, { payload: selectedId }) {
    return Object.assign(Object.assign({}, state), { categories: Object.assign(Object.assign({}, state.categories), { selectedId }) });
}
export function updateOrganizations(state, { payload: normalizedOrganizations }) {
    return Object.assign(Object.assign({}, state), { organizations: Object.assign(Object.assign({}, state.organizations), normalizedOrganizations) });
}
export function updateSelectedOrganizations(state, { payload: selectedOrganizations }) {
    return Object.assign(Object.assign({}, state), { organizations: Object.assign(Object.assign({}, state.organizations), { selected: selectedOrganizations }) });
}
export function updateTaxonomyNodes(state, { payload: taxonomyNodes }) {
    return Object.assign(Object.assign({}, state), { taxonomyNodes: Object.assign(Object.assign({}, state.taxonomyNodes), taxonomyNodes) });
}
export function updateSelectedTaxonomyNodeId(state, { payload: selectedId }) {
    return Object.assign(Object.assign({}, state), { taxonomyNodes: Object.assign(Object.assign({}, state.taxonomyNodes), { selectedId }) });
}
export function updateClasses(state, { payload: classes }) {
    return Object.assign(Object.assign({}, state), { classes });
}
export function updateProductClassId(state, { payload: { productId, classId } }) {
    var _a;
    return Object.assign(Object.assign(Object.assign({}, state), (state.products.byId[productId]
        && {
            products: Object.assign(Object.assign({}, state.products), { byId: Object.assign(Object.assign({}, state.products.byId), { [productId]: Object.assign(Object.assign({}, state.products.byId[productId]), { classId }) }) }),
        })), (((_a = state.productDetailsPanel) === null || _a === void 0 ? void 0 : _a.id) === productId
        && { productDetailsPanel: Object.assign(Object.assign({}, state.productDetailsPanel), { classId }) }));
}
export function updateExpandedCategories(state, { payload: expanded }) {
    return Object.assign(Object.assign({}, state), { categories: Object.assign(Object.assign({}, state.categories), { expanded }) });
}
export function updatePageSettings(state, { payload: { pageName, settings } }) {
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: settings }) });
}
export function updateExpandedTaxonomyNodes(state, { payload: expanded }) {
    return Object.assign(Object.assign({}, state), { taxonomyNodes: Object.assign(Object.assign({}, state.taxonomyNodes), { expanded }) });
}
export function updateLoadingStatus(state, { payload: resourceLoadingStatus }) {
    return Object.assign(Object.assign({}, state), { resourceLoadingStatus: Object.assign(Object.assign({}, state.resourceLoadingStatus), resourceLoadingStatus) });
}
export function clearResourceLoadingStatus(state) {
    const clearedResourceLoadingStatus = Object.keys(state.resourceLoadingStatus).reduce((loadingStatuses, resource) => Object.assign(loadingStatuses, { [resource]: false }), {});
    return Object.assign(Object.assign({}, state), { resourceLoadingStatus: Object.assign({}, clearedResourceLoadingStatus) });
}
export function updateVisited(state) {
    return Object.assign(Object.assign({}, state), { visited: true });
}
export function switchPage(state, { payload: { pageName, overrides } }) {
    const pageType = Routing.Page.Enrich.PAGE_TO_TYPE[pageName];
    const pageProcessor = pageTypeProcessingClasses[pageType];
    const pageSettings = pageProcessor.getMergedPageSettings({
        overrides,
        pageSettings: state.pages[pageName],
    });
    return Object.assign(Object.assign({}, state), { currentPage: pageName, pages: Object.assign(Object.assign({}, state.pages), { [pageName]: pageSettings }) });
}
export function updatePageName(state, { payload: pageName }) {
    return Object.assign(Object.assign({}, state), { currentPage: pageName });
}
export function clearTaxonomyNodeSelections(state) {
    return Object.assign(Object.assign({}, state), { taxonomyNodes: Object.assign(Object.assign({}, state.taxonomyNodes), { selectedId: null, expanded: [] }) });
}
export function clearCategorySelections(state) {
    return Object.assign(Object.assign({}, state), { categories: Object.assign(Object.assign({}, state.categories), { selectedId: null, expanded: [] }) });
}
export function updateConfidenceThreshold(state, { payload: threshold }) {
    const pageName = state.currentPage;
    const page = state.pages[pageName] || {};
    const validThreshold = getValidConfidenceThreshold(threshold);
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, page), { confidence: validThreshold }) }) });
}
export function updateAiClassificationJobs(state, { payload: jobs }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { aiClassificationJobs: jobs }) });
}
export function updateDateRange(state, { payload: dateRange }) {
    const pageName = state.currentPage;
    const page = state.pages[pageName] || {};
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, page), { dateRange }) }) });
}
export function updateRemainingPredictionsByJob(state, { payload: remainingPredictionsByJob }) {
    return Object.assign(Object.assign({}, state), { machineLearning: Object.assign(Object.assign({}, state.machineLearning), { remainingPredictionsByJob }) });
}
export function updatePredictionState(state, { payload: progress }) {
    const pageName = state.currentPage;
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, state.pages[pageName]), { progress }) }) });
}
export function updateSubmitterSelection(state, { payload: submitters }) {
    const pageName = state.currentPage;
    return Object.assign(Object.assign({}, state), { pages: Object.assign(Object.assign({}, state.pages), { [pageName]: Object.assign(Object.assign({}, state.pages[pageName]), { submitters }) }) });
}
export function updateClassificationRules(state, { payload: classificationRules }) {
    return Object.assign(Object.assign({}, state), { ruleLibrary: Object.assign(Object.assign({}, state.ruleLibrary), { classificationRules }) });
}
export function updateRuleLibrarySelectedOrganizations(state, { payload: selectedOrganizations }) {
    return Object.assign(Object.assign({}, state), { ruleLibrary: Object.assign(Object.assign({}, state.ruleLibrary), { selectedOrganizations }) });
}
