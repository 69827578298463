import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, pageHeader, breakpoints, appHeader, label, }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        '&>*': {
            marginLeft: spacing(2),
        },
    },
    title: Object.assign(Object.assign({}, pageHeader), { color: label.color, whiteSpace: 'nowrap', backgroundColor: 'transparent', top: appHeader.height, [breakpoints.down('sm')]: {
            top: appHeader.height * 2,
        } }),
});
const FilterWrapper = withStyles(styles)(function FilterWrapper({ title, classes, children }) {
    return (<div className={classes.root}>
      <Typography className={classes.title}>{title}:</Typography>
      {children}
    </div>);
});
export default FilterWrapper;
