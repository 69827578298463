import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@/decorators';
import { REPORTS } from '.';
const TAB_HEIGHT = 48;
export const styles = ({ palette, table }) => ({
    tabContainer: {
        width: '100%',
        minHeight: TAB_HEIGHT,
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            bottom: 0,
            zIndex: 0,
            borderBottom: `2px solid ${table.highlight}`,
        },
    },
    tab: {
        textTransform: 'capitalize',
        fontSize: '15px',
        color: palette.mainFont,
        '&$selected': {
            background: table.background,
            transitionDelay: '300ms',
        },
    },
    tabIndicator: {
        background: 'transparent',
        height: TAB_HEIGHT,
        top: 0,
        border: `2px solid ${table.highlight}`,
        borderBottom: `2px solid ${table.background}`,
        zIndex: 1,
        boxSizing: 'border-box',
    },
    tabLabelContainer: {
        '&:after': {
            content: "''",
            position: 'absolute',
            height: '50%',
            top: '25%',
            left: 0,
            borderLeft: `1px solid ${palette.contrastFont}`,
        },
    },
    selected: {},
});
const TabHeaders = withStyles(styles)(function TabHeaders({ currentReport, onChange, classes }) {
    return (<Tabs value={currentReport} onChange={onChange} scrollable scrollButtons="off" classes={{
        flexContainer: classes.tabContainer,
        indicator: classes.tabIndicator,
    }}>
      {REPORTS.map(({ id, label }, index) => (<Tab value={id} label={label} classes={{
        root: classes.tab,
        selected: classes.selected,
        wrapper: index !== 0 && currentReport !== REPORTS[index - 1].id ? classes.tabLabelContainer : null,
    }} key={id}/>))}
    </Tabs>);
});
export default TabHeaders;
