import { __decorate } from "tslib";
import React from 'react';
import NoDataError from '@/components/no-data-error';
import ReportTable from '@/components/reports/table-v2';
import InsightsModal from '@/components/reports/table-v2/enhancements/insights-modal';
import { Align, Type } from '@/components/reports/table-v2/types';
import { commaSeparateNumber, formatPercentageFromNumber, roundNumber } from '@/components/reports/table-v2/utils';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import PopularSearchesBody from './body';
import PopularSearchesHead from './head';
var ReportKey = State.Analytics.ResultKey;
var InsightsType = State.Analytics.InsightsType;
export const COLUMNS = [
    {
        id: 'rowNum',
        label: 'Rank',
        csvLabel: 'Rank',
        type: Type.SMALL,
        align: Align.LEFT,
        formatter: null,
        tooltip: '',
    },
    {
        id: ReportKey.TERM,
        label: 'Term',
        csvLabel: 'Term',
        formatter: null,
        tooltip: 'Term',
        type: Type.LARGE,
        align: Align.LEFT,
    },
    {
        id: ReportKey.UNIQUE_SEARCHES,
        label: '#',
        csvLabel: 'Unique Searches (#)',
        align: Align.RIGHT,
        formatter: commaSeparateNumber,
        tooltip: 'Unique Searches - the volume of searches',
        type: Type.SMALL,
    },
    {
        id: ReportKey.UNIQUE_VIEW_PRODUCT_RATIO,
        label: 'CTR',
        csvLabel: 'Clickthrough Rate (CTR)',
        align: Align.RIGHT,
        formatter: formatPercentageFromNumber,
        tooltip: 'Clickthrough Rate - how likely a shopper is to view a product',
        type: Type.SMALL,
    },
    {
        id: ReportKey.UNIQUE_ADD_TO_CART_RATIO,
        label: 'ATCR',
        csvLabel: 'Add To Cart Rate (ATCR)',
        align: Align.RIGHT,
        formatter: formatPercentageFromNumber,
        tooltip: 'Add To Cart Rate - how likely a shopper is to add a product from the results to their cart',
        type: Type.SMALL,
    },
    {
        id: ReportKey.UNIQUE_ORDER_RATIO,
        label: 'CVR',
        csvLabel: 'Conversion Rate (CVR)',
        align: Align.RIGHT,
        formatter: formatPercentageFromNumber,
        tooltip: 'Conversion Rate - how likely a shopper is to buy a product',
        type: Type.SMALL,
    },
    {
        id: ReportKey.CONVERSION_SCORE,
        label: 'CS',
        csvLabel: 'Conversion Score (CS)',
        align: Align.RIGHT,
        formatter: roundNumber,
        tooltip: 'Conversion Score - how effective the search term is at getting shoppers to buy products',
        type: Type.SMALL,
    },
    {
        id: ReportKey.EXPLORATION_SCORE,
        label: 'ES',
        csvLabel: 'Exploration Score (ES)',
        align: Align.RIGHT,
        formatter: roundNumber,
        tooltip: 'Exploration Score - how much shoppers explore the results set',
        type: Type.SMALL,
    },
];
export const selectors = {
    currentReport: Selectors.currentReportSelector,
    currentInsights: Selectors.currentInsightsSelector,
};
export const actionCreators = {
    loadInsights: ActionCreators.loadInsights,
    refreshInsights: ActionCreators.refreshInsights,
};
let PopularSearches = class PopularSearches extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            modalData: null,
            isModalOpen: false,
        };
        this.openModal = (modalData) => {
            const { loadInsights, currentInsights, refreshInsights } = this.props;
            if (modalData !== this.state.modalData) {
                Object.values(InsightsType).map((insight) => refreshInsights(insight, null));
            }
            this.setState({ modalData, isModalOpen: true });
            loadInsights(currentInsights, modalData[ReportKey.TERM]);
        };
        this.closeModal = () => this.setState({ isModalOpen: false });
    }
    render() {
        const { data, defaultSort, filterSize } = this.props;
        return data ? (<React.Fragment>
        <ReportTable body={PopularSearchesBody} columns={COLUMNS} data={data} defaultSort={defaultSort} filterSize={filterSize} head={PopularSearchesHead} onRowClick={(index) => this.openModal(data[index])}/>
        <InsightsModal snapshot={this.state.modalData} open={this.state.isModalOpen} onClose={this.closeModal}/>
      </React.Fragment>) : (<NoDataError />);
    }
};
PopularSearches = __decorate([
    connect(selectors, actionCreators)
], PopularSearches);
export default PopularSearches;
