import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var Template;
(function (Template) {
    Template.DEFAULT = {
        zones: [],
        image: 'default.png',
        thumbnailImage: 'thumbnail.png',
    };
    Template.CONFIG = {
        type: ModelType.TEMPLATE,
        defaults: Template.DEFAULT,
        filter: filterByNameAndId,
    };
})(Template || (Template = {}));
export default Template;
