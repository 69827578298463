import { connectRouter } from 'connected-react-router';
import { compose, mergeDeepRight } from 'ramda';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { mergePersistedState } from 'redux-localstorage';
import * as Reducers from '.';
import createAnalyticsReducer from './analytics';
import auth from './auth';
import cache from './cache';
import changeLog from './change-log';
import collections from './collections';
import copyToArea from './copy-to-area';
import enrich from './enrich';
import glancerAnalytics from './glancer-analytics';
import merchandising from './merchandising';
import notifications from './notifications';
import searchPreview from './search-preview';
import session from './session';
import ui from './ui';
export const enhanceReducers = compose(mergePersistedState(mergeDeepRight), combineReducers);
export default function createReducers(history) {
    return Reducers.enhanceReducers({
        analytics: createAnalyticsReducer(),
        auth,
        cache,
        collections,
        changeLog,
        copyToArea,
        enrich,
        form: formReducer,
        glancerAnalytics,
        merchandising,
        notifications,
        router: connectRouter(history),
        searchPreview,
        session,
        ui,
    });
}
