export const getDateRangeSettings = ({ overrides, urlPageSettings, pageSettings, defaults, }) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        start: (_c = (_b = (_a = overrides === null || overrides === void 0 ? void 0 : overrides.start) !== null && _a !== void 0 ? _a : urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.start) !== null && _b !== void 0 ? _b : pageSettings === null || pageSettings === void 0 ? void 0 : pageSettings.start) !== null && _c !== void 0 ? _c : defaults.start,
        end: (_f = (_e = (_d = overrides === null || overrides === void 0 ? void 0 : overrides.end) !== null && _d !== void 0 ? _d : urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.end) !== null && _e !== void 0 ? _e : pageSettings === null || pageSettings === void 0 ? void 0 : pageSettings.end) !== null && _f !== void 0 ? _f : defaults.end,
    });
};
export const buildDateRangePageSettingsShape = ({ start, end }) => ({
    dateRange: {
        start,
        end,
    },
});
