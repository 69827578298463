import { call, debounce, fork, put, select, takeEvery, takeLatest, } from 'redux-saga/effects';
import rootClient from '@/client';
import { NetworkException } from '@/client/fetch';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Selectors from '@/store/selectors';
import * as Tasks from './tasks';
export const VALIDATE_PASSWORD_DEBOUNCE = 200;
export const LOGIN_ERROR_KEY = 'login';
export const INACTIVE_SESSION_ERROR = 'You were logged out due to being inactive for 30 minutes. Please login again.';
export const FAILED_LOGIN_ERROR = 'Login Failed. Please try again.';
export const LOCKOUT_ERROR = 'This account has been locked after 5 failed login attempts. Contact an administrator from your organization to have your password reset.';
export const LOCKOUT_WARNING = 'This account has had several failed login attempts and will be locked after 5 consecutive failures. If you have forgotten your password, contact an administrator from your organization for assistance.';
export function* expireToken() {
    const { pathname, search } = yield select(Selectors.activeLocationSelector);
    yield put(ActionCreators.showError(LOGIN_ERROR_KEY, INACTIVE_SESSION_ERROR));
    yield put(ActionCreators.clearAuthentication());
    yield put(ActionCreators.goToLoginWithNext(pathname, search));
}
export function* authenticateUser({ payload: { email, password } }) {
    const next = yield select(Selectors.nextPathSelector);
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const { token, result: user } = yield call(rootClient.login, email, password);
        yield call(Tasks.login, token, user, next);
        yield put(ActionCreators.clearError(LOGIN_ERROR_KEY));
    }
    catch (e) {
        if (e instanceof NetworkException) {
            if ((e.errors.length !== 0 && e.errors[0].includes('failed login attempts'))
                || (e.warnings.length !== 0 && e.warnings[0].includes('is now locked'))) {
                return yield put(ActionCreators.showError(LOGIN_ERROR_KEY, LOCKOUT_ERROR));
            }
            if (e.warnings.length !== 0 && e.warnings[0].includes('several login failures')) {
                return yield put(ActionCreators.showError(LOGIN_ERROR_KEY, LOCKOUT_WARNING));
            }
        }
        yield put(ActionCreators.showError(LOGIN_ERROR_KEY, FAILED_LOGIN_ERROR));
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* initiateSSOAuthentication({ payload: provider }) {
    try {
        yield call(Tasks.openSSOPrompt, provider);
    }
    catch (e) {
        yield Tasks.simpleError('open external login', e);
    }
}
export function* authenticateSSOUser({ payload: { token, user } }) {
    const next = yield select(Selectors.nextPathSelector);
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        yield call(Tasks.login, token, user, next);
        yield put(ActionCreators.clearError(LOGIN_ERROR_KEY));
    }
    catch (e) {
        yield put(ActionCreators.showError(LOGIN_ERROR_KEY, FAILED_LOGIN_ERROR));
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* validateToken({ payload: nextAction }) {
    const token = yield select(Selectors.authTokenSelector);
    try {
        const { result: user } = yield call(rootClient.auth(token).validate);
        yield call(Tasks.refreshActiveArea, user.areas, token);
        yield put(ActionCreators.acknowledgeAuthentication(token, user));
        yield call(Tasks.adminKeyLoad, user);
        yield put(ActionCreators.loadGrove());
        if (nextAction) {
            yield put(nextAction);
        }
        yield put(ActionCreators.clearError(LOGIN_ERROR_KEY));
    }
    catch (e) {
        yield call(expireToken);
    }
}
export function* exchangeSSOCode(action) {
    const { payload: provider } = action;
    try {
        const queryString = yield select(Selectors.rawActiveQuerySelector);
        const { token, result: user } = yield call(rootClient.loginSSO, provider, queryString);
        if (!user || !token)
            throw new Error('Missing user or token value.');
        const data = yield call(ActionCreators.authenticateSSOUser, token, user);
        yield call(window.opener.postMessage, data, window.location.origin);
    }
    catch (e) {
        console.error(e);
        const errorMessage = `Failed to log in with external provider ${provider}.`;
        const error = yield call(ActionCreators.exchangeSSOCodeError, errorMessage, e.message);
        if (window.opener) {
            yield call(window.opener.postMessage, error, window.location.origin);
        }
    }
}
export function* getGrove() {
    const token = yield select(Selectors.authTokenSelector);
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const { result: grove } = yield call(rootClient.auth(token).grove);
        yield put(ActionCreators.refreshGrove(grove));
        // eslint-disable-next-line no-empty
    }
    catch (e) {
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* validatePasswordFormat({ payload: password, meta }) {
    if (!password)
        return;
    try {
        const { result } = yield call(rootClient.validatePassword, password);
        if (result.valid) {
            yield put(ActionCreators.validatePasswordFormatReply(result.requirements, meta));
        }
        else {
            yield put(ActionCreators.validatePasswordFormatError(result.requirements, meta));
        }
    }
    catch (e) {
        yield Tasks.simpleError('validate password', e);
    }
}
export function* getExternalAuthenticationProviders() {
    var _a;
    try {
        const providers = yield call(rootClient.fetchExternalAuthenticationProviders);
        yield put(ActionCreators.updateExternalAuthenticationProvidersList(providers));
    }
    catch (e) {
        if (e instanceof NetworkException && ((_a = e.errors) === null || _a === void 0 ? void 0 : _a.includes('Access Denied (403)'))) {
            console.warn('No External Authentication Providers Found');
        }
        else {
            yield Tasks.simpleError('get external authentication providers', e);
        }
    }
}
export function* displayError({ payload: { message, details } }) {
    console.error(details);
    yield Tasks.error(message);
}
export default function* authSaga() {
    yield takeEvery([Actions.VALIDATE_TOKEN, Actions.REFRESH_AREA_ACCESS], validateToken);
    yield takeEvery(Actions.EXCHANGE_SSO_CODE, exchangeSSOCode);
    yield takeEvery(Actions.EXCHANGE_SSO_CODE_ERROR, displayError);
    yield takeEvery(Actions.AUTHENTICATE_USER, authenticateUser);
    yield takeEvery(Actions.INITIATE_SSO_AUTHENTICATION, initiateSSOAuthentication);
    yield takeEvery(Actions.AUTHENTICATE_SSO_USER, authenticateSSOUser);
    yield takeLatest(Actions.LOAD_GROVE, getGrove);
    yield takeLatest(Actions.EXPIRE_TOKEN, expireToken);
    yield takeLatest(Actions.GET_EXTERNAL_AUTHENTICATION_PROVIDERS, getExternalAuthenticationProviders);
    yield debounce(VALIDATE_PASSWORD_DEBOUNCE, Actions.VALIDATE_PASSWORD_FORMAT, validatePasswordFormat);
}
