import React from 'react';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
import MenuItem from '../menu-item';
export const actionCreators = {
    logoutProxy: ActionCreators.proxyAction,
};
export const mergeProps = (_, { logoutProxy }) => ({
    logout: () => logoutProxy(ActionCreators.logout()),
});
const LogoutMenuItem = connect(null, actionCreators, mergeProps)(function LogoutMenuItem({ logout }) {
    return (<MenuItem onClick={logout}>
      <LogoutIcon />
      Logout
    </MenuItem>);
});
export default LogoutMenuItem;
