import { CriteriaBuilder, field, uuid, where, } from '@groupby/enrich-criteria';
import { parse } from '@groupby/enrich-criteria/cel';
import escapeStringRegexp from 'escape-string-regexp';
import State from '@/store/state';
import { convertToDate, filterOutLastTwoChar } from '@/utils';
var JobProgress = State.Enrich.JobProgress;
/**
 * Builds product Criteria for the Enrich Client.
 * Will chain predicates based on the data passed in to create a CriteriaBuilder instance.
 */
export function buildProductCriteria({ classified, idSubsets, organizations, query, selectedCategory, selectedClass, }) {
    const criteria = [];
    if (organizations === null || organizations === void 0 ? void 0 : organizations.length) {
        const organizationCriteria = buildOrganizationsCriteria(organizations);
        criteria.push(organizationCriteria);
    }
    if (selectedCategory) {
        const categoryCriteria = buildCategoryCriteria(selectedCategory);
        criteria.push(categoryCriteria);
    }
    if (query) {
        const queryCriteria = buildProductQueryCriteria(query);
        criteria.push(queryCriteria);
    }
    if (classified !== undefined && classified !== null) {
        const classifiedCriteria = buildClassifiedCriteria(classified);
        criteria.push(classifiedCriteria);
    }
    if (selectedClass) {
        const classCriteria = buildClassCriteria(selectedClass);
        criteria.push(classCriteria);
    }
    if (idSubsets) {
        const subsets = buildUuidSubsetCriteria(idSubsets);
        criteria.push(...subsets);
    }
    return criteria.reduce((finalCriteria, criterion) => finalCriteria.and(criterion), new CriteriaBuilder());
}
/**
 * Builds organizations Criteria for the Enrich Client based on an array of organizations.
 * Will convert string organizationIds to uuids.
 */
export function buildOrganizationsCriteria(organizations) {
    const organizationsUuid = organizations.map((organization) => uuid(organization));
    return where(field('organizationId'), '@<', organizationsUuid);
}
/**
 * Builds query Criteria for the Enrich Client product resource based on a query string.
 * Will escape RegExp characters in the given query string.
 */
export function buildProductQueryCriteria(query) {
    const regexQuery = new RegExp(escapeStringRegexp(query), 'i');
    return where([field('title'), '=~', regexQuery]).or([field('description'), '=~', regexQuery]);
}
/**
 * Builds selected category Criteria for the Enrich Client based on the selected category id.
 * Will convert string categoryIds to uuids.
 */
export function buildCategoryCriteria(selectedCategory) {
    const selectedCategoryUuid = uuid(selectedCategory);
    return where(field('categoryIds'), '@>', selectedCategoryUuid);
}
/**
 * Builds classified Criteria for the Enrich Client based on the classified status.
 */
export function buildClassifiedCriteria(classified) {
    return where(field('classId'), classified ? '!=' : '==', null);
}
/**
 * Builds selected class Criteria for the Enrich Client based on the selected class id.
 * Will convert string classIds to uuids.
 */
export function buildClassCriteria(selectedClass) {
    const selectedClassUuid = uuid(selectedClass);
    return where(field('classId'), '==', selectedClassUuid);
}
/**
 * Builds selected model Criteria for the Enrich Client based on the selected models.
 * Model ids are string descriptions rather than uuids. Therefore no conversion of ids takes place here.
 */
export function buildModelCriteria(selectedModels) {
    return where(field('config.modelId'), '@<', selectedModels);
}
/**
 * Builds date range Criteria for the Enrich Client based on the selected date range.
 * Will convert string dates to Date objects.
 */
export function buildDateRangeCriteria(dateRange) {
    const { startDate, endDate } = convertDateRangeDateToCriteriaDateType(dateRange);
    return where(field('createdAt'), '>=', startDate).and(field('createdAt'), '<', endDate);
}
/**
 * Builds selected user Criteria for the Enrich Client based on the selected users.
 * Will convert string userIds to uuids.
 */
export function buildUserCriteria(selectedUsers) {
    const usersUuid = selectedUsers.map((user) => uuid(user));
    return where(field('createdBy'), '@<', usersUuid);
}
/**
 * Builds Criteria for the Enrich Client to filter Job queries by AiClassification only.
 */
export function buildAiClassificationCriteria() {
    return where(field('kind'), '==', 'AiClassification');
}
/**
 * Builds a list of id subset Criteria.
 * Will convert the ids to uuids.
 *
 * @param idSubsets An array of objects containing an idField and ids.
 */
export function buildUuidSubsetCriteria(idSubsets) {
    return idSubsets.map(({ ids, idField }) => {
        const uuids = ids.map((id) => uuid(id));
        return where(field(idField), '@<', uuids);
    });
}
/**
 * Builds prediction state Criteria for the Enrich Client.
 * Builds predicate for completed jobs (progress of 1) or in progress jobs (progress less than 1)
 */
export function buildPredictionStateCriteria(predictionStateProgress) {
    switch (predictionStateProgress) {
        case JobProgress.complete:
            return where(field('progress'), '==', 1);
        case JobProgress.inProgress:
            return where(field('progress'), '<', 1);
        default:
            return new CriteriaBuilder();
    }
}
/**
 * Builds job Criteria for the Enrich Client.
 * Will chain predicates based on the data passed in to create a CriteriaBuilder instance.
 */
export function buildJobCriteria({ dateRange, jobKind, models, organizations, predictionState, users, }) {
    const criteria = [];
    if (jobKind === 'AiClassification') {
        const aiClassificationCriteria = buildAiClassificationCriteria();
        criteria.push(aiClassificationCriteria);
    }
    const dateRangeCriteria = buildDateRangeCriteria(dateRange);
    criteria.push(dateRangeCriteria);
    if (models === null || models === void 0 ? void 0 : models.length) {
        const modelsCriteria = buildModelCriteria(models);
        criteria.push(modelsCriteria);
    }
    if (organizations === null || organizations === void 0 ? void 0 : organizations.length) {
        const organizationCriteria = buildOrganizationsCriteria(organizations);
        criteria.push(organizationCriteria);
    }
    if (users === null || users === void 0 ? void 0 : users.length) {
        const userCriteria = buildUserCriteria(users);
        criteria.push(userCriteria);
    }
    const predictionStateCriteria = buildPredictionStateCriteria(predictionState);
    criteria.push(predictionStateCriteria);
    return criteria.reduce((finalCriteria, criterion) => finalCriteria.and(criterion), new CriteriaBuilder());
}
/**
 * Parses a string into its equivalent Criteria.
 * The empty string is parsed into no-op Criteria.
 */
export function parseCriteriaString(criteria) {
    return criteria ? parse(criteria) : new CriteriaBuilder();
}
/**
 * Converts date range string dates to accepted Date object.
 * One day is added to the end date so that query range includes the full end date.
 */
export function convertDateRangeDateToCriteriaDateType(dateRange) {
    const startDate = convertToDate(filterOutLastTwoChar(dateRange.start));
    const endDate = convertToDate(filterOutLastTwoChar(dateRange.end));
    endDate.setDate(endDate.getDate() + 1);
    return {
        startDate,
        endDate,
    };
}
/**
 * Builds rule Criteria for the Enrich Client.
 * Will chain predicates based on the data passed in to create a CriteriaBuilder instance.
 */
export function buildRuleCriteria({ ruleQuery: query, organizations, }) {
    const criteria = [];
    if (query) {
        const queryCriteria = buildRuleQueryCriteria(query);
        criteria.push(queryCriteria);
    }
    if (organizations.length) {
        const organizationCriteria = buildRuleOrganizationsCriteria(organizations);
        criteria.push(organizationCriteria);
    }
    return criteria.reduce((finalCriteria, criterion) => finalCriteria.and(criterion), new CriteriaBuilder());
}
/**
 * Builds query Criteria for the Enrich Client rule resource based on a query string.
 * Will escape RegExp characters in the given query string.
 */
export function buildRuleQueryCriteria(query) {
    const regexQuery = new RegExp(escapeStringRegexp(query), 'i');
    return where(field('name'), '=~', regexQuery);
}
/**
 * Builds organization Criteria for the Enrich Client rule resource based on an array of selected organizations.
 */
export function buildRuleOrganizationsCriteria(organizations) {
    const organizationsUuid = organizations.map((organization) => uuid(organization));
    return (where(field('organizationIds'), '@<', organizationsUuid).or(field('organizationIds'), '@>', organizationsUuid));
}
