export var Sort;
(function (Sort) {
    let Order;
    (function (Order) {
        Order["ASCENDING"] = "Ascending";
        Order["DESCENDING"] = "Descending";
    })(Order = Sort.Order || (Sort.Order = {}));
    Sort.ORDERS = [Order.ASCENDING, Order.DESCENDING];
})(Sort || (Sort = {}));
export var ZoneContent;
(function (ZoneContent) {
    let Type;
    (function (Type) {
        Type["CONTENT"] = "Content";
        Type["QUERY"] = "Query";
        Type["RICH_CONTENT"] = "Rich_Content";
        Type["EXPERT_QUERY"] = "Expert_Query";
        Type["GENERATED_CONTENT"] = "Generated_Content";
    })(Type = ZoneContent.Type || (ZoneContent.Type = {}));
    ZoneContent.TYPES = [
        Type.CONTENT,
        Type.QUERY,
        { value: Type.RICH_CONTENT, label: 'Rich Content' },
        { value: Type.EXPERT_QUERY, label: 'Expert Query' },
        { value: Type.GENERATED_CONTENT, label: 'Generated Content' },
    ];
    ZoneContent.DEFAULT = { type: Type.CONTENT };
})(ZoneContent || (ZoneContent = {}));
