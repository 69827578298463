import { __decorate } from "tslib";
import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@/components/controls/button';
import CheckBoxSet from '@/components/controls/checkbox-set';
import { DEVICE_OPTIONS } from '@/components/reports/container/filter-modal';
import { DEVICE_LIST } from '@/constants';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette }) => ({
    popover: {
        marginTop: spacing(1),
    },
    filterButton: {
        padding: `${spacing(1 / 2)}px ${spacing(1.25)}px`,
        fontSize: spacing(1.75),
        fontWeight: 500,
        minHeight: spacing(4),
        textTransform: 'capitalize',
        color: palette.mainFont,
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    labelClassName: {
        margin: 0,
    },
    buttonContainer: {
        display: 'flex',
        padding: `0 ${spacing(2)}px ${spacing(2)}px ${spacing(2)}px`,
        '&>:last-child': {
            marginLeft: spacing(1.5),
        },
    },
});
let DeviceTypeFilter = class DeviceTypeFilter extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            filterOpen: false,
            devices: this.props.devices,
        };
        this.buttonRef = React.createRef();
        this.openFilter = () => this.setState({ filterOpen: true, devices: this.props.devices || DEVICE_LIST });
        this.closeFilter = () => this.setState({ filterOpen: false });
        this.saveFilter = () => {
            this.props.onSave(this.state.devices);
            this.closeFilter();
        };
        this.onDeviceChange = (devices) => {
            if (devices.length !== 0) {
                this.setState({ devices });
            }
        };
    }
    render() {
        const { classes } = this.props;
        const { devices, filterOpen } = this.state;
        return (<div>
        <Button variant="tertiary" onClick={this.openFilter} buttonRef={this.buttonRef} className={classes.filterButton} disableRipple>
          Devices
        </Button>
        <Popover open={filterOpen} onClose={this.closeFilter} anchorEl={this.buttonRef.current} className={classes.popover} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
          <CheckBoxSet options={DEVICE_OPTIONS} className={classes.labelClassName} column selected={devices} onChange={this.onDeviceChange} labelClassName={classes.labelClassName}/>
          <div className={classes.buttonContainer}>
            <Button variant="primary" onClick={this.saveFilter}>
              save
            </Button>
            <Button variant="tertiary" onClick={this.closeFilter}>
              cancel
            </Button>
          </div>
        </Popover>
      </div>);
    }
};
DeviceTypeFilter = __decorate([
    (withStyles(styles))
], DeviceTypeFilter);
export default DeviceTypeFilter;
