import * as Actions from '../types/session';
import { createAction } from '../utils';
export const switchArea = (area, force) => createAction(Actions.SWITCH_AREA, area, ...(force ? [{ force }] : []));
export const switchModelType = (modelType) => createAction(Actions.SWITCH_MODEL_TYPE, modelType);
export const setLoading = (loading) => createAction(Actions.SET_LOADING, loading);
export const refreshAreaAccess = () => createAction(Actions.REFRESH_AREA_ACCESS);
export const loadGrove = () => createAction(Actions.LOAD_GROVE);
export const refreshGrove = (grove) => createAction(Actions.REFRESH_GROVE, grove);
export const addPrimaryKey = () => createAction(Actions.ADD_PRIMARY_KEY);
export const removeSecondaryKey = () => createAction(Actions.REMOVE_SECONDARY_KEY);
export const loadKeys = (force) => createAction(Actions.LOAD_KEYS, force);
export const refreshKeys = (keys) => createAction(Actions.REFRESH_KEYS, keys);
export const setInactive = () => createAction(Actions.SET_INACTIVE);
