import * as Actions from '@/store/actions/types';
import * as InsightsReducer from './insights';
export const DEFAULT = {};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_INSIGHTS:
            return InsightsReducer.refreshInsightsReducer(state, action);
        default:
            return state;
    }
};
export function refreshInsightsReducer(state, { payload: { insightsType, data } }) {
    return Object.assign(Object.assign({}, state), { [insightsType]: data });
}
