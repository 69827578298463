import * as Actions from '../types/enrich';
import { createAction } from '../utils';
export const loadEnrich = () => createAction(Actions.LOAD_ENRICH);
export const loadEnrichData = () => createAction(Actions.LOAD_ENRICH_DATA);
export const refreshEnrichData = (data) => createAction(Actions.REFRESH_ENRICH_DATA, data);
export const refreshEnrichSnapshots = (data) => createAction(Actions.REFRESH_ENRICH_SNAPSHOT, data);
export const refreshEnrichFilters = (dateRange) => createAction(Actions.REFRESH_ENRICH_FILTERS, dateRange);
export const refreshEnrichTokens = (tokens) => createAction(Actions.REFRESH_ENRICH_TOKENS, tokens);
export const refreshEnrichCustomerId = (customerId) => createAction(Actions.REFRESH_ENRICH_CUSTOMER_ID, customerId);
export const updateEnrichCurrentPageSize = (pageSize) => createAction(Actions.UPDATE_ENRICH_CURRENT_PAGE_SIZE, pageSize);
export const updateEnrichPageOffset = (offset) => createAction(Actions.UPDATE_ENRICH_PAGE_OFFSET, offset);
export const updateEnrichProductsExpanded = (expanded) => createAction(Actions.UPDATE_ENRICH_PRODUCTS_EXPANDED, expanded);
export const loadEnrichProducts = () => createAction(Actions.LOAD_ENRICH_PRODUCTS);
export const updateEnrichProducts = (products) => createAction(Actions.UPDATE_ENRICH_PRODUCTS, products);
export const loadEnrichRuleLibraryPreviewProducts = (filters) => createAction(Actions.LOAD_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS, filters);
export const updateEnrichRuleLibraryPreviewProducts = (products) => createAction(Actions.UPDATE_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS, products);
export const updateCurrentTotalEnrichItems = (currentTotalItems) => createAction(Actions.UPDATE_ENRICH_CURRENT_TOTAL_ITEMS, currentTotalItems);
export const updateEnrichProductCounts = (productCounts) => createAction(Actions.UPDATE_ENRICH_PRODUCT_COUNTS, productCounts);
export const updateEnrichProductSelection = (id, select) => createAction(Actions.UPDATE_ENRICH_PRODUCT_SELECTION, { id, select });
export const updateEnrichProductDetailsPanel = (product) => createAction(Actions.UPDATE_ENRICH_PRODUCT_DETAILS_PANEL, product);
export const closeEnrichProductDetailsPanel = () => createAction(Actions.CLOSE_ENRICH_PRODUCT_DETAILS_PANEL);
export const updateEnrichSelectedProductFields = (selectedFields) => createAction(Actions.UPDATE_ENRICH_SELECTED_PRODUCT_FIELDS, selectedFields);
export const loadEnrichTotals = () => createAction(Actions.LOAD_ENRICH_PRODUCT_COUNTS);
export const updateEnrichQuery = (query) => createAction(Actions.UPDATE_ENRICH_QUERY, query);
export const toggleEnrichSort = (field) => createAction(Actions.TOGGLE_ENRICH_SORT, field);
export const loadEnrichMLModels = () => createAction(Actions.LOAD_ENRICH_ML_MODELS);
export const updateEnrichMLModels = (models) => createAction(Actions.UPDATE_ENRICH_ML_MODELS, { models });
export const updateEnrichMLModelSelection = (model) => createAction(Actions.UPDATE_ENRICH_ML_MODEL_SELECTION, { model });
export const updateEnrichJobModelsSelection = (models) => createAction(Actions.UPDATE_ENRICH_JOB_MODELS_SELECTION, models);
export const updateEnrichJobManagementUsers = (users) => createAction(Actions.UPDATE_ENRICH_JOB_MANAGEMENT_USERS, users);
export const loadEnrichMLClassPredictions = () => (createAction(Actions.LOAD_ENRICH_ML_CLASS_PREDICTIONS));
export const updateEnrichMLClassPredictions = (classPredictions) => createAction(Actions.UPDATE_ENRICH_ML_CLASS_PREDICTIONS, classPredictions);
export const updateEnrichMLSelectedClassPredictions = (selectedClassPrediction) => createAction(Actions.UPDATE_ENRICH_ML_SELECTED_CLASS_PREDICTIONS, selectedClassPrediction);
export const clearEnrichMLSelectedClassPredictions = () => (createAction(Actions.CLEAR_ENRICH_ML_SELECTED_CLASS_PREDICTIONS));
export const removeEnrichMLSelectedClassPrediction = (selectedClassPredictionId) => createAction(Actions.REMOVE_ENRICH_ML_SELECTED_CLASS_PREDICTION, selectedClassPredictionId);
export const loadEnrichCategories = () => createAction(Actions.LOAD_ENRICH_CATEGORIES);
export const updateEnrichCategories = (categories) => createAction(Actions.UPDATE_ENRICH_CATEGORIES, categories);
export const updateEnrichSelectedCategory = (categoryId) => createAction(Actions.UPDATE_ENRICH_SELECTED_CATEGORY, categoryId);
export const loadAllEnrichOrganizations = () => createAction(Actions.LOAD_ALL_ENRICH_ORGANIZATIONS);
export const updateEnrichOrganizations = (organizationList) => createAction(Actions.UPDATE_ENRICH_ORGANIZATIONS, organizationList);
export const updateSelectedEnrichOrganizations = (selectedOrganizations) => createAction(Actions.UPDATE_SELECTED_ENRICH_ORGANIZATIONS, selectedOrganizations);
export const updateEnrichTaxonomyNodes = (taxonomyNodes) => createAction(Actions.UPDATE_ENRICH_TAXONOMY_NODES, taxonomyNodes);
export const updateEnrichSelectedTaxonomyNodeId = (taxonomyNodeId) => createAction(Actions.UPDATE_ENRICH_SELECTED_TAXONOMY_NODE_ID, taxonomyNodeId);
export const updateEnrichClasses = (classes) => createAction(Actions.UPDATE_ENRICH_CLASSES, classes);
export const updateEnrichProductClassId = (productId, classId) => createAction(Actions.UPDATE_ENRICH_PRODUCT_CLASS_ID, { productId, classId });
export const receiveEnrichProductClassId = (productId, classId) => createAction(Actions.RECEIVE_ENRICH_PRODUCT_CLASS_ID, { productId, classId });
export const updateEnrichExpandedCategories = (expandedCategoryIds) => createAction(Actions.UPDATE_ENRICH_EXPANDED_CATEGORIES, expandedCategoryIds);
export const updateEnrichExpandedTaxonomyNodes = (expandedTaxonomyNodeIds) => createAction(Actions.UPDATE_ENRICH_EXPANDED_TAXONOMY_NODES, expandedTaxonomyNodeIds);
export const updateEnrichResourceLoadingStatus = (resourceLoadingStatus) => createAction(Actions.UPDATE_ENRICH_RESOURCE_LOADING_STATUS, resourceLoadingStatus);
export const createEnrichClassificationJob = () => createAction(Actions.CREATE_ENRICH_CLASSIFICATION_JOB);
export const loadEnrichTaxonomyNodes = () => createAction(Actions.LOAD_ENRICH_TAXONOMY_NODES);
export const loadEnrichClasses = () => createAction(Actions.LOAD_ENRICH_CLASSES);
export const switchEnrichPage = (pageName, overrides) => createAction(Actions.SWITCH_ENRICH_PAGE, { pageName, overrides });
export const updateEnrichPageSettings = (pageName, settings) => createAction(Actions.UPDATE_ENRICH_PAGE_SETTINGS, { pageName, settings });
export const updateEnrichPageName = (pageName) => createAction(Actions.UPDATE_ENRICH_PAGE_NAME, pageName);
export const notifyEnrichPageVisited = () => createAction(Actions.NOTIFY_ENRICH_PAGE_VISITED);
export const updateEnrichVisited = () => createAction(Actions.UPDATE_ENRICH_VISITED);
export const saveEnrichMLClassPredictions = () => createAction(Actions.SAVE_ENRICH_ML_CLASS_PREDICTIONS);
export const deleteEnrichMLClassPrediction = (id) => createAction(Actions.DELETE_ENRICH_ML_CLASS_PREDICTION, id);
export const clearEnrichTaxonomyNodeSelections = () => createAction(Actions.CLEAR_ENRICH_TAXONOMY_NODE_SELECTIONS);
export const clearEnrichCategorySelections = () => createAction(Actions.CLEAR_ENRICH_CATEGORY_SELECTIONS);
export const updateEnrichConfidenceThreshold = (threshold) => createAction(Actions.UPDATE_ENRICH_CONFIDENCE_THRESHOLD, threshold);
export const loadEnrichAiClassificationJobs = () => createAction(Actions.LOAD_ENRICH_AI_CLASSIFICATION_JOBS);
export const updateEnrichAiClassificationJobs = (jobs) => createAction(Actions.UPDATE_ENRICH_AI_CLASSIFICATION_JOBS, jobs);
export const updateEnrichDateRange = (dateRange) => createAction(Actions.UPDATE_ENRICH_DATE_RANGE, dateRange);
export const updateEnrichRemainingPredictionsByJob = (remainingPredictionsByJob) => createAction(Actions.UPDATE_ENRICH_REMAINING_PREDICTIONS_BY_JOB, remainingPredictionsByJob);
export const updateEnrichJobsPredictionState = (jobProgress) => createAction(Actions.UPDATE_ENRICH_JOBS_PREDICTION_STATE, jobProgress);
export const updateEnrichJobSubmitterSelection = (submitters) => createAction(Actions.UPDATE_ENRICH_JOB_SUBMITTER_SELECTION, submitters);
export const loadEnrichPageData = () => createAction(Actions.LOAD_ENRICH_PAGE_DATA);
export const clearEnrichResourceLoadingStatus = () => createAction(Actions.CLEAR_ENRICH_RESOURCE_LOADING_STATUS);
export const createEnrichClassificationRule = (classificationRuleCreation) => createAction(Actions.CREATE_ENRICH_CLASSIFICATION_RULE, classificationRuleCreation);
export const updateEnrichClassificationRule = (classificationRuleUpdate) => createAction(Actions.UPDATE_ENRICH_CLASSIFICATION_RULE, classificationRuleUpdate);
export const deleteEnrichClassificationRule = (classificationRuleId) => createAction(Actions.DELETE_ENRICH_CLASSIFICATION_RULE, classificationRuleId);
export const loadEnrichClassificationRules = () => createAction(Actions.LOAD_ENRICH_CLASSIFICATION_RULES);
export const updateEnrichClassificationRules = (classificationRules) => createAction(Actions.UPDATE_ENRICH_CLASSIFICATION_RULES, classificationRules);
export const prepareEnrichClassificationRule = (classificationRuleId) => createAction(Actions.PREPARE_ENRICH_CLASSIFICATION_RULE, classificationRuleId);
export const updateEnrichRuleLibrarySelectedOrganizations = (selectedOrganizations) => createAction(Actions.UPDATE_ENRICH_RULE_LIBRARY_SELECTED_ORGANIZATIONS, selectedOrganizations);
