import { __decorate } from "tslib";
import React from 'react';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import Button from '@/components/controls/button';
import DateRangePicker from '@/components/controls/date-range-picker';
import { DATE_FORMAT_HOUR } from '@/constants';
import { withStyles } from '@/decorators';
import * as Utils from '@/utils';
export const styles = ({ spacing, palette }) => ({
    button: {
        background: 'none',
        lineHeight: 2,
        padding: 0,
        borderRadius: 0,
        minHeight: spacing(4),
        borderBottom: `1px solid ${palette.mainFont}`,
        color: palette.mainFont,
        textTransform: 'capitalize',
        fontWeight: 400,
        paddingLeft: spacing(1 / 2),
        '&:disabled': {
            background: '#f1f1f1',
            borderBottom: '1px solid #e0e0e0',
            borderRight: '0px solid',
            color: 'rgba(0,0,0,0.6)',
        },
        '&:hover': {
            background: 'inherit',
            borderBottomWidth: 2,
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
});
let DashboardDateRangePicker = class DashboardDateRangePicker extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            popoverOpen: false,
        };
        this.dateControlEl = React.createRef();
        this.handleOpen = () => this.setState({ popoverOpen: true });
        this.handleClose = () => this.setState({ popoverOpen: false });
        this.handleSelect = (start, end) => this.props.setDateRange(Utils.convertToUtc(start), Utils.convertToUtc(end));
    }
    render() {
        const { dateRange: { start, end }, minDate, maxDate, maxSelectRange, classes, disabled, } = this.props;
        const localStartDate = Utils.convertToLocal(start);
        const localEndDate = Utils.convertToLocal(end);
        const startDate = Utils.filterOutLastTwoChar(localStartDate);
        const endDate = Utils.filterOutLastTwoChar(localEndDate);
        const startTime = Utils.extractLastTwoChar(localStartDate);
        const endTime = Utils.extractLastTwoChar(localEndDate);
        return (<React.Fragment>
        <Button className={classes.button} buttonRef={this.dateControlEl} onClick={this.handleOpen} disableRipple flat disabled={disabled}>
          {formatUiDate(startDate)} – {formatUiDate(endDate)}
          <ArrowDropDownIcon />
        </Button>
        <Popover open={this.state.popoverOpen} anchorEl={this.dateControlEl.current} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={this.handleClose}>
          <DateRangePicker range={{
            startDate,
            endDate,
            startTime,
            endTime,
        }} onClose={this.handleClose} onChange={this.handleSelect} maxDate={maxDate} minDate={minDate} maxSelectRange={maxSelectRange}/>
        </Popover>
      </React.Fragment>);
    }
};
DashboardDateRangePicker = __decorate([
    (withStyles(styles))
], DashboardDateRangePicker);
export default DashboardDateRangePicker;
export function formatUiDate(date) {
    return moment(date, DATE_FORMAT_HOUR).format('MMM DD YYYY');
}
