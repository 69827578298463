import React from 'react';
import Grid from '@material-ui/core/Grid';
import NoDataIcon from 'mdi-react/DatabaseRemoveIcon';
import { withStyles } from '@/decorators';
export const styles = ({ label, spacing }) => ({
    root: {
        height: '100%',
    },
    error: {
        textAlign: 'center',
        color: label.color,
        fontSize: spacing(2),
    },
});
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const NoDataError = withStyles(styles)(function NoDataError({ classes }) {
    return (<Grid container item xs={12} justify="center" alignItems="center" className={classes.root}>
      <Grid className={classes.error}>
        <NoDataIcon size={40}/>
        <div>No Data Available</div>
      </Grid>
    </Grid>);
});
export default NoDataError;
