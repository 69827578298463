import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '.';
import Form from '../form';
import { connectForm } from '../utils';
class ConnectedListFormManager extends React.Component {
    render() {
        const { fields, editingIndex, formComponent: FormComponent, displayComponent, reorderable, onEdit, onSave, onReorder, onAdd, onChange, formName, defaultValues, sort, limit, } = this.props;
        const ConnectedForm = connectForm(formName, defaultValues, Form);
        return (<React.Fragment>
        <List fields={fields} editingIndex={editingIndex} formComponent={FormComponent} displayComponent={displayComponent} reorderable={reorderable} onEdit={onEdit} onSave={onSave} onReorder={onReorder} sort={sort} limit={limit}/>
        <ConnectedForm onAdd={onAdd} onChange={onChange}>
          <Grid container alignItems="center" spacing={2}>
            <FormComponent member="value"/>
          </Grid>
        </ConnectedForm>
      </React.Fragment>);
    }
}
export default ConnectedListFormManager;
