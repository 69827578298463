import moize from 'moize';
import { isFilterManagementCustomer } from '@/config/feature-flags';
import { APIType, ChartType, ModelType, MODEL_PATHS, Role, Routing, } from '@/constants';
import State from '@/store/state';
var DashboardType = State.Analytics.DashboardType;
var ReportType = State.Analytics.ReportType;
export const ANALYTICS_ROUTES = {
    path: Routing.ANALYTICS_PATH,
    sections: [
        {
            label: 'Search Analytics',
            path: Routing.Section.Analytics.SEARCH,
            routes: [
                {
                    path: DashboardType.SEARCH_PERFORMANCE,
                    label: 'Search Performance',
                },
                {
                    path: DashboardType.SEARCH_TERM,
                    label: 'Search Term Analysis',
                },
                {
                    path: ReportType.TOP_REFINEMENTS,
                    label: 'Top Refinements',
                    roles: [Role.ADMIN],
                },
            ],
        },
        {
            label: 'Beacons',
            path: Routing.Section.Analytics.BEACON,
            routes: [
                {
                    path: APIType.BEACON_HEALTH,
                    label: 'Health Check',
                },
            ],
        },
        {
            label: 'Monitoring',
            path: Routing.Section.Analytics.MONITORING,
            routes: [
                {
                    path: DashboardType.API_RESPONSE_TIME,
                    label: 'API Response Time',
                },
            ],
        },
        {
            label: 'Contract Metrics',
            path: Routing.Section.Analytics.CONTRACT_METRICS,
            routes: [
                {
                    path: DashboardType.API_USAGE,
                    label: 'API Usage',
                },
                {
                    path: ChartType.RECORD_COUNT,
                    label: 'Record Count',
                },
            ],
        },
    ],
};
export const GLANCER_ANALYTICS_ROUTES = {
    path: Routing.GLANCER_ANALYTICS_PATH,
    sections: [
        {
            label: 'Beacons',
            path: Routing.Section.Analytics.BEACON,
            routes: [
                {
                    path: APIType.BEACON_HEALTH,
                    label: 'Health Check',
                },
            ],
        },
    ],
};
export const MERCHANDISING_ROUTES = {
    path: Routing.MERCHANDISING_PATH,
    sections: [
        {
            label: 'Site Management',
            path: Routing.Section.Merchandising.SITE,
            routes: [
                {
                    name: ModelType.RULE,
                    path: MODEL_PATHS[ModelType.RULE],
                    label: 'Rules',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.ZONE,
                    path: MODEL_PATHS[ModelType.ZONE],
                    label: 'Zones',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.RELATED_QUERY,
                    path: MODEL_PATHS[ModelType.RELATED_QUERY],
                    label: 'Related Queries',
                    roles: [Role.QUERY_REWRITE],
                },
            ],
        },
        {
            label: 'Recall & Relevancy',
            path: Routing.Section.Merchandising.RECALL,
            routes: [
                {
                    name: ModelType.BIASING_PROFILE,
                    path: MODEL_PATHS[ModelType.BIASING_PROFILE],
                    label: 'Biasing Profiles',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
                {
                    name: ModelType.MATCH_STRATEGY,
                    path: MODEL_PATHS[ModelType.MATCH_STRATEGY],
                    label: 'Match Strategy',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
                ...(isFilterManagementCustomer()
                    ? [
                        {
                            name: ModelType.FILTER,
                            path: MODEL_PATHS[ModelType.FILTER],
                            label: 'Area Filters',
                            roles: [Role.ENGINE_CONFIGURATION],
                        },
                    ]
                    : []),
                {
                    name: ModelType.SPELLING,
                    path: MODEL_PATHS[ModelType.SPELLING],
                    label: 'Spelling',
                    roles: [Role.QUERY_REWRITE],
                },
                {
                    name: ModelType.SYNONYM,
                    path: MODEL_PATHS[ModelType.SYNONYM],
                    label: 'Synonym',
                    roles: [Role.QUERY_REWRITE],
                },
                {
                    name: ModelType.STOP_WORD,
                    path: MODEL_PATHS[ModelType.STOP_WORD],
                    label: 'Stop Words',
                    roles: [Role.QUERY_REWRITE],
                },
                {
                    name: ModelType.PHRASE,
                    path: MODEL_PATHS[ModelType.PHRASE],
                    label: 'Phrases',
                    roles: [Role.QUERY_REWRITE],
                },
                {
                    name: ModelType.REDIRECT,
                    path: MODEL_PATHS[ModelType.REDIRECT],
                    label: 'Redirects',
                    roles: [Role.QUERY_REWRITE],
                },
            ],
        },
        {
            label: 'Configurations',
            path: Routing.Section.Merchandising.CONFIG,
            routes: [
                {
                    name: ModelType.TEMPLATE,
                    path: MODEL_PATHS[ModelType.TEMPLATE],
                    label: 'Templates',
                    roles: [Role.MERCHANDISING],
                },
                {
                    name: ModelType.NAVIGATION,
                    path: MODEL_PATHS[ModelType.NAVIGATION],
                    label: 'Dynamic Navigation',
                    roles: [Role.ENGINE_CONFIGURATION],
                },
            ],
        },
        {
            label: 'Admin',
            path: Routing.Section.Merchandising.ADMIN,
            routes: [
                {
                    name: ModelType.AREA,
                    path: MODEL_PATHS[ModelType.AREA],
                    label: 'Area Management',
                    roles: [Role.ADMIN],
                },
                {
                    name: ModelType.USER,
                    path: MODEL_PATHS[ModelType.USER],
                    label: 'User Management',
                    roles: [Role.ADMIN],
                },
                {
                    name: ModelType.KEY,
                    label: 'Key Management',
                    path: MODEL_PATHS[ModelType.KEY],
                    options: { skipReducer: true },
                    roles: [Role.ADMIN],
                },
            ],
        },
        {
            label: 'History',
            path: Routing.Section.Merchandising.HISTORY,
            routes: [
                {
                    name: ModelType.CHANGE_LOG,
                    path: MODEL_PATHS[ModelType.CHANGE_LOG],
                    label: 'Change Log',
                    roles: [Role.ADMIN],
                },
            ],
            roles: [Role.ADMIN],
        },
    ],
};
export const ENRICH_ROUTES = {
    path: Routing.ENRICH_PATH,
    sections: [
        {
            label: 'Product library',
            path: Routing.Section.Enrich.LIBRARY,
            routes: [
                {
                    path: Routing.Page.Enrich.BY_CATEGORY,
                    label: 'By category',
                },
                {
                    path: Routing.Page.Enrich.BY_TAXONOMY,
                    label: 'By taxonomy',
                },
            ],
        },
        // CUE-381: hide out of scope nav links
        // {
        //   label: 'Taxonomy',
        //   path: Routing.Section.Enrich.TAXONOMY,
        //   routes: [
        //     {
        //       path: Routing.Page.Enrich.GLOBAL_TAXONOMY,
        //       label: 'Global taxonomy',
        //     },
        //   ],
        // },
        {
            label: 'Rules',
            path: Routing.Section.Enrich.RULES,
            routes: [
                {
                    path: Routing.Page.Enrich.RULE_LIBRARY,
                    label: 'Rule library',
                },
            ],
        },
    ],
};
export const extractRoutes = moize((sections) => sections.reduce((acc, section) => [...acc, ...section.routes], []));
