import { ModelType } from '@/constants';
import { filterByIdAnd } from '@/utils';
var Spelling;
(function (Spelling) {
    Spelling.DEFAULT = { promote: true };
    Spelling.CONFIG = {
        type: ModelType.SPELLING,
        defaults: Spelling.DEFAULT,
        filter: filterByIdAnd(['name', 'correction']),
        promotable: true,
    };
})(Spelling || (Spelling = {}));
export default Spelling;
