import React from 'react';
import PageTitle from '@/components/page/title';
import PageWrapper from '@/components/page/wrapper';
import StaticContent from './content';
import StaticHeader from './header';
function StaticPage({ id, title, darkMode, children, }) {
    return (<PageWrapper id={id} darkMode={darkMode}>
      {title && (<StaticHeader>
          <PageTitle>{title}</PageTitle>
        </StaticHeader>)}
      <StaticContent>{children}</StaticContent>
    </PageWrapper>);
}
export default StaticPage;
