import { __decorate } from "tslib";
import React from 'react';
import classNames from 'classnames';
import * as ReactDND from 'react-dnd';
import { reduxForm } from 'redux-form';
import { withStyles } from '@/decorators';
import * as Selectors from '@/store/selectors';
import theme from '@/theme';
import { connect } from '@/utils';
import { ListItemPreview, NavigationItemPreview, ProductItemPreview, TableRowPreview, } from './preview';
export const DRAG_FORM = 'drag';
export const ItemTypes = {
    LIST_ITEM: 'ListItem',
    TABLE_ROW: 'TableRow',
    PRODUCT_ITEM: 'ProductItem',
    NAVIGATION_ITEM: 'NavigationItem',
};
export const styles = ({ palette, spacing, zIndex, breakpoints, }) => ({
    root: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: zIndex.drag,
        left: 0,
        top: 0,
        padding: '4%',
        width: '100%',
        height: '100%',
    },
    preview: {
        display: 'flex',
        maxWidth: '75%',
        padding: `${spacing(1 / 4)}px ${spacing(1 / 2)}px`,
        backgroundColor: palette.highlight,
        borderRadius: 2,
        '&$withPreviewDrawer': {
            maxWidth: '30%',
        },
        '&$thumbnail': {
            backgroundColor: 'transparent',
            maxWidth: '10%',
            [breakpoints.down('md')]: {
                maxWidth: '15%',
            },
        },
        '&$navigationItem': {
            backgroundColor: 'transparent',
            maxWidth: '20%',
            [breakpoints.down('md')]: {
                maxWidth: '25%',
            },
        },
        '&>*': {
            flex: 1,
        },
    },
    withPreviewDrawer: {},
    thumbnail: {},
    navigationItem: {},
});
export const selectors = {
    previewDrawerIsOpen: Selectors.previewDrawerOpenSelector,
};
let DragLayer = class DragLayer extends React.PureComponent {
    render() {
        const { item, itemType, isDragging, previewDrawerIsOpen, classes, } = this.props;
        if (!isDragging) {
            return null;
        }
        return (<div className={classes.root}>
        <div className={classNames(classes.preview, {
            [classes.withPreviewDrawer]: previewDrawerIsOpen,
            [classes.thumbnail]: itemType === ItemTypes.PRODUCT_ITEM,
            [classes.navigationItem]: itemType === ItemTypes.NAVIGATION_ITEM,
        })} style={getItemStyles(this.props.currentOffset)}>
          {this.renderItem(itemType, item)}
        </div>
      </div>);
    }
    renderItem(type, itemProps) {
        switch (type) {
            case ItemTypes.LIST_ITEM:
                return <ListItemPreview {...itemProps}/>;
            case ItemTypes.TABLE_ROW:
                return <TableRowPreview {...itemProps}/>;
            case ItemTypes.PRODUCT_ITEM:
                return <ProductItemPreview {...itemProps}/>;
            case ItemTypes.NAVIGATION_ITEM:
                return <NavigationItemPreview {...itemProps}/>;
            default:
                return null;
        }
    }
};
DragLayer = __decorate([
    connect(selectors),
    (withStyles(styles)),
    (reduxForm({ form: DRAG_FORM })),
    (ReactDND.DragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    })))
], DragLayer);
export default DragLayer;
export function getItemStyles(offset) {
    if (!offset) {
        return { display: 'none' };
    }
    const { x, y } = offset;
    const transform = `translate(${x}px, calc(${y}px - ${theme.footer.height}px))`;
    return {
        transform,
        WebkitTransform: transform,
    };
}
