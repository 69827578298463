import React from 'react';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import Route from './route';
export const selectors = {
    activeRoute: Selectors.activeRouteSelector,
};
const RouteLink = connect(selectors)(function RouteLink({ label, onClick, activeRoute, path, darkMode, }) {
    const active = activeRoute === path || activeRoute.startsWith(`${path}/`);
    const handleClick = () => (path === activeRoute && !window.location.search ? null : onClick());
    return <Route label={label} onClick={handleClick} active={active} url={path} darkMode={darkMode}/>;
});
export default RouteLink;
