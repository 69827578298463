import { __decorate } from "tslib";
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const ELLIPSES_TIMEOUT = 250;
export const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        padding: spacing(2),
        justifyContent: 'center',
    },
    top: {
        flexDirection: 'column-reverse',
    },
    right: {
        flexDirection: 'row',
        '& span': {
            marginRight: spacing(2),
        },
    },
    bottom: {
        flexDirection: 'column',
    },
    left: {
        flexDirection: 'row-reverse',
        '& span': {
            marginLeft: spacing(2),
        },
    },
});
let Loading = class Loading extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { ellipses: '', loading: false };
        this.timer = null;
        this.ellipsesTimer = null;
        this.showLoading = () => this.setState({ loading: true });
        this.updateEllipses = () => {
            if (this.state.ellipses.length < 3) {
                this.setState({ ellipses: `${this.state.ellipses}.` });
            }
            else {
                this.setState({ ellipses: '' });
            }
        };
    }
    render() {
        const { iconOrientation = 'bottom', isLoading, label = 'Loading', progressSize = 40, rootClass, showEllipsis = true, classes, } = this.props;
        if (!isLoading || !this.state.loading) {
            return null;
        }
        return (<div className={classNames(classes.root, classes[iconOrientation], rootClass)}>
        {label && (<Typography variant="caption">
            {label}
            {showEllipsis && this.state.ellipses}
          </Typography>)}
        <CircularProgress size={progressSize}/>
      </div>);
    }
    componentDidMount() {
        this.updateTimers(this.props.isLoading);
    }
    componentWillUnmount() {
        this.clearTimers();
    }
    componentDidUpdate() {
        this.updateTimers(this.props.isLoading);
    }
    updateTimers(isLoading) {
        const hasTimers = this.timer !== null && this.ellipsesTimer !== null;
        if (isLoading && !hasTimers) {
            this.setTimers();
        }
        else if (!isLoading) {
            this.clearTimers();
        }
    }
    setTimers() {
        this.timer = setTimeout(this.showLoading, this.props.timeout);
        this.ellipsesTimer = setInterval(this.updateEllipses, ELLIPSES_TIMEOUT);
    }
    clearTimers() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.ellipsesTimer) {
            clearInterval(this.ellipsesTimer);
            this.ellipsesTimer = null;
        }
    }
};
Loading.defaultProps = {
    timeout: 0,
};
Loading = __decorate([
    (withStyles(styles))
], Loading);
export default Loading;
export function SimpleLoading() {
    return <Loading isLoading label="loading"/>;
}
