import { __decorate } from "tslib";
import React from 'react';
import IdleTimer from 'react-idle-timer';
import * as MerchandisingActions from '@/store/actions/merchandising';
import { withArea } from '@/store/area-context';
import { connect } from '@/utils';
import IdleAlert from './idle-alert';
import LandingAlert from './landing-alert';
import TimedOutAlert from './timed-out-alert';
export const RESOURCE_LOCK_DURATIONS = {
    HEARTBEAT_DEBOUNCE: 30 * 1000,
    IDLE_TO_MODAL: 5 * 60 * 1000,
    MODAL_TO_UNLOCK: 5 * 60 * 1000,
    POLL_DEBOUNCE: 30 * 1000,
};
export const actionCreators = {
    refreshLock: MerchandisingActions.creators.refreshLock,
};
let LockedResourceAlertManager = class LockedResourceAlertManager extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            openUnavailableAlert: this.props.isDisabled,
            idle: false,
        };
        this.closeAlert = () => this.setState({ openUnavailableAlert: false });
        this.setIdle = () => this.setState({ idle: true });
        this.setActive = () => this.setState({ idle: false });
    }
    render() {
        const { email, isDisabled, isOwner, refreshLock, } = this.props;
        const { idle, openUnavailableAlert } = this.state;
        if (openUnavailableAlert) {
            return <LandingAlert email={email} onOkay={this.closeAlert}/>;
        }
        if (idle) {
            if (isOwner) {
                return <IdleAlert reset={this.setActive}/>;
            }
            return <TimedOutAlert />;
        }
        if (isDisabled) {
            return null;
        }
        return (<IdleTimer timeout={RESOURCE_LOCK_DURATIONS.IDLE_TO_MODAL} onIdle={this.setIdle} onAction={refreshLock} debounce={RESOURCE_LOCK_DURATIONS.HEARTBEAT_DEBOUNCE}/>);
    }
};
LockedResourceAlertManager = __decorate([
    withArea,
    connect(null, actionCreators)
], LockedResourceAlertManager);
export default LockedResourceAlertManager;
