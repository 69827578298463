import { __decorate } from "tslib";
import React from 'react';
import DateRangePicker from '@/components/dashboard/date-range-picker';
import FilterWrapper from '@/components/dashboard/filters/wrapper';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as Utils from '@/utils';
import { getLastNumberOfDays, getToday } from '@/utils/time';
import { MAX_ANALYTICS_SELECT_RANGE } from '@/constants';
var DashboardType = State.Analytics.DashboardType;
export const searchTermFilter = Selectors.filterSelector(DashboardType.SEARCH_TERM);
export const selectors = {
    filters: searchTermFilter,
};
// eslint-disable-next-line max-len
export const setSearchTermDateRange = (start, end) => ActionCreators.setDashboardV2DateRange(start, end, DashboardType.SEARCH_TERM);
export const actionCreators = {
    setDateRange: setSearchTermDateRange,
    loadReport: ActionCreators.loadReport,
};
let GlobalFilters = class GlobalFilters extends React.PureComponent {
    render() {
        const { filters: { dateRange }, setDateRange, } = this.props;
        return (<FilterWrapper title="Time Range">
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} maxSelectRange={MAX_ANALYTICS_SELECT_RANGE}/>
      </FilterWrapper>);
    }
    componentDidMount() {
        const { filters: { dateRange }, } = this.props;
        if (dateRange.start) {
            this.props.loadReport(this.props.reportType);
        }
        else {
            this.props.setDateRange(getLastNumberOfDays(29), getToday());
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.filters.dateRange !== this.props.filters.dateRange) {
            this.props.loadReport(this.props.reportType);
        }
    }
};
GlobalFilters = __decorate([
    Utils.connect(selectors, actionCreators)
], GlobalFilters);
export default GlobalFilters;
