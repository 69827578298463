import blueGrey from '@material-ui/core/colors/blueGrey';
import createSpacing from '@material-ui/core/styles/createSpacing';
import State from '@/store/state';
var IDs = State.Analytics.ResultKey;
const spacing = createSpacing(8);
export const ACCENT_COLOURS = {
    50: '#f1f9e2',
    100: '#ddf0b7',
    200: '#c6e787',
    300: '#afdd56',
    400: '#9dd532',
    500: '#8cce0e',
    600: '#84c90c',
    700: '#79c20a',
    800: '#6fbc08',
    900: '#5cb004',
    A100: '#ebffda',
    A200: '#cfffa7',
    A400: '#b3ffa7',
    A700: '#a6ff5a',
    contrastDefaultColor: 'light',
};
export var LABEL_CLASSES;
(function (LABEL_CLASSES) {
    LABEL_CLASSES["percentile25"] = "percentile25";
    LABEL_CLASSES["percentile50"] = "percentile50";
    LABEL_CLASSES["percentile75"] = "percentile75";
    LABEL_CLASSES["percentile95"] = "percentile95";
    LABEL_CLASSES["percentile99"] = "percentile99";
})(LABEL_CLASSES || (LABEL_CLASSES = {}));
export const ANALYTICS_COLOURS = {
    [IDs.UNIQUE_SEARCHES]: '#F59650',
    [IDs.UNIQUE_VIEW_PRODUCT_RATIO]: '#8ECC2C',
    [IDs.UNIQUE_ADD_TO_CART_RATIO]: '#42D5A9',
    [IDs.UNIQUE_ORDER_RATIO]: '#787878',
    totalQueries: '#2E966A',
    [IDs.CONVERSION_SCORE]: 'black',
    [IDs.EXPLORATION_SCORE]: 'black',
    [LABEL_CLASSES.percentile25]: '#50C897',
    [LABEL_CLASSES.percentile50]: '#2A8A61',
    [LABEL_CLASSES.percentile75]: '#88BD8D',
    [LABEL_CLASSES.percentile95]: '#F97152',
    [LABEL_CLASSES.percentile99]: '#F4B588',
};
export const PRIMARY_COLOUR = {
    light: '#8bce18',
    main: '#5cb004',
    dark: '#448005',
    contrastText: '#fff',
};
export const SECONDARY_COLOUR = {
    light: '#ddf0b7',
    main: '#6b6b6b',
    dark: '#afdd56',
    contrastText: '#fff',
};
export const ERROR_COLOUR = {
    main: '#D2212C',
};
export const WARNING_COLOUR = {
    main: '#FFD800',
};
export const ACTIVE_COLOUR = '#4D9AFF';
export const HIGHLIGHT_COLOUR = '#8cce0e';
export const BACKGROUND_COLOUR = {
    default: '#FFFFFF',
    paper: '#FAFAFA',
};
const theme = {
    palette: {
        primary: PRIMARY_COLOUR,
        secondary: SECONDARY_COLOUR,
        error: ERROR_COLOUR,
        warning: WARNING_COLOUR,
        contrastFont: '#999999',
        mainFont: '#666666',
        secondaryFont: '#4A494F',
        active: ACTIVE_COLOUR,
        highlight: HIGHLIGHT_COLOUR,
        accentColours: ACCENT_COLOURS,
        disabledFont: '#9e9e9e',
        analytics: ANALYTICS_COLOURS,
        background: BACKGROUND_COLOUR,
    },
    frame: {
        background: blueGrey[800],
        color: '#D8D8D8',
    },
    appHeader: {
        height: spacing(8),
        heightMobile: spacing(16),
        heightXSMobile: spacing(15),
    },
    pageHeader: {
        backgroundColor: '#fff',
        height: spacing(7.5),
        position: 'sticky',
        zIndex: 1100,
    },
    toggleButton: {
        border: '#e7eaee',
        selected: '#448004',
    },
    drawer: {
        width: spacing(30),
    },
    footer: {
        height: spacing(6),
        heightMobile: spacing(8),
    },
    label: {
        color: '#898989',
    },
    fontSize: {
        itemHeader: '1.2em',
        formHeading: '1.1em',
        itemSubHeader: '1em',
        formControl: '0.9em',
        caption: '0.8em',
        pageHeaderContent: '0.75em',
    },
    thumbnailColor: {
        hover: '#eaebec',
        select: '#888888',
    },
    zIndex: {
        stickyHeader: 1090,
        pageHeader: 1100,
        drag: 1600,
        header: 1700,
        temporaryDrawer: 1800,
        drawerModal: 1900,
    },
    tooltip: {
        maxWidth: spacing(29),
        whiteSpace: 'normal',
    },
    table: {
        background: '#FAFAFA',
        highlight: '#90c75c',
        border: '#E0E0E0',
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    enrichBreadcrumbs: {
        height: spacing(4.5),
    },
};
export default theme;
