import { equals } from 'ramda';
import { call, put, select, takeLatest, throttle, } from 'redux-saga/effects';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Tasks from '@/store/sagas/tasks';
import * as Selectors from '@/store/selectors';
export const AUTOCOMPLETE_REFRESH_TIMEOUT = 300000;
export function* fetchProductAttributes() {
    try {
        const cachedSuggestions = yield select(Selectors.productAttributesSelector);
        const client = yield call(Tasks.authClient);
        const suggestions = yield call(Tasks.authCall, client.productAttributes);
        if (!equals(cachedSuggestions, suggestions)) {
            yield put(ActionCreators.refreshProductAttributes(suggestions));
        }
    }
    catch (e) {
        yield Tasks.error('Fetching product attributes failed.', e);
    }
}
export function* fetchProductAttributeValues({ payload: field }) {
    try {
        const cachedSuggestions = yield select(Selectors.productValuesByFieldSelector, field);
        const area = yield select(Selectors.activeAreaSelector);
        const client = yield call(Tasks.authClient);
        const suggestions = yield call(Tasks.authCall, client.productAttributeValues, area, field);
        if (!equals(cachedSuggestions, suggestions)) {
            yield put(ActionCreators.updateProductAttributeValues(field, suggestions));
        }
    }
    catch (e) {
        yield Tasks.error('Fetching product attribute values failed.', e);
    }
}
export function* fetchCollections() {
    try {
        const cachedCollections = yield select(Selectors.collectionsSelector);
        const client = yield call(Tasks.authClient);
        const collections = yield call(Tasks.authCall, client.collections);
        if (!equals(cachedCollections, collections)) {
            yield put(ActionCreators.refreshCollections(collections));
        }
    }
    catch (e) {
        yield Tasks.error('Fetching autocomplete collections failed.', e);
    }
}
export default function* collectionsSaga() {
    yield throttle(AUTOCOMPLETE_REFRESH_TIMEOUT, Actions.FETCH_PRODUCT_ATTRIBUTES, fetchProductAttributes);
    yield takeLatest(Actions.FETCH_PRODUCT_ATTRIBUTE_VALUES, fetchProductAttributeValues);
    yield throttle(AUTOCOMPLETE_REFRESH_TIMEOUT, Actions.FETCH_COLLECTIONS, fetchCollections);
}
