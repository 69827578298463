import * as Actions from '@/store/actions/types';
import * as Collections from '@/store/reducers/collections';
export const DEFAULT = {
    collections: [],
    productAttributes: [],
    productAttributeValues: {
        allFields: [],
        byField: {},
    },
    fetchValues: false,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_PRODUCT_ATTRIBUTES:
            return Collections.refreshProductAttributesReducer(state, action);
        case Actions.REFRESH_PRODUCT_ATTRIBUTE_VALUES:
            return Collections.refreshProductAttributeValuesReducer(state, action);
        case Actions.REFRESH_COLLECTIONS:
            return Collections.refreshCollectionsReducer(state, action);
        case Actions.ENABLE_FETCH_AUTOCOMPLETE:
            return Collections.enableFetchAutocompleteReducer(state);
        case Actions.DISABLE_FETCH_AUTOCOMPLETE:
            return Collections.disableFetchAutocompleteReducer(state);
        default:
            return state;
    }
};
export function refreshProductAttributesReducer(state, { payload: productAttributes }) {
    return Object.assign(Object.assign({}, state), { productAttributes });
}
export function refreshProductAttributeValuesReducer(state, { payload: { field, values } }) {
    return Object.assign(Object.assign({}, state), { productAttributeValues: {
            allFields: state.productAttributeValues.allFields.includes(field)
                ? state.productAttributeValues.allFields
                : [...state.productAttributeValues.allFields, field],
            byField: Object.assign(Object.assign({}, state.productAttributeValues.byField), { [field]: values }),
        } });
}
export function refreshCollectionsReducer(state, { payload: collections }) {
    return Object.assign(Object.assign({}, state), { collections });
}
export function enableFetchAutocompleteReducer(state) {
    return Object.assign(Object.assign({}, state), { fetchValues: true });
}
export function disableFetchAutocompleteReducer(state) {
    return Object.assign(Object.assign({}, state), { fetchValues: false });
}
