export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const INITIATE_SSO_AUTHENTICATION = 'INITIATE_SSO_AUTHENTICATION';
export const AUTHENTICATE_SSO_USER = 'AUTHENTICATE_SSO_USER';
export const ACKNOWLEDGE_AUTHENTICATION = 'ACKNOWLEDGE_AUTHENTICATION';
export const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
export const VALIDATE_TOKEN = 'TOKEN:VALIDATE';
export const UPDATE_TOKEN = 'TOKEN:UPDATE';
export const EXPIRE_TOKEN = 'TOKEN:EXPIRE';
export const EXCHANGE_SSO_CODE = 'SSO_CODE:EXCHANGE';
export const EXCHANGE_SSO_CODE_ERROR = 'SSO_CODE_ERROR:EXCHANGE';
export const VALIDATE_PASSWORD_FORMAT = 'PASSWORD:VALIDATE_FORMAT';
export const VALIDATE_PASSWORD_FORMAT_REPLY = 'PASSWORD:VALIDATE_FORMAT:REPLY';
export const GET_EXTERNAL_AUTHENTICATION_PROVIDERS = 'EXTERNAL_AUTHENTICATION_PROVIDERS:GET';
export const UPDATE_EXTERNAL_AUTHENTICATION_PROVIDERS_LIST = 'EXTERNAL_AUTHENTICATION_PROVIDERS_LIST:UPDATE';
