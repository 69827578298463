import React from 'react';
import { enrichRoute } from '@/routes';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
import RouteLink from '../route-link';
export const actionCreators = {
    goToEnrich: ActionCreators.goToEnrich,
};
export const mergeProps = (_, { goToEnrich }, { route }) => ({
    goToEnrich: () => goToEnrich(route.path),
});
const EnrichRoute = connect(null, actionCreators, mergeProps)(function EnrichRoute({ goToEnrich, route }) {
    const path = enrichRoute(route.path);
    return <RouteLink label={route.label} path={path} onClick={goToEnrich}/>;
});
export default EnrichRoute;
