import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = {
    root: {
        '&$darkMode': {
            background: '#efeeee',
        },
        '&$fillHeight': {
            minHeight: '100%',
        },
    },
    darkMode: {},
    fillHeight: {},
};
const PageWrapper = withStyles(styles)(function PageWrapper({ id, className, fillHeight, darkMode, classes, children, }) {
    return (<div id={id && `page--${id}`} className={classNames(classes.root, { [classes.darkMode]: darkMode, [classes.fillHeight]: fillHeight }, className)}>
      {children}
    </div>);
});
export default PageWrapper;
