import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette, fontSize }) => ({
    titleContainer: {
        borderBottom: `1px solid ${palette.grey[200]}`,
    },
    title: {
        fontSize: fontSize.caption,
        fontWeight: 600,
    },
    icon: {
        color: palette.mainFont,
        fontSize: '1.4em',
    },
    outerSection: {
        borderRight: `1px solid ${palette.grey[200]}`,
        borderBottom: `1px solid ${palette.grey[200]}`,
        '& > *': {
            padding: spacing(2),
        },
        '&:last-child': {
            borderRight: 0,
        },
    },
});
const FilterSection = withStyles(styles)(function FilterSection({ title, icon: Icon, classes, sm = 12, md, lg, children, }) {
    return (<Grid container item direction="column" lg={lg} md={md} sm={sm} className={classes.outerSection}>
      <Grid item container alignItems="center" justify="space-between" className={classes.titleContainer}>
        <span className={classes.title}>{title}</span>
        <Icon className={classes.icon}/>
      </Grid>
      {children}
    </Grid>);
});
export default FilterSection;
