import Cookie from 'js-cookie';
import { AREAS } from '@/client/db';
import { Role } from '@/constants';
import * as Actions from '@/store/actions/types';
import * as AuthReducers from './auth';
import { E2E_COOKIE_AUTHENTICATON, SKIP_AUTHENTICATION } from '@/config/global';
export const DEFAULT = (() => {
    switch (true) {
        case !!SKIP_AUTHENTICATION:
            return {
                isAuthenticated: true,
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjAsImVtYWlsIjoiam9obmRvZUBleGFtcGxlLmNvbSIsImV4cCI6MjE0NzQ4MzY0N30.kLOZhDp3UzqArFIYyv6Twhqhs20x-EqZ6yu3cJc4mJM',
                externalProviders: [],
                user: {
                    allowedAreas: AREAS.map(({ name }) => name),
                    allowedAccess: [Role.MERCHANDISING, Role.QUERY_REWRITE, Role.ENGINE_CONFIGURATION, Role.ADMIN],
                },
            };
        case !!E2E_COOKIE_AUTHENTICATON:
            return {
                isAuthenticated: false,
                user: null,
                externalProviders: [],
                token: Cookie.get('E2E_INJECTED_TOKEN') || null,
            };
        default:
            return {
                isAuthenticated: false,
                token: null,
                externalProviders: [],
                user: null,
            };
    }
})();
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.ACKNOWLEDGE_AUTHENTICATION:
            return AuthReducers.acknowledgeAuthenticationReducer(state, action);
        case Actions.CLEAR_AUTHENTICATION:
            return AuthReducers.clearAuthenticationReducer(state);
        case Actions.UPDATE_EXTERNAL_AUTHENTICATION_PROVIDERS_LIST:
            return AuthReducers.updateExternalProvidersReducer(state, action);
        case Actions.UPDATE_TOKEN:
            return AuthReducers.updateTokenReducer(state, action);
        default:
            return state;
    }
};
export function clearAuthenticationReducer(state) {
    return Object.assign(Object.assign({}, state), { isAuthenticated: false, token: null, user: null });
}
export function acknowledgeAuthenticationReducer(state, { payload: { token, user } }) {
    return Object.assign(Object.assign({}, state), { token,
        user, isAuthenticated: true });
}
export function updateTokenReducer(state, { payload: token }) {
    return Object.assign(Object.assign({}, state), { token });
}
export function updateExternalProvidersReducer(state, { payload: externalProviders }) {
    return Object.assign(Object.assign({}, state), { externalProviders });
}
