import moize from 'moize';
import { createSelector } from 'reselect';
export function analyticsSelector({ analytics }) {
    return analytics;
}
export const beaconHealthchecksSelector = createSelector(analyticsSelector, ({ healthchecks }) => healthchecks);
export const chartDataSelector = (dashboardType, dataSetKey) => createSelector(analyticsSelector, ({ chartsData }) => (chartsData[dashboardType] && chartsData[dashboardType][dataSetKey]) || null);
export const snapshotDataSelector = (dashboardType, dataSetKey) => createSelector(analyticsSelector, ({ snapshotsData }) => (snapshotsData[dashboardType] && snapshotsData[dashboardType][dataSetKey]) || null);
export const filterSelector = moize((dashboardType) => createSelector(analyticsSelector, ({ dashboardFilters }) => dashboardFilters[dashboardType] || { dateRange: { start: null, end: null } }));
export const reportWindowSelector = createSelector(analyticsSelector, ({ reportsWindow }) => reportsWindow);
export const currentReportSelector = createSelector(analyticsSelector, ({ currentReport }) => currentReport);
export const reportDataSelector = moize((reportType) => createSelector(analyticsSelector, ({ reports }) => (reports[reportType] && reports[reportType].data) || null));
export const reportDataSelectorV2 = createSelector(analyticsSelector, ({ currentReport, reports }) => (reports[currentReport] && reports[currentReport].data) || null);
export const reportFiltersSelector = moize((reportType) => createSelector(analyticsSelector, ({ reports }) => (reports[reportType] && reports[reportType].filters) || { includeNavigationNulls: true }));
export const reportQuerySelector = moize((reportType) => createSelector(analyticsSelector, ({ reports }) => reports[reportType] && reports[reportType].query));
export const reportFiltersSelectorV2 = createSelector(analyticsSelector, ({ reports, currentReport }) => (reports[currentReport] && reports[currentReport].filters) || {});
export const reportQuerySelectorV2 = createSelector(analyticsSelector, ({ reports, currentReport }) => reports[currentReport] && reports[currentReport].query);
export const reportQueryMatchTypeSelector = createSelector(analyticsSelector, ({ reports, currentReport }) => reports[currentReport] && reports[currentReport].matchType);
export const reportQueryActiveQuerySelector = createSelector(analyticsSelector, ({ reports, currentReport }) => (reports[currentReport] && reports[currentReport].activeQuery) || { matchType: null, query: null });
export const currentInsightsSelector = createSelector(analyticsSelector, ({ currentInsights }) => currentInsights);
export const insightsDataSelector = createSelector(analyticsSelector, ({ insights, currentInsights }) => insights[currentInsights]);
