import * as Actions from '@/store/actions/types';
import State from '@/store/state';
import * as SearchPreviewReducers from './search-preview';
const DEFAULT_PRODUCT_PAGE_SIZE = 60;
export const DEFAULT = {
    configurationByArea: {},
    products: [],
    pushToTopProducts: [],
    resultManagementProducts: [],
    restrictToIdsProductIds: [],
    query: '',
    queryType: State.SearchPreview.QueryType.QUERY,
    pageSize: DEFAULT_PRODUCT_PAGE_SIZE,
    error: null,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.UPDATE_SEARCH_PREVIEW_QUERY:
            return SearchPreviewReducers.updateQueryReducer(state, action);
        case Actions.UPDATE_SEARCH_PREVIEW_QUERY_TYPE:
            return SearchPreviewReducers.updateQueryTypeReducer(state, action);
        case Actions.UPDATE_SEARCH_PREVIEW_CONFIG:
            return SearchPreviewReducers.updateConfigReducer(state, action);
        case Actions.UPDATE_SEARCH_PREVIEW_PRODUCTS:
            return SearchPreviewReducers.updateProductsReducer(state, action);
        case Actions.UPDATE_PUSH_TO_TOP_PRODUCTS:
            return SearchPreviewReducers.updatePushToTopProductsReducer(state, action);
        case Actions.UPDATE_RESULT_MANAGEMENT_PRODUCTS:
            return SearchPreviewReducers.updateResultManagementProductsReducer(state, action);
        case Actions.UPDATE_RESTRICT_TO_IDS_PRODUCTS:
            return SearchPreviewReducers.updateRestrictToIdsProductIdsReducer(state, action);
        case Actions.LOAD_SEARCH_PREVIEW_PRODUCTS:
            return SearchPreviewReducers.loadSearchPreviewReducer(state, action);
        case Actions.UPDATE_SEARCH_PREVIEW_ERROR:
            return SearchPreviewReducers.updateErrorReducer(state, action);
        case Actions.RESET_SEARCH_PREVIEW_QUERY:
            return SearchPreviewReducers.resetQueryReducer(state);
        default:
            return state;
    }
};
export function updateQueryReducer(state, { payload: query }) {
    return Object.assign(Object.assign({}, state), { query });
}
export function updateQueryTypeReducer(state, { payload: queryType }) {
    return Object.assign(Object.assign({}, state), { queryType });
}
export function updateConfigReducer(state, { payload: { area, config } }) {
    return Object.assign(Object.assign({}, state), { configurationByArea: Object.assign(Object.assign({}, state.configurationByArea), { [area]: config }) });
}
export function isValidField(value) {
    return value !== null ? value.trim().length !== 0 : false;
}
export function trimFields(configuration) {
    return Object.keys(configuration).reduce((acc, curr) => (isValidField(configuration[curr]) ? Object.assign(Object.assign({}, acc), { [curr]: configuration[curr].trim() }) : acc), {});
}
export function updateProductsReducer(state, { payload: products }) {
    return Object.assign(Object.assign({}, state), { products, error: null });
}
export function loadSearchPreviewReducer(state, { payload: pageSize }) {
    return Object.assign(Object.assign({}, state), { pageSize: pageSize || DEFAULT_PRODUCT_PAGE_SIZE, error: null });
}
export function updatePushToTopProductsReducer(state, { payload: pushToTopProducts }) {
    return Object.assign(Object.assign({}, state), { pushToTopProducts, error: null });
}
export function updateResultManagementProductsReducer(state, { payload: resultManagementProducts }) {
    const validProducts = resultManagementProducts.filter((product) => product.includedPins.length !== 0);
    return Object.assign(Object.assign({}, state), { resultManagementProducts: validProducts, error: null });
}
export function updateRestrictToIdsProductIdsReducer(state, { payload: restrictToIdsProductIds }) {
    return Object.assign(Object.assign({}, state), { restrictToIdsProductIds, error: null });
}
export function updateErrorReducer(state, { payload: error }) {
    return Object.assign(Object.assign({}, state), { error, products: [] });
}
export function resetQueryReducer(state) {
    return Object.assign(Object.assign({}, state), { query: '' });
}
