import { __decorate } from "tslib";
import React from 'react';
import { equals, identity } from 'ramda';
import FormActions from 'redux-form/lib/actions';
import { connect, getFormManagerName, trimValue, withFormName, } from '@/utils';
import ConnectedListFormManager from './list/connected-component';
import ConnectedTableFormManager from './table/connected-component';
export const actionCreators = {
    resetForm: FormActions.reset,
};
let ConnectedFormManager = class ConnectedFormManager extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { editingIndex: -1 };
        this.formState = null;
        this.onAdd = () => {
            const { fields, resetForm, transform, defaultValues, } = this.props;
            const formValues = this.formState
                ? equals(this.formState.value, defaultValues)
                    ? null
                    : this.formState.value
                : null;
            const result = transform(formValues);
            if (result) {
                if (typeof result === 'string' && result.trim().length > 0) {
                    fields.push(trimValue(result));
                }
                else if (Array.isArray(result)) {
                    result.forEach((item) => item.trim().length > 0 && fields.push(trimValue(item)));
                }
                else {
                    fields.push(Object.keys(result).reduce((acc, curr) => Object.assign(acc, { [curr]: result[curr] }), {}));
                }
            }
            this.formState = null;
            resetForm(this.formName);
        };
        // eslint-disable-next-line no-return-assign
        this.onChange = (formState) => (this.formState = formState);
        this.onEdit = (index) => this.setState({ editingIndex: index });
        this.onSave = () => this.setState({ editingIndex: -1 });
        this.onReorder = (sourceIndex, targetIndex) => this.props.fields.move(sourceIndex, targetIndex);
    }
    get formName() {
        const { formName, name } = this.props;
        return getFormManagerName(formName, name);
    }
    render() {
        const { defaultValues, fields, formComponent, displayComponent, reorderable, table, columns, sort, limit, } = this.props;
        return table ? (<ConnectedTableFormManager columns={columns} fields={fields} editingIndex={this.state.editingIndex} formComponent={formComponent} displayComponent={displayComponent} reorderable={reorderable} onEdit={this.onEdit} onSave={this.onSave} onReorder={this.onReorder} onAdd={this.onAdd} onChange={this.onChange} formName={this.formName} defaultValues={defaultValues}/>) : (<ConnectedListFormManager fields={fields} editingIndex={this.state.editingIndex} formComponent={formComponent} displayComponent={displayComponent} reorderable={reorderable} onEdit={this.onEdit} onSave={this.onSave} onReorder={this.onReorder} onAdd={this.onAdd} onChange={this.onChange} formName={this.formName} defaultValues={defaultValues} sort={sort} limit={limit}/>);
    }
};
ConnectedFormManager.defaultProps = {
    transform: identity,
    formName: undefined,
};
ConnectedFormManager = __decorate([
    connect(null, actionCreators),
    withFormName
], ConnectedFormManager);
export default ConnectedFormManager;
