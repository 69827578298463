import { all, call, put, takeLatest, takeLeading, select, } from 'redux-saga/effects';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Selectors from '@/store/selectors';
import * as Tasks from './tasks';
export function* getGlancerDashboardList() {
    try {
        const client = yield call(Tasks.authClient);
        const result = yield call(Tasks.authCall, client.glancerAnalytics.dashboards);
        yield put(ActionCreators.updateGlancerDashboardList(result.dashboards));
    }
    catch (e) {
        yield Tasks.simpleError('Fetch Dashboards');
    }
}
export function* getEmbedUrl({ payload: dashboardId }) {
    try {
        const [area, dashboards] = yield all([
            select(Selectors.activeAreaSelector),
            select(Selectors.glancerDashboardsSelector),
        ]);
        const path = dashboards.byId[dashboardId].embedPath;
        const client = yield call(Tasks.authClient);
        const result = yield call(Tasks.authCall, client.glancerAnalytics.embedUrl, area, path);
        yield put(ActionCreators.updateGlancerEmbedUrl(result));
        yield Tasks.success('Fetched dashboard.');
    }
    catch (e) {
        yield Tasks.simpleError('Fetch Embed URL');
    }
}
export default function* glancerAnalyticsSaga() {
    yield takeLeading(Actions.GET_GLANCER_DASHBOARD_LIST, getGlancerDashboardList);
    yield takeLatest(Actions.GET_GLANCER_EMBED_URL, getEmbedUrl);
}
