import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import TableCell from '@/components/cell';
import { Align, Direction, Type } from '@/components/reports/table-v2/types';
import { includesScoreId } from '@/components/reports/table-v2/utils';
import Tooltip from '@/components/tooltip';
import { withStyles } from '@/decorators';
export const styles = ({ palette, spacing, table }) => ({
    tableHeadRow: {
        '&>*': {
            top: 0,
            position: 'sticky',
            background: palette.primary.contrastText,
            boxShadow: `0px ${spacing(1 / 8)}px ${table.border}`,
        },
    },
    icon: {
        color: table.highlight,
    },
    label: {
        fontSize: spacing(1.75),
        '&$rightAlignLabel': {
            flexDirection: 'row-reverse',
        },
        '&$defaultIconDirection': {
            '&>*': {
                transition: 'none',
            },
            '&:hover': {
                '&>*': {
                    transform: 'none',
                },
            },
        },
        '&$paddingRight': {
            paddingRight: 3,
        },
        '&:hover': {
            '&>*': {
                opacity: 1,
            },
        },
    },
    actionCell: {
        padding: `${spacing(1)}px ${spacing(3)}px`,
    },
    rightAlignLabel: {},
    defaultIconDirection: {},
    paddingRight: {},
});
const PopularSearchesHead = withStyles(styles)(function PopularSearchesHead({ classes, onClick, columns, order, orderBy, }) {
    return (<TableHead>
      <TableRow className={classes.tableHeadRow}>
        {columns.map(({ label, type = Type.SMALL, align = Align.LEFT, tooltip, id, }) => {
        const onClickID = onClick(id);
        const isActiveColumn = orderBy === id;
        return (<TableCell type={type} key={label} align={align}>
              <Tooltip title={tooltip}>
                <TableSortLabel active={isActiveColumn} direction={order} onClick={onClickID} className={classes.label} classes={{
            root: classNames(align === Align.RIGHT && classes.rightAlignLabel, order === Direction.ASC && !isActiveColumn && classes.defaultIconDirection, includesScoreId(id) && classes.paddingRight),
            icon: classes.icon,
        }}>
                  {label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>);
    })}
        <TableCell type={Type.SMALL} align={Align.RIGHT} classes={{ root: classes.actionCell }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>);
});
export default PopularSearchesHead;
