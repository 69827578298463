import React from 'react';
import { Line } from 'react-chartjs-2';
import State from '@/store/state';
import { ANALYTICS_COLOURS } from '@/theme';
import { getTimeFormat } from '@/utils/time';
import * as DashboardDefaults from '../defaults';
var IDs = State.Analytics.ResultKey;
const CHARTS = {
    top: {
        dataSets: [
            {
                label: 'Unique Searches',
                dataType: IDs.UNIQUE_SEARCHES,
                colour: ANALYTICS_COLOURS[IDs.UNIQUE_SEARCHES],
                options: {
                    yAxisID: 'y-axis-1',
                    backgroundColor: ANALYTICS_COLOURS[IDs.UNIQUE_SEARCHES],
                },
            },
        ],
        options: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS), { legend: {
                display: false,
            }, tooltips: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.tooltips), { callbacks: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.tooltips.callbacks), { label: (tooltip, data) => {
                        const { label } = data.datasets[tooltip.datasetIndex];
                        return `${label}: ${DashboardDefaults.formatter(tooltip.yLabel)}`;
                    } }) }), scales: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales), { yAxes: [
                    Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales.yAxes), { scaleLabel: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales.yAxes.scaleLabel), { labelString: 'Search Volume', display: true }), id: 'y-axis-1' }),
                ], xAxes: [Object.assign({}, DashboardDefaults.OPTIONS.scales.xAxes)] }) }),
    },
    bottom: {
        dataSets: [
            {
                label: 'Conversion Rate',
                dataType: IDs.UNIQUE_ORDER_RATIO,
                colour: ANALYTICS_COLOURS[IDs.UNIQUE_ORDER_RATIO],
                options: {
                    stack: '1',
                    backgroundColor: ANALYTICS_COLOURS[IDs.UNIQUE_ORDER_RATIO],
                    yAxisID: 'y-axis-2',
                },
            },
            {
                label: 'Add To Cart Rate',
                dataType: IDs.UNIQUE_ADD_TO_CART_RATIO,
                colour: ANALYTICS_COLOURS[IDs.UNIQUE_ADD_TO_CART_RATIO],
                options: {
                    stack: '2',
                    backgroundColor: ANALYTICS_COLOURS[IDs.UNIQUE_ADD_TO_CART_RATIO],
                    yAxisID: 'y-axis-2',
                },
            },
            {
                label: 'Clickthrough Rate',
                dataType: IDs.UNIQUE_VIEW_PRODUCT_RATIO,
                colour: ANALYTICS_COLOURS[IDs.UNIQUE_VIEW_PRODUCT_RATIO],
                options: {
                    stack: '3',
                    backgroundColor: ANALYTICS_COLOURS[IDs.UNIQUE_VIEW_PRODUCT_RATIO],
                    yAxisID: 'y-axis-2',
                },
            },
        ],
        options: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS), { tooltips: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.tooltips), { callbacks: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.tooltips.callbacks), { label: (tooltip, data) => {
                        const { label } = data.datasets[tooltip.datasetIndex];
                        return `${label}: ${tooltip.yLabel}%`;
                    } }) }), scales: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales), { yAxes: [
                    Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales.yAxes), { scaleLabel: Object.assign(Object.assign({}, DashboardDefaults.OPTIONS.scales.yAxes.scaleLabel), { labelString: 'Rate (%)', display: true }), id: 'y-axis-2' }),
                ], xAxes: [Object.assign({}, DashboardDefaults.OPTIONS.scales.xAxes)] }) }),
    },
};
function Chart({ type, data, dateRange: { start, end } }) {
    const value = {
        labels: data.map((value) => getTimeFormat(start, end, value.datetime)),
        datasets: CHARTS[type].dataSets.map(({ label, dataType, colour, options, }) => (Object.assign(Object.assign(Object.assign({}, DashboardDefaults.getDataSet(label, colour, data.length)), options), { data: data.map((info) => info[dataType]) }))),
    };
    return <Line data={value} options={CHARTS[type].options}/>;
}
export default Chart;
