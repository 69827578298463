import { __rest } from "tslib";
import { call, put, select } from 'redux-saga/effects';
import { LEGACY_REPORTS, REPORTS } from '@/analytics/dashboards/search-term';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as Tasks from '../tasks';
import { addIndicies, sortResult } from './insights';
import { getReportDateParam } from './utils';
var ReportType = State.Analytics.ReportType;
var DashboardType = State.Analytics.DashboardType;
export const SIZE = 1000;
export const REPORT_LOADER = {
    [ReportType.TOP_REFINEMENTS]: loadTopRefinementsTask,
    [ReportType.PERFORMANCE]: loadPerformanceTask,
    [ReportType.NULLS]: loadNullsTask,
    [ReportType.NAVIGATIONS]: loadNavigationsTask,
};
export function* loadTopRefinementsTask(reportType) {
    const analyticsClient = yield call(Tasks.authAnalyticsClient);
    const reportRequestPayload = yield call(Tasks.createReportRequest, reportType);
    const { result } = yield call(analyticsClient.topRefinements, reportRequestPayload);
    return result.map((_a) => {
        var { name, values = [] } = _a, rest = __rest(_a, ["name", "values"]);
        return (Object.assign({ values, value: name }, rest));
    });
}
export function formatSearchReport(result) {
    return result.map(({ query, count }) => ({ count, value: query }));
}
export function* loadReportTask({ payload: reportType }) {
    try {
        yield put(ActionCreators.refreshReport(null, reportType));
        const { devices } = yield select(Selectors.reportFiltersSelector(reportType));
        const { dateRange: { start: startDate, end: endDate }, } = yield select(Selectors.filterSelector(DashboardType.SEARCH_TERM));
        const query = yield select(Selectors.reportQuerySelector(reportType));
        const matchType = yield select(Selectors.reportQueryMatchTypeSelector);
        const { start, end } = getReportDateParam(startDate, endDate);
        const reportConfig = {
            start,
            end,
            matchType,
            searchTerm: query,
            deviceType: devices,
        };
        const reportProfile = [...REPORTS, ...LEGACY_REPORTS].find((report) => report.id === reportType);
        const result = yield call(REPORT_LOADER[reportType], reportType, reportConfig);
        yield put(ActionCreators.refreshReport(result.status
            ? []
            : addIndicies(reportProfile.defaultSort ? sortResult(result, reportProfile.defaultSort) : result), reportType));
        if (reportType !== ReportType.TOP_REFINEMENTS) {
            yield put(ActionCreators.updateReportActiveQuery(reportType, query, matchType));
        }
    }
    catch (e) {
        yield Tasks.simpleError('load report', e);
    }
}
export function* loadPerformanceTask(_, reportConfig) {
    const authClient = yield call(Tasks.authClient);
    const customerArea = yield select(Selectors.activeAreaSelector);
    const { result } = yield call(authClient.analyticsV2.performance, Object.assign(Object.assign({}, reportConfig), { customerArea, size: SIZE }));
    return result;
}
export function* loadNullsTask(_, reportConfig) {
    const authClient = yield call(Tasks.authClient);
    const customerArea = yield select(Selectors.activeAreaSelector);
    const { result } = yield call(authClient.analyticsV2.nulls, Object.assign(Object.assign({}, reportConfig), { customerArea, size: SIZE }));
    return result;
}
export function* loadNavigationsTask(_, reportConfig) {
    const authClient = yield call(Tasks.authClient);
    const customerArea = yield select(Selectors.activeAreaSelector);
    const { result } = yield call(authClient.analyticsV2.navigations, Object.assign(Object.assign({}, reportConfig), { customerArea }));
    return result;
}
