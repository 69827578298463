import React from 'react';
import { identity } from 'ramda';
import ReactMarkdown from 'react-markdown';
import Alert from '@/components/alert';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const selectors = {
    alert: Selectors.alertSelector,
};
export const actionCreators = {
    onCancel: ActionCreators.hideAlert,
    dispatch: identity,
};
export const mergeProps = ({ alert }, { dispatch, onCancel }) => ({
    open: !!alert,
    onAccept: () => {
        dispatch(alert.action);
        onCancel();
    },
});
const ConfirmationAlert = connect(selectors, actionCreators, mergeProps)(function ConfirmationAlert({ alert, onAccept, onCancel }) {
    if (!alert) {
        return null;
    }
    return (<Alert open title="Confirmation" primaryButton={{ onClick: onAccept }} onCancel={onCancel}>
      <ReactMarkdown renderers={{ paragraph: 'div' }} source={alert.text}/>
    </Alert>);
});
export default ConfirmationAlert;
