import { __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { compose } from 'ramda';
import { getEmptyImage } from 'react-dnd-html5-backend';
import enhanceDragItem from './enhancer';
import { ItemTypes } from './layer';
class DragItem extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            hoverTop: false,
            hoverBottom: false,
            hoverLeft: false,
            hoverRight: false,
        };
    }
    render() {
        const _a = this.props, { connectDragSource, connectDropTarget, connectDragPreview, component: Component, isOver, index, reorder, className, classes, children } = _a, props = __rest(_a, ["connectDragSource", "connectDropTarget", "connectDragPreview", "component", "isOver", "index", "reorder", "className", "classes", "children"]);
        const { hoverTop, hoverBottom, hoverLeft, hoverRight, } = this.state;
        const connectElement = compose(connectDragSource, connectDropTarget);
        const element = (<Component className={classNames(className, classes.root, {
            [classes.hoverTop]: hoverTop,
            [classes.hoverBottom]: hoverBottom,
            [classes.hoverLeft]: hoverLeft,
            [classes.hoverRight]: hoverRight,
        })} {...props}>
        {children}
      </Component>);
        return connectElement(element);
    }
    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), { captureDraggingState: true });
    }
    // Note: This will trigger a second render under certain circumstances, but is more idiomatic.
    componentDidUpdate(prevProps) {
        if (!this.props.isOver && prevProps.isOver) {
            this.setState({
                hoverTop: false,
                hoverBottom: false,
                hoverLeft: false,
                hoverRight: false,
            });
        }
    }
}
const EnhancedListItem = enhanceDragItem(ItemTypes.LIST_ITEM)(DragItem);
export const DraggableListItem = React.forwardRef(function DraggableListItem(props, ref) {
    return <EnhancedListItem ref={ref} component="li" {...props}/>;
});
const EnhancedProductItem = enhanceDragItem(ItemTypes.PRODUCT_ITEM, 'horizontal')(DragItem);
export const DraggableProductItem = React.forwardRef(function DraggableProductItem(props, ref) {
    return <EnhancedProductItem ref={ref} component="div" {...props}/>;
});
const EnhancedNagivigationItem = enhanceDragItem(ItemTypes.NAVIGATION_ITEM)(DragItem);
export const DraggableNavigationItem = React.forwardRef(function DraggableNavigationItem(props, ref) {
    return <EnhancedNagivigationItem ref={ref} component="div" {...props}/>;
});
const EnhancedTableRow = enhanceDragItem(ItemTypes.TABLE_ROW)(DragItem);
export const DraggableTableRow = React.forwardRef(function DraggableTableRow(props, ref) {
    return <EnhancedTableRow ref={ref} component="tr" style={{ height: 'inherit' }} {...props}/>;
});
export default DragItem;
