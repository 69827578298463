import * as qs from 'qs';
import { Routing } from '@/constants';
import { buildPaginationPageSettingsShape, getPageNumber, getPaginationSettings, transformPaginationToPageSettings, } from '@/enrich/routing/utils/pagination';
import { buildDateRangePageSettingsShape, getDateRangeSettings } from '@/enrich/routing/utils/date-range';
import { getUpdatedQueryString, getFreshQueryString } from '@/enrich/routing/utils/query-string-gen';
import State from '@/store/state';
var JobProgress = State.Enrich.JobProgress;
class JobPage {
    constructor() {
        this.getFreshQueryString = getFreshQueryString.bind(this);
        this.getUpdatedQueryString = getUpdatedQueryString.bind(this);
    }
    getMergedPageSettings({ overrides = {}, pageSettings: currentPageSettings, queryString = '', }) {
        const parsed = qs.parse(queryString);
        const urlPageSettings = this.transformToPageSettings(parsed);
        const defaults = Routing.Page.Enrich.DEFAULT_PAGE_SETTINGS.job;
        const newSettings = Object.keys(defaults).reduce((newSettings, key) => {
            var _a, _b, _c;
            if (key === 'pagination') {
                const { pageSize, offset } = getPaginationSettings({
                    overrides: { pageSize: overrides.pageSize, offset: overrides.offset },
                    urlPageSettings: urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.pagination,
                    pageSettings: currentPageSettings === null || currentPageSettings === void 0 ? void 0 : currentPageSettings.pagination,
                    defaults: defaults.pagination,
                });
                newSettings.pageSize = pageSize;
                newSettings.offset = offset;
            }
            else if (key === 'dateRange') {
                const { start, end } = getDateRangeSettings({
                    overrides: { start: overrides.start, end: overrides.end },
                    urlPageSettings: urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.dateRange,
                    pageSettings: currentPageSettings === null || currentPageSettings === void 0 ? void 0 : currentPageSettings.dateRange,
                    defaults: defaults.dateRange,
                });
                newSettings.start = start;
                newSettings.end = end;
            }
            else {
                newSettings[key] = (_c = (_b = (_a = overrides[key]) !== null && _a !== void 0 ? _a : urlPageSettings[key]) !== null && _b !== void 0 ? _b : currentPageSettings[key]) !== null && _c !== void 0 ? _c : defaults[key];
            }
            return newSettings;
        }, {});
        return this.buildPageSettingShape(newSettings);
    }
    transformToPageSettings(queryStringParams) {
        const { page, pageSize, start, end, progress, submitters, } = queryStringParams;
        const pagination = transformPaginationToPageSettings({ page, pageSize });
        const pageSettingsProgress = JobProgress[progress];
        const parsedSubmitters = submitters === undefined
            ? undefined
            : submitters === '' ? [] : submitters.split(',');
        return this.buildPageSettingShape(Object.assign(Object.assign({}, pagination), { start,
            end, progress: pageSettingsProgress, submitters: parsedSubmitters }));
    }
    buildPageSettingShape(pageSettingsHash) {
        const { offset, pageSize, start, end, progress, submitters, } = pageSettingsHash;
        const pagination = buildPaginationPageSettingsShape({ pageSize, offset });
        const dateRange = buildDateRangePageSettingsShape({ start, end });
        return Object.assign(Object.assign(Object.assign({}, pagination), dateRange), { progress,
            submitters });
    }
    transformToUrlSettings(pageSettings) {
        const { pagination: { pageSize, offset }, dateRange: { start, end }, progress, submitters, } = pageSettings;
        const page = getPageNumber(offset, pageSize);
        const urlSettingsProgress = JobProgress[progress];
        const stringifiedSubmitters = submitters.join();
        return {
            page,
            pageSize,
            start,
            end,
            progress: urlSettingsProgress,
            submitters: stringifiedSubmitters,
        };
    }
}
export default JobPage;
