import React from 'react';
import NoDataError from '@/components/no-data-error';
import ReportTable from '@/components/reports/table-v2';
import { Align, Type } from '@/components/reports/table-v2/types';
import State from '@/store/state';
import NavigationsBody from './body';
import NavigationsHead from './head';
var ReportKey = State.Analytics.ResultKey;
export const columns = [
    {
        id: ReportKey.NAVIGATION,
        label: 'Title',
        type: Type.LARGE,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'Title',
        csvLabel: 'title',
    },
    {
        id: ReportKey.UNIQUE_NAVIGATIONS,
        label: 'Clicks',
        type: Type.SMALL,
        align: Align.CENTER,
        formatter: null,
        tooltip: 'Clicks',
        csvLabel: 'clicks',
    },
];
const Navigations = function Navigations({ data, defaultSort, filterSize }) {
    return data.length ? (<ReportTable head={NavigationsHead} body={NavigationsBody} data={data} columns={columns} filterSize={filterSize} defaultSort={defaultSort}/>) : (<NoDataError />);
};
export default Navigations;
