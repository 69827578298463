import React from 'react';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@/components/controls/icon-button';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    controls: {
        flex: 1,
        padding: 1.5 * spacing(1),
    },
});
const FormItem = withStyles(styles)(function FormItem({ onSave, onRemove, classes, children, }) {
    return (<React.Fragment>
      <IconButton onClick={onSave}>
        <CheckIcon />
      </IconButton>
      <Grid container item alignItems="center" spacing={3} classes={{ container: classes.controls }}>
        {children}
      </Grid>
      <IconButton onClick={onRemove}>
        <DeleteIcon />
      </IconButton>
    </React.Fragment>);
});
export default FormItem;
