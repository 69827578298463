import { __rest } from "tslib";
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from './controls/button';
export const TITLE_ID = 'alert-title';
export const DESCRIPTION_ID = 'alert-description';
function Alert(_a) {
    var { title, cancelLabel = 'Cancel' } = _a, _b = _a.primaryButton, _c = _b === void 0 ? {} : _b, { label: primaryLabel = 'Ok' } = _c, primaryButton = __rest(_c, ["label"]), { onCancel, secondaryButton, children } = _a, props = __rest(_a, ["title", "cancelLabel", "primaryButton", "onCancel", "secondaryButton", "children"]);
    return (<Dialog {...props} onClose={onCancel} aria-labelledby={TITLE_ID} aria-describedby={DESCRIPTION_ID}>
      <DialogTitle id={TITLE_ID}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={DESCRIPTION_ID}>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {primaryButton.onClick && (<Button variant="primary" onClick={primaryButton.onClick} disabled={primaryButton.disabled} tooltip={primaryButton.tooltip}>
            {primaryLabel}
          </Button>)}
        {secondaryButton && (<Button onClick={secondaryButton.onClick} disabled={secondaryButton.disabled} tooltip={secondaryButton.tooltip}>
            {secondaryButton.label}
          </Button>)}
        {onCancel && <Button onClick={onCancel}>{cancelLabel}</Button>}
      </DialogActions>
    </Dialog>);
}
export default Alert;
