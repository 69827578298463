import { __rest } from "tslib";
import { call, put, select, takeLatest, delay, } from 'redux-saga/effects';
import moment from 'moment';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Tasks from '@/store/sagas/tasks';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { formatQueryParam, mapToApiModelType } from '@/utils';
import { ModelType, DATE_FORMAT_DASH } from '@/constants';
import { formatDateToDash, getToday } from '@/utils/time';
var FilterField = State.ChangeLog.FilterField;
export function* loadChangeLogData(appendData = false, skip = 0) {
    try {
        const _a = yield select(Selectors.changeLogFilterSelector), { dateRange, type, email, name, id } = _a, restOfFilters = __rest(_a, ["dateRange", "type", "email", "name", "id"]);
        const mappedType = type ? mapToApiModelType(type[0]) : null;
        const trimmedName = name ? name[0].trim() : null;
        const trimmedId = id ? id[0].trim() : null;
        if (!appendData) {
            yield put(ActionCreators.refreshChangeLogData(null));
        }
        const client = yield call(Tasks.authClient);
        let data = yield call(Tasks.authCall, client.changeLog, `${formatQueryParam(Object.assign({ start: formatDateToDash(dateRange.start), end: getAccurateEndDate(dateRange.end), type: mappedType, name: trimmedName, id: trimmedId, email: email[0] ? email[0] : null, skip: skip.toString() }, restOfFilters))}`);
        yield put(ActionCreators.setChangeLogDataLimitReached(data.length < 1000));
        const currData = yield select(Selectors.changeLogDataSelector);
        if (appendData) {
            data = currData.concat(data);
        }
        yield put(ActionCreators.refreshChangeLogData(data));
    }
    catch (e) {
        yield Tasks.error('Fetching change log data failed.', e);
    }
}
export function* initializeChangelogQuickView({ payload: { dateRange } }) {
    try {
        const area = yield select(Selectors.activeAreaSelector);
        const type = yield select(Selectors.activeModelTypeSelector);
        const activeQuery = yield select(Selectors.activeQuerySelector);
        const filters = getFilters(type, area, activeQuery.id);
        yield put(ActionCreators.setChangeLogFilters(Object.assign({ dateRange }, filters)));
        const client = yield call(Tasks.authClient);
        const changeLogsData = yield call(Tasks.authCall, client.changeLog, `${formatQueryParam(Object.assign({ start: formatDateToDash(dateRange.start), end: getAccurateEndDate(dateRange.end) }, filters))}`);
        yield put(ActionCreators.refreshChangeLogData(changeLogsData));
    }
    catch (e) {
        yield Tasks.error('Fetching change log data failed.', e);
    }
}
export function* delayLoadChangeLogData({ payload: { appendData, skip } }) {
    yield delay(500);
    yield call(loadChangeLogData, appendData, skip);
}
export default function* changeLogSaga() {
    yield takeLatest(Actions.LOAD_CHANGE_LOG_DATA, delayLoadChangeLogData);
    yield takeLatest(Actions.INITIALIZE_CHANGE_LOG_QUICK_VIEW, initializeChangelogQuickView);
}
export function getFilters(modelType, area, id) {
    const filteredArea = [ModelType.AREA, ModelType.USER, ModelType.KEY].includes(modelType) ? null : [area];
    const type = mapToApiModelType(modelType);
    return {
        [FilterField.AREA]: filteredArea,
        [FilterField.ACTION]: null,
        [FilterField.TYPE]: [type],
        [FilterField.NAME]: null,
        [FilterField.ID]: modelType === ModelType.KEY ? null : [id],
        [FilterField.EMAIL]: [],
        [FilterField.SKIP]: 0,
    };
}
export function getAccurateEndDate(endDate) {
    // This is to check if UTC (5 hours ahead of EST) has turned over to the next day, if so we need to send the next day in the call
    if (endDate === getToday()) {
        return moment.utc().format(DATE_FORMAT_DASH);
    }
    return formatDateToDash(endDate);
}
