import { __decorate, __rest } from "tslib";
import React from 'react';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
import Content from './content';
export const ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'right' };
export const TIMEOUT = 3500;
export const styles = ({ zIndex, spacing, label, breakpoints, footer, }) => ({
    root: {
        position: 'fixed',
        zIndex: zIndex.snackbar,
        bottom: spacing(1.5),
        right: 0,
        [breakpoints.down('sm')]: {
            bottom: footer.heightMobile,
        },
    },
    notification: {
        position: 'static',
        left: 'auto',
        marginTop: spacing(1),
        [breakpoints.down('sm')]: {
            zIndex: zIndex.temporaryDrawer,
        },
    },
    content: {
        padding: `0 0 0 ${spacing(2)}px`,
        background: '#fff',
        textAlign: 'end',
        flexGrow: 0,
        minWidth: 0,
        minHeight: spacing(9),
    },
    icon: {
        fill: label.color,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        margin: `0 ${spacing(2)}px`,
        padding: 0,
    },
});
export const selectors = {
    notifications: ({ notifications }) => notifications,
};
export const actionCreators = {
    closeNotification: ActionCreators.closeNotification,
    removeNotification: ActionCreators.removeNotification,
};
let Notifications = class Notifications extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { isOpen: true };
        this.closeNotification = (id) => (_, reason) => {
            if (reason === 'timeout') {
                this.props.closeNotification(id);
            }
        };
        this.clickCloseNotification = (id) => () => this.props.closeNotification(id);
        this.removeNotification = (id) => () => this.props.removeNotification(id);
    }
    render() {
        const { classes, notifications } = this.props;
        return (<div id="notifications" className={classes.root}>
        {notifications.map((_a) => {
            var { id, visible, timeout = TIMEOUT } = _a, notificationProps = __rest(_a, ["id", "visible", "timeout"]);
            return (<Snackbar classes={{ root: classes.notification }} open={visible} anchorOrigin={ANCHOR_ORIGIN} autoHideDuration={timeout} onClose={this.closeNotification(id)} onExited={this.removeNotification(id)} TransitionComponent={Fade} transitionDuration={500} message={<Content {...notificationProps}/>} ContentProps={{
                classes: { root: classes.content, action: classes.action },
            }} key={id} action={<CloseIcon className={classes.icon} onClick={this.clickCloseNotification(id)}/>}/>);
        })}
      </div>);
    }
};
Notifications = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators)
], Notifications);
export default Notifications;
