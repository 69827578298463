import { DEFAULT_AREA } from '@/constants';
import * as Actions from '@/store/actions/types';
import * as SessionReducers from './session';
export const DEFAULT = {
    isLoading: false,
    isInactive: false,
    area: DEFAULT_AREA,
    modelType: null,
    keys: {},
    routingLoaded: false,
    grove: null,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_KEYS:
            return SessionReducers.refreshKeysReducer(state, action);
        case Actions.SWITCH_AREA:
            return SessionReducers.switchAreaReducer(state, action);
        case Actions.SWITCH_MODEL_TYPE:
            return SessionReducers.switchModelTypeReducer(state, action);
        case Actions.SET_LOADING:
            return SessionReducers.setLoadingReducer(state, action);
        case Actions.ROUTING_LOADED:
            return SessionReducers.setRoutingLoadedReducer(state);
        case Actions.SET_INACTIVE:
            return SessionReducers.setInactiveReducer(state, true);
        case Actions.ACKNOWLEDGE_AUTHENTICATION:
        case Actions.CLEAR_AUTHENTICATION:
            return SessionReducers.setInactiveReducer(state, false);
        case Actions.REFRESH_GROVE:
            return SessionReducers.refreshGroveReducer(state, action);
        default:
            return state;
    }
};
export function refreshKeysReducer(state, { payload: keys }) {
    return Object.assign(Object.assign({}, state), { keys });
}
export function switchAreaReducer(state, { payload: area }) {
    return Object.assign(Object.assign({}, state), { area });
}
export function switchModelTypeReducer(state, { payload: modelType }) {
    return Object.assign(Object.assign({}, state), { modelType });
}
export function setLoadingReducer(state, { payload: isLoading }) {
    return Object.assign(Object.assign({}, state), { isLoading });
}
export function refreshGroveReducer(state, { payload: grove }) {
    return Object.assign(Object.assign({}, state), { grove });
}
export function setRoutingLoadedReducer(state) {
    return Object.assign(Object.assign({}, state), { routingLoaded: true });
}
export function setInactiveReducer(state, isInactive) {
    return Object.assign(Object.assign({}, state), { isInactive });
}
