import React from 'react';
import Cookie from 'js-cookie';
import moize from 'moize';
import { Redirect, Route, Switch } from 'react-router';
import { withProps } from 'recompose';
import loadable from '@/components/loadable';
import { hasEnrichAccess } from '@/config/feature-flags';
import { DISABLE_REDIRECT_COOKIE_NAME, Routing } from '@/constants';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import { SimpleLoading } from '../loading';
import AnalyticsRouter from './analytics';
import EnrichRouter from './enrich';
import MerchandisingRouter from './merchandising';
import GlancerAnalyticsRouter from './glancer-analytics';
export const selectors = {
    routingLoaded: Selectors.routingLoadedSelector,
    location: Selectors.activeLocationSelector,
    activeArea: Selectors.activeAreaSelector,
    hasGlancerAccess: Selectors.glancerAccessSelector,
};
const Router = connect(selectors)(function Router({ activeArea, hasGlancerAccess, routingLoaded }) {
    if (!routingLoaded) {
        return <SimpleLoading />;
    }
    return (<Switch>
      <Route path={Routing.MERCHANDISING_PATH} component={withArea(activeArea, MerchandisingRouter)}/>
      {hasGlancerAccess
        ? <Route path={Routing.GLANCER_ANALYTICS_PATH} component={withArea(activeArea, GlancerAnalyticsRouter)}/>
        : <Route path={Routing.ANALYTICS_PATH} component={withArea(activeArea, AnalyticsRouter)}/>}
      {hasEnrichAccess() && <Route path={Routing.ENRICH_PATH} component={EnrichRouter}/>}
      <Route exact path={Routing.RELEASE_NOTES_PATH} component={loadable(() => import('@/pages/release-notes' /* webpackChunkName: "release-notes" */))}/>
      <Route exact path={Routing.OLD_CC_PATH} component={visitOldCC}/>
      <Redirect to={Routing.MERCHANDISING_PATH}/>
    </Switch>);
});
export default Router;
export function visitOldCC() {
    Cookie.set(DISABLE_REDIRECT_COOKIE_NAME, 'true');
    window.location.pathname = '/';
    return null;
}
export const withArea = moize((area, component) => withProps({ area })(component));
