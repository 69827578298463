import * as Actions from '../types/search-preview';
import { createAction } from '../utils';
export const updateSearchPreviewConfig = (area, config) => createAction(Actions.UPDATE_SEARCH_PREVIEW_CONFIG, { area, config });
export const loadSearchPreviewProducts = (pageSize) => createAction(Actions.LOAD_SEARCH_PREVIEW_PRODUCTS, pageSize);
export const loadPushToTopProducts = (formName, isBringToTop) => createAction(Actions.LOAD_PUSH_TO_TOP_PRODUCTS, { formName, isBringToTop });
export const updateSearchPreviewProducts = (products) => createAction(Actions.UPDATE_SEARCH_PREVIEW_PRODUCTS, products);
export const updateSearchPreviewError = (error) => createAction(Actions.UPDATE_SEARCH_PREVIEW_ERROR, error);
export const updateSearchPreviewQuery = (query) => createAction(Actions.UPDATE_SEARCH_PREVIEW_QUERY, query);
export const resetSearchPreviewQuery = () => createAction(Actions.RESET_SEARCH_PREVIEW_QUERY);
export const updateSearchPreviewQueryType = (queryType) => createAction(Actions.UPDATE_SEARCH_PREVIEW_QUERY_TYPE, queryType);
export const updatePushToTopProducts = (products) => createAction(Actions.UPDATE_PUSH_TO_TOP_PRODUCTS, products);
export const loadResultManagementProducts = (formName, { productIds, restrictToIds = false }) => createAction(Actions.LOAD_RESULT_MANAGEMENT_PRODUCTS, { productIds, restrictToIds, formName });
export const updateResultManagementProducts = (products) => createAction(Actions.UPDATE_RESULT_MANAGEMENT_PRODUCTS, products);
export const updateRestrictToIdsProductIds = (products) => createAction(Actions.UPDATE_RESTRICT_TO_IDS_PRODUCTS, products);
