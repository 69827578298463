import { ModelType, Role } from '@/constants';
import { filterByIdAnd, sortBy } from '@/utils';
var User;
(function (User) {
    User.ACCESS_OPTIONS = [
        { value: Role.MERCHANDISING, label: 'Rules' },
        { value: Role.QUERY_REWRITE, label: 'Recall & Relevancy' },
        { value: Role.ENGINE_CONFIGURATION, label: 'Configuration' },
        { value: Role.ADMIN, label: 'Admin' },
    ];
    User.DEFAULT = {
        areas: [],
        commandCenterAccess: [],
    };
    User.CONFIG = {
        type: ModelType.USER,
        global: true,
        defaults: User.DEFAULT,
        filter: filterByIdAnd(['email']),
        sort: sortBy('email'),
    };
})(User || (User = {}));
export default User;
