import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_HOUR, DATE_FORMAT_MIN } from '@/constants';
import { convertToISO, convertToLocal, convertToUtc, getDuration, } from '@/utils';
import * as TimeUtils from '@/utils/time';
export const RESPONSE_TIME_INTERVAL = {
    1: 2,
    3: 5,
    6: 10,
    12: 15,
};
export function getInterval(start, end) {
    const duration = getDuration(start, end);
    switch (true) {
        case duration <= 49:
            return 1;
        case duration <= 121:
            return 3;
        case duration <= 337:
            return 6;
        case duration <= 601:
            return 12;
        default:
            return 24;
    }
}
export function getDSTConfig(start, end, staticInterval) {
    const hoursDiff = moment(convertToISO(end), DATE_FORMAT_HOUR).diff(moment(convertToISO(start), DATE_FORMAT_HOUR), 'hours');
    const dataInterval = staticInterval || getInterval(start, end);
    const dataPoints = Math.round(hoursDiff / dataInterval);
    const datetimes = [];
    for (let i = 0; i <= dataPoints; i++) {
        if (i === 0) {
            datetimes.push(convertToLocal(start));
        }
        else {
            datetimes.push(moment
                .utc(convertToISO(datetimes[i - 1]), DATE_FORMAT_HOUR)
                .add(dataInterval, 'hours')
                .format(DATE_FORMAT_HOUR));
        }
    }
    return datetimes.reduce((acc, curr, index, arr) => {
        if (index === 0) {
            return acc;
        }
        return TimeUtils.isDst(curr) !== TimeUtils.isDst(arr[index - 1])
            ? Object.assign(Object.assign({}, acc), { rangeBreak: convertToUtc(curr, true) }) : acc;
    }, {});
}
export function getReportDateParam(start, end) {
    return {
        start: moment(start, DATE_FORMAT_HOUR).format(DATE_FORMAT),
        end: moment(end, DATE_FORMAT_HOUR)
            .add(1, 'day')
            .format(DATE_FORMAT),
    };
}
export function getDateRangeParam({ start, end, snapshot, staticInterval, }) {
    const endDate = moment(convertToISO(end), DATE_FORMAT_HOUR)
        .add(1, 'day')
        .format(DATE_FORMAT_HOUR);
    const { rangeBreak } = getDSTConfig(start, endDate, staticInterval);
    const interval = staticInterval || getInterval(start, endDate);
    if (snapshot) {
        return [
            {
                start,
                end: endDate,
                interval: moment(convertToISO(endDate), DATE_FORMAT_HOUR).diff(moment(convertToISO(start), DATE_FORMAT_HOUR), 'hours'),
            },
        ];
    }
    if (!rangeBreak) {
        return [{ start, interval, end: endDate }];
    }
    return [{ start, interval, end: rangeBreak }, { interval, start: rangeBreak, end: endDate }];
}
export function getResponseTimeConfig(timeRange) {
    const interval = RESPONSE_TIME_INTERVAL[timeRange];
    const minPassed = TimeUtils.getMinutesCurrentHour();
    const mod = interval > minPassed ? minPassed : minPassed % interval;
    return {
        interval,
        delayBy: (interval - mod) * 60 * 1000,
        start: moment()
            .seconds(0)
            .subtract(timeRange, 'hours')
            .subtract(mod, 'minutes')
            .utc()
            .format(DATE_FORMAT_MIN),
        end: moment()
            .seconds(0)
            .subtract(mod, 'minutes')
            .utc()
            .format(DATE_FORMAT_MIN),
    };
}
export function formatRecords(result) {
    const formattedResult = [];
    const usedDatetimes = [];
    result.forEach(({ datetime, collection, minCount, maxCount, lastUpdated, }) => {
        const usedIndex = usedDatetimes.indexOf(datetime);
        if (usedIndex === -1) {
            const newItem = {
                datetime,
                minCount,
                maxCount,
                collections: [
                    {
                        collection,
                        minCount,
                        maxCount,
                        lastUpdated,
                    },
                ],
            };
            formattedResult.push(newItem);
            usedDatetimes.push(datetime);
            return;
        }
        formattedResult[usedIndex].minCount += minCount;
        formattedResult[usedIndex].maxCount += maxCount;
        formattedResult[usedIndex].collections = [
            ...formattedResult[usedIndex].collections,
            {
                collection,
                minCount,
                maxCount,
                lastUpdated,
            },
        ];
    });
    return formattedResult;
}
export function combineResults(fullResult) {
    return fullResult.reduce((acc, curr) => {
        const { result } = curr;
        if (!result.length) {
            return acc;
        }
        return acc.concat(result);
    }, []);
}
export function findAndFilterDSTData(fullResult) {
    // removes the last data point of the first result set if daylight savings was observed/unobserved
    // and a slight offset extra data point was returned from API
    if (fullResult.length > 1) {
        const resultSetOne = fullResult[0].result;
        const resultSetTwo = fullResult[1].result;
        const resultOneDatetime = resultSetOne[resultSetOne.length - 1].datetime;
        const resultTwoDatetime = resultSetTwo[0].datetime;
        if (resultOneDatetime.substring(0, 10) === resultTwoDatetime.substring(0, 10)) {
            fullResult[0].result.pop();
        }
    }
    return fullResult;
}
