import { __rest } from "tslib";
import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { DraggableTableRow } from '@/components/drag/item';
import { withStyles } from '@/decorators';
export const styles = ({ palette }) => ({
    root: {
        width: '100%',
        '&:nth-child(odd)': {
            backgroundColor: palette.accentColours[50],
        },
    },
});
const TableDisplayWrapper = withStyles(styles)(function TableDisplayWrapper(_a) {
    var { reorderable, index, editingIndex, reorder, classes, children } = _a, props = __rest(_a, ["reorderable", "index", "editingIndex", "reorder", "classes", "children"]);
    return (<TableRow className={classes.root} {...(reorderable && editingIndex === -1 ? { index, reorder, component: DraggableTableRow } : {})} {...props}>
      {children}
    </TableRow>);
});
export default TableDisplayWrapper;
