import React from 'react';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette }) => ({
    root: Object.assign(Object.assign({ color: palette.grey[800], padding: `${spacing(2)}px ${spacing(4)}px` }, Object.keys(Array(5).fill(0)).reduce((headers, index) => Object.assign(headers, { [`& h${Number(index) + 2}`]: { paddingTop: spacing(2) } }), {})), { '& h1': {
            fontSize: '1.9rem',
            '& em': {
                fontSize: '0.6em',
                verticalAlign: 'top',
                color: palette.grey[600],
            },
        }, '& p': {
            marginLeft: spacing(2),
        }, '& li': {
            lineHeight: '1.4em',
            '& strong': {
                color: palette.grey[600],
                margin: `0 ${spacing(2)}px`,
            },
        }, '& hr': {
            margin: `${spacing(4)}px 0`,
        }, '& a': {
            color: palette.primary.main,
            '&:hover': {
                color: palette.primary.light,
            },
        }, '& em': {
            color: palette.grey[500],
        }, '& code': {
            color: blueGrey[500],
            fontWeight: 600,
            fontSize: '1.1em',
        } }),
});
const StaticPageContent = withStyles(styles)(function StaticPageContent({ classes, children, }) {
    return <main className={classes.root}>{children}</main>;
});
export default StaticPageContent;
