import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import { compose } from 'ramda';
import IconButton from '@/components/controls/icon-button';
import { withStyles, } from '@/decorators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const styles = ({ frame }) => ({
    root: {
        color: frame.color,
    },
});
export const selectors = {
    previewDrawerIsOpen: Selectors.previewDrawerOpenSelector,
};
export const enhance = compose(connect(selectors), withStyles(styles));
const DrawerToggle = enhance(function DrawerToggle({ openDrawer, previewDrawerIsOpen, classes, }) {
    const button = (<IconButton className={classes.root} aria-label="open drawer" onClick={openDrawer}>
      <MenuIcon />
    </IconButton>);
    return previewDrawerIsOpen ? (button) : (<Hidden mdUp implementation="css">
      {button}
    </Hidden>);
});
export default DrawerToggle;
