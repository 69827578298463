import React from 'react';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, frame, fontSize }) => ({
    root: {
        minWidth: spacing(18),
        background: frame.background,
        justifyContent: 'space-between',
        fontSize: fontSize.caption,
        textTransform: 'uppercase',
        fontWeight: 500,
        color: grey[400],
        '&:hover': {
            textDecoration: 'underline',
            background: frame.background,
            color: frame.color,
        },
    },
});
const MenuItem = withStyles(styles)(function MenuItem({ onClick, classes, children }) {
    return (<MaterialMenuItem className={classes.root} onClick={onClick}>
      {children}
    </MaterialMenuItem>);
});
export default MenuItem;
