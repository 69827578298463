import { __decorate } from "tslib";
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@/components/cell';
import { createModelName } from '@/analytics/dashboards/shared/utils';
import ActionsCell from '@/components/reports/table-v2/cells/actions';
import FormattedCell from '@/components/reports/table-v2/cells/formatted';
import * as Utils from '@/components/reports/table-v2/utils';
import { ModelType } from '@/constants';
import { withStyles } from '@/decorators';
import Rule from '@/merchandising/models/rule';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
export const styles = ({ palette, spacing, table }) => ({
    content: {
        '&>*': {
            background: table.background,
        },
    },
    tableCell: {
        fontSize: spacing(1.75),
        color: palette.mainFont,
        '&$highlight': {
            fontWeight: 'bold',
        },
    },
    tableRow: {
        cursor: 'pointer',
        '&>*': {
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.07)',
            },
        },
        '&>:nth-child(even)': {
            background: palette.primary.contrastText,
        },
    },
});
export const SCORE_GRADIENT = [
    { rangeIndex: 0, color: [209, 13, 13] },
    { rangeIndex: 50, color: [226, 196, 39] },
    { rangeIndex: 100, color: [63, 148, 45] },
];
export const actionCreators = {
    createModel: ActionCreators.goToCreate,
};
export const mergeProps = (_, { createModel }) => ({
    actions: [
        {
            label: 'Add Rule with Search Matches Trigger',
            callback: ({ term }) => createModel(ModelType.RULE, {
                name: createModelName('TopSearch', 'Query', term),
                triggerSets: [[{ value: term, type: Rule.Trigger.Type.SEARCH_MATCHES }]],
            }),
        },
    ],
});
let PopularSearchesBody = class PopularSearchesBody extends React.PureComponent {
    render() {
        const { data, columns, actions, classes, onRowClick, } = this.props;
        return (<React.Fragment key={'table-body'}>
        <TableBody className={classes.tableRow}>
          {data.map((result, index) => (<React.Fragment key={index}>
                <TableRow>
                  {columns.map(({ id, type, align, formatter, }, cellIndex) => {
            const key = `${index}-${cellIndex}`;
            // eslint-disable-next-line dot-notation
            const onClick = () => onRowClick && onRowClick(result['rowNum'] - 1);
            return Utils.includesScoreId(id) ? (<FormattedCell align={align} colorGradient={SCORE_GRADIENT} formatter={formatter} key={key} onClick={onClick} result={result[id]} type={type}/>) : (<TableCell onClick={onClick} align={align} type={type} key={key}>
                        <span>{formatter ? formatter(result[id]) : result[id]}</span>
                      </TableCell>);
        })}
                  <ActionsCell actions={actions} reportingResult={result} key={`row${index}-cell${columns.length}`}/>
                </TableRow>
              </React.Fragment>))}
        </TableBody>
      </React.Fragment>);
    }
};
PopularSearchesBody = __decorate([
    (withStyles(styles)),
    connect(null, actionCreators, mergeProps)
], PopularSearchesBody);
export default PopularSearchesBody;
