import React from 'react';
import Tab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import { withStyles } from '@/decorators';
import { INSIGHTS } from '.';
const TAB_HEIGHT = 48;
export const styles = ({ palette, table }) => ({
    tabContainer: {
        minHeight: TAB_HEIGHT,
        '&:after': {
            content: "''",
            borderBottom: `1px solid ${table.border}`,
            position: 'absolute',
            width: '100%',
            bottom: 0,
            zIndex: 0,
        },
    },
    tab: {
        textTransform: 'capitalize',
        fontSize: '15px',
        color: palette.mainFont,
        background: 'transparent',
    },
    tabIndicator: {
        background: table.highlight,
    },
});
const Tabs = withStyles(styles)(function Tabs({ currentInsights, onChange, classes }) {
    return (<MaterialTabs value={currentInsights} onChange={onChange} scrollable scrollButtons="off" classes={{
        flexContainer: classes.tabContainer,
        indicator: classes.tabIndicator,
    }}>
      {INSIGHTS.map(({ id, label }) => (<Tab value={id} label={label} classes={{
        root: classes.tab,
    }} key={id}/>))}
    </MaterialTabs>);
});
export default Tabs;
