import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    root: {
        display: 'grid',
        gridGap: spacing(2),
        gridTemplateColumns: `repeat(auto-fill, minmax(0, ${spacing(25)}px))`,
        justifyContent: 'space-between',
        margin: 0,
        padding: spacing(0, 2, 0, 0),
    },
});
const ProductList = withStyles(styles)(function ProductList({ classes, className, children }) {
    return (<ul className={classNames(classes.root, className)}>
      {children}
    </ul>);
});
export default ProductList;
