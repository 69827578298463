import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DEFAULT_RELEVANCY, DEFAULT_STRATEGY } from '@/constants';
import { withStyles } from '@/decorators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const selectors = {
    grove: Selectors.groveSelector,
};
const AreaInfo = connect(selectors)(function AreaInfo({ activeArea, activeUser, grove, id, }) {
    if (!activeArea) {
        return null;
    }
    return (<List id={id}>
      <ListItem>
        <ListText label="Customer ID:" value={activeUser.customerId}/>
      </ListItem>
      <ListItem>
        <ListText label="Grove:" value={grove || <i>Not Available</i>}/>
      </ListItem>
      <ListItem>
        <ListText label="Area:" value={activeArea.name}/>
      </ListItem>
      <ListItem>
        <ListText label="Biasing Profile:" value={activeArea.biasingProfile || <em>{DEFAULT_RELEVANCY}</em>}/>
      </ListItem>
      <ListItem>
        <ListText label="Match Strategy:" value={activeArea.matchStrategy || <em>{DEFAULT_STRATEGY}</em>}/>
      </ListItem>
    </List>);
});
export const listTextStyles = ({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
    },
    text: {
        fontSize: spacing(1.75),
        marginLeft: spacing(2),
    },
});
export const ListText = withStyles(listTextStyles)(function ListText({ label, value, classes }) {
    return (<ListItemText classes={{ root: classes.root, primary: classes.text, secondary: classes.text }} primary={label} secondary={value}/>);
});
export default AreaInfo;
