export function includesScoreId(id) {
    return id.includes('Score');
}
export function colorPicker(gradient, weight) {
    const { firstColor, secondColor, dividend, corrector, } = getColorBucket(gradient, weight);
    const ratio = (weight - corrector) / (dividend - corrector);
    const rgb = blendRGB(firstColor, secondColor, ratio);
    return `rgb(${rgb.join()})`;
}
export function getColorBucket(gradient, weight) {
    const secondIndex = weight >= 100 ? gradient.length - 1 : gradient.findIndex((value) => weight < value.rangeIndex);
    const firstIndex = secondIndex - 1;
    return {
        secondColor: gradient[firstIndex].color,
        firstColor: gradient[secondIndex].color,
        dividend: gradient[secondIndex].rangeIndex,
        corrector: gradient[firstIndex].rangeIndex,
    };
}
export function blendRGB(firstColor, secondColor, ratio) {
    const inverseRatio = 1 - ratio;
    return firstColor.map((_, index) => Math.round(firstColor[index] * ratio + secondColor[index] * inverseRatio));
}
export function formatPercentageFromNumber(value) {
    return value ? `${value.toFixed(1)}%` : `${(0).toFixed(1)}%`;
}
export function commaSeparateNumber(value) {
    return value ? `${value.toLocaleString()}` : 0;
}
export function roundNumber(value) {
    return value ? `${Math.round(value)}` : 0;
}
