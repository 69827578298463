import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@/decorators';
import ProductImage from './image';
export const styles = ({ spacing }) => ({
    container: {
        display: 'none',
    },
    textLink: {
        textDecorationColor: 'rgba(0, 0, 0, 0.87)',
        display: 'block',
        '&:visited': {
            textDecorationColor: 'rgba(0, 0, 0, 0.87)',
        },
    },
    imageLink: {
        textDecoration: 'none',
    },
    titleContainer: {
        position: 'relative',
        height: spacing(6),
        overflow: 'hidden',
        '&:hover': {
            overflow: 'unset',
            zIndex: 5,
        },
    },
    title: {
        width: '100%',
        overflowWrap: 'break-word',
        '&:hover': {
            background: '#f1f9e2',
            overflowY: 'unset',
            height: 'auto',
        },
        '&:visited': {
            color: 'initial',
        },
    },
});
const Product = withStyles(styles)(function Product({ product: { title = 'Product Title', imageUrl, url, notInCollection, }, pinned, onPin, xs, sm, md, lg, xl, icon, showIcons, component: Component, accentColor, className, classes, }) {
    const productTitle = (<Typography variant="body1" className={classes.title}>
      {title}
    </Typography>);
    const content = (<React.Fragment>
      {onPin ? (<ProductImage imageUrl={imageUrl} alt={title} icon={icon} accentColor={accentColor} bordered={pinned} onClick={onPin} notInCollection={notInCollection}/>) : showIcons ? (<ProductImage imageUrl={imageUrl} alt={title} showIcons={showIcons} notInCollection={notInCollection}/>) : (<ProductImage imageUrl={imageUrl} alt={title} url={url}/>)}
      <div className={classes.titleContainer}>
        {url ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url} target="_blank" className={classes.textLink}>
            {productTitle}
          </a>) : (productTitle)}
      </div>
    </React.Fragment>);
    return (<Grid item {...{
        xs,
        sm,
        md,
        lg,
        xl,
    }} className={className}>
      {Component ? <Component>{content}</Component> : content}
    </Grid>);
});
export default Product;
