import { cancel, fork, spawn, take, } from 'redux-saga/effects';
export function* spawnOnTake(pattern, saga, args) {
    yield take(pattern);
    yield spawn(saga, ...args);
}
export function* wrapContinuousSaga(saga, args, cancelOn) {
    const continuous = yield fork(saga, ...args);
    if (!cancelOn)
        return;
    yield take(cancelOn);
    yield cancel(continuous);
}
