import * as Actions from '@/store/actions/types/analytics';
import { createAction } from '@/store/actions/utils';
import State from '@/store/state';
export const setReportWindow = (window) => createAction(Actions.SET_WINDOW, window);
export const updateReportFilters = (reportType, filters) => createAction(Actions.UPDATE_REPORT_FILTERS, { reportType, filters });
export const updateReportQuery = (reportType, query, matchType) => createAction(Actions.UPDATE_REPORT_QUERY, { reportType, query, matchType });
export const updateReportActiveQuery = (reportType, query, matchType) => createAction(Actions.UPDATE_REPORT_ACTIVE_QUERY, { reportType, query, matchType });
export const removeReportQuery = (reportType) => createAction(Actions.REMOVE_REPORT_QUERY, reportType);
export const updateCurrentReport = (reportType) => createAction(Actions.UPDATE_CURRENT_REPORT, reportType);
export const removeReportFilter = (type, reportType, indexOrDevice) => createAction(Actions.REMOVE_REPORT_FILTER, {
    type,
    reportType,
    [type === State.Analytics.ReportFilters.Type.DEVICE ? 'device' : 'index']: indexOrDevice,
});
export const loadReport = (reportType) => createAction(Actions.LOAD_REPORT, reportType);
export const refreshReport = (data, reportType) => createAction(Actions.REFRESH_REPORT, { data, reportType });
