import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import TableCell from '@/components/cell';
import { Align, Type } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
export const styles = ({ table }) => ({
    row: {
        background: table.background,
    },
    lastRow: {
        borderBottom: `solid 1px ${table.border}`,
    },
    cell: {
        border: 'none',
    },
});
const ExpandableRow = withStyles(styles)(function ExpandableRow({ parentRowIndex, classes, data, columns, className, }) {
    return (<React.Fragment>
      {data.map((record, index) => (<TableRow key={`${parentRowIndex}-${index}`} className={classnames(className, { [classes.lastRow]: data.length === index + 1 })}>
            {columns.map((column, index) => {
        const { id, align = Align.LEFT, type = Type.SMALL } = column;
        const content = record[id];
        return id ? (<Tooltip key={index} title={content} placement="top-start">
                  <TableCell key={index} className={classes.cell} align={align} type={type}>
                    {content}
                  </TableCell>
                </Tooltip>) : (<TableCell className={classes.cell} type={Type.SMALL}/>);
    })}
          </TableRow>))}
    </React.Fragment>);
});
export default ExpandableRow;
