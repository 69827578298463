export function sortByPriority({ priority: lhs }, { priority: rhs }) {
    return lhs - rhs;
}
export var PushSortDirection;
(function (PushSortDirection) {
    PushSortDirection["UP"] = "UP";
    PushSortDirection["DOWN"] = "DOWN";
})(PushSortDirection || (PushSortDirection = {}));
export function sortWithPush(direction, pusher, sorter) {
    return (lhs, rhs) => {
        const pushLHS = pusher(lhs);
        const pushRHS = pusher(rhs);
        if (pushLHS === pushRHS) {
            return sorter(lhs, rhs);
        }
        if (pushLHS) {
            return direction === PushSortDirection.UP ? -1 : 1;
        }
        if (pushRHS) {
            return direction === PushSortDirection.UP ? 1 : -1;
        }
    };
}
