import { __decorate } from "tslib";
import React from 'react';
import Alert from '@/components/alert';
import Timer from '@/components/timer';
import { SESSION_INACTIVE_TIMEOUT } from '@/constants';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const styles = {
    root: {
        zIndex: 1700,
    },
};
export const selectors = {
    inactive: Selectors.inactiveSessionSelector,
};
export const actionCreators = {
    expireToken: ActionCreators.expireToken,
    refreshToken: ActionCreators.validateToken,
};
let IdleAlert = class IdleAlert extends React.PureComponent {
    render() {
        const { inactive = false, refreshToken, expireToken, classes, } = this.props;
        return (<Alert title={<span>
            {'Your session will timeout in '}
            <Timer timeout={SESSION_INACTIVE_TIMEOUT} onComplete={this.props.expireToken}/>
          </span>} primaryButton={{ label: 'Stay Logged In', onClick: () => refreshToken() }} cancelLabel="Logout" open={inactive} onCancel={expireToken} classes={{ root: classes.root }}/>);
    }
};
IdleAlert = __decorate([
    connect(selectors, actionCreators),
    (withStyles(styles))
], IdleAlert);
export default IdleAlert;
