import { __rest } from "tslib";
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import moize from 'moize';
import { compose } from 'ramda';
import { withStyles, } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
export const styles = ({ palette, spacing }) => ({
    root: {
        paddingRight: spacing(2),
        paddingLeft: spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&$active': {
            backgroundColor: palette.primary.main,
            color: palette.mainFont,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
            '&:not(:hover)': {
                color: palette.common.white,
            },
        },
    },
    listItem: {
        '&$withIcon': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    text: {
        color: palette.mainFont,
        paddingLeft: spacing(4),
        '&$withIcon': {
            paddingLeft: 0,
        },
        '&$active': {
            color: 'inherit',
        },
        '&$darkMode': {
            color: palette.primary.contrastText,
        },
    },
    icon: {
        marginRight: spacing(1),
    },
    active: {},
    withIcon: {},
    darkMode: {},
});
export const actionCreators = {
    closeDrawer: ActionCreators.closeDrawer,
};
export const enhance = compose(withStyles(styles), connect(null, actionCreators));
const Route = enhance(function Route({ url, label, onClick, active, closeDrawer, icon, darkMode, classes, }) {
    const withIcon = !!icon;
    const handleClick = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onClick && onClick();
        closeDrawer();
    };
    return (<ListItem onClick={handleClick} component={listItemLink(url)} classes={{ root: classNames(classes.root, { [classes.active]: active }) }}>
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText primary={label} classes={{
        root: classNames(classes.listItem, { [classes.withIcon]: withIcon }),
        primary: classNames(classes.text, {
            [classes.active]: active,
            [classes.withIcon]: withIcon,
            [classes.darkMode]: darkMode,
        }),
    }}/>
    </ListItem>);
});
const listItemLink = moize((href) => React.forwardRef(function ListItemLink(_a, ref) {
    var { onClick } = _a, props = __rest(_a, ["onClick"]);
    return (<li ref={ref}>
          <a // eslint-disable-line jsx-a11y/anchor-has-content
     href={href && href.substr(1)} onClick={(e) => {
        e.preventDefault();
        onClick();
    }} {...props}/>
        </li>);
}));
export default Route;
