import { __decorate } from "tslib";
import React from 'react';
import Popover from '@material-ui/core/Popover';
import Collections from '@/components/controls/autocomplete/collections';
import ProductAttribute from '@/components/controls/autocomplete/product-attributes';
import Button from '@/components/controls/button';
import TextField from '@/components/controls/textfield';
import LabeledSection from '@/components/labeled/section';
import { DEFAULT_AREA } from '@/constants';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect, isValidField } from '@/utils';
var ConfigurationFields = State.SearchPreview.ConfigurationFields;
export const POPOVER_LAYOUT_PROPS = {
    BackdropProps: { style: { backgroundColor: 'none' } },
    hideBackdrop: false,
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    transformOrigin: { vertical: 'top', horizontal: 'right' },
};
export const styles = ({ spacing }) => ({
    popover: {
        width: spacing(46),
        padding: spacing(3),
        overflow: 'visible',
    },
    title: {
        margin: 0,
        fontWeight: 500,
        marginBottom: spacing(2),
    },
    imagePrefixField: {
        margin: `${spacing(1)}px 0 ${spacing(1)}px 0`,
    },
    backdrop: {
        backgroundColor: 'none',
    },
    headingSection: {
        marginBottom: spacing(2),
        marginTop: 0,
    },
    fields: {
        margin: `${spacing(1 / 2)}px 0 ${spacing(1 / 2)}px 0`,
    },
    button: {
        marginTop: spacing(1),
    },
});
export const selectors = {
    searchPreview: Selectors.searchPreviewSelector,
};
export const actionCreators = {
    updateSearchPreview: ActionCreators.updateSearchPreviewConfig,
};
export const mergeProps = ({ searchPreview }, { updateSearchPreview }, { area }) => ({
    config: searchPreview.configurationByArea[area] || searchPreview.configurationByArea[DEFAULT_AREA] || {},
    updateSearchPreview: (config) => updateSearchPreview(area, config),
});
let ConfigMenu = class ConfigMenu extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = Object.assign(Object.assign({}, this.props.config), { errorMessage: null });
        this.onChange = (field) => (value) => this.setState({ [field]: value });
        this.onSave = () => {
            const { updateSearchPreview, onClose, onChange } = this.props;
            if (isValidField(this.state.collection)) {
                updateSearchPreview(this.state);
                onChange();
                onClose();
            }
            else {
                this.setState({ errorMessage: 'Please set a collection' });
            }
        };
        this.onClose = () => {
            const { config, onClose } = this.props;
            onClose();
            this.setState(Object.assign(Object.assign({}, config), { errorMessage: null }));
        };
        this.onEnterSubmit = ({ key }) => key === 'Enter' && this.onSave();
    }
    render() {
        const { open, rootEl, classes } = this.props;
        const { imageUrl, imageUrlPrefix, imageUrlSuffix, url, title, collection, errorMessage, } = this.state;
        return (<Popover {...POPOVER_LAYOUT_PROPS} anchorEl={rootEl} onKeyPress={this.onEnterSubmit} open={open} onClose={this.onClose} classes={{ paper: classes.popover }}>
        <p className={classes.title}>Settings:</p>
        <LabeledSection label="Image URL" size="small" tooltip="URL of the image to be shown in the product tile" labelClassName={classes.headingSection}>
          <ProductAttribute className={classes.fields} value={imageUrl || ''} onChange={this.onChange(ConfigurationFields.IMAGE_URL)} portal={false} fullWidth placeholder="Image Attribute - eg. imageUrl" condenseHeight refineable form="config-image-url-input"/>
          <TextField formControlClassName={classes.imagePrefixField} fullWidth placeholder="Image Prefix - eg. http://www.groupbyinc.com/imgs/" onChange={this.onChange(ConfigurationFields.IMAGE_URL_PREFIX)} value={imageUrlPrefix}/>
          <TextField formControlClassName={classes.fields} fullWidth placeholder="Image Suffix - eg. .jpg" onChange={this.onChange(ConfigurationFields.IMAGE_URL_SUFFIX)} value={imageUrlSuffix}/>
        </LabeledSection>
        <LabeledSection label="URL" size="small" tooltip="URL of the page when you click on the product tile" labelClassName={classes.headingSection}>
          <ProductAttribute className={classes.fields} fullWidth placeholder="URL" portal={false} onChange={this.onChange(ConfigurationFields.URL)} value={url || ''} condenseHeight refineable form="config-product-url-input"/>
        </LabeledSection>
        <LabeledSection label="Title" size="small" tooltip="Product title displayed under the product tile" labelClassName={classes.headingSection}>
          <ProductAttribute className={classes.fields} fullWidth placeholder="Title" onChange={this.onChange(ConfigurationFields.TITLE)} portal={false} condenseHeight value={title || ''} refineable form="config-product-title-input"/>
        </LabeledSection>
        <LabeledSection label="Collection" size="small" tooltip="Associated collection of products" labelClassName={classes.headingSection}>
          <Collections fullWidth className={classes.fields} placeholder="Collection" portal={false} onChange={this.onChange(ConfigurationFields.COLLECTION)} value={collection || ''} error={!isValidField(collection) && errorMessage} form="config-collections-input"/>
        </LabeledSection>

        <Button variant="primary" onClick={this.onSave} className={classes.button}>
          SAVE
        </Button>
      </Popover>);
    }
};
ConfigMenu = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators, mergeProps)
], ConfigMenu);
export default ConfigMenu;
