import moment from 'moment';
import { all, call, delay, put, select, } from 'redux-saga/effects';
import { DATE_FORMAT_HOUR } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { getToday } from '@/utils/time';
import * as Tasks from '../tasks';
import { wrapContinuousSaga } from '../utils';
import * as AnalyticsUtils from './utils';
var DataSetKey = State.Analytics.DataSetKey;
var DashboardType = State.Analytics.DashboardType;
export const SERVICES = ['search', 'recommendations', 'sayt'];
export const API_USAGE_INTERVAL = 24;
export const DASHBOARD_LOADERS = {
    [DashboardType.SEARCH_PERFORMANCE]: {
        charts: [loadClickthroughsTask],
        snapshots: [loadClickthroughsSnapshotTask],
    },
    [DashboardType.API_USAGE]: {
        charts: [loadServicesTask, loadTimeseriesTask],
        snapshots: [loadServicesSnapshotTask, loadTimeseriesSnapshotTask],
    },
    [DashboardType.API_RESPONSE_TIME]: {
        charts: [loadResponseTimeTask],
        snapshots: [],
    },
    [DashboardType.RECORDS]: {
        charts: [loadRecordsTask],
        snapshots: [loadCurrentRecordsTask],
    },
};
export function* loadClickthroughsTask({ dateRange: { start, end }, devices }, dashboard) {
    try {
        yield put(ActionCreators.refreshData(null, dashboard, DataSetKey.CLICKTHROUGHS));
        const authClient = yield call(Tasks.authClient);
        const customerArea = yield select(Selectors.activeAreaSelector);
        const dateRange = AnalyticsUtils.getDateRangeParam({ start, end });
        const fullResult = yield all(dateRange.map((range) => call(authClient.analyticsV2.clickthroughs, Object.assign(Object.assign({}, range), { customerArea, deviceType: devices }))));
        const combinedResult = AnalyticsUtils.combineResults(fullResult);
        yield put(ActionCreators.refreshData(combinedResult.length === 0 ? [] : combinedResult, dashboard, DataSetKey.CLICKTHROUGHS));
    }
    catch (e) {
        yield Tasks.simpleError('load clickthroughs', e);
    }
}
export function* loadClickthroughsSnapshotTask({ dateRange: { start, end }, devices }, dashboard) {
    try {
        yield put(ActionCreators.refreshSnapshot(null, dashboard, DataSetKey.CLICKTHROUGHS));
        const authClient = yield call(Tasks.authClient);
        const customerArea = yield select(Selectors.activeAreaSelector);
        const { result } = yield call(authClient.analyticsV2.clickthroughs, Object.assign(Object.assign({}, AnalyticsUtils.getDateRangeParam({ start, end, snapshot: true })[0]), { customerArea, deviceType: devices }));
        yield put(ActionCreators.refreshSnapshot(result.status ? [] : result[0], dashboard, DataSetKey.CLICKTHROUGHS));
    }
    catch (e) {
        yield Tasks.simpleError('load search performance snapshot', e);
    }
}
export function* loadServicesTask({ dateRange: { start, end } }, dashboard) {
    try {
        yield put(ActionCreators.refreshData(null, dashboard, DataSetKey.SERVICES));
        const authClient = yield call(Tasks.authClient);
        const dateRange = AnalyticsUtils.getDateRangeParam({ start, end, staticInterval: API_USAGE_INTERVAL });
        const fullResult = yield all(dateRange.map((range) => call(authClient.analyticsV2.services, Object.assign(Object.assign({}, range), { interval: API_USAGE_INTERVAL }))));
        const filteredDSTData = AnalyticsUtils.findAndFilterDSTData(fullResult);
        const combinedResult = AnalyticsUtils.combineResults(filteredDSTData);
        yield put(ActionCreators.refreshData(combinedResult.length === 0 ? [] : combinedResult, dashboard, DataSetKey.SERVICES));
    }
    catch (e) {
        yield Tasks.simpleError('load services', e);
    }
}
export function* loadServicesSnapshotTask({ dateRange: { start, end } }, dashboard) {
    try {
        yield put(ActionCreators.refreshSnapshot(null, dashboard, DataSetKey.SERVICES));
        const authClient = yield call(Tasks.authClient);
        const { result } = yield call(authClient.analyticsV2.services, Object.assign({}, AnalyticsUtils.getDateRangeParam({ start, end, snapshot: true })[0]));
        const filteredResult = result[0]
            ? Object.keys(result[0])
                .filter((service) => SERVICES.includes(service))
                .reduce((obj, key) => (Object.assign(Object.assign({}, obj), { [key]: result[0][key] })), {})
            : [];
        yield put(ActionCreators.refreshSnapshot(filteredResult, dashboard, DataSetKey.SERVICES));
    }
    catch (e) {
        yield Tasks.simpleError('load services snapshot', e);
    }
}
export function* loadTimeseriesTask({ dateRange: { start, end } }, dashboard) {
    try {
        yield put(ActionCreators.refreshData(null, dashboard, DataSetKey.TIMESERIES));
        const authClient = yield call(Tasks.authClient);
        const dateRange = AnalyticsUtils.getDateRangeParam({ start, end, staticInterval: API_USAGE_INTERVAL });
        const fullResult = yield all(dateRange.map((range) => call(authClient.analyticsV2.timeseries, Object.assign(Object.assign({}, range), { interval: API_USAGE_INTERVAL }))));
        const filteredDSTData = AnalyticsUtils.findAndFilterDSTData(fullResult);
        const combinedResult = AnalyticsUtils.combineResults(filteredDSTData);
        yield put(ActionCreators.refreshData(combinedResult.length === 0 ? [] : combinedResult, dashboard, DataSetKey.TIMESERIES));
    }
    catch (e) {
        yield Tasks.simpleError('load timeseries', e);
    }
}
export function* loadTimeseriesSnapshotTask({ dateRange: { start, end } }, dashboard) {
    try {
        yield put(ActionCreators.refreshSnapshot(null, dashboard, DataSetKey.TIMESERIES));
        const authClient = yield call(Tasks.authClient);
        const { result } = yield call(authClient.analyticsV2.timeseries, Object.assign({}, AnalyticsUtils.getDateRangeParam({ start, end, snapshot: true })[0]));
        yield put(ActionCreators.refreshSnapshot(result.status ? [] : result[0], dashboard, DataSetKey.TIMESERIES));
    }
    catch (e) {
        yield Tasks.simpleError('load timeseries snapshot', e);
    }
}
export function* loadRecordsTask({ dateRange: { start, end } }, dashboard) {
    try {
        yield put(ActionCreators.refreshData(null, dashboard, DataSetKey.RECORDS));
        const authClient = yield call(Tasks.authClient);
        const dateRange = AnalyticsUtils.getDateRangeParam({ start, end });
        const fullResult = yield all(dateRange.map((range) => call(authClient.analyticsV2.recordsTimeseries, range)));
        const combinedResult = AnalyticsUtils.combineResults(fullResult);
        yield put(ActionCreators.refreshData(combinedResult.length === 0 ? [] : combinedResult, dashboard, DataSetKey.RECORDS));
    }
    catch (e) {
        yield Tasks.simpleError('load records', e);
    }
}
export function* loadCurrentRecordsTask(_, dashboard) {
    const start = getToday();
    const end = moment()
        .hours(0)
        .utc()
        .add(1, 'day')
        .format(DATE_FORMAT_HOUR);
    try {
        yield put(ActionCreators.refreshSnapshot(null, dashboard, DataSetKey.RECORDS));
        const authClient = yield call(Tasks.authClient);
        const { result } = yield call(authClient.analyticsV2.records, {
            start,
            end,
            interval: 1,
        });
        const formattedResult = Array.isArray(result) && AnalyticsUtils.formatRecords(result);
        yield put(ActionCreators.refreshSnapshot(result.status ? [] : formattedResult[formattedResult.length - 1], dashboard, DataSetKey.RECORDS));
    }
    catch (e) {
        yield Tasks.simpleError('load current records', e);
    }
}
export function* loadResponseTimeTask() {
    yield put(ActionCreators.refreshData(null, DashboardType.API_RESPONSE_TIME, DataSetKey.RESPONSE_TIME));
    yield call(wrapContinuousSaga, backgroundResponseTimeRefreshTask, Actions.STOP_REFRESH_DASHBOARD);
}
export function* backgroundResponseTimeRefreshTask() {
    while (true) {
        const { timeRange, service } = yield select(Selectors.filterSelector(DashboardType.API_RESPONSE_TIME));
        try {
            const { interval, start, end } = AnalyticsUtils.getResponseTimeConfig(timeRange);
            const authClient = yield call(Tasks.authClient);
            const { result } = yield call(authClient.analyticsV2.responseTime, {
                start,
                end,
                service,
                interval,
            });
            yield put(ActionCreators.refreshData(result.status ? [] : result, DashboardType.API_RESPONSE_TIME, DataSetKey.RESPONSE_TIME));
        }
        catch (e) {
            yield Tasks.simpleError('load response time', e);
        }
        finally {
            yield delay(AnalyticsUtils.getResponseTimeConfig(timeRange).delayBy);
        }
    }
}
export default function* refreshDashboard({ payload: dashboard }) {
    const filters = yield select(Selectors.filterSelector(dashboard));
    const refreshChartActions = DASHBOARD_LOADERS[dashboard].charts.map((loadChart) => call(loadChart, filters, dashboard));
    const refreshSnapshotActions = DASHBOARD_LOADERS[dashboard].snapshots.map((loadSnapshot) => call(loadSnapshot, filters, dashboard));
    yield all([...refreshChartActions, ...refreshSnapshotActions]);
}
