import { combineReducers } from 'redux';
import chartsDataReducer from './charts-data';
import currentInsightsReducer from './current-insights';
import currentReportReducer from './current-report';
import dashboardFiltersReducer from './dashboard-filters';
import healthChecksReducer from './health-checks';
import insightsReducer from './insights';
import reportsReducer from './reports';
import reportsWindowReducer from './reports-window';
import shapshotsDataReducer from './snapshots-data';
export const reducers = {
    reportsWindow: reportsWindowReducer,
    currentReport: currentReportReducer,
    currentInsights: currentInsightsReducer,
    reports: reportsReducer,
    healthchecks: healthChecksReducer,
    chartsData: chartsDataReducer,
    snapshotsData: shapshotsDataReducer,
    dashboardFilters: dashboardFiltersReducer,
    insights: insightsReducer,
};
export const createAnalyticsReducer = () => combineReducers(reducers);
export default createAnalyticsReducer;
