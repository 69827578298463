import React from 'react';
import { Redirect, Route, Switch, } from 'react-router';
import Sections from '@/glancer-analytics';
import Loading from '@/components/loading';
import { Routing } from '@/constants';
import * as Selectors from '@/store/selectors';
import { connect, denormalize } from '@/utils';
export const selectors = {
    dashboards: Selectors.glancerDashboardsSelector,
};
const GenericDashboardRouter = connect(selectors)(function GenericDashboardRouter({ match, dashboards }) {
    const dashboardList = denormalize(dashboards);
    const GenericDashboard = Sections.generic;
    return (<React.Fragment>
      {!dashboards.allIds.length
        ? <Loading isLoading={true} label="Getting available dashboards..."/>
        : (<Switch>
            {dashboardList.map((dashboard) => (<Route path={`${match.path}/${dashboard.id}`} key={dashboard.id}>
                <GenericDashboard name={dashboard.title} id={dashboard.id}/>
              </Route>))}
            <Redirect to={`${Routing.GLANCER_ANALYTICS_PATH}/${dashboardList[0].id}`}/>
          </Switch>)}
    </React.Fragment>);
});
export default GenericDashboardRouter;
