import State from '@/store/state';
import { cuid } from '@/utils';
import * as Actions from '../types/ui';
import { createAction } from '../utils';
export const scrollToTop = () => createAction(Actions.SCROLL_TO_TOP);
export const showAlert = (text, action) => createAction(Actions.SHOW_ALERT, { text, action });
export const hideAlert = () => createAction(Actions.HIDE_ALERT);
export const showError = (id, message) => createAction(Actions.SHOW_ERROR, { id, message });
export const clearError = (id) => createAction(Actions.CLEAR_ERROR, id);
export const addError = (message, details) => createAction(Actions.ADD_NOTIFICATION, Object.assign({ message, type: State.Notification.Type.ERROR, id: cuid() }, (details ? { details } : {})));
export const addSuccess = (message, details) => createAction(Actions.ADD_NOTIFICATION, Object.assign({ message, type: State.Notification.Type.SUCCESS, id: cuid() }, (details ? { details } : {})));
export const openDrawer = () => createAction(Actions.OPEN_DRAWER);
export const closeDrawer = () => createAction(Actions.CLOSE_DRAWER);
export const expandDrawer = () => createAction(Actions.EXPAND_DRAWER);
export const collapseDrawer = () => createAction(Actions.COLLAPSE_DRAWER);
export const openPreviewDrawer = () => createAction(Actions.OPEN_PREVIEW_DRAWER);
export const closePreviewDrawer = () => createAction(Actions.CLOSE_PREVIEW_DRAWER);
export const openPositionModal = (position) => createAction(Actions.OPEN_POSITION_MODAL, position);
export const updateLockBanner = (open) => createAction(Actions.UPDATE_LOCK_BANNER, { open });
export const closePositionModal = () => createAction(Actions.CLOSE_POSITION_MODAL);
export const openCopyToAreaModal = (id) => createAction(Actions.OPEN_COPY_TO_AREA_MODAL, id);
export const closeCopyToAreaModal = () => createAction(Actions.CLOSE_COPY_TO_AREA_MODAL);
export const openChangeLogModal = () => createAction(Actions.OPEN_CHANGELOG_MODAL);
export const closeChangeLogModal = () => createAction(Actions.CLOSE_CHANGELOG_MODAL);
export const closeNotification = (id) => createAction(Actions.CLOSE_NOTIFICATION, id);
export const removeNotification = (id) => createAction(Actions.REMOVE_NOTIFICATION, id);
