import React from 'react';
import { green, red } from '@material-ui/core/colors';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
import State from '@/store/state';
var Notification = State.Notification;
export const styles = ({ fontSize, spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: spacing(62.5),
        '&$error': {
            color: red[500],
        },
        '&$success': {
            color: green[500],
        },
    },
    error: {},
    success: {},
    text: {
        marginLeft: spacing(1.5),
        color: palette.mainFont,
    },
    message: {
        margin: 0,
        fontWeight: 500,
        fontSize: fontSize.formHeading,
    },
    details: {
        margin: 0,
        fontSize: fontSize.formControl,
    },
});
const NotificationContent = withStyles(styles)(function NotificationContent({ message, type, details, classes, }) {
    return (<div className={classNames(classes.root, classes[type])}>
      {Notification.Type.ERROR === type ? <ErrorIcon /> : <SuccessIcon />}
      <div className={classes.text}>
        <h3 className={classes.message}>{message}</h3>
        {details && <p className={classes.details}>{details}</p>}
      </div>
    </div>);
});
export default NotificationContent;
