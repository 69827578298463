import React from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import IconButton from '@/components/controls/icon-button';
import { withStyles } from '@/decorators';
import Wrapper from './wrapper';
export const styles = ({ palette, spacing }) => ({
    icon: {
        marginRight: spacing(1),
        padding: spacing(1 / 4),
        '&:hover': {
            color: palette.secondary.dark,
        },
    },
});
const FormManagerForm = withStyles(styles)(function FormManagerForm({ onAdd, classes, children, }) {
    return (<Wrapper bordered>
      <IconButton onClick={onAdd} color="secondary" classes={{ root: classes.icon }} aria-label="Add New">
        <AddIcon />
      </IconButton>
      {children}
    </Wrapper>);
});
export default FormManagerForm;
