import { __decorate } from "tslib";
import React from 'react';
import Dashboard from '@/components/dashboard';
import { Direction } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import PopularSearchesChart from './charts/popular-searches';
import Content from './content';
import FilterSection, { View } from './filters';
import GlobalFilters from './filters/global';
import PopularSearchesReport from './reports/popular-searches';
import TopNullsReport from './reports/top-nulls';
import TabHeaders from './tab-headers';
var ReportType = State.Analytics.ReportType;
export const REPORTS = [
    {
        id: ReportType.PERFORMANCE,
        label: 'top searches',
        reportComponent: PopularSearchesReport,
        chartComponent: PopularSearchesChart,
        defaultSort: { orderBy: 'rowNum', order: Direction.ASC },
    },
    {
        id: ReportType.NULLS,
        label: 'top nulls',
        reportComponent: TopNullsReport,
        defaultSort: { orderBy: 'uniqueSearches', order: Direction.DESC },
    },
];
export const LEGACY_REPORTS = [
    {
        id: ReportType.TOP_REFINEMENTS,
        label: 'top refinements',
        defaultSort: { orderBy: 'count', order: Direction.DESC },
    },
];
export const styles = ({ spacing, palette, table, fontSize, toggleButton, }) => ({
    root: {
        margin: spacing(1.5),
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
    },
    content: {
        background: table.background,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    filterContainer: {
        display: 'block',
        flexDirection: 'column',
        fontSize: fontSize.caption,
        borderBottom: `1px solid ${toggleButton.border}`,
        '& >*': {
            padding: `${spacing(2)}px ${spacing(2)}px ${spacing(1)}px ${spacing(2)}px`,
            boxSizing: 'border-box',
            height: 'inherit',
        },
    },
    reportContainer: {
        color: palette.contrastFont,
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        flex: 10,
    },
});
export const selectors = {
    reportData: Selectors.reportDataSelectorV2,
    currentReport: Selectors.currentReportSelector,
    activeQuery: Selectors.reportQueryActiveQuerySelector,
};
export const actionCreators = {
    loadReport: ActionCreators.loadReport,
    updateCurrentReport: ActionCreators.updateCurrentReport,
};
let SearchTerm = class SearchTerm extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { filterSize: 100, view: View.TABLE };
        this.handleTabChange = (_, reportType) => {
            this.props.updateCurrentReport(reportType);
            this.setState({ view: View.TABLE });
        };
        this.handleToggleChange = (view) => this.setState({ view });
        this.onShowTopChange = (filterSize) => this.setState({ filterSize: Number(filterSize) });
    }
    render() {
        const { currentReport, reportData, activeQuery, classes, } = this.props;
        if (!currentReport) {
            return null;
        }
        const { filterSize, view } = this.state;
        const { label } = REPORTS.find(({ id }) => currentReport === id);
        const chartAvailable = currentReport === ReportType.PERFORMANCE;
        return (<Dashboard.Container title="Search Term Analysis" id="search-term-analysis" filters={<GlobalFilters reportType={currentReport} key={currentReport}/>}>
        <div className={classes.root}>
          <TabHeaders currentReport={currentReport} onChange={this.handleTabChange}/>
          <div className={classes.content}>
            <div className={classes.filterContainer}>
              <FilterSection currentReport={currentReport} chartAvailable={chartAvailable} view={view} reportData={reportData} reportName={label} filterSize={filterSize} activeQuery={activeQuery} handleToggleChange={this.handleToggleChange} onChange={this.onShowTopChange}/>
            </div>
            <div className={classes.reportContainer}>
              <Content reportData={reportData} filterSize={filterSize} key={currentReport} currentReport={currentReport} isChartView={view === View.CHART && chartAvailable}/>
            </div>
          </div>
        </div>
      </Dashboard.Container>);
    }
};
SearchTerm = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators)
], SearchTerm);
export default SearchTerm;
