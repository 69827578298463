import * as Actions from '@/store/actions/types';
import { isValidField } from '@/store/reducers/search-preview';
import State from '@/store/state';
import * as ReportsReducers from './reports';
export const DEFAULT = {};
export default function (state = DEFAULT, action) {
    switch (action.type) {
        case Actions.UPDATE_REPORT_FILTERS:
            return ReportsReducers.updateReportFiltersReducer(state, action);
        case Actions.UPDATE_REPORT_QUERY:
            return ReportsReducers.updateReportQueryReducer(state, action);
        case Actions.UPDATE_REPORT_ACTIVE_QUERY:
            return ReportsReducers.updateReportActiveQueryReducer(state, action);
        case Actions.REMOVE_REPORT_QUERY:
            return ReportsReducers.removeQueryReducer(state, action);
        case Actions.REMOVE_REPORT_FILTER:
            return ReportsReducers.removeReportFilterReducer(state, action);
        case Actions.REFRESH_REPORT:
            return ReportsReducers.refreshReportDataReducer(state, action);
        default:
            return state;
    }
}
export function refreshReportDataReducer(state, { payload: { data, reportType } }) {
    return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { data }) });
}
export function updateReportFiltersReducer(state, { payload: { reportType, filters } }) {
    return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { filters }) });
}
export function updateReportQueryReducer(state, { payload: { reportType, query, matchType } }) {
    return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { matchType, query: isValidField(query) ? query : null }) });
}
export function updateReportActiveQueryReducer(state, { payload: { reportType, query, matchType } }) {
    return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { activeQuery: Object.assign(Object.assign({}, state[reportType].activeQuery), { matchType, query: isValidField(query) ? query : null }) }) });
}
export function removeQueryReducer(state, { payload: reportType }) {
    return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { query: null }) });
}
export function removeReportFilterReducer(state, { payload: { type, reportType, index, device, }, }) {
    switch (type) {
        case State.Analytics.ReportFilters.Type.DEVICE:
            return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { filters: Object.assign(Object.assign({}, state[reportType].filters), { devices: state[reportType].filters.devices.filter((deviceType) => deviceType !== device) }) }) });
        case State.Analytics.ReportFilters.Type.METADATA: {
            const { metadata } = state[reportType].filters;
            return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { filters: Object.assign(Object.assign({}, state[reportType].filters), { metadata: [...metadata.slice(0, index), ...metadata.slice(index + 1)] }) }) });
        }
        case State.Analytics.ReportFilters.Type.NAVIGATION_NULLS:
            return Object.assign(Object.assign({}, state), { [reportType]: Object.assign(Object.assign({}, state[reportType]), { filters: Object.assign(Object.assign({}, state[reportType].filters), { includeNavigationNulls: false }) }) });
        default:
            return state;
    }
}
