import { createSelector } from 'reselect';
export function changeLogSelector({ changeLog }) {
    return changeLog;
}
export const changeLogDataLimitReachedSelector = createSelector(changeLogSelector, ({ dataLimitReached }) => dataLimitReached);
export const changeLogFilterSelector = createSelector(changeLogSelector, ({ filters }) => filters);
export const changeLogIsActiveSelector = createSelector(changeLogSelector, ({ isActive }) => isActive);
export const changeLogDataSelector = createSelector(changeLogSelector, ({ data }) => data);
export const changeLogSearchBySelector = createSelector(changeLogSelector, ({ searchBy }) => searchBy);
export const changeLogDateRangeSelector = createSelector(changeLogFilterSelector, ({ dateRange }) => dateRange);
