import * as Actions from '@/store/actions/types';
import State from '@/store/state';
import * as CurrentInsightsReducer from './current-insights';
var InsightsType = State.Analytics.InsightsType;
export const DEFAULT = InsightsType.METRICS;
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.UPDATE_CURRENT_INSIGHTS:
            return CurrentInsightsReducer.updateCurrentInsightsReducer(action);
        default:
            return state;
    }
};
export function updateCurrentInsightsReducer({ payload: currentInsights, }) {
    return currentInsights;
}
