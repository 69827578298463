import React from 'react';
import StatusIcon from '@material-ui/icons/Flag';
import MenuItem from '../menu-item';
function StatusMenuItem() {
    const url = 'http://status.groupbycloud.com';
    return (<MenuItem onClick={() => window.open(url, '_blank')}>
      <StatusIcon />
      Status Page
    </MenuItem>);
}
export default StatusMenuItem;
