import pathMatch from 'path-match';
import { createSelector } from 'reselect';
import { Routing } from '@/constants';
import { isGlobalModelType } from '@/merchandising/models/utils';
import { activeUserSelector } from '@/store/selectors';
import { denormalize, parseSearch } from '@/utils';
import * as RouterSelectors from './router';
export function merchandisingSelector({ merchandising }) {
    return merchandising;
}
export const currentPageSelector = createSelector(RouterSelectors.activeQuerySelector, (query) => {
    // eslint-disable-next-line no-restricted-globals
    if ('page' in query && query.page != null && !isNaN(query.page)) {
        return Math.max(1, Number(query.page));
    }
    return 1;
});
export const currentFilterSelector = createSelector(RouterSelectors.activeQuerySelector, (query) => {
    if ('filter' in query && query.filter) {
        return query.filter;
    }
    return '';
});
export const isEditingModelSelector = createSelector(RouterSelectors.activeLocationSelector, ({ pathname, search }) => !!pathMatch({})(Routing.MODEL_MANAGER_PATH)(pathname) && !!parseSearch(search).id);
export const isCreatingModelSelector = createSelector(RouterSelectors.activePathSelector, (pathname) => !!pathMatch({})(Routing.MODEL_CREATOR_PATH)(pathname));
export const isLockableFormSelector = createSelector(isEditingModelSelector, isCreatingModelSelector, (isEditing, isCreating) => isEditing && !isCreating);
export const isLockableListSelector = createSelector(RouterSelectors.activeLocationSelector, ({ pathname, search }) => !!pathMatch({})(Routing.MODEL_MANAGER_PATH)(pathname) && search === '');
export const isLockablePageSelector = createSelector(isLockableFormSelector, isLockableListSelector, (lockablePage, lockableList) => lockablePage || lockableList);
export const unlockInProgressSelector = createSelector(merchandisingSelector, ({ unlockInProgress }) => unlockInProgress);
export const managerSelector = (modelType, area) => createSelector(merchandisingSelector, (merchandising) => merchandisingManagerSelector(merchandising, modelType, area));
export const managerModelsSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => managerState && denormalize(managerState));
export const managerModelSelect = (managerState, id) => managerState && managerState.byId[id];
export const managerModelSelector = (modelType, area, id) => createSelector(managerSelector(modelType, area), (managerState) => managerModelSelect(managerState, id));
export const managerModelLoadedSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => managerState && managerState.loaded);
export const isManagerLoadedSelector = (modelType, area) => createSelector(managerSelector(modelType, area), (managerState) => !!managerState);
export const isManagerFormLoadedSelector = createSelector(merchandisingSelector, ({ formLoaded }) => formLoaded);
export function merchandisingManagerSelector(merchandising, modelType, area) {
    const isGlobal = isGlobalModelType(modelType);
    if (isGlobal) {
        return merchandising.global[modelType];
    }
    const areaModels = merchandising.byArea[modelType];
    if (areaModels && areaModels.allAreas.includes(area)) {
        return areaModels.byArea[area];
    }
}
export function managerModelsSelectorCreator(state) {
    return (modelType, area) => managerModelsSelector(modelType, area)(state);
}
export function managerModelLoadedSelectorCreator(state) {
    return (modelType, area) => managerModelLoadedSelector(modelType, area)(state);
}
export function isManagerLoadedSelectorCreator(state) {
    return (modelType, area) => isManagerLoadedSelector(modelType, area)(state);
}
export const locksByIdsSelector = createSelector(merchandisingSelector, ({ locksByIds }) => locksByIds);
export const modelLockStatusSelector = createSelector(merchandisingSelector, ({ isLocked }) => isLocked);
export const modelLockOwnerSelector = createSelector(merchandisingSelector, ({ lockOwner }) => lockOwner);
export const isModelLockOwnerSelector = createSelector(modelLockOwnerSelector, activeUserSelector, (lockOwner, user) => !!user && lockOwner === user.email);
