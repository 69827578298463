import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { FormCell } from '@/components/cell';
import SmallIconButton from '@/components/small-icon-button';
function FormManagerTableDisplay({ onEdit, onRemove, children }) {
    return (<React.Fragment>
      <FormCell>
        <SmallIconButton onClick={onEdit}>
          <EditIcon />
        </SmallIconButton>
      </FormCell>
      {children}
      <FormCell>
        <SmallIconButton onClick={onRemove}>
          <DeleteIcon />
        </SmallIconButton>
      </FormCell>
    </React.Fragment>);
}
export default FormManagerTableDisplay;
