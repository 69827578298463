import * as CollectionsSelectors from './collections';
export function productAttributesSelector(state) {
    return state.collections.productAttributes;
}
export function productAttributeValuesSelector(state) {
    return state.collections.productAttributeValues.byField;
}
export function autocompleteEnableFetchSelector({ collections: { fetchValues } }) {
    return fetchValues;
}
export function productValuesAtAttributeSelector(state, { productAttribute }) {
    const byField = CollectionsSelectors.productAttributeValuesSelector(state);
    return byField[productAttribute] || [];
}
export function collectionsSelector({ collections: { collections } }) {
    return collections;
}
export function productValuesByFieldSelector(state, field) {
    const byField = CollectionsSelectors.productAttributeValuesSelector(state);
    return byField[field];
}
