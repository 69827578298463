import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
import { ZoneContent } from '.';
var Zone;
(function (Zone) {
    Zone.DEFAULT = { zone: { type: ZoneContent.Type.CONTENT } };
    Zone.CONFIG = {
        type: ModelType.ZONE,
        defaults: Zone.DEFAULT,
        filter: filterByNameAndId,
    };
})(Zone || (Zone = {}));
export default Zone;
