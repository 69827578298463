import pathMatcher from 'path-match';
import { createSelector } from 'reselect';
import { Routing, ENRICH_FIELD_DISPLAY_NAMES } from '@/constants';
import { pageTypeProcessingClasses } from '@/enrich/routing/utils/index';
import * as RouterSelectors from './router';
import { getClassificationWildcard } from '@/routes/enrich';
import { getOrgNamesFromJobsFilter, getValidatedPredictions } from './utils';
import { currentIdSelector } from './query-param';
const pathMatch = pathMatcher({ end: false });
export function enrichSelector({ enrich }) {
    return enrich;
}
export const enrichDataSelector = createSelector(enrichSelector, ({ data }) => data);
export const enrichSnapshotSelector = createSelector(enrichSelector, ({ snapshots }) => snapshots);
export const enrichCustomerIdSelector = createSelector(enrichSelector, ({ customerId }) => customerId);
export const enrichFiltersSelector = createSelector(enrichSelector, ({ dateRange }) => dateRange);
export const enrichTokensSelector = createSelector(enrichSelector, ({ tokens }) => tokens);
export const enrichPageSizesSelector = createSelector(enrichSelector, ({ pagination: { pageSizes } }) => pageSizes);
export const enrichCurrentTotalItemsSelector = createSelector(enrichSelector, ({ currentTotalItems }) => currentTotalItems);
export const enrichAllProductsSelector = createSelector(enrichSelector, ({ products }) => products);
export const enrichProductCountsSelector = createSelector(enrichSelector, ({ productCounts }) => productCounts);
export const enrichProductsExpandedSelector = createSelector(enrichSelector, ({ productsExpanded }) => productsExpanded);
export const enrichSectionSelector = createSelector(RouterSelectors.activeRouteSelector, (location) => {
    const pattern = Routing.Page.Enrich.SECTION_PATH;
    const match = pathMatch(pattern)(location);
    if (!match)
        return '';
    const section = match[Routing.Page.Enrich.SECTION_WILDCARD];
    if (!Object.values(Routing.Page.Enrich.VALID_SECTIONS).includes(section)) {
        return '';
    }
    return section;
});
export const enrichProductsFilterSelector = createSelector(RouterSelectors.activeRouteSelector, enrichSectionSelector, (location, section) => {
    var _a;
    if (!pathMatch(Routing.Page.Enrich.SECTION_PATH)(location)) {
        return '';
    }
    const classPathMatch = getClassificationWildcard(location);
    if (!classPathMatch) {
        return (_a = Routing.Page.Enrich.Products.DEFAULT_FILTER[section]) !== null && _a !== void 0 ? _a : Routing.Page.Enrich.Products.DEFAULT_FILTER.default;
    }
    const Filter = Routing.Page.Enrich.Products.Option;
    if (!Object.values(Filter).includes(classPathMatch)) {
        return '';
    }
    return classPathMatch;
});
export const enrichClassificationTypeSelector = createSelector(enrichProductsFilterSelector, RouterSelectors.activeRouteSelector, (productsFilter, location) => {
    if (productsFilter !== Routing.Page.Enrich.Products.Option.UNCLASSIFIED) {
        return '';
    }
    const pattern = Routing.Page.Enrich.CLASSIFICATION_TYPE_PATH;
    const match = pathMatch(pattern)(location);
    if (!match) {
        return Routing.Page.Enrich.ClassificationTypes.DEFAULT_TYPE;
    }
    const value = match[Routing.Page.Enrich.CLASS_TYPE_WILDCARD];
    const options = Routing.Page.Enrich.ClassificationTypes.Option;
    if (!Object.values(options).includes(value)) {
        return '';
    }
    return value;
});
export const enrichPageSettingsSelector = function (pageName) {
    return createSelector(enrichSelector, ({ pages }) => pages[pageName]);
};
export const enrichAllPageSettingsSelector = createSelector(enrichSelector, ({ pages }) => pages);
export const enrichCurrentPageSelector = createSelector(enrichSelector, ({ currentPage }) => currentPage);
export const enrichCurrentPageSettingsSelector = createSelector(enrichCurrentPageSelector, enrichAllPageSettingsSelector, (pageName, allPageSettings) => {
    var _a;
    if (!pageName)
        return {};
    return (_a = allPageSettings[pageName]) !== null && _a !== void 0 ? _a : {};
});
export const enrichCurrentPageTypeSelector = createSelector(enrichCurrentPageSelector, (currentPage) => Routing.Page.Enrich.PAGE_TO_TYPE[currentPage]);
export const enrichCurrentPageTypeProcessingClassSelector = createSelector(enrichCurrentPageTypeSelector, (currentPageType) => pageTypeProcessingClasses[currentPageType]);
export const enrichMergedPageSettingsSelector = createSelector(RouterSelectors.activeQuerySelector, enrichCurrentPageSettingsSelector, enrichCurrentPageTypeProcessingClassSelector, (queryString, pageSettings, pageTypeProcessor) => pageTypeProcessor.getMergedPageSettings({ queryString, pageSettings }));
export const enrichCanonicalQueryParamsSelector = createSelector(RouterSelectors.activeQuerySelector, enrichCurrentPageSettingsSelector, enrichCurrentPageTypeProcessingClassSelector, (queryString, pageSettings, pageTypeProcessor) => {
    const completeSettings = pageTypeProcessor.getMergedPageSettings({ pageSettings });
    return pageTypeProcessor.getUpdatedQueryString(queryString, completeSettings);
});
export const enrichConfidence = createSelector(enrichCurrentPageSettingsSelector, ({ confidence }) => confidence);
export const enrichDateRangeSelector = createSelector(enrichCurrentPageSettingsSelector, ({ dateRange }) => dateRange);
export const enrichCurrentPageSizeSelector = createSelector(enrichCurrentPageSettingsSelector, ({ pagination: { pageSize } }) => pageSize);
export const enrichPageOffsetSelector = createSelector(enrichCurrentPageSettingsSelector, ({ pagination: { offset } }) => offset);
export const enrichQuery = createSelector(enrichCurrentPageSettingsSelector, ({ query }) => query);
export const enrichSubmittersSelector = createSelector(enrichCurrentPageSettingsSelector, ({ submitters }) => submitters);
export const enrichProductsByIdSelector = createSelector(enrichSelector, ({ products: { byId } }) => byId);
export const enrichProductsAllIdsSelector = createSelector(enrichSelector, ({ products: { allIds } }) => allIds);
export const enrichProductDetailsPanelSelector = createSelector(enrichSelector, ({ productDetailsPanel }) => productDetailsPanel);
export const enrichProductFieldsSelector = createSelector(enrichSelector, ({ productFields }) => productFields);
export const enrichSelectedProductFieldsSelector = createSelector(enrichSelector, ({ userSettings: { selectedProductFields } }) => selectedProductFields);
export const enrichSupplementedProductFieldsSelector = createSelector(enrichProductFieldsSelector, (productFields) => Object.keys(productFields)
    .reduce((supplementedFields, field) => Object.assign(supplementedFields, {
    [field]: {
        label: ENRICH_FIELD_DISPLAY_NAMES[field],
        value: productFields[field].id,
        source: productFields[field].source,
    },
}), {}));
export const enrichProductsSelectedSelector = createSelector(enrichSelector, ({ byCategory: { productsSelected } }) => productsSelected);
export const enrichSortDirectionSelector = createSelector(enrichSelector, ({ sortDirection }) => sortDirection);
export const enrichSortFieldSelector = createSelector(enrichSelector, ({ sortField }) => sortField);
export const enrichMachineLearningModelsSelector = createSelector(enrichSelector, ({ machineLearning: { models } }) => models);
export const enrichMachineLearningSelectedModelSelector = createSelector(enrichSelector, ({ machineLearning: { selectedModel } }) => selectedModel);
export const enrichJobManagementSelectedModelsSelector = createSelector(enrichSelector, ({ jobManagement: { selectedModels } }) => selectedModels);
export const enrichJobManagementSelectedModelsIdsSelector = createSelector(enrichSelector, ({ jobManagement: { selectedModels } }) => selectedModels.map((model) => model.value));
export const enrichJobManagementUsersSelector = createSelector(enrichSelector, ({ jobManagement: { users } }) => users);
export const enrichMachineLearningClassPredictionsSelector = createSelector(enrichSelector, ({ machineLearning: { classPredictions } }) => classPredictions);
export const enrichMachineLearningSelectedClassPredictionsSelector = createSelector(enrichSelector, ({ machineLearning: { selectedClassPredictions } }) => selectedClassPredictions);
export const enrichCategoriesSelector = createSelector(enrichSelector, ({ categories: { all } }) => all);
export const enrichSelectedCategorySelector = createSelector(enrichSelector, ({ categories: { selectedId } }) => selectedId);
export const enrichExpandedCategoriesSelector = createSelector(enrichSelector, ({ categories: { expanded } }) => expanded);
export const enrichExpandedTaxonomyNodesSelector = createSelector(enrichSelector, ({ taxonomyNodes: { expanded } }) => expanded);
export const enrichBreadcrumbsSelector = createSelector(RouterSelectors.activeRouteSelector, (location) => {
    const pathParts = [];
    const crumbData = [];
    location.slice(1).split('/').forEach((section) => {
        pathParts.push(section);
        const link = `/${pathParts.join('/')}`;
        if (link !== Routing.ENRICH_PATH) {
            crumbData.push({
                link,
                title: Routing.Page.Enrich.SEGMENT_TO_TEXT[section],
            });
        }
    });
    return crumbData;
});
export const enrichOrganizationsAllIdsSelector = createSelector(enrichSelector, ({ organizations: { allIds } }) => allIds);
export const enrichOrganizationsByIdSelector = createSelector(enrichSelector, ({ organizations: { byId } }) => byId);
export const enrichSelectedOrganizationsSelector = createSelector(enrichSelector, ({ organizations: { selected } }) => selected);
export const enrichTaxonomyNodesAllIdsSelector = createSelector(enrichSelector, ({ taxonomyNodes: { allIds } }) => allIds);
export const enrichTaxonomyNodesByIdSelector = createSelector(enrichSelector, ({ taxonomyNodes: { byId } }) => byId);
export const enrichSelectedTaxonomyNodeSelector = createSelector(enrichSelector, ({ taxonomyNodes: { selectedId } }) => selectedId);
export const enrichClassesSelector = createSelector(enrichSelector, ({ classes }) => classes);
export const enrichResourceLoadingStatusSelector = createSelector(enrichSelector, ({ resourceLoadingStatus }) => resourceLoadingStatus);
export const enrichIsLoadingSelector = createSelector(enrichResourceLoadingStatusSelector, (resourceLoadingStatus) => Object.values(resourceLoadingStatus).some((loading) => loading));
export const enrichVisitedSelector = createSelector(enrichSelector, ({ visited }) => visited);
export const enrichJobsPredictionStateSelector = createSelector(enrichCurrentPageSettingsSelector, ({ progress }) => progress);
export const enrichAiClassificationJobsSelector = createSelector(enrichSelector, ({ machineLearning: { aiClassificationJobs } }) => aiClassificationJobs);
export const enrichRemainingPredictionsByJobSelector = createSelector(enrichSelector, ({ machineLearning: { remainingPredictionsByJob } }) => remainingPredictionsByJob);
export const enrichAiClassificationJobRowsSelector = createSelector(enrichAiClassificationJobsSelector, enrichOrganizationsByIdSelector, enrichRemainingPredictionsByJobSelector, enrichJobManagementUsersSelector, (aiClassificationJobs, orgsById, remainingPredictionsByJob, users) => aiClassificationJobs.map((job) => {
    var _a, _b, _c;
    const filter = (_a = job.config) === null || _a === void 0 ? void 0 : _a.productFilter;
    const orgNames = (Object.keys(orgsById).length && filter) ? getOrgNamesFromJobsFilter(filter, orgsById) : [];
    const completedPredictions = job.id in remainingPredictionsByJob
        ? getValidatedPredictions(job, remainingPredictionsByJob[job.id]) : undefined;
    return Object.assign(Object.assign({}, job), { organizationNames: orgNames, validatedPredictions: completedPredictions, createdBy: (_c = (_b = users.byId[job.createdBy]) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : '' });
}));
export const enrichClassificationRulesSelector = createSelector(enrichSelector, ({ ruleLibrary: { classificationRules } }) => classificationRules);
export const enrichClassificationRuleSelector = createSelector(currentIdSelector, enrichClassificationRulesSelector, (id, rules) => rules.byId[id]);
export const enrichRuleLibrarySelectedOrganizationsSelector = createSelector(enrichSelector, ({ ruleLibrary: { selectedOrganizations } }) => selectedOrganizations);
export const enrichRuleLibraryPreviewProductsSelector = createSelector(enrichSelector, ({ ruleLibrary: { previewProducts } }) => previewProducts);
