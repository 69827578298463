import { __rest } from "tslib";
import React from 'react';
import MaterialButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { compose } from 'ramda';
import { disabling } from '@/components/disabling';
import { withStyles, } from '@/decorators';
export const styles = ({ palette: { grey }, tooltip }) => ({
    tooltip,
    tooltipContainer: {
        display: 'inline-block',
    },
    primary: {},
    secondary: {},
    tertiary: {
        backgroundColor: grey[300],
    },
    flat: {
        boxShadow: 'none',
    },
});
export const enhance = compose(disabling, withStyles(styles));
const Button = enhance(function Button(_a) {
    var { variant, flat, tooltip, tooltipPlacement = 'top-start', className, classes, buttonRootClassName, labelClassName, children } = _a, props = __rest(_a, ["variant", "flat", "tooltip", "tooltipPlacement", "className", "classes", "buttonRootClassName", "labelClassName", "children"]);
    const variantProps = createVariantProps(variant);
    const button = (<MaterialButton variant="contained" className={classNames({
        [classes.primary]: variant === Variant.PRIMARY,
        [classes.secondary]: variant === Variant.SECONDARY,
        [classes.tertiary]: variant === Variant.TERTIARY,
        [classes.flat]: flat,
    }, className)} classes={{ root: buttonRootClassName, label: labelClassName }} {...variantProps} {...props}>
      {children}
    </MaterialButton>);
    return tooltip ? (<div className={classes.tooltipContainer}>
      <Tooltip title={tooltip} placement={tooltipPlacement} classes={{ tooltip: classes.tooltip }}>
        <div>{button}</div>
      </Tooltip>
    </div>) : (button);
});
export default Button;
export function createVariantProps(variant) {
    switch (variant) {
        case Variant.PRIMARY:
            return { color: 'primary' };
        case Variant.SECONDARY:
            return { color: 'secondary' };
        default:
            return {};
    }
}
export var Variant;
(function (Variant) {
    Variant.PRIMARY = 'primary';
    Variant.SECONDARY = 'secondary';
    Variant.TERTIARY = 'tertiary';
})(Variant || (Variant = {}));
