import React from 'react';
import { Route, Switch } from 'react-router';
import Sections from '@/glancer-analytics';
import GlancerAnalyticsDrawer from '@/components/drawer/glancer-analytics';
import DrawerContainer from '@/components/drawer/container';
import { extractRoutes, GLANCER_ANALYTICS_ROUTES } from '@/routes/drawer';
import AreaContext from '@/store/area-context';
import GenericDashboardRouter from './generic-dashboard';
function GlancerAnalyticsRouter({ match, area }) {
    return (<DrawerContainer component={GlancerAnalyticsDrawer}>
      <AreaContext.Provider value={area}>
        <Switch>
          {extractRoutes(GLANCER_ANALYTICS_ROUTES.sections).map((route) => (<Route path={`${match.path}/${route.path}`} component={Sections[route.path]} key={route.path}/>))}
          <Route component={GenericDashboardRouter}/>
        </Switch>
      </AreaContext.Provider>
    </DrawerContainer>);
}
export default GlancerAnalyticsRouter;
