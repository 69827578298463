export var Type;
(function (Type) {
    Type["SMALL"] = "small";
    Type["MEDIUM"] = "medium";
    Type["LARGE"] = "large";
})(Type || (Type = {}));
export var Align;
(function (Align) {
    Align["LEFT"] = "left";
    Align["CENTER"] = "center";
    Align["RIGHT"] = "right";
})(Align || (Align = {}));
export var Direction;
(function (Direction) {
    Direction["ASC"] = "asc";
    Direction["DESC"] = "desc";
})(Direction || (Direction = {}));
