import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BrokenImage from '@material-ui/icons/BrokenImage';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, breakpoints, palette }) => ({
    container: {
        height: spacing(22.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        border: '3px solid transparent',
        overflow: 'hidden',
        [breakpoints.down('lg')]: {
            height: spacing(18),
        },
        '&$clickable': {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '&$bordered': {
            border: `3px solid ${palette.primary.main}`,
        },
    },
    image: {
        overflow: 'hidden',
        '&>*': {
            width: '100%',
        },
    },
    imageLink: {
        textDecoration: 'none',
    },
    brokenImageIcon: {
        fontSize: spacing(11),
    },
    icon: {
        position: 'absolute',
        top: spacing(1.5),
        right: spacing(1.5),
    },
    resultManagementIcons: {
        display: 'flex',
        '&>*': {
            marginLeft: spacing(1),
        },
    },
    text: {
        textAlign: 'center',
        textDecoration: 'none',
    },
    bordered: {},
    clickable: {},
});
const ProductImage = withStyles(styles)(function ProductImage({ classes, accentColor, alt, bordered, elevation, icon, imageUrl, notInCollection, showIcons, tabIndex, url, onClick, onKeyDown, }) {
    const image = (<Paper className={classNames(classes.container, { [classes.clickable]: onClick }, { [classes.bordered]: bordered })} style={{ border: !showIcons && `3px solid ${accentColor}` }} elevation={elevation} tabIndex={tabIndex} onClick={onClick} onKeyDown={onKeyDown}>
      <Grid item container direction="column" justify="center">
        <Grid item container alignItems="center" direction="column" className={classes.image}>
          {imageUrl ? (<img src={imageUrl} alt={alt}/>) : (<React.Fragment>
              <BrokenImage color="secondary" fontSize="inherit" classes={{ fontSizeInherit: classes.brokenImageIcon }}/>
              <Typography variant="caption" classes={{ caption: classes.text }}>
                {notInCollection ? 'Product is not available within selected collection' : 'No Image Available'}
              </Typography>
            </React.Fragment>)}
        </Grid>
        {showIcons ? (<div className={classNames(classes.icon, classes.resultManagementIcons)}>
            {showIcons.map((icon, index) => <div key={index}>{icon}</div>)}
          </div>) : (<div className={classes.icon}>{icon}</div>)}
      </Grid>
    </Paper>);
    return url ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url} target="_blank" className={classes.imageLink}>
      {image}
    </a>) : (image);
});
export default ProductImage;
