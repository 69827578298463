import { __decorate } from "tslib";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { defaultInputRanges, defaultStaticRanges, DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '@/components/controls/button';
import { MIN_DATE } from '@/constants';
import { withStyles } from '@/decorators';
import { convertToDate, formatTo12H, formatTo24H } from '@/utils';
export const LAST_MONTH = 'Last Month';
export const LAST_WEEK = 'Last Week';
export const DAYS_TO_TODAY = 'days up to today';
export const RANGE_KEY = 'selectedRange';
export const RANGE_SELECTION_COLOR = 'rgba(92, 176, 4, 0.7)';
export const STATIC_RANGES = defaultStaticRanges.filter(({ label }) => label === LAST_WEEK || label === LAST_MONTH);
export const INPUT_RANGES = defaultInputRanges.filter(({ label }) => label === DAYS_TO_TODAY);
export const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
    },
    error: {
        display: 'flex',
        textAlign: 'center',
        margin: `${spacing(1 / 2)}px ${spacing(1.25)}px 0 0`,
        maxWidth: spacing(19.5),
    },
    buttonContainer: {
        display: 'flex',
        padding: `0 ${spacing(1.5)}px ${spacing(1.5)}px 0`,
        '&>:last-child': {
            marginLeft: spacing(1.5),
        },
    },
});
let DateRangePicker = class DateRangePicker extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            startDate: convertToDate(this.props.range.startDate),
            endDate: convertToDate(this.props.range.endDate),
            startTime: formatTo12H(this.props.range.startTime),
            endTime: formatTo12H(this.props.range.endTime),
        };
        this.onChange = ({ [RANGE_KEY]: { startDate, endDate }, }) => {
            this.setState({
                startDate: convertToDate(startDate),
                endDate: convertToDate(endDate),
            });
        };
        this.onConfirm = () => {
            const { onChange, onClose } = this.props;
            const { startDate, endDate, startTime, endTime, } = this.state;
            const combinedStartDateTime = `${formatDatePickerV2(startDate)}${formatTo24H(startTime)}`;
            const combinedEndDateTime = `${formatDatePickerV2(endDate)}${formatTo24H(endTime)}`;
            onChange(combinedStartDateTime, combinedEndDateTime);
            onClose();
        };
    }
    render() {
        const { classes, onClose, maxDate, minDate, maxSelectRange, } = this.props;
        const { startDate, endDate } = this.state;
        const hasError = getDateDiff(startDate, endDate) > maxSelectRange;
        return (<div className={classes.root}>
        <ReactDateRangePicker ranges={[
            {
                startDate,
                endDate,
                key: RANGE_KEY,
                color: RANGE_SELECTION_COLOR,
            },
        ]} onChange={this.onChange} maxDate={maxDate ? maxDate() : new Date()} minDate={minDate ? minDate() : MIN_DATE} staticRanges={STATIC_RANGES} inputRanges={INPUT_RANGES}/>
        <Grid container justify="flex-end">
          {hasError && (<Typography variant="caption" color="error" className={classes.error}>
              Please select a date range less than {this.props.maxSelectRange + 1} days
            </Typography>)}
          <div className={classes.buttonContainer}>
            <Button variant="primary" onClick={this.onConfirm} disabled={hasError}>
              ok
            </Button>
            <Button variant="tertiary" onClick={onClose}>
              cancel
            </Button>
          </div>
        </Grid>
      </div>);
    }
};
DateRangePicker = __decorate([
    (withStyles(styles))
], DateRangePicker);
export default DateRangePicker;
export function formatDatePickerV2(datetime) {
    return moment(datetime)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('YYYYMMDD');
}
export function getDateDiff(start, end) {
    return moment(end).diff(moment(start), 'days');
}
