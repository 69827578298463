import { field, where, uuid, } from '@groupby/enrich-criteria';
import * as ConnectedReactRouter from 'connected-react-router';
import { all, call, fork, put, select, takeEvery, takeLatest, } from 'redux-saga/effects';
import { LIMIT_FOR_PERIOD_IN_DAY, Routing } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Selectors from '@/store/selectors';
import { getDuration, generateEnrichCacheKey } from '@/utils';
import State from '../state';
import * as Tasks from './tasks';
import { updateQueryParams } from '@/enrich/routing/utils/index';
var PERIOD = State.Enrich.Period;
// replace with max limit from API once API update has been made
export const maxApiLimit = 500;
// for developer testing
const CACHE_ENABLED = true;
export function* loadEnrichData() {
    try {
        const enrichClient = yield call(Tasks.enrichClient);
        const { start, end } = yield select(Selectors.enrichFiltersSelector);
        const customerId = yield select(Selectors.enrichCustomerIdSelector);
        const duration = getDuration(start || '20190101', end || '20190131');
        const { result: customerInfo } = yield call(enrichClient.customersContract, { customerId });
        const { result: { data }, } = yield call(enrichClient.clientProductsReports, {
            customerId,
            startDate: start || '20190101',
            endDate: end || '20190131',
            period: duration > LIMIT_FOR_PERIOD_IN_DAY ? PERIOD.Month : PERIOD.Day,
        });
        const snapshotResult = data[data.length - 1];
        yield put(ActionCreators.refreshEnrichData(data));
        yield put(ActionCreators.refreshEnrichSnapshots(Object.assign(Object.assign({}, snapshotResult), customerInfo)));
    }
    catch (e) {
        yield Tasks.simpleError('load current records', e);
    }
}
export function* authenticateEnrichCustomer() {
    try {
        const authClient = yield call(Tasks.authClient);
        const { result: tokens } = yield call(authClient.enrichTokens);
        yield put(ActionCreators.refreshEnrichTokens(tokens));
        yield put(ActionCreators.refreshEnrichCustomerId(tokens[0].enrichId));
    }
    catch (e) {
        yield Tasks.simpleError('authenticate user', e);
    }
}
export function* loadEnrich() {
    const loadingTask = yield fork(Tasks.startLoading);
    yield call(authenticateEnrichCustomer);
    yield call(loadEnrichData);
    yield Tasks.stopLoading(loadingTask);
}
export function* fetchEnrich(resource, method, ...args) {
    const { url: host, token } = yield call(Tasks.ccEnrichClient);
    const client = yield call(Tasks.createEnrichHttpClient, { host, token });
    const fetchedResource = yield call([client[resource], method], ...args);
    return fetchedResource;
}
export function* fetchCachedEnrich(resource, method, ...args) {
    const key = generateEnrichCacheKey(resource, method, ...args);
    const cachedResponse = yield select(Selectors.cacheEntry(key));
    let response;
    if (!CACHE_ENABLED || typeof cachedResponse === 'undefined') {
        response = yield call(fetchEnrich, resource, method, ...args);
        yield put(ActionCreators.addCacheEntry(key, response));
    }
    else {
        response = cachedResponse;
    }
    return response;
}
export function* getEnrichProductRequestFilter() {
    const enrichSection = yield select(Selectors.enrichSectionSelector);
    const productsFilter = yield select(Selectors.enrichProductsFilterSelector);
    const filterCriteria = {
        organizations: yield select(Selectors.enrichSelectedOrganizationsSelector),
        query: yield select(Selectors.enrichQuery),
        classified: yield call(Tasks.getClassifiedFromEnrichProductsFilter, productsFilter),
        selectedCategory: null,
        selectedClass: null,
    };
    switch (enrichSection) {
        case Routing.Page.Enrich.BY_CATEGORY:
            filterCriteria.selectedCategory = yield select(Selectors.enrichSelectedCategorySelector);
            break;
        case Routing.Page.Enrich.BY_TAXONOMY:
            filterCriteria.selectedClass = yield call(Tasks.getSelectedClass);
            break;
    }
    const filter = yield call(Tasks.createEnrichCriteria, filterCriteria);
    return filter;
}
export function* loadEnrichProducts() {
    try {
        const organizations = yield select(Selectors.enrichSelectedOrganizationsSelector);
        if (!organizations.length) {
            yield put(ActionCreators.updateEnrichProducts({ byId: {}, allIds: [] }));
        }
        else {
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ products: true }));
            const offset = yield select(Selectors.enrichPageOffsetSelector);
            const pageSize = yield select(Selectors.enrichCurrentPageSizeSelector);
            const productFilter = yield call(getEnrichProductRequestFilter);
            const { elements: products, total } = yield call(fetchCachedEnrich, 'products', 'getPage', {
                page: {
                    limit: pageSize,
                    offset,
                },
                filter: productFilter,
            });
            const normalizedProducts = yield call(Tasks.normalizeEnrichResources, products);
            yield put(ActionCreators.updateCurrentTotalEnrichItems(total));
            yield put(ActionCreators.updateEnrichProducts(normalizedProducts));
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ products: false }));
        }
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ products: false }));
        yield Tasks.simpleError('Load Enrich Products', e);
    }
}
export function* loadEnrichProductDetailsPanel() {
    try {
        const currentPage = yield select(Selectors.enrichCurrentPageSettingsSelector);
        if (currentPage.productId) {
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productDetailsPanel: true }));
            const product = yield call(fetchCachedEnrich, 'products', 'getOne', currentPage.productId);
            yield put(ActionCreators.updateEnrichProductDetailsPanel(product));
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productDetailsPanel: false }));
        }
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productDetailsPanel: false }));
        yield Tasks.simpleError('Load Enrich Details Panel', e);
    }
}
export function* loadEnrichProductQuantities() {
    try {
        const total = yield call(fetchCachedEnrich, 'products', 'getTotal');
        const classified = yield call(fetchCachedEnrich, 'products', 'getTotal', where([field('classId'), '!=', null]));
        const unclassified = total - classified;
        yield put(ActionCreators.updateEnrichProductCounts({ total, classified, unclassified }));
    }
    catch (e) {
        yield Tasks.simpleError('Load Enrich Product Quantities', e);
    }
}
export function* loadEnrichRuleLibraryPreviewProducts({ payload: { organizations, filter, pageSize }, }) {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ previewProducts: true }));
        const [organizationsFilter, productFilter] = yield all([
            call(Tasks.createEnrichCriteria, { organizations }),
            call(Tasks.parseEnrichCriteriaString, filter),
        ]);
        const { elements: products } = yield call(fetchCachedEnrich, 'products', 'getPage', {
            page: {
                limit: pageSize,
                offset: 0,
            },
            filter: organizationsFilter.and(productFilter),
        });
        const normalizedProducts = yield call(Tasks.normalizeEnrichResources, products);
        yield put(ActionCreators.updateEnrichRuleLibraryPreviewProducts(normalizedProducts));
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ previewProducts: false }));
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ previewProducts: false }));
        yield Tasks.simpleError('Load Enrich Preview Products', e);
    }
}
export function* generateMultipleParallelQueries(resource, offsets, limit, filter) {
    try {
        const allData = yield all(offsets.map((offset) => call(fetchCachedEnrich, resource, 'getMany', { page: { limit, offset }, filter })));
        return [].concat(...allData);
    }
    catch (e) {
        yield Tasks.simpleError('Generate Multiple Parallel Queries', e);
    }
}
export function* loadEnrichCategories() {
    try {
        const organizations = yield select(Selectors.enrichSelectedOrganizationsSelector);
        if (!organizations.length) {
            yield put(ActionCreators.updateEnrichCategories([]));
            yield put(ActionCreators.clearEnrichCategorySelections());
        }
        else {
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ categories: true }));
            // categories to eventually be filtered by query
            const query = '';
            const filter = yield call(Tasks.createEnrichCriteria, { query, organizations });
            const total = yield call(fetchCachedEnrich, 'categories', 'getTotal', filter);
            const generatedOffsets = yield call(Tasks.generateMultipleQueryOffsets, total, maxApiLimit);
            const allCategories = yield call(generateMultipleParallelQueries, 'categories', generatedOffsets, maxApiLimit, filter);
            yield put(ActionCreators.updateEnrichCategories(allCategories));
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ categories: false }));
            yield put(ActionCreators.clearEnrichCategorySelections());
            const enrichSection = yield select(Selectors.enrichSectionSelector);
            if (enrichSection === Routing.Page.Enrich.BY_CATEGORY) {
                yield put(ActionCreators.loadEnrichProducts());
            }
        }
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ categories: false }));
        yield Tasks.simpleError('Load Enrich Categories', e);
    }
}
export function* loadAllEnrichOrganizations() {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ organizations: true }));
        const total = yield call(fetchCachedEnrich, 'organizations', 'getTotal');
        const generatedOffsets = yield call(Tasks.generateMultipleQueryOffsets, total, maxApiLimit);
        const allOrganizations = yield call(generateMultipleParallelQueries, 'organizations', generatedOffsets, maxApiLimit);
        const normalizedOrganizations = yield call(Tasks.normalizeEnrichResources, allOrganizations);
        yield put(ActionCreators.updateEnrichOrganizations(normalizedOrganizations));
        // selected organization(s) status on load of a new set of organizations to be determined
        yield put(ActionCreators.updateSelectedEnrichOrganizations([]));
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ organizations: false }));
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ organizations: false }));
        yield Tasks.simpleError('Load Enrich Organizations', e);
    }
}
export function* loadEnrichMLModels() {
    try {
        const models = yield call(fetchCachedEnrich, 'models', 'getMany');
        const transformedModels = yield call(Tasks.transformEnrichMLModels, models);
        yield put(ActionCreators.updateEnrichMLModels(transformedModels));
        yield put(ActionCreators.updateEnrichMLModelSelection(null));
    }
    catch (e) {
        yield Tasks.simpleError('Load Enrich Machine Learning Models', e);
    }
}
export function* loadEnrichMLClassPredictions() {
    try {
        // to be updated with class prediction organizations
        const [limit, offset, query, confidence] = yield all([
            select(Selectors.enrichCurrentPageSizeSelector),
            select(Selectors.enrichPageOffsetSelector),
            select(Selectors.enrichQuery),
            select(Selectors.enrichConfidence),
        ]);
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: true }));
        const parsedQuery = yield call(Tasks.parseEnrichCriteriaString, query);
        const confidenceFilter = where(field('options.0.confidence'), '>=', confidence / 100).and(parsedQuery);
        const { elements: classPredictions, total } = yield call(fetchCachedEnrich, 'classPredictions', 'getPage', { filter: confidenceFilter, page: { limit, offset } });
        if (!classPredictions.length) {
            yield call(Tasks.throwConfidenceThresholdError, confidence);
        }
        const productIds = classPredictions.map((p) => uuid(p.productId));
        const productFilter = yield where(field('id'), '@<', productIds);
        const products = yield call(fetchCachedEnrich, 'products', 'getMany', { filter: productFilter });
        const normalizedProducts = yield call(Tasks.normalizeEnrichResources, products);
        const classes = yield select(Selectors.enrichClassesSelector);
        const transformedClassPredictions = yield call(Tasks.transformEnrichClassPredictions, classPredictions, classes);
        yield all([
            put(ActionCreators.updateCurrentTotalEnrichItems(total)),
            put(ActionCreators.updateEnrichMLClassPredictions(transformedClassPredictions)),
            put(ActionCreators.updateEnrichProducts(normalizedProducts)),
            put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: false })),
        ]);
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: false }));
        yield Tasks.detailedError('Load Enrich Machine Learning Class Predictions', e.message);
    }
}
export function* createClassificationJob() {
    try {
        const selectedOrganizations = yield select(Selectors.enrichSelectedOrganizationsSelector);
        if (selectedOrganizations.length) {
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ jobCreation: true }));
            const selectedModel = yield select(Selectors.enrichMachineLearningSelectedModelSelector);
            const productFilter = yield call(getEnrichProductRequestFilter);
            yield call(fetchEnrich, 'jobs', 'createOne', {
                kind: 'AiClassification',
                config: {
                    modelId: selectedModel.value,
                    productFilter,
                },
            });
            yield put(ActionCreators.updateEnrichResourceLoadingStatus({ jobCreation: false }));
            yield Tasks.success(`Classification job created for ${selectedModel.label}`);
        }
        else {
            yield Tasks.error('Select an organization to create a classification job');
        }
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ jobCreation: false }));
        yield Tasks.simpleError('Create Classification Job', e);
    }
}
export function* loadEnrichTaxonomyNodes() {
    try {
        const organizations = yield select(Selectors.enrichSelectedOrganizationsSelector);
        if (!organizations.length) {
            yield put(ActionCreators.updateEnrichTaxonomyNodes({
                allIds: [],
                byId: {},
            }));
            yield put(ActionCreators.clearEnrichTaxonomyNodeSelections());
        }
        else {
            const total = yield call(fetchEnrich, 'taxonomyNodes', 'getTotal');
            const generatedOffsets = yield call(Tasks.generateMultipleQueryOffsets, total, maxApiLimit);
            const allTaxonomyNodes = yield call(generateMultipleParallelQueries, 'taxonomyNodes', generatedOffsets, maxApiLimit);
            const normalizedTaxonomyNodes = yield call(Tasks.normalizeEnrichResources, allTaxonomyNodes);
            yield put(ActionCreators.updateEnrichTaxonomyNodes(normalizedTaxonomyNodes));
            yield put(ActionCreators.clearEnrichTaxonomyNodeSelections());
            const enrichSection = yield select(Selectors.enrichSectionSelector);
            if (enrichSection === Routing.Page.Enrich.BY_TAXONOMY) {
                yield put(ActionCreators.loadEnrichProducts());
            }
        }
    }
    catch (e) {
        yield Tasks.simpleError('Load Enrich Taxonomies', e);
    }
}
export function* loadEnrichClasses() {
    try {
        const total = yield call(fetchEnrich, 'classes', 'getTotal');
        const generatedOffsets = yield call(Tasks.generateMultipleQueryOffsets, total, maxApiLimit);
        const allClasses = yield call(generateMultipleParallelQueries, 'classes', generatedOffsets, maxApiLimit);
        const normalizedClasses = yield call(Tasks.normalizeEnrichResources, allClasses);
        yield put(ActionCreators.updateEnrichClasses(normalizedClasses));
    }
    catch (e) {
        yield Tasks.simpleError('Load Enrich Classes', e);
    }
}
export function* updateEnrichProductClassId({ payload: { classId, productId } }) {
    let oldClassId;
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productClassIdUpdate: true }));
        const productsById = yield select(Selectors.enrichProductsByIdSelector);
        const productDetailsPanel = yield select(Selectors.enrichProductDetailsPanelSelector);
        if ((productDetailsPanel === null || productDetailsPanel === void 0 ? void 0 : productDetailsPanel.id) === productId) {
            oldClassId = productDetailsPanel.classId;
        }
        else {
            oldClassId = productsById[productId].classId;
        }
        yield put(ActionCreators.receiveEnrichProductClassId(productId, classId));
        yield call(fetchEnrich, 'products', 'updateOne', { id: productId, classId });
        // prevent classId from being reverted since the update call succeeded
        oldClassId = undefined;
        yield put(ActionCreators.clearCache());
        if ((productDetailsPanel === null || productDetailsPanel === void 0 ? void 0 : productDetailsPanel.id) === productId) {
            yield put(ActionCreators.closeEnrichProductDetailsPanel());
        }
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productClassIdUpdate: false }));
        yield put(ActionCreators.loadEnrichProducts());
        yield put(ActionCreators.loadEnrichTotals());
        yield Tasks.success('Updated product class');
    }
    catch (e) {
        if (oldClassId !== undefined) {
            yield put(ActionCreators.receiveEnrichProductClassId(productId, oldClassId));
        }
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ productClassIdUpdate: false }));
        yield Tasks.simpleError('Update Enrich Product Class', e);
    }
}
export function* loadAiClassificationJobs() {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ aiClassificationJobs: true }));
        const [sortDirection, sortField, limit, offset] = yield all([
            select(Selectors.enrichSortDirectionSelector),
            select(Selectors.enrichSortFieldSelector),
            select(Selectors.enrichCurrentPageSizeSelector),
            select(Selectors.enrichPageOffsetSelector),
        ]);
        const filter = yield call(Tasks.generateEnrichJobRequestFilter, 'AiClassification');
        const sort = [{ propertyName: sortField, direction: sortDirection }];
        const { elements: classificationJobs, total } = yield call(fetchCachedEnrich, 'jobs', 'getPage', { filter, page: { limit, offset }, sort });
        yield all([
            put(ActionCreators.updateCurrentTotalEnrichItems(total)),
            put(ActionCreators.updateEnrichAiClassificationJobs(classificationJobs)),
            call(loadNumberOfRemainingPredictionsForJobs, classificationJobs),
        ]);
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ aiClassificationJobs: false }));
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ aiClassificationJobs: false }));
        yield Tasks.simpleError('Load Ai Classification Jobs');
    }
}
export function* loadNumberOfRemainingPredictionsForJobs(classificationJobs) {
    const jobPredictionValidationNumbers = yield all(classificationJobs.map((job) => call(fetchJobPredictionValidationNumbers, job)));
    const remainingPredictionsByJobId = jobPredictionValidationNumbers
        .reduce((remainingPredictionsByJob, jobPredictionValues) => Object.assign(remainingPredictionsByJob, { [jobPredictionValues.jobId]: jobPredictionValues.remainingPredictions }), {});
    yield put(ActionCreators.updateEnrichRemainingPredictionsByJob(remainingPredictionsByJobId));
}
export function* fetchJobPredictionValidationNumbers(job) {
    var _a;
    try {
        const totalPredictionsInJob = (_a = job.result) === null || _a === void 0 ? void 0 : _a.predictionCount;
        if (!totalPredictionsInJob)
            return { remainingPredictions: 0, jobId: job.id };
        const predictionFilter = where([field('jobId'), '==', uuid(job.id)]);
        const { total } = yield call(fetchCachedEnrich, 'classPredictions', 'getPage', { filter: predictionFilter, page: { limit: 0 } });
        return { remainingPredictions: total, jobId: job.id };
    }
    catch (e) {
        yield Tasks.simpleError('Fetch Job Prediction Validation Numbers', e);
    }
}
export function* loadJobManagementUsers() {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ users: true }));
        const total = yield call(fetchCachedEnrich, 'users', 'getTotal');
        const generatedOffsets = yield call(Tasks.generateMultipleQueryOffsets, total, maxApiLimit);
        const users = yield call(generateMultipleParallelQueries, 'users', generatedOffsets, maxApiLimit);
        const normalizedUsers = yield call(Tasks.normalizeEnrichResources, users);
        yield put(ActionCreators.updateEnrichJobManagementUsers(normalizedUsers));
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ users: false }));
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ users: false }));
        yield Tasks.simpleError('Load Job Management Users');
    }
}
export function* loadEnrichPage() {
    try {
        yield call(Tasks.resetEnrichLoadingStatus);
        const currentPage = yield select(Selectors.enrichCurrentPageSelector);
        if (currentPage === Routing.Page.Enrich.Pages.ByCategory.VALIDATION) {
            yield call(loadEnrichMLClassPredictions);
        }
        else if (currentPage === Routing.Page.Enrich.Pages.ByCategory.JOB_MANAGEMENT) {
            yield all([
                call(loadAiClassificationJobs),
                call(loadJobManagementUsers),
            ]);
        }
        else if (currentPage === Routing.Page.Enrich.Pages.RuleLibrary.MAIN) {
            yield call(loadEnrichClassificationRules);
        }
        else {
            yield all([
                call(loadEnrichProducts),
                call(loadEnrichProductDetailsPanel),
            ]);
        }
    }
    catch (e) {
        yield Tasks.simpleError('Load Enrich Page', e);
    }
}
export function* loadInitialEnrichData() {
    try {
        yield all([
            call(loadEnrichClasses),
            call(loadEnrichMLModels),
            call(loadAllEnrichOrganizations),
        ]);
    }
    catch (e) {
        yield Tasks.simpleError('Load Initial Enrich Data');
    }
}
export function* saveEnrichMLClassPredictions() {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: true }));
        const selectedClassPredictions = yield select(Selectors.enrichMachineLearningSelectedClassPredictionsSelector);
        yield all(Object.values(selectedClassPredictions).map((prediction) => call(validateSingleMLClassPrediction, prediction)));
        yield put(ActionCreators.clearEnrichMLSelectedClassPredictions());
        yield call(refreshEnrichMLClassPredictionUIState);
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: false }));
        yield Tasks.success('Saved class predictions');
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classPredictions: false }));
        yield Tasks.simpleError('Save Enrich ML Predictions');
    }
}
/**
 * This Saga should not be called in isolation.
 * This Saga validates predictions; however, it does not perform any updates to refresh data for the UI.
 */
export function* validateSingleMLClassPrediction(prediction) {
    try {
        yield call(fetchEnrich, 'products', 'updateOne', { id: prediction.productId, classId: prediction.classId });
    }
    catch (e) {
        yield Tasks.detailedError('Validate ML Class Prediction - update product', e.message);
        return;
    }
    try {
        yield call(fetchEnrich, 'classPredictions', 'deleteOne', prediction.id);
    }
    catch (e) {
        yield Tasks.detailedError('Validate ML Class Prediction - delete prediction', e.message);
        yield call(fetchEnrich, 'products', 'updateOne', { id: prediction.productId, classId: null });
    }
}
export function* refreshEnrichMLClassPredictionUIState() {
    try {
        yield put(ActionCreators.clearCache());
        yield all([
            call(loadEnrichMLClassPredictions),
            call(loadEnrichProductQuantities),
        ]);
    }
    catch (e) {
        yield Tasks.simpleError('Refresh ML Class Prediction State');
    }
}
export function* deleteEnrichMLClassPrediction({ payload: id }) {
    try {
        yield call(fetchEnrich, 'classPredictions', 'deleteOne', id);
        yield put(ActionCreators.removeEnrichMLSelectedClassPrediction(id));
        yield call(refreshEnrichMLClassPredictionUIState);
        yield Tasks.success('Deleted class prediction');
    }
    catch (e) {
        yield Tasks.simpleError('Delete Enrich ML Prediction');
    }
}
export function* createEnrichClassificationRule({ payload: creationData }) {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: true }));
        yield call(fetchEnrich, 'classificationRules', 'createOne', creationData);
        yield put(ActionCreators.clearCache());
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield put(ActionCreators.goToEnrich(Routing.Page.Enrich.RULE_LIBRARY));
        yield Tasks.success('Saved Classification Rule');
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield (Tasks.detailedError('Save Classification Rule', e.message));
    }
}
export function* updateEnrichClassificationRule({ payload: updateData }) {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: true }));
        yield call(fetchEnrich, 'classificationRules', 'updateOne', updateData);
        yield put(ActionCreators.clearCache());
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield put(ActionCreators.goToEnrich(Routing.Page.Enrich.RULE_LIBRARY));
        yield Tasks.success('Updated Classification Rule');
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield (Tasks.detailedError('Update Classification Rule', e.message));
    }
}
export function* deleteEnrichClassificationRule({ payload: id }) {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: true }));
        yield call(fetchEnrich, 'classificationRules', 'deleteOne', id);
        yield put(ActionCreators.clearCache());
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield call(loadEnrichClassificationRules);
        yield Tasks.success('Deleted Classification Rule');
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield (Tasks.detailedError('Delete Classification Rule', e.message));
    }
}
export function* loadEnrichClassificationRules() {
    try {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: true }));
        const [offset, pageSize] = yield all([
            select(Selectors.enrichPageOffsetSelector),
            select(Selectors.enrichCurrentPageSizeSelector),
        ]);
        const filter = yield call(Tasks.generateRuleFilter);
        const { elements: rules, total } = yield call(fetchCachedEnrich, 'classificationRules', 'getPage', Object.assign({ page: { limit: pageSize, offset } }, (filter && { filter })));
        const normalizedRules = yield call(Tasks.normalizeEnrichResources, rules);
        yield all([
            put(ActionCreators.updateCurrentTotalEnrichItems(total)),
            put(ActionCreators.updateEnrichClassificationRules(normalizedRules)),
        ]);
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
    }
    catch (e) {
        yield put(ActionCreators.updateEnrichResourceLoadingStatus({ classificationRules: false }));
        yield Tasks.simpleError('Load Enrich Classification Rules', e);
    }
}
export function* prepareEnrichClassificationRule({ payload: classificationRuleId }) {
    try {
        const [, rule] = yield all([
            call(loadInitialEnrichData),
            call(fetchCachedEnrich, 'classificationRules', 'getOne', classificationRuleId),
        ]);
        const normalizedRules = yield call(Tasks.normalizeEnrichResources, [rule]);
        yield put(ActionCreators.updateEnrichClassificationRules(normalizedRules));
    }
    catch (e) {
        yield Tasks.error('Failed to load Enrich Classification Rule.', e);
        const path = Routing.Page.Enrich.RULE_LIBRARY_PATH;
        yield put(ConnectedReactRouter.push(path, { path }));
    }
}
export function* visitEnrich() {
    try {
        yield call(Tasks.setPageName);
        const visited = yield select(Selectors.enrichVisitedSelector);
        if (visited)
            return;
        yield call(setup);
        yield put(ActionCreators.updateEnrichVisited());
    }
    catch (e) {
        yield Tasks.simpleError('Visit Enrich', e);
    }
}
export function* setup() {
    try {
        yield all([
            call(loadInitialEnrichData),
            call(Tasks.setPageSettings),
        ]);
    }
    catch (e) {
        yield Tasks.simpleError('Set Up Enrich', e);
    }
}
export function* setCanonicalQueryParams() {
    try {
        const path = yield select(Selectors.activePathSelector);
        if (!Routing.Page.Enrich.PAGE_TO_TYPE[Routing.Page.Enrich.PATH_TO_PAGE[path]])
            return;
        const state = yield select(Selectors.locationStateSelector);
        const updatedQueryParams = yield select(Selectors.enrichCanonicalQueryParamsSelector);
        yield call(updateQueryParams, path, updatedQueryParams, state);
    }
    catch (e) {
        yield Tasks.simpleError('Set Query Params', e);
    }
}
export default function* enrichSaga() {
    yield takeLatest(Actions.LOAD_ENRICH, loadEnrich);
    yield takeLatest(Actions.LOAD_ENRICH_DATA, loadEnrichData);
    yield takeLatest(Actions.LOAD_ENRICH_PRODUCT_COUNTS, loadEnrichProductQuantities);
    yield takeLatest([
        Actions.LOAD_ENRICH_CATEGORIES,
        Actions.UPDATE_SELECTED_ENRICH_ORGANIZATIONS,
    ], loadEnrichCategories);
    yield takeLatest([
        Actions.LOAD_ENRICH_TAXONOMY_NODES,
        Actions.UPDATE_SELECTED_ENRICH_ORGANIZATIONS,
    ], loadEnrichTaxonomyNodes);
    yield takeLatest(Actions.LOAD_ALL_ENRICH_ORGANIZATIONS, loadAllEnrichOrganizations);
    yield takeLatest([
        Actions.UPDATE_ENRICH_SELECTED_CATEGORY,
        Actions.UPDATE_ENRICH_SELECTED_TAXONOMY_NODE_ID,
        Actions.LOAD_ENRICH_PAGE_DATA,
        Actions.LOAD_ENRICH_PRODUCTS,
        Actions.UPDATE_ENRICH_CURRENT_PAGE_SIZE,
        Actions.UPDATE_ENRICH_PAGE_OFFSET,
        Actions.UPDATE_ENRICH_DATE_RANGE,
        Actions.UPDATE_ENRICH_JOB_MODELS_SELECTION,
        Actions.UPDATE_ENRICH_JOB_SUBMITTER_SELECTION,
        Actions.UPDATE_ENRICH_JOBS_PREDICTION_STATE,
        Actions.LOAD_ENRICH_CLASSIFICATION_RULES,
        Actions.UPDATE_ENRICH_RULE_LIBRARY_SELECTED_ORGANIZATIONS,
    ], loadEnrichPage);
    yield takeLatest(Actions.LOAD_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS, loadEnrichRuleLibraryPreviewProducts);
    yield takeLatest([
        Actions.LOAD_ENRICH_AI_CLASSIFICATION_JOBS,
        Actions.TOGGLE_ENRICH_SORT,
    ], loadAiClassificationJobs);
    yield takeLatest(Actions.LOAD_ENRICH_ML_MODELS, loadEnrichMLModels);
    yield takeEvery(Actions.CREATE_ENRICH_CLASSIFICATION_JOB, createClassificationJob);
    yield takeLatest(Actions.LOAD_ENRICH_CLASSES, loadEnrichClasses);
    yield takeLatest(Actions.LOAD_ENRICH_ML_CLASS_PREDICTIONS, loadEnrichMLClassPredictions);
    yield takeLatest(Actions.NOTIFY_ENRICH_PAGE_VISITED, visitEnrich);
    yield takeLatest(Actions.UPDATE_ENRICH_PRODUCT_CLASS_ID, updateEnrichProductClassId);
    yield takeLatest(Actions.SAVE_ENRICH_ML_CLASS_PREDICTIONS, saveEnrichMLClassPredictions);
    yield takeEvery(Actions.DELETE_ENRICH_ML_CLASS_PREDICTION, deleteEnrichMLClassPrediction);
    yield takeLatest(Actions.CREATE_ENRICH_CLASSIFICATION_RULE, createEnrichClassificationRule);
    yield takeLatest(Actions.UPDATE_ENRICH_CLASSIFICATION_RULE, updateEnrichClassificationRule);
    yield takeLatest(Actions.DELETE_ENRICH_CLASSIFICATION_RULE, deleteEnrichClassificationRule);
    yield takeLatest(Actions.PREPARE_ENRICH_CLASSIFICATION_RULE, prepareEnrichClassificationRule);
    yield takeLatest([
        Actions.UPDATE_SELECTED_ENRICH_ORGANIZATIONS,
        Actions.UPDATE_ENRICH_SELECTED_CATEGORY,
        Actions.LOAD_ENRICH_PAGE_DATA,
        Actions.LOAD_ENRICH_PRODUCTS,
        Actions.LOAD_ENRICH_CLASSIFICATION_RULES,
        Actions.UPDATE_ENRICH_PAGE_OFFSET,
        Actions.UPDATE_ENRICH_CURRENT_PAGE_SIZE,
        Actions.UPDATE_ENRICH_QUERY,
        Actions.UPDATE_ENRICH_CONFIDENCE_THRESHOLD,
        Actions.UPDATE_ENRICH_DATE_RANGE,
        Actions.UPDATE_ENRICH_JOB_SUBMITTER_SELECTION,
        Actions.UPDATE_ENRICH_JOBS_PREDICTION_STATE,
        Actions.UPDATE_ENRICH_PRODUCT_DETAILS_PANEL,
        Actions.CLOSE_ENRICH_PRODUCT_DETAILS_PANEL,
    ], setCanonicalQueryParams);
}
