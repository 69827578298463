import * as qs from 'qs';
export function getUpdatedQueryString(queryString, mergedPageSettings) {
    const transformedSettings = this.transformToUrlSettings(mergedPageSettings);
    const urlParams = qs.parse(queryString);
    Object.assign(urlParams, transformedSettings);
    return qs.stringify(urlParams);
}
export function getFreshQueryString({ overrides = {}, pageSettings, }) {
    const merged = this.getMergedPageSettings({
        overrides,
        pageSettings,
    });
    return this.getUpdatedQueryString('', merged);
}
