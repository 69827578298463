import { __awaiter, __rest } from "tslib";
import { identity } from 'ramda';
import fetch from './fetch';
import * as APIUtils from './utils';
export const JSON_HEADERS = { 'Content-Type': 'application/json' };
export const AUTH_TOKEN_HEADER = 'authentication';
export function authenticated(token, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, method = 'GET', body: rawBody, files, parse, headers: rawHeaders = {}, expiry, skipCache, } = cb(...args);
        let headers = rawHeaders;
        let body = null;
        if (files) {
            headers = Object.keys(headers)
                .filter((key) => key.toLowerCase() !== 'content-type')
                .reduce((filtered, key) => {
                filtered[key] = headers[key];
                return filtered;
            }, {});
            body = yield APIUtils.mapFilesToMultipartBody(files);
        }
        else if (rawBody) {
            headers = Object.assign(Object.assign({}, headers), JSON_HEADERS);
            body = typeof rawBody === 'string' ? rawBody : JSON.stringify(rawBody);
        }
        const request = {
            method,
            skipCache,
            expiry: expiry,
            headers: Object.assign(Object.assign({}, headers), { Authentication: `Bearer ${token}` }),
            body: body,
        };
        return fetch(url, request)
            .then(APIUtils.extractResponse(parse))
            .then(APIUtils.extractToken);
    });
}
export function enrichAuthenticated(token, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, method = 'GET', body, parse, headers, expiry, skipCache, } = cb(...args);
        const request = {
            method,
            skipCache,
            expiry: expiry,
            headers: Object.assign(Object.assign({}, headers), { authorization: `Bearer ${token}` }),
            body: body,
        };
        return fetch(url, request).then(APIUtils.extractResponse(parse));
    });
}
export function analyticsAuthenticated(clientKey, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, method = 'GET', body, headers = {}, parse, expiry, skipCache, } = cb(...args);
        const request = Object.assign({ method,
            skipCache, expiry: expiry, headers: Object.assign(Object.assign({}, headers), { Authorization: clientKey }) }, (body ? { body: JSON.stringify(body) } : {}));
        return fetch(url, request).then(APIUtils.extractResponse(parse));
    });
}
export function adminAuthenticated(clientKey, cb) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        const { url, headers = {}, expiry, skipCache, } = cb(...args);
        const request = {
            headers,
            skipCache,
            expiry: expiry,
            method: 'POST',
            body: JSON.stringify({ clientKey }),
        };
        return fetch(url, request).then(APIUtils.extractResponse());
    });
}
/* eslint-disable @typescript-eslint/require-await */
export function mapFilesToMultipartBody(files) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        for (const key of Object.keys(files)) {
            formData.append(key, files[key]);
        }
        return formData;
    });
}
export function extractResponse(parse = identity) {
    return (_a) => __awaiter(this, void 0, void 0, function* () {
        var { result } = _a, response = __rest(_a, ["result"]);
        const rawResult = result.result || result;
        const parsedResult = parse ? parse(rawResult) : rawResult;
        return Object.assign(Object.assign({}, response), { result: parsedResult });
    });
}
export function extractProvidersList(response) {
    var _a;
    return (_a = response === null || response === void 0 ? void 0 : response.result) === null || _a === void 0 ? void 0 : _a.result;
}
export function extractToken(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const token = response.headers[AUTH_TOKEN_HEADER];
        return Object.assign(Object.assign({}, response), (token ? { token } : {}));
    });
}
/* eslint-enable @typescript-eslint/require-await */
export function arrayParser(parse) {
    return (result) => {
        if (parse) {
            return Array.isArray(result) ? result.map(parse).filter(Boolean) : parse(result);
        }
        return result;
    };
}
