export const LOAD_ENRICH = 'ENRICH:LOAD';
export const LOAD_ENRICH_DATA = 'ENRICH_DATA:LOAD';
export const REFRESH_ENRICH_DATA = 'ENRICH_DATA:REFRESH';
export const REFRESH_ENRICH_SNAPSHOT = 'ENRICH_SNAPSHOT:REFRESH';
export const REFRESH_ENRICH_FILTERS = 'ENRICH_FILTERS:REFRESH';
export const REFRESH_ENRICH_CUSTOMER_ID = 'ENRICH_CUSTOMER_ID:REFRESH';
export const REFRESH_ENRICH_TOKENS = 'ENRICH_TOKENS:REFRESH';
export const UPDATE_ENRICH_CURRENT_PAGE_SIZE = 'ENRICH_CURRENT_PAGE_SIZE:UPDATE';
export const UPDATE_ENRICH_PAGE_OFFSET = 'ENRICH_PAGE_OFFSET:UPDATE';
export const UPDATE_ENRICH_PRODUCTS_EXPANDED = 'ENRICH_PRODUCTS_EXPANDED:UPDATE';
export const LOAD_ENRICH_PRODUCTS = 'ENRICH_PRODUCTS:LOAD';
export const UPDATE_ENRICH_PRODUCTS = 'ENRICH_PRODUCTS:UPDATE';
export const LOAD_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS = 'ENRICH_RULE_LIBRARY:PREVIEW_PRODUCTS:LOAD';
export const UPDATE_ENRICH_RULE_LIBRARY_PREVIEW_PRODUCTS = 'ENRICH_RULE_LIBRARY:PREVIEW_PRODUCTS:UPDATE';
export const UPDATE_ENRICH_PRODUCT_SELECTION = 'ENRICH_PRODUCT_SELECTION:UPDATE';
export const UPDATE_ENRICH_CURRENT_TOTAL_ITEMS = 'ENRICH_CURRENT_TOTAL_ITEMS:UPDATE';
export const UPDATE_ENRICH_PRODUCT_COUNTS = 'ENRICH_PRODUCT_COUNTS:UPDATE';
export const UPDATE_ENRICH_PRODUCT_DETAILS_PANEL = 'ENRICH_PRODUCT_DETAILS_PANEL:UPDATE';
export const CLOSE_ENRICH_PRODUCT_DETAILS_PANEL = 'ENRICH_PRODUCT_DETAILS_PANEL:CLOSE';
export const LOAD_ENRICH_PRODUCT_COUNTS = 'ENRICH_PRODUCT_COUNTS:LOAD';
export const UPDATE_ENRICH_SELECTED_PRODUCT_FIELDS = 'ENRICH_SELECTED_PRODUCT_FIELDS:UPDATE';
export const UPDATE_ENRICH_QUERY = 'ENRICH_QUERY:UPDATE';
export const TOGGLE_ENRICH_SORT = 'ENRICH_SORT:TOGGLE';
export const LOAD_ENRICH_ML_MODELS = 'ENRICH_ML_MODELS:LOAD';
export const UPDATE_ENRICH_ML_MODELS = 'ENRICH_ML_MODELS:UPDATE';
export const UPDATE_ENRICH_ML_MODEL_SELECTION = 'ENRICH_ML_MODEL_SELECTION:UPDATE';
export const UPDATE_ENRICH_JOB_MODELS_SELECTION = 'ENRICH_JOB_MODELS_SELECTION:UPDATE';
export const UPDATE_ENRICH_JOB_MANAGEMENT_USERS = 'ENRICH_JOB_USERS:UPDATE';
export const LOAD_ENRICH_ML_CLASS_PREDICTIONS = 'ENRICH_ML_CLASS_PREDICTIONS:LOAD';
export const UPDATE_ENRICH_ML_CLASS_PREDICTIONS = 'ENRICH_ML_CLASS_PREDICTIONS:UPDATE';
export const UPDATE_ENRICH_ML_SELECTED_CLASS_PREDICTIONS = 'ENRICH_ML_SELECTED_CLASS_PREDICTIONS:UPDATE';
export const CLEAR_ENRICH_ML_SELECTED_CLASS_PREDICTIONS = 'ENRICH_ML_SELECTED_CLASS_PREDICTIONS:CLEAR';
export const REMOVE_ENRICH_ML_SELECTED_CLASS_PREDICTION = 'ENRICH_ML_SELECTED_CLASS_PREDICTION:REMOVE';
export const LOAD_ENRICH_CATEGORIES = 'ENRICH_CATEGORIES:LOAD';
export const UPDATE_ENRICH_CATEGORIES = 'ENRICH_CATEGORIES:UPDATE';
export const UPDATE_ENRICH_SELECTED_CATEGORY = 'ENRICH_SELECTED_CATEGORY:UPDATE';
export const LOAD_ALL_ENRICH_ORGANIZATIONS = 'ENRICH_ALL_ORGANIZATIONS:LOAD';
export const UPDATE_ENRICH_ORGANIZATIONS = 'ENRICH_ORGANIZATIONS:UPDATE';
export const UPDATE_SELECTED_ENRICH_ORGANIZATIONS = 'ENRICH_SELECTED_ORGANIZATIONS:UPDATE';
export const UPDATE_ENRICH_TAXONOMY_NODES = 'ENRICH_TAXONOMY_NODES:UPDATE';
export const UPDATE_ENRICH_SELECTED_TAXONOMY_NODE_ID = 'ENRICH_SELECTED_TAXONOMY_NODE_ID:UPDATE';
export const UPDATE_ENRICH_CLASSES = 'ENRICH_CLASSES:UPDATE';
export const UPDATE_ENRICH_PRODUCT_CLASS_ID = 'ENRICH_PRODUCT_CLASS_ID:UPDATE';
export const RECEIVE_ENRICH_PRODUCT_CLASS_ID = 'ENRICH_PRODUCT_CLASS_ID:RECEIVE';
export const UPDATE_ENRICH_EXPANDED_CATEGORIES = 'ENRICH_EXPANDED_CATEGORIES:UPDATE';
export const UPDATE_ENRICH_EXPANDED_TAXONOMY_NODES = 'ENRICH_EXPANDED_TAXONOMY_NODES:UPDATE';
export const UPDATE_ENRICH_RESOURCE_LOADING_STATUS = 'ENRICH_RESOURCE_LOADING_STATUS:UPDATE';
export const CREATE_ENRICH_CLASSIFICATION_JOB = 'ENRICH_CLASSIFICATION_JOB:CREATE';
export const LOAD_ENRICH_TAXONOMY_NODES = 'ENRICH_TAXONOMY_NODES:LOAD';
export const LOAD_ENRICH_CLASSES = 'ENRICH_CLASSES:LOAD';
export const SWITCH_ENRICH_PAGE = 'ENRICH_PAGE:SWITCH';
export const UPDATE_ENRICH_PAGE_SETTINGS = 'ENRICH_PAGE_SETTINGS:UPDATE';
export const UPDATE_ENRICH_PAGE_NAME = 'ENRICH_PAGE_NAME:UPDATE';
export const NOTIFY_ENRICH_PAGE_VISITED = 'ENRICH_PAGE_VISITED:NOTIFY';
export const UPDATE_ENRICH_VISITED = 'ENRICH_VISITED:UPDATE';
export const SAVE_ENRICH_ML_CLASS_PREDICTIONS = 'ENRICH_ML_CLASS_PREDICTIONS:SAVE';
export const DELETE_ENRICH_ML_CLASS_PREDICTION = 'ENRICH_ML_CLASS_PREDICTION:DELETE';
export const CLEAR_ENRICH_TAXONOMY_NODE_SELECTIONS = 'ENRICH_TAXONOMY_NODE_SELECTIONS:CLEAR';
export const CLEAR_ENRICH_CATEGORY_SELECTIONS = 'ENRICH_CATEGORY_SELECTIONS:CLEAR';
export const UPDATE_ENRICH_CONFIDENCE_THRESHOLD = 'ENRICH_CONFIDENCE_THRESHOLD:UPDATE';
export const LOAD_ENRICH_AI_CLASSIFICATION_JOBS = 'ENRICH_AI_CLASSIFICATION_JOBS:LOAD';
export const UPDATE_ENRICH_AI_CLASSIFICATION_JOBS = 'ENRICH_AI_CLASSIFICATION_JOBS:UPDATE';
export const UPDATE_ENRICH_REMAINING_PREDICTIONS_BY_JOB = 'ENRICH_REMAINING_PREDICTIONS_BY_JOB:UPDATE';
export const UPDATE_ENRICH_DATE_RANGE = 'ENRICH_DATE_RANGE:UPDATE';
export const UPDATE_ENRICH_JOBS_PREDICTION_STATE = 'UPDATE_ENRICH_JOBS_PREDICTION_STATE:UPDATE';
export const UPDATE_ENRICH_JOB_SUBMITTER_SELECTION = 'ENRICH_JOBS_SUBMITTER_SELECTION:UPDATE';
export const LOAD_ENRICH_PAGE_DATA = 'ENRICH_PAGE_DATA:LOAD';
export const CLEAR_ENRICH_RESOURCE_LOADING_STATUS = 'ENRICH_RESOURCE_LOADING_STATUS:CLEAR';
export const CREATE_ENRICH_CLASSIFICATION_RULE = 'ENRICH_CLASSIFICATION_RULE:CREATE';
export const UPDATE_ENRICH_CLASSIFICATION_RULE = 'ENRICH_CLASSIFICATION_RULE:UPDATE';
export const DELETE_ENRICH_CLASSIFICATION_RULE = 'ENRICH_CLASSIFICATION_RULE:DELETE';
export const LOAD_ENRICH_CLASSIFICATION_RULES = 'ENRICH_CLASSIFICATION_RULES:LOAD';
export const UPDATE_ENRICH_CLASSIFICATION_RULES = 'ENRICH_CLASSIFICATION_RULES:UPDATE';
export const PREPARE_ENRICH_CLASSIFICATION_RULE = 'ENRICH_CLASSIFICATION_RULE:PREPARE';
export const UPDATE_ENRICH_RULE_LIBRARY_SELECTED_ORGANIZATIONS = 'ENRICH_RULE_LIBRARY:SELECTED_ORGANIZATIONS:UPDATE';
