import React from 'react';
import classNames from 'classnames';
import MaterialTooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@/decorators';
export const styles = ({ palette, spacing, tooltip }) => ({
    tooltip,
    container: {
        verticalAlign: 'sub',
        marginRight: spacing(1),
    },
    icon: {
        width: spacing(2),
        height: spacing(2),
    },
    actionColor: {
        color: palette.contrastFont,
    },
});
const Tooltip = withStyles(styles)(function Labeled({ title, iconClassName, iconStyle, tooltipClassName, placement, classes, children, }) {
    return (<MaterialTooltip classes={{ tooltip: classNames(classes.tooltip, tooltipClassName) }} title={title || ''} enterDelay={300} leaveDelay={300} placement={placement || 'top-start'}>
      {children || (<span className={classNames(classes.container, iconClassName)}>
          <HelpIcon color="action" classes={{ root: classes.icon, colorAction: classNames(classes.actionColor, iconStyle) }}/>
        </span>)}
    </MaterialTooltip>);
});
export default Tooltip;
