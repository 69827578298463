import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var StopWord;
(function (StopWord) {
    StopWord.DEFAULT = { promote: true };
    StopWord.CONFIG = {
        type: ModelType.STOP_WORD,
        defaults: StopWord.DEFAULT,
        filter: filterByNameAndId,
        promotable: true,
    };
})(StopWord || (StopWord = {}));
export default StopWord;
