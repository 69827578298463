import { equals } from 'ramda';
import { call, put, select } from 'redux-saga/effects';
import { ModelType } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as MerchandisingActions from '@/store/actions/merchandising';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as utils from '@/utils';
import * as Tasks from '../tasks';
var ModelCommentAction = State.ChangeLog.ModelCommentAction;
export function* loadAllModels(modelType, { area, skipCache } = {}) {
    const client = yield call(Tasks.crudClient, modelType, area);
    const loadedModels = yield call(Tasks.authCall, client.find, skipCache);
    const currentModels = yield select(Selectors.managerModelsSelector(modelType, area));
    if (equals(loadedModels, currentModels))
        return;
    yield put(MerchandisingActions.withArea(area)(MerchandisingActions.creators.storeAllModels(modelType, loadedModels)));
}
export function* loadOneModel(modelType, id, { area, skipCache } = {}) {
    const client = yield call(Tasks.crudClient, modelType, area);
    const loadedModel = yield call(Tasks.authCall, client.get, id, skipCache);
    const currentModel = yield select(Selectors.managerModelSelector(modelType, area, id));
    // feature flagging mechanism used to toggle to change log feature on a per-customer basis.
    const authClient = yield call(Tasks.authClient);
    const resp = yield call(Tasks.authCall, authClient.changeLogStatus);
    const isActive = resp.status ? resp.result : resp;
    yield put(ActionCreators.setChangeLogIsActive(isActive));
    if (equals(loadedModel, currentModel))
        return;
    yield put(MerchandisingActions.withArea(area)(MerchandisingActions.creators.storeOneModel(modelType, loadedModel)));
}
export function* createModel(modelType, model, { area }) {
    const formDiff = yield call(Tasks.getFormDiff, modelType, model);
    const comment = Tasks.generateComment(ModelCommentAction.CREATE, { formDiff, modelType });
    const client = yield call(Tasks.crudClient, modelType, area);
    const createdModel = yield call(Tasks.authCall, client.create(comment), model);
    const currentModels = yield select(Selectors.managerModelsSelector(modelType, area));
    const combinedModels = [...currentModels, createdModel];
    yield put(MerchandisingActions.withArea(area)(MerchandisingActions.creators.storeAllModels(modelType, combinedModels)));
    let options = {};
    switch (modelType) {
        case ModelType.AREA:
            yield call(handleAreaCreation, model);
            break;
        case ModelType.RULE:
            options = { page: utils.totalPages(combinedModels.length) };
            break;
    }
    yield put(ActionCreators.goToManager(null, options));
}
export function* updateModel(modelType, id, model, isPromoteToggle, { area }) {
    const formDiff = isPromoteToggle ? ['promote'] : yield call(Tasks.getFormDiff, modelType, model);
    const comment = Tasks.generateComment(ModelCommentAction.UPDATE, { formDiff, modelType });
    const client = yield call(Tasks.crudClient, modelType, area);
    yield call(Tasks.authCall, client.update(comment), id, model);
    switch (modelType) {
        case ModelType.USER:
            yield put(ActionCreators.refreshAreaAccess());
            break;
    }
    yield call(loadOneModel, modelType, id, { area, skipCache: true });
}
export function* patchModel(modelType, id, updateFields, { area }) {
    const diffs = Object.keys(updateFields);
    const comment = Tasks.generateComment(ModelCommentAction.UPDATE, { formDiff: diffs, modelType });
    const client = yield call(Tasks.crudClient, modelType, area);
    yield call(Tasks.authCall, client.patch(comment), id, updateFields);
    yield call(loadOneModel, modelType, id, { area, skipCache: true });
}
export function* removeModel(modelType, id, { area }) {
    const models = yield select(Selectors.managerModelsSelector(modelType, area));
    const currentPage = yield select(Selectors.currentPageSelector);
    const client = yield call(Tasks.crudClient, modelType, area);
    if (currentPage > utils.totalPages(models.length - 1)) {
        yield put(ActionCreators.goToManager(null, { page: currentPage - 1 }));
    }
    yield call(Tasks.authCall, client.remove, id);
    yield call(loadAllModels, modelType, { area, skipCache: true });
}
export function* copyModelToArea(modelType, id, sourceArea, targetArea) {
    // get source model
    let sourceClient = yield call(Tasks.crudClient, modelType, sourceArea);
    const sourceModel = yield call(Tasks.authCall, sourceClient.get, id, true);
    // look for duplicate
    const foundDuplicate = yield call(Tasks.findDuplicate, modelType, targetArea, sourceModel.name);
    if (foundDuplicate) {
        yield put(ActionCreators.setDuplicateModelId(foundDuplicate.id));
        return false;
    }
    // create target model with source model info
    const targetComment = Tasks.generateComment(ModelCommentAction.SINGLE_PROMOTE_TARGET, {
        sourceArea,
        sourceName: modelType === ModelType.SYNONYM ? sourceModel.synonyms[0] : sourceModel.name,
    });
    const targetClient = yield call(Tasks.crudClient, modelType, targetArea);
    const createdModel = yield call(Tasks.authCall, targetClient.create(targetComment, id), sourceModel);
    // facilitate an update operation in the source model
    const sourceComment = Tasks.generateComment(ModelCommentAction.SINGLE_PROMOTE_SOURCE, {
        targetArea,
        targetName: modelType === ModelType.SYNONYM ? sourceModel.synonyms[0] : createdModel.name,
    });
    sourceClient = yield call(Tasks.crudClient, modelType, sourceArea);
    yield call(Tasks.authCall, sourceClient.update(sourceComment), id, sourceModel);
    yield put(ActionCreators.closeCopyToAreaModal());
}
export function* replaceModelInArea(modelType, id, sourceArea, targetArea) {
    let sourceClient = yield call(Tasks.crudClient, modelType, sourceArea);
    const sourceModel = yield call(Tasks.authCall, sourceClient.get, id, true);
    const foundDuplicate = yield call(Tasks.findDuplicate, modelType, targetArea, sourceModel.name);
    const targetComment = Tasks.generateComment(ModelCommentAction.SINGLE_PROMOTE_TARGET, {
        sourceArea,
        sourceName: sourceModel.name,
    });
    const targetClient = yield call(Tasks.crudClient, modelType, targetArea);
    yield call(Tasks.authCall, targetClient.update(targetComment, foundDuplicate.id), foundDuplicate.id, sourceModel);
    const sourceComment = Tasks.generateComment(ModelCommentAction.SINGLE_PROMOTE_SOURCE, {
        targetArea,
        targetName: foundDuplicate.name,
    });
    sourceClient = yield call(Tasks.crudClient, modelType, sourceArea);
    yield call(Tasks.authCall, sourceClient.update(sourceComment), id, sourceModel);
    yield put(ActionCreators.closeCopyToAreaModal());
}
export function* handleAreaCreation({ name }) {
    const isCopying = (yield select(Selectors.formActionSelector)) === 'copy';
    yield put(ActionCreators.refreshAreaAccess());
    if (isCopying) {
        const formDefaults = yield select(Selectors.formDefaultsSelector);
        yield put(ActionCreators.promoteArea(formDefaults.name, name));
    }
}
