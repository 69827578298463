export const getPaginationSettings = ({ overrides, urlPageSettings, pageSettings, defaults, }) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        pageSize: (_c = (_b = (_a = overrides === null || overrides === void 0 ? void 0 : overrides.pageSize) !== null && _a !== void 0 ? _a : urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.pageSize) !== null && _b !== void 0 ? _b : pageSettings === null || pageSettings === void 0 ? void 0 : pageSettings.pageSize) !== null && _c !== void 0 ? _c : defaults.pageSize,
        offset: (_f = (_e = (_d = overrides === null || overrides === void 0 ? void 0 : overrides.offset) !== null && _d !== void 0 ? _d : urlPageSettings === null || urlPageSettings === void 0 ? void 0 : urlPageSettings.offset) !== null && _e !== void 0 ? _e : pageSettings === null || pageSettings === void 0 ? void 0 : pageSettings.offset) !== null && _f !== void 0 ? _f : defaults.offset,
    });
};
export const buildPaginationPageSettingsShape = ({ pageSize, offset }) => ({
    pagination: {
        pageSize,
        offset,
    },
});
export const transformPaginationToPageSettings = ({ page, pageSize }) => {
    let pageSizeNum = parseInt(pageSize, 10);
    if (Number.isNaN(pageSizeNum))
        pageSizeNum = undefined;
    let offset = (parseInt(page, 10) - 1) * pageSizeNum;
    if (Number.isNaN(offset))
        offset = undefined;
    return { offset, pageSize: pageSizeNum };
};
export const getPageNumber = (offset, pageSize) => {
    let page = Math.ceil(offset / pageSize) + 1;
    if (Number.isNaN(page))
        page = undefined;
    return page;
};
