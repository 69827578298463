import { __rest } from "tslib";
import { DEFAULT_RELEVANCY, DEFAULT_STRATEGY } from '@/constants';
import Rule from '@/merchandising/models/rule';
export default {
    rule(_a) {
        var { bringToTop, triggers, triggerSets, biasingProfile, matchStrategy, filters, sort } = _a, model = __rest(_a, ["bringToTop", "triggers", "triggerSets", "biasingProfile", "matchStrategy", "filters", "sort"]);
        return Object.assign(Object.assign({}, model), { sort: sort || Rule.DEFAULT.sort, bringToTop: bringToTop || [], triggerSets: triggerSets || (triggers ? [triggers] : []), biasingProfile: biasingProfile === DEFAULT_RELEVANCY ? null : biasingProfile, matchStrategy: matchStrategy === DEFAULT_STRATEGY ? null : matchStrategy, filters: (filters || []).map((filter) => (filter.exclude
                ? Object.assign(Object.assign({}, filter), { type: Rule.Filter.Type.EXCLUDE_NAVIGATION_VALUE }) : filter)) });
    },
    area(_a) {
        var { biasingProfile, matchStrategy, relaxMatchStrategyEnabled, relaxMatchStrategyName } = _a, model = __rest(_a, ["biasingProfile", "matchStrategy", "relaxMatchStrategyEnabled", "relaxMatchStrategyName"]);
        return Object.assign(Object.assign(Object.assign({}, model), { biasingProfile: biasingProfile === DEFAULT_RELEVANCY ? null : biasingProfile, matchStrategy: matchStrategy === DEFAULT_STRATEGY ? null : matchStrategy }), (relaxMatchStrategyEnabled ? { relaxMatchStrategyName } : { relaxMatchStrategyName: false }));
    },
    filter(model) {
        return model.exclude
            ? Object.assign(Object.assign({}, model), { type: Rule.Filter.Type.EXCLUDE_NAVIGATION_VALUE }) : model;
    },
    matchStrategy(_a) {
        var { rules } = _a, model = __rest(_a, ["rules"]);
        return Object.assign(Object.assign({}, model), { rules: (rules || []).map((_a) => {
                var { terms, termsGreaterThan, percentage, mustMatch: rawMustMatch } = _a, rule = __rest(_a, ["terms", "termsGreaterThan", "percentage", "mustMatch"]);
                const mustMatch = percentage ? `${rawMustMatch}%` : String(rawMustMatch);
                return Object.assign(Object.assign({}, rule), { mustMatch, greaterThan: !!termsGreaterThan, terms: termsGreaterThan || terms });
            }) });
    },
};
