import * as Actions from '@/store/actions/types';
import * as ReportsWindowReducer from './reports-window';
export const DEFAULT = null;
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SET_WINDOW:
            return ReportsWindowReducer.setWindowReducer(action);
        default:
            return state;
    }
};
export function setWindowReducer({ payload: window }) {
    return window;
}
