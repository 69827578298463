import { call, delay, fork, put, retry, select, takeEvery, } from 'redux-saga/effects';
import MerchandisingConfig from '@/config/merchandising';
import { ModelType, NUMBER_OF_RETRIES, RETRY_DELAY } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import { creators as merchActionCreators, withArea } from '@/store/actions/merchandising';
import * as Actions from '@/store/actions/types';
import * as ResourceLocking from '@/store/sagas/locking';
import * as Tasks from '@/store/sagas/tasks';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as Operations from './operations';
// import SANITIZERS from './sanitizers';
import TRANSFORMERS from './transformers';
var ModelCommentAction = State.ChangeLog.ModelCommentAction;
export const LOAD_ALL_TIMEOUT = 30000;
export const NORMALIZATION_PRIORITY_INTERVAL = 65536;
export function* createModel({ payload: model, meta: { modelType, area } }) {
    yield call(Tasks.asyncUserAction, `create ${modelType}`, Operations.createModel, [modelType, model, { area }]);
}
export function* updateModel({ payload: { id, model, isPromoteToggle = false }, meta: { modelType, area }, }) {
    yield call(Tasks.asyncUserAction, `update ${modelType}`, Operations.updateModel, [
        modelType,
        id,
        model,
        isPromoteToggle,
        { area },
    ]);
}
export function* patchModel({ payload: { id, updateFields }, meta: { modelType, area }, }) {
    yield call(Tasks.asyncUserAction, `update ${modelType}`, Operations.patchModel, [
        modelType,
        id,
        updateFields,
        { area },
    ]);
}
export function* saveModel({ payload, meta: { modelType, area } }) {
    let { model } = payload;
    // const sanitize = SANITIZERS[modelType];
    // if (sanitize) {
    //   model = yield call(sanitize, model);
    // }
    const transform = TRANSFORMERS[modelType];
    if (transform) {
        model = yield call(transform, model);
    }
    // creating model
    if (!model.id) {
        yield call(createModel, withArea(area)(merchActionCreators.createModel(modelType, model)));
        return;
    }
    yield call(updateModel, withArea(area)(merchActionCreators.updateModel(modelType, model.id, model)));
    if (!payload.goToTable || !(yield select(Selectors.isEditingModelSelector)))
        return;
    yield call(goToManagerCurrentPage, modelType);
}
export function* removeModel({ payload: id, meta: { modelType, area } }) {
    yield call(Tasks.asyncUserAction, `delete ${modelType}`, Operations.removeModel, [modelType, id, { area }]);
}
export function* loadAllModels({ payload: skipCache, meta: { modelType, area } }) {
    const isLoading = yield select(Selectors.isLoadingSelector);
    if (isLoading)
        return;
    try {
        yield retry(NUMBER_OF_RETRIES, RETRY_DELAY, Operations.loadAllModels, modelType, { area, skipCache });
    }
    catch (e) {
        yield Tasks.error(`Failed to load ${modelType}s. Please refresh.`, e);
    }
}
export function* loadOneModel({ payload: { id, skipCache }, meta: { area, modelType } }) {
    const isLoading = yield select(Selectors.isLoadingSelector);
    if (isLoading)
        return;
    yield call(Tasks.asyncSystemAction, `load ${modelType}`, Operations.loadOneModel, [
        modelType,
        id,
        { area, skipCache },
    ]);
}
export function* copyToArea({ payload: { id, targetArea }, meta: { modelType, area } }) {
    yield call(Tasks.asyncUserAction, `promote ${modelType}`, Operations.copyModelToArea, [
        modelType,
        id,
        area,
        targetArea,
    ]);
}
export function* replaceInArea({ payload: { id, targetArea }, meta: { modelType, area } }) {
    yield call(Tasks.asyncUserAction, `promote ${modelType}`, Operations.replaceModelInArea, [
        modelType,
        id,
        area,
        targetArea,
    ]);
}
export function* reorderById(action) {
    const { payload: { sourceId, targetId, relationship }, meta: { modelType, area }, } = action;
    const models = yield call(enabledModels, modelType, area);
    const sourceIndex = models.findIndex(({ value: { id } }) => id === sourceId);
    let targetIndex = models.findIndex(({ value: { id } }) => id === targetId);
    if (relationship !== State.UI.Position.Relationship.ABOVE) {
        targetIndex = Math.min(models.length, targetIndex + 1);
    }
    if (sourceIndex < targetIndex) {
        targetIndex -= 1;
    }
    yield call(reorderModels, sourceIndex, targetIndex, models, modelType, area);
}
export function* reorderByIndex(action) {
    const { payload: { sourceIndex, targetIndex }, meta: { modelType, area }, } = action;
    const models = yield call(enabledModels, modelType, area);
    const realSourceIndex = models.findIndex(({ index }) => index === sourceIndex);
    const realTargetIndex = models.findIndex(({ index }) => index === targetIndex);
    yield call(reorderModels, realSourceIndex, realTargetIndex, models, modelType, area);
}
export function* reorderModels(sourceIndex, targetIndex, models, modelType, area) {
    if (sourceIndex === -1 || targetIndex === -1) {
        yield Tasks.simpleError(`unable to reorder the selected ${modelType}`);
        return;
    }
    if (modelType !== ModelType.RULE && modelType !== ModelType.NAVIGATION) {
        return;
    }
    const comment = Tasks.generateComment(ModelCommentAction.REORDER, { sourceIndex, targetIndex });
    const client = yield call(Tasks.crudClient, modelType, area);
    const { value: model } = models[sourceIndex];
    const newPriority = calculatePriority(sourceIndex, targetIndex, models);
    try {
        yield call(Tasks.authCall, client.update(comment), model.id, Object.assign(Object.assign({}, model), { priority: newPriority }));
        return yield call(Tasks.asyncUserAction, `reorder ${modelType}`, Operations.loadAllModels, [
            modelType,
            { area, skipCache: true },
        ]);
    }
    catch (e) {
        return yield Tasks.simpleError(`unable to reorder the selected ${modelType}`, e);
    }
}
export function* togglePromotion({ payload: { id, promote }, meta: { modelType, area } }) {
    yield put(ActionCreators.setLoading(true));
    yield put(withArea(area)(merchActionCreators.patchModel(modelType, id, { promote })));
}
export default function* modelsSaga() {
    yield takeEvery(Actions.CREATE_MODEL, createModel);
    yield takeEvery(Actions.UPDATE_MODEL, updateModel);
    yield takeEvery(Actions.PATCH_MODEL, patchModel);
    yield takeEvery(Actions.SAVE_MODEL, saveModel);
    yield takeEvery(Actions.REMOVE_MODEL, removeModel);
    yield takeEvery(Actions.LOAD_ONE_MODEL, loadOneModel);
    yield takeEvery(Actions.TOGGLE_PROMOTION, togglePromotion);
    yield takeEvery(Actions.COPY_TO_AREA, copyToArea);
    yield takeEvery(Actions.REPLACE_IN_AREA, replaceInArea);
    yield takeEvery(Actions.REORDER_BY_ID, reorderById);
    yield takeEvery(Actions.REORDER_BY_INDEX, reorderByIndex);
    yield takeEvery(Actions.DISABLE_FORM, Tasks.destroyActiveForms);
    yield takeEvery(Actions.LOCK_MODEL, ResourceLocking.lockModelTask);
    yield takeEvery(Actions.UNLOCK_MODEL, ResourceLocking.unlockModelTask);
    yield call(throttleBy, LOAD_ALL_TIMEOUT, actionDiscriminator, Actions.LOAD_ALL_MODELS, loadAllModels);
}
export function* throttleBy(ms, discriminator, type, fn) {
    const handled = {};
    yield takeEvery(type, function* (action) {
        const id = discriminator(action);
        if (handled[id])
            return;
        handled[id] = true;
        yield fork(function* () {
            yield delay(ms);
            handled[id] = false;
        });
        yield call(fn, action);
    });
}
export function* goToManagerCurrentPage(modelType) {
    const page = yield select(Selectors.currentPageSelector);
    yield put(ActionCreators.goToManager(modelType, { page }));
}
export function* enabledModels(modelType, area) {
    const config = MerchandisingConfig[modelType];
    const models = (yield select(Selectors.managerModelsSelector(modelType, area))).map((value, index) => ({
        value,
        index,
    }));
    if (!config.isDisabled) {
        return models;
    }
    return models.filter(({ value }) => !config.isDisabled(value));
}
export function actionDiscriminator({ meta: { modelType, area } }) {
    return `${modelType}${area ? `:${area}` : ''}`;
}
export function calculatePriority(sourceIndex, targetIndex, models) {
    const priorities = models.map((m) => m.value.priority);
    if (sourceIndex === targetIndex) {
        return priorities[targetIndex];
    }
    const modifiedIndex = targetIndex + (sourceIndex < targetIndex ? 0 : -1);
    const maxIndex = models.length - 1;
    // if moving a resource to the lowest priority(biggest value), assign it a priority value 2^16 larger than the current biggest value
    if (modifiedIndex >= maxIndex) {
        return priorities[maxIndex] + NORMALIZATION_PRIORITY_INTERVAL;
    }
    // otherwise, assign it a priority between the previous and next priority values
    const prev = priorities[modifiedIndex];
    const next = priorities[modifiedIndex + 1];
    return ((prev || 0) + next) / 2;
}
