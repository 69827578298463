import { __rest } from "tslib";
import { all, call, put, select, } from 'redux-saga/effects';
import { sortTable } from '@/components/reports/table-v2';
import { INSIGHTS } from '@/components/reports/table-v2/enhancements/insights-modal/content';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as Tasks from '../tasks';
import * as InsightsModule from './insights';
import * as Utils from './utils';
var InsightsType = State.Analytics.InsightsType;
var DashboardType = State.Analytics.DashboardType;
var ReportType = State.Analytics.ReportType;
var ResultKey = State.Analytics.ResultKey;
export const SIZE = 1000;
export const INSIGHTS_LOADER = {
    [InsightsType.METRICS]: loadMetricsTask,
    [InsightsType.PRODUCTS]: loadProductsTask,
    [InsightsType.NAVIGATIONS]: loadNavigationsTask,
};
export function* loadInsightsTask({ payload: { insightsType, query } }) {
    try {
        const { devices } = yield select(Selectors.reportFiltersSelector(ReportType.PERFORMANCE));
        const { dateRange: { start, end }, } = yield select(Selectors.filterSelector(DashboardType.SEARCH_TERM));
        const customerArea = yield select(Selectors.activeAreaSelector);
        const chartDateRange = Utils.getDateRangeParam({ start, end });
        const reportDateRange = Utils.getReportDateParam(start, end);
        const dateRange = insightsType === InsightsType.METRICS ? chartDateRange : reportDateRange;
        const insightsConfig = {
            dateRange,
            customerArea,
            matchType: State.Analytics.MatchType.EXACT,
            searchTerm: query,
            deviceType: devices,
        };
        const insightProfile = INSIGHTS.find((insight) => insight.id === insightsType);
        const result = yield call(INSIGHTS_LOADER[insightsType], insightsConfig);
        yield put(ActionCreators.refreshInsights(insightsType, result.status
            ? []
            : addIndicies(insightProfile.defaultSort ? sortResult(result, insightProfile.defaultSort) : result)));
    }
    catch (e) {
        yield Tasks.simpleError('load insights', e);
    }
}
export function* loadProductsTask(insightsConfig) {
    const authClient = yield call(Tasks.authClient);
    const { dateRange } = insightsConfig, config = __rest(insightsConfig, ["dateRange"]);
    const { result = [] } = yield call(authClient.analyticsV2.products, Object.assign(Object.assign(Object.assign({}, dateRange), config), { size: SIZE }));
    return InsightsModule.patchResults(result);
}
export function patchResults(results) {
    return results.map((result) => {
        if (!result.skus) {
            return result;
        }
        const stringifiedSku = result[ResultKey.SKUS].map((sku) => JSON.stringify(sku));
        const set = new Set(stringifiedSku);
        const arr = Array.from(set);
        result[ResultKey.SKUS] = arr.map((v) => JSON.parse(v));
        const totalsUpdate = {
            [ResultKey.VIEW_PRODUCTS]: 0,
            [ResultKey.ADD_TO_CARTS]: 0,
            [ResultKey.ORDERS]: 0,
        };
        result.skus.forEach((sku) => {
            totalsUpdate.viewProducts += sku.viewProducts;
            totalsUpdate.addToCarts += sku.addToCarts;
            totalsUpdate.orders += sku.orders;
        });
        return Object.assign(Object.assign({}, result), totalsUpdate);
    });
}
export function* loadMetricsTask(insightsConfig) {
    const authClient = yield call(Tasks.authClient);
    const { dateRange } = insightsConfig, config = __rest(insightsConfig, ["dateRange"]);
    const fullResult = yield all(dateRange.map((range) => call(authClient.analyticsV2.clickthroughs, Object.assign(Object.assign({}, config), range))));
    return Utils.combineResults(fullResult);
}
export function* loadNavigationsTask(insightsConfig) {
    const authClient = yield call(Tasks.authClient);
    const { dateRange } = insightsConfig, config = __rest(insightsConfig, ["dateRange"]);
    const { result } = yield call(authClient.analyticsV2.navigations, Object.assign(Object.assign(Object.assign({}, dateRange), config), { size: SIZE }));
    return result;
}
export const addIndicies = (data) => data.map((item, index) => (Object.assign(Object.assign({}, item), { rowNum: index + 1 })));
export const sortResult = (result, options) => result.sort(sortTable(options.order, options.orderBy));
