import React from 'react';
import Typography from '@material-ui/core/Typography';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import TableIcon from '@material-ui/icons/TableChart';
import ToggleButtons from '@/components/controls/toggle-buttons';
import { withStyles } from '@/decorators';
import ReportSizeFilter from './report-size';
import TabFilters from './tab';
import { QUERY_OPTIONS } from './tab/search-query';
export var View;
(function (View) {
    View["TABLE"] = "table";
    View["CHART"] = "chart";
})(View || (View = {}));
const TOGGLE_BUTTONS = [
    { value: View.TABLE, content: <TableIcon /> },
    { value: View.CHART, content: <BubbleChartIcon /> },
];
export const styles = ({ spacing, palette, toggleButton }) => ({
    root: {
        display: 'flex',
    },
    toggleButtons: {
        display: 'flex',
        alignItems: 'center',
        '&>:first-child': {
            border: `1px solid ${toggleButton.border}`,
            borderRight: 'none',
        },
        '&>:last-child': {
            border: `1px solid ${toggleButton.border}`,
        },
    },
    button: {
        backgroundColor: palette.primary.contrastText,
        color: palette.contrastFont,
        borderRadius: '4px',
        transition: '250ms',
        '&$selectedToggle': {
            backgroundColor: '#5BB003',
            color: palette.primary.contrastText,
            '&:hover': {
                backgroundColor: palette.primary.dark,
            },
        },
    },
    label: {
        fontSize: spacing(1.5),
        color: palette.mainFont,
        paddingTop: `${spacing(1)}px`,
    },
    selectedToggle: {},
    searchContainer: {
        display: 'flex',
    },
});
const FilterSection = withStyles(styles)(function FilterSection({ currentReport, chartAvailable, view, activeQuery, filterSize, reportData, handleToggleChange, onChange, reportName, classes, }) {
    const totalResults = reportData ? reportData.length : 0;
    const { matchType, query } = activeQuery;
    const queryOption = QUERY_OPTIONS.find((option) => option.value === matchType) || { label: '' };
    return (<div>
      <div className={classes.searchContainer}>
        <TabFilters reportType={currentReport} key={currentReport} reportName={reportName}/>
        {chartAvailable && (<ToggleButtons className={classes.toggleButtons} buttonsList={TOGGLE_BUTTONS.map(({ value, content }) => ({
        value,
        content,
        selected: view === value,
        onClick: () => handleToggleChange(value),
        classes: { root: classes.button, selected: classes.selectedToggle },
    }))}/>)}
        <ReportSizeFilter totalResults={totalResults} dataLength={filterSize} onChange={onChange}/>
      </div>
      <Typography className={classes.label}>
        <b>{totalResults}</b> Total Results {query && `for Search ${queryOption.label.toLowerCase()} ${query}`}
      </Typography>
    </div>);
});
export default FilterSection;
