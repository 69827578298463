import { __rest } from "tslib";
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = {
    root: {
        display: 'flex',
        padding: '2px 0',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
};
const DisplayWrapper = withStyles(styles)(function DisplayWrapper(_a) {
    var { className, paperClassName, classes, children } = _a, props = __rest(_a, ["className", "paperClassName", "classes", "children"]);
    return (<ListItem className={classNames(classes.root, className)} {...props}>
      <Paper className={classNames(classes.paper, paperClassName)} elevation={2}>{children}</Paper>
    </ListItem>);
});
export default DisplayWrapper;
