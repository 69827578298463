import moize from 'moize';
import State from '@/store/state';
import { createDateRangeEnding } from '@/utils/time';
var Device = State.Analytics.ReportFilters.Device;
export const DISABLE_REDIRECT_COOKIE_NAME = 'DISABLE_OLD_CC_REDIRECT';
export const CONFIG_MENU_OPENED_COOKIE_NAME = 'CC_CONFIG_MENU_OPENED';
export const NAVIGATION_MENU_OPENED_COOKIE_NAME = 'CC_NAVIGATION_MENU_OPENED';
export const PROTECTED_FORM_NAMESPACE = 'PROTECTED_FORM';
export const JSON_EDITOR_FORM_NAMESPACE = 'JSON_EDITOR';
export const WRAPPED_COMPONENT_KEY = 'wrappedComponent';
export const DEFAULT_RELEVANCY = 'Default Relevancy';
export const DEFAULT_STRATEGY = 'Default Strategy';
export const DEFAULT_AREA = 'Production';
export const PAGE_SIZE = 25;
export const DEFAULT_SKIP = 1000;
export const SESSION_EXPIRATION_TIMEOUT = 1800000;
export const SESSION_INACTIVE_TIMEOUT = 300000;
export const MAX_PRODUCTS_PAGE_SIZE = 120;
export const MAX_ITEM_LENGTH = 14;
export const MIN_DATE = new Date('2018-06-30');
export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_HOUR = 'YYYYMMDDHH';
export const DATE_FORMAT_MIN = 'YYYYMMDDHHmm';
export const DATE_FORMAT_DASH = 'YYYY-MM-DD';
export const LIMIT_FOR_PERIOD_IN_DAY = 720;
export const MAX_ANALYTICS_SELECT_RANGE = 30;
export const DEFAULT_CHANGE_LOG_SELECT_RANGE = 13;
export const DEFAULT_CACHE_TTL = 600000;
export const SEARCH_QUERY_UPDATE_DELAY = 500;
export const NUMBER_OF_RETRIES = 3;
export const RETRY_DELAY = 100;
export var Role;
(function (Role) {
    Role.MERCHANDISING = 'merchandising';
    Role.QUERY_REWRITE = 'queryRewrite';
    Role.ENGINE_CONFIGURATION = 'engineConfiguration';
    Role.ADMIN = 'admin';
    Role.ANALYTICS = 'analytics';
})(Role || (Role = {}));
export const ROLES = [
    { value: Role.MERCHANDISING, label: 'Rules' },
    { value: Role.QUERY_REWRITE, label: 'Recall & Relevancy' },
    { value: Role.ENGINE_CONFIGURATION, label: 'Configuration' },
    { value: Role.ADMIN, label: 'Admin' },
    { value: Role.ANALYTICS, label: 'Analytics 2.0' },
];
export var ModelType;
(function (ModelType) {
    ModelType.RULE = 'rule';
    ModelType.ZONE = 'zone';
    ModelType.BIASING_PROFILE = 'biasingProfile';
    ModelType.MATCH_STRATEGY = 'matchStrategy';
    ModelType.SPELLING = 'spelling';
    ModelType.SYNONYM = 'synonym';
    ModelType.STOP_WORD = 'stopWord';
    ModelType.PHRASE = 'phrase';
    ModelType.REDIRECT = 'redirect';
    ModelType.RELATED_QUERY = 'relatedQuery';
    ModelType.TEMPLATE = 'template';
    ModelType.NAVIGATION = 'navigation';
    ModelType.AREA = 'area';
    ModelType.USER = 'user';
    ModelType.KEY = 'key';
    ModelType.FILTER = 'filter';
    ModelType.CHANGE_LOG = 'changeLog';
})(ModelType || (ModelType = {}));
export const MODEL_PATHS = {
    [ModelType.RULE]: 'rules',
    [ModelType.ZONE]: 'zones',
    [ModelType.BIASING_PROFILE]: 'biasingProfiles',
    [ModelType.MATCH_STRATEGY]: 'matchStrategies',
    [ModelType.SPELLING]: 'spellings',
    [ModelType.SYNONYM]: 'synonyms',
    [ModelType.STOP_WORD]: 'stopWords',
    [ModelType.PHRASE]: 'phrases',
    [ModelType.REDIRECT]: 'redirects',
    [ModelType.RELATED_QUERY]: 'relatedQueries',
    [ModelType.TEMPLATE]: 'templates',
    [ModelType.NAVIGATION]: 'navigations',
    [ModelType.AREA]: 'areas',
    [ModelType.USER]: 'users',
    [ModelType.KEY]: 'keys',
    [ModelType.FILTER]: 'filters',
    [ModelType.CHANGE_LOG]: 'changeLog',
};
export var ChartType;
(function (ChartType) {
    ChartType.RECORD_COUNT = 'recordCount';
    ChartType.QPS = 'qps';
})(ChartType || (ChartType = {}));
export var APIType;
(function (APIType) {
    APIType.BEACON_HEALTH = 'beaconHealth';
})(APIType || (APIType = {}));
export var ChartName;
(function (ChartName) {
    ChartName["QPS"] = "qps";
    ChartName["TOTAL_QUERIES"] = "totalQueries";
    ChartName["MONTHLY_SNAPSHOT"] = "monthlySnapshot";
    ChartName["QPS_DISTRIBUTION"] = "qpsDistribution";
    ChartName["SEARCH_CONVERSION_FUNNEL"] = "searchConversionFunnel";
    ChartName["QUERY_VOLUME"] = "queryVolume";
    ChartName["ERRORS"] = "errors";
    ChartName["API_RESPONSE_TIME"] = "apiResponseTime";
})(ChartName || (ChartName = {}));
export const CHARTS = [
    ChartName.QPS,
    ChartName.TOTAL_QUERIES,
    ChartName.MONTHLY_SNAPSHOT,
    ChartName.QPS_DISTRIBUTION,
    ChartName.SEARCH_CONVERSION_FUNNEL,
    ChartName.QUERY_VOLUME,
    ChartName.ERRORS,
    ChartName.API_RESPONSE_TIME,
];
export var Routing;
(function (Routing) {
    Routing.MERCHANDISING_PATH = '/merchandising';
    Routing.MODEL_MANAGER_PATH = `${Routing.MERCHANDISING_PATH}/:model/:area?`;
    Routing.MODEL_CREATOR_PATH = `${Routing.MERCHANDISING_PATH}/:model/:area?/_create`;
    Routing.KEY_MANAGER_PATH = `${Routing.MERCHANDISING_PATH}/keys`;
    Routing.ANALYTICS_PATH = '/analytics';
    Routing.CHART_PATH = `${Routing.ANALYTICS_PATH}/:chart`;
    Routing.GLANCER_ANALYTICS_PATH = Routing.ANALYTICS_PATH;
    Routing.GLANCER_ANALYTICS_DASHBOARD_PATH = `${Routing.GLANCER_ANALYTICS_PATH}/:id`;
    Routing.ENRICH_PATH = '/enrich';
    Routing.ENRICH_TOKEN_PATH = '/enrichToken';
    Routing.LOGIN_PATH = '/login';
    Routing.SSO_LOGIN_SEGMENT = '/login/oauth2/code';
    Routing.SSO_LOGIN_PATH = `${Routing.SSO_LOGIN_SEGMENT}/:provider`;
    Routing.RELEASE_NOTES_PATH = '/release-notes';
    Routing.MAINTENANCE_PATH = '/maintenance';
    Routing.OLD_CC_PATH = '/visit-old-cc';
    let Section;
    (function (Section) {
        let Analytics;
        (function (Analytics) {
            Analytics.SEARCH = 'search';
            Analytics.MONITORING = 'monitoring';
            Analytics.CONTRACT_METRICS = 'contract-metrics';
            Analytics.BEACON = 'beacon';
        })(Analytics = Section.Analytics || (Section.Analytics = {}));
        let GlancerAnalytics;
        (function (GlancerAnalytics) {
            GlancerAnalytics.DASHBOARDS = 'dashboards';
        })(GlancerAnalytics = Section.GlancerAnalytics || (Section.GlancerAnalytics = {}));
        let Merchandising;
        (function (Merchandising) {
            Merchandising.SITE = 'site';
            Merchandising.RECALL = 'recall';
            Merchandising.CONFIG = 'config';
            Merchandising.ADMIN = 'admin';
            Merchandising.HISTORY = 'history';
        })(Merchandising = Section.Merchandising || (Section.Merchandising = {}));
        let Enrich;
        (function (Enrich) {
            // CUE-120: hide enrich dashboard
            // export const REPORTING = 'reporting';
            Enrich.LIBRARY = 'product_library';
            Enrich.RULES = 'rules';
            Enrich.TAXONOMY = 'taxonomy';
        })(Enrich = Section.Enrich || (Section.Enrich = {}));
    })(Section = Routing.Section || (Routing.Section = {}));
    let Page;
    (function (Page) {
        let Enrich;
        (function (Enrich) {
            Enrich.BY_CATEGORY = 'by-category';
            Enrich.BY_TAXONOMY = 'by-taxonomy';
            Enrich.GLOBAL_TAXONOMY = 'global-taxonomy';
            Enrich.RULE_LIBRARY = 'rule-library';
            Enrich.VALID_SECTIONS = [
                Enrich.BY_CATEGORY,
                Enrich.BY_TAXONOMY,
                Enrich.GLOBAL_TAXONOMY,
                Enrich.RULE_LIBRARY,
            ];
            Enrich.SECTION_WILDCARD = 'section';
            Enrich.SECTION_PATH = `${Routing.ENRICH_PATH}/:${Enrich.SECTION_WILDCARD}`;
            Enrich.CATEGORY_PATH = `${Routing.ENRICH_PATH}/${Enrich.BY_CATEGORY}`;
            Enrich.TAXONOMY_PATH = `${Routing.ENRICH_PATH}/${Enrich.BY_TAXONOMY}`;
            Enrich.RULE_LIBRARY_PATH = `${Routing.ENRICH_PATH}/${Enrich.RULE_LIBRARY}`;
            Enrich.PRODUCTS_WILDCARD = 'products';
            Enrich.CLASSIFICATION_PATH = `${Enrich.SECTION_PATH}/:${Enrich.PRODUCTS_WILDCARD}`;
            Enrich.CLASS_TYPE_WILDCARD = 'classificationType';
            Enrich.CLASSIFICATION_TYPE_PATH = `${Enrich.CLASSIFICATION_PATH}/:${Enrich.CLASS_TYPE_WILDCARD}`;
            let Products;
            (function (Products) {
                let Option;
                (function (Option) {
                    Option["TOTAL"] = "total";
                    Option["CLASSIFIED"] = "classified";
                    Option["UNCLASSIFIED"] = "unclassified";
                })(Option = Products.Option || (Products.Option = {}));
                Products.DEFAULT_FILTER = {
                    [Routing.Page.Enrich.BY_TAXONOMY]: Option.CLASSIFIED,
                    [Routing.Page.Enrich.BY_CATEGORY]: Option.TOTAL,
                    default: Option.TOTAL,
                };
            })(Products = Enrich.Products || (Enrich.Products = {}));
            let RuleActions;
            (function (RuleActions) {
                RuleActions.NEW = 'new';
                RuleActions.EDIT = 'edit';
            })(RuleActions = Enrich.RuleActions || (Enrich.RuleActions = {}));
            let ClassificationTypes;
            (function (ClassificationTypes) {
                let Option;
                (function (Option) {
                    Option["AUTOMATED"] = "automated-classification";
                    Option["MANUAL"] = "manual-classification";
                    Option["VALIDATION"] = "product-validation";
                    Option["JOB_MANAGEMENT"] = "job-management";
                })(Option = ClassificationTypes.Option || (ClassificationTypes.Option = {}));
                ClassificationTypes.DEFAULT_TYPE = Option.AUTOMATED;
            })(ClassificationTypes = Enrich.ClassificationTypes || (Enrich.ClassificationTypes = {}));
            Enrich.SEGMENT_TO_TEXT = {
                [Enrich.BY_CATEGORY]: 'By Category',
                [Enrich.BY_TAXONOMY]: 'By Taxonomy',
                [Enrich.GLOBAL_TAXONOMY]: 'Global Taxonomy',
                [Enrich.RULE_LIBRARY]: 'Rule Library',
                [RuleActions.NEW]: 'New',
                [RuleActions.EDIT]: 'Edit',
                [Products.Option.TOTAL]: 'Total Products',
                [Products.Option.CLASSIFIED]: 'Classified',
                [Products.Option.UNCLASSIFIED]: 'Unclassified',
                [ClassificationTypes.Option.AUTOMATED]: 'Automated Classification',
                [ClassificationTypes.Option.MANUAL]: 'Manual Classification',
                [ClassificationTypes.Option.VALIDATION]: 'Product Validation',
                [ClassificationTypes.Option.JOB_MANAGEMENT]: 'Job Management',
            };
            let ByCategory;
            (function (ByCategory) {
                ByCategory.MAIN_PATH = Enrich.CATEGORY_PATH;
                ByCategory.CLASSIFIED_PATH = `${Enrich.CATEGORY_PATH}/${Products.Option.CLASSIFIED}`;
                ByCategory.UNCLASSIFIED_PATH = `${Enrich.CATEGORY_PATH}/${Products.Option.UNCLASSIFIED}`;
                ByCategory.TOTAL_PATH = `${Enrich.CATEGORY_PATH}/${Products.Option.TOTAL}`;
                ByCategory.VALIDATION_PATH = `${ByCategory.UNCLASSIFIED_PATH}/${ClassificationTypes.Option.VALIDATION}`;
                ByCategory.JOBS_PATH = `${ByCategory.UNCLASSIFIED_PATH}/${ClassificationTypes.Option.JOB_MANAGEMENT}`;
            })(ByCategory = Enrich.ByCategory || (Enrich.ByCategory = {}));
            let ByTaxonomy;
            (function (ByTaxonomy) {
                ByTaxonomy.MAIN_PATH = Enrich.TAXONOMY_PATH;
                ByTaxonomy.CLASSIFIED_PATH = `${Enrich.TAXONOMY_PATH}/${Products.Option.CLASSIFIED}`;
            })(ByTaxonomy = Enrich.ByTaxonomy || (Enrich.ByTaxonomy = {}));
            let RuleLibrary;
            (function (RuleLibrary) {
                RuleLibrary.MAIN_PATH = Enrich.RULE_LIBRARY_PATH;
                RuleLibrary.ADD_RULE_PATH = `${Enrich.RULE_LIBRARY_PATH}/${RuleActions.NEW}`;
                RuleLibrary.EDIT_RULE_PATH = `${Enrich.RULE_LIBRARY_PATH}/${RuleActions.EDIT}`;
            })(RuleLibrary = Enrich.RuleLibrary || (Enrich.RuleLibrary = {}));
            let Pages;
            (function (Pages) {
                let ByCategory;
                (function (ByCategory) {
                    ByCategory.MAIN = 'byCategoryMain';
                    ByCategory.UNCLASSIFIED = 'byCategoryUnclassified';
                    ByCategory.CLASSIFIED = 'byCategoryClassified';
                    ByCategory.VALIDATION = 'byCategoryValidation';
                    ByCategory.JOB_MANAGEMENT = 'byCategoryJobManagement';
                })(ByCategory = Pages.ByCategory || (Pages.ByCategory = {}));
                let ByTaxonomy;
                (function (ByTaxonomy) {
                    ByTaxonomy.CLASSIFIED = 'byTaxonomyClassified';
                })(ByTaxonomy = Pages.ByTaxonomy || (Pages.ByTaxonomy = {}));
                let RuleLibrary;
                (function (RuleLibrary) {
                    RuleLibrary.MAIN = 'ruleLibraryList';
                    RuleLibrary.ADD_RULE = 'ruleLibraryAddRule';
                    RuleLibrary.EDIT_RULE = 'ruleLibraryEditRule';
                })(RuleLibrary = Pages.RuleLibrary || (Pages.RuleLibrary = {}));
            })(Pages = Enrich.Pages || (Enrich.Pages = {}));
            Enrich.PATH_TO_PAGE = {
                [ByCategory.VALIDATION_PATH]: Pages.ByCategory.VALIDATION,
                [ByCategory.JOBS_PATH]: Pages.ByCategory.JOB_MANAGEMENT,
                [ByCategory.UNCLASSIFIED_PATH]: Pages.ByCategory.UNCLASSIFIED,
                [ByCategory.CLASSIFIED_PATH]: Pages.ByCategory.CLASSIFIED,
                [ByCategory.TOTAL_PATH]: Pages.ByCategory.MAIN,
                [ByCategory.MAIN_PATH]: Pages.ByCategory.MAIN,
                [ByTaxonomy.MAIN_PATH]: Pages.ByTaxonomy.CLASSIFIED,
                [ByTaxonomy.CLASSIFIED_PATH]: Pages.ByTaxonomy.CLASSIFIED,
                [RuleLibrary.ADD_RULE_PATH]: Pages.RuleLibrary.ADD_RULE,
                [RuleLibrary.EDIT_RULE_PATH]: Pages.RuleLibrary.EDIT_RULE,
                [RuleLibrary.MAIN_PATH]: Pages.RuleLibrary.MAIN,
            };
            // Should we do this dynamically somewhere? In a utility file?
            Enrich.PAGE_TO_PATH = {
                [Pages.ByCategory.VALIDATION]: ByCategory.VALIDATION_PATH,
                [Pages.ByCategory.JOB_MANAGEMENT]: ByCategory.JOBS_PATH,
                [Pages.ByCategory.UNCLASSIFIED]: ByCategory.UNCLASSIFIED_PATH,
                [Pages.ByCategory.CLASSIFIED]: ByCategory.CLASSIFIED_PATH,
                [Pages.ByCategory.MAIN]: ByCategory.MAIN_PATH,
                [Pages.ByTaxonomy.CLASSIFIED]: ByTaxonomy.CLASSIFIED_PATH,
                [Pages.RuleLibrary.ADD_RULE]: RuleLibrary.ADD_RULE_PATH,
                [Pages.RuleLibrary.EDIT_RULE]: RuleLibrary.EDIT_RULE_PATH,
                [Pages.RuleLibrary.MAIN]: RuleLibrary.MAIN_PATH,
            };
            let PAGE_TYPES;
            (function (PAGE_TYPES) {
                PAGE_TYPES["PRODUCT"] = "product";
                PAGE_TYPES["VALIDATION"] = "validation";
                PAGE_TYPES["JOB"] = "job";
                PAGE_TYPES["RULE_LIBRARY"] = "ruleLibrary";
            })(PAGE_TYPES = Enrich.PAGE_TYPES || (Enrich.PAGE_TYPES = {}));
            Enrich.PAGE_TO_TYPE = {
                [Pages.ByCategory.VALIDATION]: PAGE_TYPES.VALIDATION,
                [Pages.ByCategory.JOB_MANAGEMENT]: PAGE_TYPES.JOB,
                [Pages.ByCategory.UNCLASSIFIED]: PAGE_TYPES.PRODUCT,
                [Pages.ByCategory.CLASSIFIED]: PAGE_TYPES.PRODUCT,
                [Pages.ByCategory.MAIN]: PAGE_TYPES.PRODUCT,
                [Pages.ByTaxonomy.CLASSIFIED]: PAGE_TYPES.PRODUCT,
                [Pages.RuleLibrary.MAIN]: PAGE_TYPES.RULE_LIBRARY,
            };
            Enrich.PAGINATION = {
                pagination: {
                    pageSize: 20,
                    offset: 0,
                },
            };
            Enrich.CONFIDENCE = {
                confidence: '0',
            };
            Enrich.QUERY = {
                query: '',
            };
            Enrich.DATE_RANGE = {
                dateRange: createDateRangeEnding(1),
            };
            Enrich.PREDICTION_STATE = {
                progress: State.Enrich.JobProgress.all,
            };
            Enrich.SUBMITTERS = {
                submitters: [],
            };
            Enrich.PRODUCT_ID = {
                productId: '',
            };
            Enrich.DEFAULT_PAGE_SETTINGS = {
                validation: Object.assign(Object.assign(Object.assign({}, Enrich.CONFIDENCE), Enrich.PAGINATION), Enrich.QUERY),
                job: Object.assign(Object.assign(Object.assign(Object.assign({}, Enrich.PAGINATION), Enrich.DATE_RANGE), Enrich.PREDICTION_STATE), Enrich.SUBMITTERS),
                product: Object.assign(Object.assign(Object.assign({}, Enrich.PAGINATION), Enrich.QUERY), Enrich.PRODUCT_ID),
                ruleLibrary: Object.assign(Object.assign({}, Enrich.PAGINATION), Enrich.QUERY),
            };
        })(Enrich = Page.Enrich || (Page.Enrich = {}));
    })(Page = Routing.Page || (Routing.Page = {}));
})(Routing || (Routing = {}));
export const DEVICE_LIST = [Device.MOBILE, Device.TABLET, Device.DESKTOP];
export const PREVIEW_CONFIG = {
    [ModelType.BIASING_PROFILE]: {
        title: 'Biasing Profile',
        component: () => null,
    },
};
export const modelTypeFromPath = moize((path) => Object.keys(MODEL_PATHS).find((key) => MODEL_PATHS[key] === path));
export const SSO_POPUP_NAME = 'sso';
export const SSO_POPUP_FEATURES = {
    azure: {
        width: 483,
        height: 600,
    },
    google: {
        width: 483,
        height: 600,
    },
};
export const ENRICH_FIELD_DISPLAY_NAMES = {
    createdAt: 'Created at',
    description: 'Description',
    id: 'Product ID',
    proprietaryId: 'External ID',
    title: 'Name',
    updatedAt: 'Updated at',
    url: 'URL',
};
