import React from 'react';
import List from '@material-ui/core/List';
import * as Page from '@/components/page';
import { ModelType, MODEL_PATHS } from '@/constants';
import { MERCHANDISING_ROUTES } from '@/routes/drawer';
import * as Selectors from '@/store/selectors';
import { connect, getSectionPath } from '@/utils';
import AreaSwitcher from '../area-switcher';
import RouteList from '../route-list';
import RouteSection from '../route-section';
import HomeRoute from './home';
import RouteLink from './link';
export const DISABLED_AREA_SWITCHER_PATHS = [
    MODEL_PATHS[ModelType.AREA],
    MODEL_PATHS[ModelType.USER],
    MODEL_PATHS[ModelType.KEY],
    MODEL_PATHS[ModelType.CHANGE_LOG],
];
export const selectors = {
    path: Selectors.activePathSelector,
};
const MerchandisingDrawer = connect(selectors)(function MerchandisingDrawer({ path }) {
    return (<React.Fragment>
      <Page.Wrapper>
        <Page.Header>{!DISABLED_AREA_SWITCHER_PATHS.includes(getSectionPath(path)) && <AreaSwitcher />}</Page.Header>
      </Page.Wrapper>
      <RouteList>
        <List dense>
          <HomeRoute />
        </List>
        {MERCHANDISING_ROUTES.sections.map((section) => (<RouteSection path={MERCHANDISING_ROUTES.path} section={section} component={RouteLink} key={section.path}/>))}
      </RouteList>
    </React.Fragment>);
});
export default MerchandisingDrawer;
