import { __rest } from "tslib";
import * as ConnectedReactRouter from 'connected-react-router';
import createPathMatcher from 'path-match';
import * as qs from 'qs';
import { call, cancel, fork, put, select, take, takeEvery, takeLatest, } from 'redux-saga/effects';
import { modelTypeFromPath, Role, Routing, } from '@/constants';
import { analyticsRoute, createModelRoute, editModelRoute, enrichRoute, modelManagerRoute, } from '@/routes';
import * as ActionCreators from '@/store/actions/creators';
import * as MerchandisingActions from '@/store/actions/merchandising';
import * as Actions from '@/store/actions/types';
import { createAction } from '@/store/actions/utils';
import * as HelperTasks from '@/store/sagas/utils';
import * as Selectors from '@/store/selectors';
import * as Utils from '@/utils';
import * as ResourceLocking from './locking';
import * as MerchandisingSaga from './merchandising';
import * as Tasks from './tasks';
export const CANCEL_ROUTE_SAGA = 'ROUTE_SAGA/CANCEL';
export const MODEL_MANAGER_RELOAD_TIMEOUT = 30000;
export const glancerAnalyticsDashboardPathMatcher = createPathMatcher({ end: false })(Routing.GLANCER_ANALYTICS_DASHBOARD_PATH);
export function cancelRouteSaga() {
    return createAction(CANCEL_ROUTE_SAGA);
}
export function enhanceRoutes(routeConfig) {
    const routeMatcher = createPathMatcher({});
    return routeConfig.map((route) => (Object.assign(Object.assign({}, route), { match: routeMatcher(route.pattern) })));
}
export const routes = enhanceRoutes([
    {
        pattern: Routing.MODEL_CREATOR_PATH,
        handler: handleModelCreation,
    },
    {
        pattern: Routing.KEY_MANAGER_PATH,
        handler: handleKeys,
    },
    {
        pattern: Routing.MODEL_MANAGER_PATH,
        handler: handleModels,
    },
]);
export function* handleModelCreation({ area }) {
    yield call(checkArea, area);
}
export function* handleKeys({ model, area }) {
    const isAuthenticated = yield select(Selectors.isAuthenticatedSelector);
    const modelType = yield call(checkModelType, model);
    yield call(checkArea, area);
    if (isAuthenticated && modelType) {
        yield call(HelperTasks.wrapContinuousSaga, handleKeyManager, [], CANCEL_ROUTE_SAGA);
    }
}
export function* handleModels({ model, area }) {
    const isAuthenticated = yield select(Selectors.isAuthenticatedSelector);
    const modelType = yield call(checkModelType, model);
    yield put(MerchandisingActions.creators.disableForm(modelType));
    yield call(checkArea, area);
    if (!isAuthenticated || !modelType)
        return;
    // eslint-disable-next-line  no-restricted-globals
    const { id } = Utils.parseSearch(location.search);
    if (yield select(Selectors.isLockablePageSelector)) {
        yield call(ResourceLocking.lockTaskManager, modelType, id, area);
    }
    if (!id)
        return;
    const activeArea = yield select(Selectors.activeAreaSelector);
    yield call(MerchandisingSaga.loadOneModel, MerchandisingActions.withArea(area || activeArea)(MerchandisingActions.creators.loadOneModel(modelType, id)));
    yield put(MerchandisingActions.creators.enableForm(modelType));
}
export function* handleKeyManager() {
    yield put(ActionCreators.loadKeys());
}
export function* wrapRouteSaga(saga, ...args) {
    const continuous = yield fork(saga, ...args);
    yield take(CANCEL_ROUTE_SAGA);
    yield cancel(continuous);
}
export function* checkArea(area) {
    if (!area)
        return;
    const activeArea = yield select(Selectors.activeAreaSelector);
    if (area !== activeArea) {
        yield put(ActionCreators.switchArea(area));
    }
}
export function* checkModelType(modelPath) {
    let modelType = yield select(Selectors.activeModelTypeSelector);
    if (!modelType) {
        modelType = modelTypeFromPath(modelPath);
        if (modelType) {
            yield put(ActionCreators.switchModelType(modelType));
        }
    }
    return modelType;
}
export function* changeLocation({ payload }) {
    const { session: { routingLoaded: isRoutingLoaded }, } = yield select();
    if (!isRoutingLoaded) {
        yield put(createAction(Actions.ROUTING_LOADED));
    }
    yield put(cancelRouteSaga());
    const { location: { pathname, state = {} }, } = payload;
    if (state.modelType) {
        const modelType = yield select(Selectors.activeModelTypeSelector);
        if (modelType !== state.modelType) {
            yield put(ActionCreators.switchModelType(state.modelType));
        }
    }
    else if (!pathname.includes('merchandising')) {
        yield put(ActionCreators.switchModelType(null));
    }
    const route = routes.find(({ match }) => !!match(pathname));
    if (!route)
        return;
    const params = route.match(pathname);
    yield call(HelperTasks.wrapContinuousSaga, route.handler, [params, payload], CANCEL_ROUTE_SAGA);
}
export function* goToManager({ payload: { modelType: overrideModelType, page = 1, filter = null, replace, merge, state, }, }) {
    let prevParams = {};
    if (merge) {
        prevParams = yield select(Selectors.activeQuerySelector);
    }
    const queryParams = qs.stringify({
        filter: filter || prevParams.filter || null,
        page: page > 1 ? page : prevParams.page ? Number(prevParams.page) : null,
    }, { skipNulls: true });
    yield call(Tasks.goTo, (modelType, area) => (replace ? ConnectedReactRouter.replace : ConnectedReactRouter.push)(`${modelManagerRoute(modelType, area)}${queryParams ? `?${queryParams}` : ''}`, Object.assign(Object.assign({}, state), { modelType })), overrideModelType);
}
export function* goToCreate(_a) {
    var _b = _a.payload, { modelType: overrideModelType } = _b, _c = _b.defaultValues, { id: _ } = _c, defaultValues = __rest(_c, ["id"]), payload = __rest(_b, ["modelType", "defaultValues"]);
    const supplementalDefaults = yield call(Tasks.getSupplementalDefaultValues, overrideModelType, payload.action);
    const enrichedDefaultValues = Object.assign(Object.assign({}, defaultValues), supplementalDefaults);
    yield call(Tasks.goTo, (modelType, area) => ConnectedReactRouter.push(createModelRoute(modelType, area), Object.assign({ defaultValues: enrichedDefaultValues, modelType, persistCurrentPage: true }, payload)), overrideModelType);
}
export function* goToEdit({ payload: { modelType: overrideModelType, area: overrideArea, id } }) {
    yield call(Tasks.goTo, (modelType, area) => ConnectedReactRouter.push(editModelRoute(modelType, overrideArea || area, id), {
        modelType,
        persistCurrentPage: true,
    }), overrideModelType);
}
/*
  Note:
  chartType refers to url path (not exclusive to charts types).
  In general, chartType can also be typed as as the set of valid chart type strings, instead of all generic strings
*/
export function* goToAnalytics({ payload: chartType }) {
    yield put(ConnectedReactRouter.push(analyticsRoute(chartType), { chartType }));
}
export function* goToEnrich({ payload: path }) {
    yield put(ConnectedReactRouter.push(enrichRoute(path), { path }));
}
export function* proxyAction({ payload: attemptedAction }) {
    const hasChanges = yield call(Tasks.anyFormsDirty);
    if (hasChanges) {
        yield put(ActionCreators.openUnsavedFormModal(attemptedAction));
    }
    else {
        yield put(attemptedAction);
    }
}
export function* dispatchAttemptedAction({ payload: attemptedAction }) {
    yield put(attemptedAction);
}
export function* goToNewArea({ payload: area }) {
    const activePath = yield select(Selectors.activePathSelector);
    const basePath = Utils.getBasePath(activePath);
    yield put(ActionCreators.switchArea(area));
    if (basePath === Role.MERCHANDISING) {
        yield put(ActionCreators.goToManager());
    }
    else if (basePath === Role.ANALYTICS && (yield select(Selectors.glancerAccessSelector))) {
        const dashboardPath = glancerAnalyticsDashboardPathMatcher(activePath);
        if (dashboardPath) {
            yield put(ActionCreators.updateGlancerEmbedUrl(''));
            yield put(ActionCreators.getGlancerEmbedUrl(dashboardPath.id));
        }
    }
}
export function* logoutSaga() {
    yield put(ActionCreators.clearAuthentication());
    yield put(ConnectedReactRouter.push('/login'));
}
export default function* routingSaga() {
    yield takeEvery(ConnectedReactRouter.LOCATION_CHANGE, changeLocation);
    yield takeLatest(Actions.GO_TO_MANAGER, goToManager);
    yield takeLatest(Actions.GO_TO_CREATE, goToCreate);
    yield takeLatest(Actions.GO_TO_EDIT, goToEdit);
    yield takeLatest(Actions.GO_TO_ENRICH, goToEnrich);
    yield takeLatest(Actions.GO_TO_ANALYTICS, goToAnalytics);
    yield takeLatest(Actions.GO_TO_NEW_AREA, goToNewArea);
    yield takeLatest(Actions.LOGOUT, logoutSaga);
    yield takeLatest(Actions.PROXY_ACTION, proxyAction);
    yield takeLatest(Actions.DISPATCH_ATTEMPTED_ACTION, dispatchAttemptedAction);
}
