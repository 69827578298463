import moize from 'moize';
import * as Actions from './types/merchandising';
import { createAction } from './utils';
export const MODEL_ACTION_TYPE = Symbol('__MODEL_ACTION__');
export const creators = Object.assign(Object.assign({}, mapActionCreators({
    createModel: (model) => createAction(Actions.CREATE_MODEL, model),
    updateModel: (id, model, isPromoteToggle) => createAction(Actions.UPDATE_MODEL, { id, model, isPromoteToggle }),
    patchModel: (id, updateFields) => createAction(Actions.PATCH_MODEL, { id, updateFields }),
    saveModel: (model, goToTable) => createAction(Actions.SAVE_MODEL, { model, goToTable }),
    togglePromotion: (id, promote) => createAction(Actions.TOGGLE_PROMOTION, { id, promote }),
    removeModel: (id) => createAction(Actions.REMOVE_MODEL, id),
    loadOneModel: (id, skipCache) => createAction(Actions.LOAD_ONE_MODEL, { id, skipCache }),
    loadAllModels: (skipCache) => createAction(Actions.LOAD_ALL_MODELS, skipCache),
    storeOneModel: (model) => createAction(Actions.STORE_ONE_MODEL, model),
    storeAllModels: (models) => createAction(Actions.STORE_ALL_MODELS, models),
    setLockStatus: (isLocked, lockOwner) => createAction(Actions.SET_MODEL_LOCK_STATUS, { isLocked, lockOwner }),
    lockModel: (id) => createAction(Actions.LOCK_MODEL, id),
    unlockModel: (id) => createAction(Actions.UNLOCK_MODEL, id),
    refreshLock: () => createAction(Actions.REFRESH_LOCK),
    setLocksById: (ids) => createAction(Actions.SET_LOCKS_BY_ID, ids),
    setUnlockInProgress: (id) => createAction(Actions.SET_UNLOCK_IN_PROGRESS, id),
    copyToArea: (id, targetArea) => createAction(Actions.COPY_TO_AREA, { id, targetArea }),
    replaceInArea: (id, targetArea) => createAction(Actions.REPLACE_IN_AREA, { id, targetArea }),
    stopHeartbeat: () => createAction(Actions.STOP_HEARTBEAT),
    reorderById: (sourceId, targetId, relationship) => createAction(Actions.REORDER_BY_ID, { sourceId, targetId, relationship }),
    reorderByIndex: (sourceIndex, targetIndex) => createAction(Actions.REORDER_BY_INDEX, { sourceIndex, targetIndex }),
}, 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
modelAction)), { enableForm: () => createAction(Actions.ENABLE_FORM), disableForm: () => createAction(Actions.DISABLE_FORM) });
// eslint-disable-next-line space-before-function-paren
export function mapActionCreators(actionCreators, wrapper) {
    return Object.keys(actionCreators).reduce((acc, key) => {
        acc[key] = wrapper(actionCreators[key]);
        return acc;
    }, {});
}
export function modelAction(creator) {
    return moize((modelType, ...args) => {
        const action = creator(...args);
        return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { modelType, [MODEL_ACTION_TYPE]: true }) });
    });
}
export function withArea(area) {
    return (action) => {
        if (area) {
            return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { area }) });
        }
        return action;
    };
}
export function isModelAction(action) {
    return action.meta && action.meta[MODEL_ACTION_TYPE];
}
