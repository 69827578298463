import { createSelector } from 'reselect';
import * as AuthSelectors from './auth';
export function authSelector({ auth }) {
    return auth;
}
export const isAuthenticatedSelector = createSelector(authSelector, ({ isAuthenticated }) => isAuthenticated);
export const authTokenSelector = createSelector(authSelector, ({ token }) => token);
export const activeUserSelector = createSelector(authSelector, ({ user }) => user);
export const customerIdSelector = createSelector(activeUserSelector, ({ customerId }) => customerId);
export const allowedAreasSelector = createSelector(activeUserSelector, ({ allowedAreas }) => allowedAreas);
export const emailSelector = createSelector(activeUserSelector, ({ email }) => email);
export const isDirtyAuthenticatedSelector = createSelector(AuthSelectors.isAuthenticatedSelector, AuthSelectors.authTokenSelector, (isAuthenticated, token) => token && !isAuthenticated);
export const externalAuthenticationProvidersSelector = createSelector(authSelector, ({ externalProviders }) => externalProviders);
