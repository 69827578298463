import { __rest } from "tslib";
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Wrapper from '@/components/form/manager/list/item/wrapper';
import { withStyles } from '@/decorators';
export function ListItemPreview(_a) {
    var { index: _index, reorder: _reorder } = _a, props = __rest(_a, ["index", "reorder"]);
    return <Wrapper {...props} component="div"/>;
}
export function ProductItemPreview(_a) {
    var { index: _index, reorder: _reorder, children } = _a, props = __rest(_a, ["index", "reorder", "children"]);
    return <div {...props}>{children}</div>;
}
export function NavigationItemPreview(_a) {
    var { index: _index, reorder: _reorder, children } = _a, props = __rest(_a, ["index", "reorder", "children"]);
    return <div {...props}>{children}</div>;
}
export const styles = {
    paper: {
        borderRadius: 0,
    },
};
export const TableRowPreview = withStyles(styles)(function TableRowPreview(_a) {
    var { index: _index, reorder: _reorder, children, classes } = _a, props = __rest(_a, ["index", "reorder", "children", "classes"]);
    return (<Wrapper {...props} component="div" paperClassName={classes.paper}>
      <Table>
        <TableBody>
          <TableRow>{children}</TableRow>
        </TableBody>
      </Table>
    </Wrapper>);
});
