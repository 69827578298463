import { __decorate } from "tslib";
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import TableCell from '@/components/cell';
import ExpansionCell from '@/components/reports/table-v2/cells/expansion';
import ExpandableRow from '@/components/reports/table-v2/expandable-row';
import { Align, Type } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
import State from '@/store/state';
var ReportKey = State.Analytics.ResultKey;
export const styles = ({ palette, spacing, table }) => ({
    tableRow: {
        '&>*': {
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.07)',
            },
        },
        '&>:nth-child(even)': {
            background: palette.primary.contrastText,
        },
    },
    tableCell: {
        fontSize: spacing(1.75),
        color: palette.mainFont,
        '&$highlight': {
            fontWeight: 'bold',
        },
    },
    content: {
        '&>*': {
            background: table.background,
        },
    },
    highlight: {},
});
export const COLUMNS_INNER = [
    {
        id: ReportKey.SKU,
        label: 'SKU',
        type: Type.MEDIUM,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'SKU',
        csvLabel: 'SKU',
    },
    {
        id: ReportKey.TITLE,
        label: 'Title',
        type: Type.LARGE,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'Title',
        csvLabel: 'Title',
    },
    {
        id: null,
        label: null,
        type: Type.SMALL,
        align: null,
        formatter: null,
        tooltip: null,
        csvLabel: null,
    },
    {
        id: ReportKey.AVG_SEARCH_POSITION,
        label: 'Avg Search Position',
        type: Type.SMALL,
        align: Align.RIGHT,
        formatter: null,
        tooltip: 'Average Search Position',
        csvLabel: 'Avg Search Position',
    },
    {
        id: ReportKey.VIEW_PRODUCTS,
        label: 'View Products',
        type: Type.SMALL,
        align: Align.RIGHT,
        formatter: null,
        tooltip: 'View Products',
        csvLabel: 'View Products',
    },
    {
        id: ReportKey.ADD_TO_CARTS,
        label: 'Add To Carts',
        type: Type.SMALL,
        align: Align.RIGHT,
        formatter: null,
        tooltip: 'Add To Carts',
        csvLabel: 'Add To Carts',
    },
    {
        id: ReportKey.ORDERS,
        label: 'Orders',
        type: Type.SMALL,
        align: Align.RIGHT,
        formatter: null,
        tooltip: 'Orders',
        csvLabel: 'Orders',
    },
];
let ProductsTableBody = class ProductsTableBody extends React.PureComponent {
    render() {
        const { columns, orderBy, data, classes, selectedRow, onExpansionClick, } = this.props;
        return (<React.Fragment>
        <TableBody className={classes.tableRow}>
          {data.map((reportingResult, index) => {
            const isSelectedRow = index === selectedRow;
            return (<React.Fragment key={index}>
                <TableRow key={`row-${index}`}>
                  {columns.map(({ id, formatter, type = Type.SMALL, align = Align.LEFT, }, cellIndex) => {
                const content = formatter ? formatter(reportingResult[id]) : reportingResult[id];
                const tableCell = (<Tooltip key={`tooltip-${id}-${index}`} title={content} placement="top-start">
                        <TableCell type={type} align={align} key={`row${index}-cell${cellIndex}`} classes={{
                    root: classNames(orderBy === id && classes.highlight, classes.tableCell),
                }}>
                          <span>{content}</span>
                        </TableCell>
                      </Tooltip>);
                return cellIndex === 1 ? (<>
                        {tableCell}
                        <ExpansionCell onClick={() => onExpansionClick(index)} rotated={isSelectedRow} showExpansionButton={true} tooltip={'View SKUs'}/>
                      </>) : (tableCell);
            })}
                </TableRow>
                {isSelectedRow && (<ExpandableRow className={classes.content} columns={COLUMNS_INNER} data={reportingResult[ReportKey.SKUS]} parentRowIndex={index}/>)}
              </React.Fragment>);
        })}
        </TableBody>
      </React.Fragment>);
    }
};
ProductsTableBody = __decorate([
    (withStyles(styles))
], ProductsTableBody);
export default ProductsTableBody;
