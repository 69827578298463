import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: spacing(1),
        boxSizing: 'border-box',
    },
    bordered: {
        border: '1px solid #e6eaee',
        borderRadius: spacing(1 / 2),
    },
});
const FormWrapper = withStyles(styles)(function FormWrapper({ bordered, className, classes, children, }) {
    return <div className={classNames(classes.root, className, { [classes.bordered]: bordered })}>{children}</div>;
});
export default FormWrapper;
