import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import MetadataIcon from '@material-ui/icons/Code';
import DevicesIcon from '@material-ui/icons/Computer';
import SearchIcon from '@material-ui/icons/Search';
import * as PropTypes from 'prop-types';
import { compose } from 'ramda';
import { withContext } from 'recompose';
import { clearFields, getFormValues, initialize, reduxForm, } from 'redux-form';
import * as voca from 'voca';
import Button from '@/components/controls/button';
import CheckboxSet from '@/components/form/controls/checkbox-set';
import TextField from '@/components/form/controls/textfield';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import FilterSection from './filter-section';
import MetadataManager from './metadata';
var Device = State.Analytics.ReportFilters.Device;
export const FORM_NAME = 'reportFilters';
export const DEVICE_OPTIONS = [Device.MOBILE, Device.TABLET, Device.DESKTOP].map((value) => ({
    value,
    label: voca.capitalize(Device.LABELS[value], true),
}));
export const CLEAR_FORM_VALUES = {
    query: null,
    devices: [],
    metadata: [],
    includeNavigationNulls: false,
};
export const styles = ({ spacing, drawer, breakpoints, appHeader, fontSize, }) => ({
    modal: {
        [breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            overflow: 'scroll',
        },
    },
    paper: {
        position: 'absolute',
        width: `calc(100vw - ${drawer.width}px - 20px)`,
        top: `${appHeader.height + spacing(1.5)}px`,
        marginRight: spacing(1.25),
        right: 0,
        fontSize: fontSize.formControl,
        borderRadius: 0,
        [breakpoints.down('sm')]: {
            position: 'absolute',
            left: 0,
            top: `${appHeader.heightMobile + spacing(1)}px`,
            margin: 'auto',
            width: '60%',
        },
    },
    metadata: {
        maxHeight: `calc(100vh - ${appHeader.height + spacing(30)}px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [breakpoints.down('md')]: {
            maxHeight: `calc(100vh - ${appHeader.height + spacing(50)}px)`,
        },
        [breakpoints.down('sm')]: {
            maxHeight: '20vh',
        },
    },
    innerSection: {
        margin: 0,
    },
    buttonContainer: {
        '& > *': {
            padding: spacing(1.5),
            '&>:first-child': {
                marginRight: spacing(2),
            },
        },
    },
});
export const getReportFilterValues = getFormValues(FORM_NAME);
export const filtersSelector = (state) => (reportType) => Selectors.reportFiltersSelector(reportType)(state);
export const selectors = {
    filtersSelector,
    formValues: getReportFilterValues,
};
export function initializeActionCreator(form, initialValues) {
    return Object.assign({ payload: {} }, initialize(form, initialValues));
}
export function clearFieldsActionCreator(form, fieldNames) {
    return Object.assign(Object.assign({}, clearFields(form, false, false, ...fieldNames)), { payload: {} });
}
export const actionCreators = {
    initialize: initializeActionCreator,
    destroyForm: ActionCreators.destroyReduxForms,
    clearFields: clearFieldsActionCreator,
    updateReportFilters: ActionCreators.updateReportFilters,
    reloadData: ActionCreators.loadReport,
};
export const mergeProps = ({ formValues, filtersSelector }, { initialize, updateReportFilters, clearFields, destroyForm, reloadData, }, { onClose, reportType }) => {
    const filters = filtersSelector(reportType);
    return {
        initialValues: filters,
        initializeForm: () => initialize(FORM_NAME, filters),
        onSubmit: () => {
            updateReportFilters(reportType, formValues || CLEAR_FORM_VALUES);
            reloadData(reportType);
            onClose();
        },
        clearFilters: () => clearFields(FORM_NAME, Object.keys(formValues)),
        closeForm: () => {
            destroyForm(FORM_NAME);
            onClose();
        },
    };
};
export const enhance = compose(connect(selectors, actionCreators, mergeProps), reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
}), withContext({ modelType: PropTypes.string }, ({ form }) => ({ modelType: form })), withStyles(styles));
const FilterModal = enhance(function FilterModal({ classes, open, closeForm, onSubmit, clearFilters, initializeForm, }) {
    return (<Modal disableAutoFocus open={open} onClose={closeForm} className={classes.modal} onRendered={initializeForm}>
      <Paper className={classes.paper} elevation={2}>
        <Grid container direction="row">
          <FilterSection title="SEARCH QUERY" icon={SearchIcon} lg={2} md={6} sm={12}>
            <Grid container item>
              <TextField name="query" placeholder="Query Term" fullWidth xs={12}/>
            </Grid>
          </FilterSection>
          <FilterSection title="DEVICE LIST" icon={DevicesIcon} lg={2} md={6} sm={12}>
            <Grid container item>
              <CheckboxSet name="devices" options={DEVICE_OPTIONS} labelClassName={classes.innerSection} column/>
            </Grid>
          </FilterSection>
          <FilterSection title="METADATA" icon={MetadataIcon} lg={8} md={12} sm={12}>
            <Grid container item className={classes.metadata}>
              <MetadataManager name="metadata" xs={12} labelClassName={classes.innerSection}/>
            </Grid>
          </FilterSection>
        </Grid>
        <Grid container justify="space-between" className={classes.buttonContainer}>
          <Grid item xs={2}>
            <Button variant="secondary" onClick={clearFilters}>
              clear
            </Button>
          </Grid>
          <Grid item container justify="flex-end" xs={9}>
            <Button onClick={closeForm}>Cancel</Button>
            <Button onClick={onSubmit} variant="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>);
});
export default FilterModal;
