import { MODEL_PATHS, Routing } from '@/constants';
import { isGlobalModelType } from '@/merchandising/models/utils';
export function modelManagerRoute(modelType, area) {
    return `${Routing.MERCHANDISING_PATH}/${MODEL_PATHS[modelType]}${isGlobalModelType(modelType) ? '' : `/${area}`}`;
}
export function createModelRoute(modelType, area) {
    return `${modelManagerRoute(modelType, area)}/_create`;
}
export function editModelRoute(modelType, area, id) {
    return `${modelManagerRoute(modelType, area)}?id=${id}`;
}
export function analyticsRoute(path) {
    return `${Routing.ANALYTICS_PATH}/${path}`;
}
export function enrichRoute(path) {
    return `${Routing.ENRICH_PATH}/${path}`;
}
