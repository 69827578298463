import { ModelType } from '@/constants';
import { safeIncludes } from '@/utils';
var RelatedQuery;
(function (RelatedQuery) {
    RelatedQuery.DEFAULT = { relatedQueries: [], promote: true };
    RelatedQuery.CONFIG = {
        type: ModelType.RELATED_QUERY,
        defaults: RelatedQuery.DEFAULT,
        filter: filterPredicate,
        promotable: true,
    };
    // eslint-disable-next-line no-inner-declarations
    function filterPredicate(filter) {
        return ({ id, name, relatedQueries }) => id.includes(filter)
            || safeIncludes(name, filter)
            || relatedQueries.some((value) => safeIncludes(value, filter));
    }
    RelatedQuery.filterPredicate = filterPredicate;
})(RelatedQuery || (RelatedQuery = {}));
export default RelatedQuery;
