import { __rest } from "tslib";
import Filter from '@/merchandising/models/filter';
import Navigation from '@/merchandising/models/navigation';
import Rule from '@/merchandising/models/rule';
import { isValidField } from '@/utils';
import * as Formatters from './merchandising';
export const PERCENTAGE = /^(\d{0,2}|100)(\.\d*)?%$/;
export const NUMBER = /^\d*$/;
export default {
    area(_a) {
        var { relaxMatchStrategyName } = _a, model = __rest(_a, ["relaxMatchStrategyName"]);
        return Object.assign(Object.assign({}, model), (relaxMatchStrategyName === false || typeof relaxMatchStrategyName !== 'string'
            ? { relaxMatchStrategyEnabled: false }
            : { relaxMatchStrategyName: relaxMatchStrategyName.trim() || null, relaxMatchStrategyEnabled: true }));
    },
    rule(_a) {
        var { template, filters, sort } = _a, model = __rest(_a, ["template", "filters", "sort"]);
        return Object.assign(Object.assign(Object.assign({}, model), (sort && isValidField(sort.field) ? { sort } : {})), { template: template && Object.assign(Object.assign({}, template), { id: null, area: model.area || null }), filters: filters
                && filters.map((filter) => (filter.type === Rule.Filter.Type.EXCLUDE_NAVIGATION_VALUE
                    ? Object.assign(Object.assign({}, filter), { type: Rule.Filter.Type.NAVIGATION_VALUE, exclude: true }) : Object.assign(Object.assign({}, filter), { exclude: false }))) });
    },
    matchStrategy(_a) {
        var { rules } = _a, model = __rest(_a, ["rules"]);
        return Object.assign(Object.assign({}, model), { rules: rules.map(formatMatchStrategyRule) });
    },
    filter(model) {
        return model.type === Filter.Type.EXCLUDE_NAVIGATION_VALUE
            ? Object.assign(Object.assign({}, model), { type: Filter.Type.NAVIGATION_VALUE, exclude: true }) : Object.assign(Object.assign({}, model), { exclude: false });
    },
    navigation(_a) {
        var { unionable } = _a, model = __rest(_a, ["unionable"]);
        if (model.type === Navigation.Type.VALUE) {
            return Object.assign({ unionable }, model);
        }
        return model;
    },
};
export function formatMatchStrategyRule(_a) {
    var { greaterThan, terms, mustMatch } = _a, rule = __rest(_a, ["greaterThan", "terms", "mustMatch"]);
    return Object.assign(Object.assign(Object.assign({}, rule), Formatters.parseMustMatch(mustMatch)), { [greaterThan ? 'termsGreaterThan' : 'terms']: terms });
}
export function parseMustMatch(rawMustMatch) {
    let mustMatch = (rawMustMatch && String(rawMustMatch).trim()) || '';
    const isPercentage = PERCENTAGE.test(mustMatch);
    if (isPercentage) {
        mustMatch = Number(mustMatch.slice(0, -1));
    }
    else if (NUMBER.test(mustMatch)) {
        mustMatch = Number(mustMatch);
    }
    return { mustMatch, percentage: isPercentage };
}
