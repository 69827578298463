import { isGlobalModelType } from '@/merchandising/models/utils';
import * as Actions from '@/store/actions/types';
import * as MerchandisingReducers from './merchandising';
export const DEFAULT = {
    global: {},
    byArea: {},
    formLoaded: false,
    locksByIds: [],
    isLocked: null,
    lockOwner: null,
    unlockInProgress: null,
};
export const NORMALIZED_DEFAULT = {
    allIds: [],
    byId: {},
    loaded: false,
};
export const AREA_MANAGER_DEFAULT = {
    allAreas: [],
    byArea: {},
};
export default function merchandisingReducer(state = DEFAULT, action) {
    switch (action.type) {
        case Actions.STORE_ONE_MODEL:
            return MerchandisingReducers.storeOneModelReducer(state, action);
        case Actions.STORE_ALL_MODELS:
            return MerchandisingReducers.storeAllModelsReducer(state, action);
        case Actions.ENABLE_FORM:
            return MerchandisingReducers.enableFormReducer(state);
        case Actions.DISABLE_FORM:
            return MerchandisingReducers.disableFormReducer(state);
        case Actions.SET_MODEL_LOCK_STATUS:
            return MerchandisingReducers.setLockStatusReducer(state, action);
        case Actions.SET_LOCKS_BY_ID:
            return MerchandisingReducers.setLocksById(state, action);
        case Actions.SET_UNLOCK_IN_PROGRESS:
            return MerchandisingReducers.setUnlockInProgress(state, action);
        default:
            return state;
    }
}
export const storeOneModelReducer = modelTransducer((state, { payload: model }) => (Object.assign(Object.assign(Object.assign({}, state), { byId: Object.assign(Object.assign({}, state.byId), { [model.id]: model }) }), (state.allIds.includes(model.id)
    ? {}
    : {
        allIds: [...state.allIds, model.id],
    }))));
export const storeAllModelsReducer = modelTransducer((state, { payload: models }) => (Object.assign(Object.assign({}, state), { loaded: true, allIds: models.map(({ id }) => id), byId: models.reduce((acc, model) => {
        acc[model.id] = model;
        return acc;
    }, {}) })));
export function enableFormReducer(state) {
    return Object.assign(Object.assign({}, state), { formLoaded: true });
}
export function disableFormReducer(state) {
    return Object.assign(Object.assign({}, state), { formLoaded: false });
}
export function setLockStatusReducer(state, { payload: { isLocked, lockOwner } }) {
    return Object.assign(Object.assign({}, state), { isLocked,
        lockOwner });
}
export function setLocksById(state, { payload: ids }) {
    return Object.assign(Object.assign({}, state), { locksByIds: ids });
}
export function setUnlockInProgress(state, { payload: id }) {
    return Object.assign(Object.assign({}, state), { unlockInProgress: id });
}
export function managerTransducer(reducer) {
    return (state, action) => {
        const isGlobal = isGlobalModelType(action.meta.modelType);
        const [stateKey, rootState, defaultState] = isGlobal
            ? ['global', state.global, NORMALIZED_DEFAULT]
            : ['byArea', state.byArea, AREA_MANAGER_DEFAULT];
        const modelExists = action.meta.modelType in rootState;
        return Object.assign(Object.assign({}, state), { [stateKey]: Object.assign(Object.assign({}, rootState), { [action.meta.modelType]: reducer(modelExists ? rootState[action.meta.modelType] : defaultState, action) }) });
    };
}
export function modelTransducer(reducer) {
    return (state, action) => {
        if (isGlobalModelType(action.meta.modelType)) {
            const globalModelExists = action.meta.modelType in state.global;
            return Object.assign(Object.assign({}, state), { global: Object.assign(Object.assign({}, state.global), { [action.meta.modelType]: reducer(globalModelExists ? state.global[action.meta.modelType] : NORMALIZED_DEFAULT, action) }) });
        }
        const modelExists = action.meta.modelType in state.byArea;
        const managerState = modelExists ? state.byArea[action.meta.modelType] : AREA_MANAGER_DEFAULT;
        const areaExists = modelExists && managerState.allAreas.includes(action.meta.area);
        return Object.assign(Object.assign({}, state), { byArea: Object.assign(Object.assign({}, state.byArea), { [action.meta.modelType]: Object.assign(Object.assign(Object.assign({}, managerState), (areaExists ? {} : { allAreas: [...managerState.allAreas, action.meta.area] })), { byArea: Object.assign(Object.assign({}, managerState.byArea), { [action.meta.area]: reducer(areaExists ? managerState.byArea[action.meta.area] : NORMALIZED_DEFAULT, action) }) }) }) });
    };
}
