import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@/decorators';
export const styles = ({ frame, zIndex }) => ({
    root: {
        backgroundColor: frame.background,
        color: frame.color,
        zIndex: zIndex.header,
    },
});
const HeaderBar = withStyles(styles)(function HeaderBar({ classes, children }) {
    return (<AppBar classes={{ colorDefault: classes.root }} color="default">
      {children}
    </AppBar>);
});
export default HeaderBar;
