import React from 'react';
import * as Page from '@/components/page';
import StaticHeader from '@/components/page/static/header';
import { withStyles } from '@/decorators';
export const styles = ({ appHeader, spacing, breakpoints }) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${spacing(8) + appHeader.height}px)`,
        boxSizing: 'border-box',
        [breakpoints.down('md')]: {
            overflowY: 'scroll',
            overflowX: 'hidden',
        },
        [breakpoints.down('sm')]: {
            height: `calc(100vh - ${spacing(8) + appHeader.heightMobile}px)`,
        },
        [breakpoints.down('xs')]: {
            height: `calc(100vh - ${spacing(8) + appHeader.heightXSMobile}px)`,
        },
    },
});
const DashboardContainer = withStyles(styles)(function DashboardContainer({ id, title, filters, classes, children, }) {
    return (<Page.Wrapper id={`${id}-chart`} darkMode>
      <StaticHeader>
        <Page.Title>{title}</Page.Title>
        {filters}
      </StaticHeader>
      <main className={classes.content}>{children}</main>
    </Page.Wrapper>);
});
export default DashboardContainer;
