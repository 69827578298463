import React from 'react';
import { withStyles } from '@/decorators';
export const styles = ({ appHeader, breakpoints }) => ({
    content: {
        position: 'absolute',
        top: appHeader.height,
        width: '100%',
        height: `calc(100vh - ${appHeader.height}px)`,
        [breakpoints.down('sm')]: {
            top: appHeader.heightMobile,
            height: `calc(100vh - ${appHeader.heightMobile}px)`,
        },
        [breakpoints.down('xs')]: {
            top: appHeader.heightXSMobile,
            height: `calc(100vh - ${appHeader.heightXSMobile}px)`,
        },
    },
});
const FrameContent = withStyles(styles)(function FrameContent({ classes, children }) {
    return <main className={classes.content}>{children}</main>;
});
export default FrameContent;
