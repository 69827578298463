import React from 'react';
import MaterialDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
import SmallIconButton from '../small-icon-button';
export const styles = ({ breakpoints, appHeader, drawer, zIndex, spacing, palette, }) => ({
    drawer: {
        '&>ul': {
            flexGrow: 0,
        },
        '&$darkMode': {
            background: '#37474F',
        },
    },
    permanent: {
        height: `calc(100vh - ${appHeader.height}px)`,
        marginTop: appHeader.height,
        width: drawer.width,
        transition: '.5s',
        [breakpoints.down('sm')]: {
            height: `calc(100vh - ${appHeader.height}px)`,
            marginTop: appHeader.height,
        },
        '&$collapsed': {
            width: 0,
        },
    },
    temporaryModal: {
        zIndex: zIndex.temporaryDrawer,
    },
    collapseToggle: {
        position: 'fixed',
        left: drawer.width,
        // center vertically with header offset
        top: `calc(${appHeader.height}px + (100vh - ${appHeader.height}px) / 2)`,
        transform: 'translate(-50%, -50%)',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: palette.primary.contrastText,
        zIndex: 1300,
        transition: 'left .5s',
        '&:hover': {
            backgroundColor: '#ebebeb',
        },
        '&$collapsed': {
            left: 0,
        },
    },
    iconLabel: {
        width: spacing(1),
        height: spacing(1),
    },
    arrowIcon: {
        transition: 'transform .5s',
        '&$collapsed': {
            transform: `translateX(${spacing(1)}px) rotate(180deg)`,
        },
    },
    collapsed: {},
    darkMode: {},
});
const Drawer = withStyles(styles)(function Drawer({ isOpen, isCollapsed, closeDrawer, toggleCollapse, darkMode, classes, children, }) {
    const mobileDrawer = (<MaterialDrawer id="drawer__mobile" classes={{ paper: classNames(classes.drawer, { [classes.darkMode]: darkMode }) }} variant="temporary" anchor="left" open={isOpen} onClose={closeDrawer} keepMounted ModalProps={{ className: classes.temporaryModal }}>
      {children}
    </MaterialDrawer>);
    return (<section id="drawer--navigation">
      <Hidden mdUp>{mobileDrawer}</Hidden>
      <Hidden smDown implementation="css">
        <SmallIconButton disableRipple disableTouchRipple onClick={toggleCollapse} className={classNames(classes.collapseToggle, { [classes.collapsed]: isCollapsed })} labelClassName={classes.iconLabel}>
          <ArrowLeftIcon className={classNames(classes.arrowIcon, { [classes.collapsed]: isCollapsed })}/>
        </SmallIconButton>
        <MaterialDrawer id="drawer__desktop" variant="permanent" open classes={{
        paper: classNames(classes.drawer, classes.permanent, { [classes.collapsed]: isCollapsed }, { [classes.darkMode]: darkMode }),
    }}>
          {children}
        </MaterialDrawer>
      </Hidden>
    </section>);
});
export default Drawer;
