import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@/components/controls/textfield';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
    },
    outlined: {
        maxWidth: spacing(6.25),
        border: `1px solid ${palette.contrastFont}`,
        borderRadius: spacing(1 / 2),
        padding: `${spacing(1 / 4)}px ${spacing(1 / 2)}px`,
        margin: `0px ${spacing(1 / 2)}px`,
    },
    label: {
        fontSize: spacing(1.5),
        color: palette.mainFont,
        marginLeft: spacing(2),
    },
});
const ReportSizeFilter = withStyles(styles)(function ReportSizeFilter({ dataLength, totalResults, onChange, classes, }) {
    const filterSize = Math.min(dataLength, totalResults);
    return (<div className={classes.root}>
      <div>
        <Typography className={classes.label}>Show Top:</Typography>
        <Grid container className={classes.outlined}>
          <TextField onChange={onChange} value={String(filterSize)} type="number" min={1} max={1000} disableUnderline simple fullWidth/>
        </Grid>
      </div>
    </div>);
});
export default ReportSizeFilter;
