import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    routeList: {
        overflowY: 'auto',
        paddingBottom: spacing(2),
    },
});
const RouteList = withStyles(styles)(function RouteList({ className, classes, children }) {
    return <div className={classNames(classes.routeList, className)}>{children}</div>;
});
export default RouteList;
