import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as Bowser } from 'bowser';
import classNames from 'classnames';
import { compose } from 'ramda';
import Drawer from '@/components/drawer';
import { withStyles, } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
const IS_SAFARI = Bowser.parse(window.navigator.userAgent).browser.name === 'Safari';
export const styles = ({ drawer, breakpoints }) => ({
    content: {
        height: '100%',
        width: '100%',
        transition: 'margin .5s, width .5s',
        '&$withDrawer': {
            [breakpoints.up('md')]: {
                marginLeft: drawer.width,
                width: `calc(100% - ${drawer.width}px)`,
            },
        },
        '&$withDrawer$safari': {
            [breakpoints.up('md')]: {
                width: `calc(100% - ${drawer.width + 16}px)`,
            },
        },
        '&$withPreviewDrawer': {
            width: '50%',
        },
    },
    withDrawer: {},
    withPreviewDrawer: {},
    safari: {},
});
export const selectors = {
    drawerIsOpen: Selectors.drawerOpenSelector,
    drawerIsCollapsed: Selectors.drawerCollapsedSelector,
    showDrawer: Selectors.showMainDrawerSelector,
    previewDrawerIsOpen: Selectors.previewDrawerOpenSelector,
};
export const actionCreators = {
    closeDrawer: ActionCreators.closeDrawer,
    collapseDrawer: ActionCreators.collapseDrawer,
    expandDrawer: ActionCreators.expandDrawer,
};
export const mergeProps = ({ drawerIsCollapsed }, { expandDrawer, collapseDrawer }) => ({
    toggleCollapse: () => (drawerIsCollapsed ? expandDrawer() : collapseDrawer()),
});
export const enhance = compose(withStyles(styles), connect(selectors, actionCreators, mergeProps));
const DrawerContainer = enhance(function DrawerContainer({ component: Component, drawerIsOpen, drawerIsCollapsed, previewDrawerIsOpen, showDrawer, closeDrawer, toggleCollapse, darkMode, classes, children, }) {
    return (<React.Fragment>
      {showDrawer && (<Drawer isOpen={drawerIsOpen} isCollapsed={drawerIsCollapsed || previewDrawerIsOpen} toggleCollapse={toggleCollapse} closeDrawer={closeDrawer} darkMode={darkMode}>
          <Component />
        </Drawer>)}
      <section className={classNames(classes.content, {
        [classes.withPreviewDrawer]: previewDrawerIsOpen,
        [classes.withDrawer]: showDrawer && !drawerIsCollapsed && !previewDrawerIsOpen,
        [classes.safari]: IS_SAFARI,
    })}>
        {children}
      </section>
    </React.Fragment>);
});
export default DrawerContainer;
