import React from 'react';
import { withStyles } from '@/decorators';
import Footer from '.';
export const styles = ({ footer, breakpoints }) => ({
    content: {
        minHeight: `calc(100% - ${footer.height}px)`,
        [breakpoints.down('sm')]: {
            minHeight: `calc(100% - ${footer.heightMobile}px)`,
        },
    },
});
const FooterContainer = withStyles(styles)(function FooterContainer({ classes, children }) {
    return (<React.Fragment>
      <main className={classes.content}>{children}</main>
      <Footer />
    </React.Fragment>);
});
export default FooterContainer;
