import React from 'react';
import { compose } from 'ramda';
import Alert from '@/components/alert';
import Timer from '@/components/timer';
import * as MerchandisingActions from '@/store/actions/merchandising';
import { withArea } from '@/store/area-context';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import { RESOURCE_LOCK_DURATIONS } from '.';
export const selectors = {
    currentId: Selectors.currentIdSelector,
    modelType: Selectors.activeModelTypeSelector,
};
export const lockResource = (modelType, area, id) => MerchandisingActions.withArea(area)(MerchandisingActions.creators.lockModel(modelType, id));
export const unlockResource = (modelType, area, id) => MerchandisingActions.withArea(area)(MerchandisingActions.creators.unlockModel(modelType, id));
export const actionCreators = {
    lockResource,
    unlockResource,
};
export const mergeProps = ({ currentId, modelType }, { unlockResource, lockResource }, { reset, area }) => ({
    lockResource: () => {
        reset();
        lockResource(modelType, area, currentId);
    },
    unlockResource: () => unlockResource(modelType, area, currentId),
});
export const enhance = compose(withArea, connect(selectors, actionCreators, mergeProps));
const IdleAlert = enhance(function IdleAlert({ lockResource, unlockResource }) {
    return (<Alert open title="Edit Access Expiring Soon" primaryButton={{ onClick: lockResource, label: 'KEEP EDITING' }}>
      You will lose access in <Timer timeout={RESOURCE_LOCK_DURATIONS.MODAL_TO_UNLOCK} onComplete={unlockResource}/>{' '}
      due to inactivity. Any unsaved changes will be lost.
    </Alert>);
});
export default IdleAlert;
