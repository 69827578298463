import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { FormCell } from '@/components/cell';
import SmallIconButton from '@/components/small-icon-button';
const FormItem = function FormItem({ onSave, children }) {
    return (<React.Fragment>
      <FormCell>
        <SmallIconButton onClick={onSave}>
          <CheckIcon />
        </SmallIconButton>
      </FormCell>
      {children}
      <FormCell />
    </React.Fragment>);
};
export default FormItem;
