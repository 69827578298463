import State from '@/store/state';
var ResultKey = State.Analytics.ResultKey;
export function formatData(data, maxVal) {
    return data.map((result) => ({
        x: result[ResultKey.EXPLORATION_SCORE],
        y: result[ResultKey.CONVERSION_SCORE],
        r: generateRadius(result[ResultKey.UNIQUE_SEARCHES], maxVal),
    }));
}
export function generateRadius(val, maxVal) {
    if (maxVal <= 0) {
        return 0;
    }
    const minRadius = 5;
    const maxRadius = 50;
    const minArea = calcArea(minRadius);
    const maxArea = calcArea(maxRadius);
    const percentage = val / maxVal;
    const area = Math.ceil(maxArea * percentage + minArea);
    return Math.round(Math.sqrt(area / Math.PI));
}
export function calcArea(radius) {
    return Math.round((Math.PI * (radius ** 2)) / 5);
}
export function popularSearchesLabelCallback({ index }, { datasets }) {
    const chartData = datasets[0].data[index];
    const reportData = datasets[0].rawGBData[index];
    const conversionScore = chartData.y.toFixed(1);
    const explorationScore = chartData.x.toFixed(1);
    const CTR = reportData[ResultKey.UNIQUE_VIEW_PRODUCT_RATIO].toFixed(1);
    const ATCR = reportData[ResultKey.UNIQUE_ADD_TO_CART_RATIO].toFixed(1);
    const CVR = reportData[ResultKey.UNIQUE_ORDER_RATIO].toFixed(1);
    return [`CTR: ${CTR}%`, `ATCR: ${ATCR}%`, `CVR: ${CVR}%`, `CS: ${conversionScore}`, `ES: ${explorationScore}`];
}
export function popularSearchesTitleCallback(tooltip, { datasets, labels }) {
    const term = labels[tooltip[0].index];
    const uniqueSearches = datasets[0].rawGBData[tooltip[0].index][ResultKey.UNIQUE_SEARCHES].toLocaleString();
    return [`Term: ${term}`, `Searches (#): ${uniqueSearches}`];
}
