import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'ramda';
import TextField from '@/components/form/controls/textfield';
import createFormManager from '@/components/form/manager';
import { withStyles } from '@/decorators';
import { wrapFormSection } from '@/utils';
export const styles = ({ spacing }) => ({
    fieldWrapper: {
        margin: `0 ${spacing(6)}px 0 ${spacing(2)}px`,
    },
    displayWrapper: {
        padding: spacing(1),
    },
});
export const enhance = compose(wrapFormSection, withStyles(styles));
export const MetadataForm = enhance(function MetadataForm({ classes, }) {
    return (<Grid container item xs={12} className={classes.fieldWrapper}>
      <Grid container item xs={6}>
        <TextField name="key" placeholder="Key" simple fullWidth xs={10} md={8}/>
      </Grid>
      <Grid container item xs={6}>
        <TextField name="value" placeholder="Value" simple fullWidth xs={10} md={8}/>
      </Grid>
    </Grid>);
});
export const MetadataDisplay = withStyles(styles)(function MetadataDisplay({ value: { key, value }, classes, }) {
    return (<Grid container item xs={12} className={classes.displayWrapper}>
      <Grid item container xs={6}>
        {key}
      </Grid>
      <Grid container item xs={6}>
        {value}
      </Grid>
    </Grid>);
});
const MetadataManager = createFormManager({
    form: MetadataForm,
    display: MetadataDisplay,
});
export default MetadataManager;
