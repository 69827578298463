import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableDisplay from '.';
import Form from '../form';
import { connectForm } from '../utils';
class ConnectedTableFormManager extends React.Component {
    render() {
        const { columns, fields, editingIndex, formComponent: FormComponent, displayComponent, reorderable, onEdit, onSave, onAdd, onChange, onReorder, formName, defaultValues, } = this.props;
        const ConnectedForm = connectForm(formName, defaultValues, Form);
        return (<React.Fragment>
        <ConnectedForm onAdd={onAdd} onChange={onChange}>
          <Table>
            <TableBody>
              <TableRow>
                <FormComponent member="value"/>
              </TableRow>
            </TableBody>
          </Table>
        </ConnectedForm>
        <TableDisplay columns={columns} fields={fields} editingIndex={editingIndex} formComponent={FormComponent} displayComponent={displayComponent} reorderable={reorderable} onEdit={onEdit} onReorder={onReorder} onSave={onSave}/>
      </React.Fragment>);
    }
}
export default ConnectedTableFormManager;
