import { __decorate } from "tslib";
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import IconButton from '@/components/controls/icon-button';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    button: {
        position: 'static',
    },
    dense: {
        padding: spacing(1),
    },
    denseMenuItem: {
        padding: `${spacing(1)}px ${spacing(1 / 2)}px`,
        '&>div:first-child': {
            paddingLeft: spacing(2),
        },
    },
    denseText: {
        '&>*': {
            fontSize: spacing(1.75),
        },
    },
});
let ActionMenu = class ActionMenu extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            anchor: null,
            isOpen: false,
        };
        this.openMenu = (event) => this.setState({ isOpen: true, anchor: event.currentTarget });
        this.closeMenu = () => this.setState({ isOpen: false });
    }
    render() {
        const { id: modelId, actions, value, index, dense, classes, } = this.props;
        const id = `report__actions--${modelId}`;
        return (<div>
        <IconButton disableRipple onClick={this.openMenu} aria-owns={id} aria-label="Actions" aria-haspopup="true" className={classNames(classes.button, { [classes.dense]: dense })}>
          <MoreIcon />
        </IconButton>
        <Menu id={id} anchorEl={this.state.anchor} open={this.state.isOpen} onClose={this.closeMenu}>
          {actions.map(({ label, callback }) => (<MenuItem onClick={() => {
            this.closeMenu();
            callback(value, index);
        }} classes={{ root: classes.denseMenuItem }} key={label}>
                <ListItemText primary={label} classes={{ root: dense && classes.denseText }}/>
              </MenuItem>))}
        </Menu>
      </div>);
    }
};
ActionMenu = __decorate([
    (withStyles(styles))
], ActionMenu);
export default ActionMenu;
