import React from 'react';
import RefAppIcon from '@material-ui/icons/Computer';
import { REFAPP_URL } from '@/client/api';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import MenuItem from '../menu-item';
export const selectors = {
    activeArea: Selectors.activeAreaSelector,
    keys: Selectors.keysSelector,
};
const RefAppMenuItem = connect(selectors)(function RefAppMenuItem({ activeUser, activeArea, keys, }) {
    const url = `${REFAPP_URL}/index.html#customerId=${activeUser.customerId}&clientKey=${keys.primaryKey}&area=${activeArea}`;
    return (<MenuItem onClick={() => window.open(url, '_blank')}>
      <RefAppIcon />
      Reference App
    </MenuItem>);
});
export default RefAppMenuItem;
