import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var Filter;
(function (Filter) {
    let Type;
    (function (Type) {
        Type.NAVIGATION_VALUE = 'Navigation_Value';
        Type.EXCLUDE_NAVIGATION_VALUE = 'Excluded_Navigation_Value';
    })(Type = Filter.Type || (Filter.Type = {}));
    Filter.TYPES = [
        { value: Type.NAVIGATION_VALUE, label: 'Filtered by' },
        { value: Type.EXCLUDE_NAVIGATION_VALUE, label: 'Filter by exclusion' },
    ];
    Filter.DEFAULT = {
        promote: true,
        type: Type.NAVIGATION_VALUE,
    };
    Filter.CONFIG = {
        type: ModelType.FILTER,
        defaults: Filter.DEFAULT,
        filter: filterByNameAndId,
        promotable: true,
    };
})(Filter || (Filter = {}));
export default Filter;
