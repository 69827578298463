import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var MatchStrategy;
(function (MatchStrategy) {
    let PartialMatchRule;
    (function (PartialMatchRule) {
        PartialMatchRule.DEFAULT = { greaterThan: false, percentage: false };
    })(PartialMatchRule = MatchStrategy.PartialMatchRule || (MatchStrategy.PartialMatchRule = {}));
    MatchStrategy.DEFAULT = { rules: [] };
    MatchStrategy.CONFIG = {
        type: ModelType.MATCH_STRATEGY,
        defaults: MatchStrategy.DEFAULT,
        filter: filterByNameAndId,
    };
})(MatchStrategy || (MatchStrategy = {}));
export default MatchStrategy;
