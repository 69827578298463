import * as Actions from '@/store/actions/types';
import State from '@/store/state';
import * as CurrentReportReducers from './current-report';
var ReportType = State.Analytics.ReportType;
export const DEFAULT = ReportType.PERFORMANCE;
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.UPDATE_CURRENT_REPORT:
            return CurrentReportReducers.updateCurrentReportReducer(action);
        default:
            return state;
    }
};
export function updateCurrentReportReducer({ payload: currentReport }) {
    return currentReport;
}
