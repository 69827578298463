import React from 'react';
import { compose } from 'ramda';
import TableCell from '@/components/cell';
import { Align, Type } from '@/components/reports/table-v2/types';
import ActionMenu from '@/components/reports/table/action-menu';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    dense: {
        padding: `${spacing(1 / 2)}px ${spacing(1)}px`,
    },
});
export const enhance = compose(withStyles(styles));
export const ActionsCell = enhance(({ reportingResult, key, actions, classes, }) => (<TableCell type={Type.SMALL} align={Align.RIGHT} key={key} className={classes.dense}>
      <ActionMenu actions={actions} value={reportingResult} dense/>
    </TableCell>));
export default ActionsCell;
