import React from 'react';
import classNames from 'classnames';
import { compose } from 'ramda';
import { disabling } from '@/components/disabling';
import { withStyles, } from '@/decorators';
export const styles = ({ palette }) => ({
    root: {
        color: palette.mainFont,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            color: palette.accentColours[900],
        },
        '&$lightColor': {
            color: palette.common.white,
        },
        '&$disabled': {
            color: palette.disabledFont,
            pointerEvents: 'none',
            cursor: 'default',
        },
    },
    lightColor: {},
    disabled: {},
});
const enhance = compose(disabling, withStyles(styles));
const Link = enhance(function Link({ onClick = () => null, href, target, lightColor, classes, children, disabled, }) {
    return (<a className={classNames(classes.root, { [classes.lightColor]: lightColor }, { [classes.disabled]: disabled })} href={href} onClick={onClick} target={target}>
      {children}
    </a>);
});
export default Link;
