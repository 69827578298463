export const LOAD_SEARCH_PREVIEW_PRODUCTS = 'SEARCH_PREVIEW_PRODUCTS:LOAD';
export const LOAD_PUSH_TO_TOP_PRODUCTS = 'PUSH_TO_TOP_PRODUCTS:LOAD';
export const LOAD_RESULT_MANAGEMENT_PRODUCTS = 'RESULT_MANAGEMENT_PRODUCTS:LOAD';
export const UPDATE_SEARCH_PREVIEW_CONFIG = 'SEARCH_PREVIEW:UPDATE_CONFIG';
export const UPDATE_SEARCH_PREVIEW_PRODUCTS = 'SEARCH_PREVIEW:UPDATE_PRODUCTS';
export const UPDATE_SEARCH_PREVIEW_QUERY = 'SEARCH_PREVIEW:UPDATE_QUERY';
export const RESET_SEARCH_PREVIEW_QUERY = 'SEARCH_PREVIEW:RESET_QUERY';
export const UPDATE_SEARCH_PREVIEW_ERROR = 'SEARCH_PREVIEW:UPDATE_ERROR';
export const UPDATE_PUSH_TO_TOP_PRODUCTS = 'PUSH_TO_TOP:UPDATE_PRODUCTS';
export const UPDATE_RESULT_MANAGEMENT_PRODUCTS = 'RESULT_MANAGEMENT:UPDATE_PRODUCTS';
export const UPDATE_RESTRICT_TO_IDS_PRODUCTS = 'RESTRICT_TO_IDS:UPDATE_PRODUCTS';
export const UPDATE_SEARCH_PREVIEW_QUERY_TYPE = 'SEARCH_PREVIEW:UPDATE_SEARCH_PREVIEW_QUERY_TYPE';
export const MODEL_FORM_UPDATE = 'MODEL_FORM:UPDATE';
