import moize from 'moize';
import { hasEnrichAccess } from '@/config/feature-flags';
import { Routing } from '@/constants';
const ROUTES = [
    { path: Routing.MERCHANDISING_PATH, label: 'Merchandising', roles: [] },
    { path: Routing.ANALYTICS_PATH, label: 'Analytics', roles: [] },
    ...(hasEnrichAccess() ? [{ path: Routing.ENRICH_PATH, label: 'Enrich', roles: [] }] : []),
    { path: Routing.RELEASE_NOTES_PATH, label: 'Release Notes', roles: [] },
];
export default ROUTES;
export const addHandlers = moize((routes, onClick) => routes.map((route) => (Object.assign(Object.assign({}, route), { onClick: () => onClick(route.path) }))));
