import React from 'react';
import Tab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import { compose } from 'ramda';
import * as Selectors from '@/store/selectors';
import { withStyles, } from '@/decorators';
import { connect, getBasePath } from '@/utils';
export const styles = ({ spacing, frame, palette }) => ({
    tab: {
        height: spacing(8),
        color: frame.color,
    },
    indicator: {
        height: (spacing(2)) / 3,
        backgroundColor: palette.highlight,
    },
});
export const selectors = {
    activePath: Selectors.activePathSelector,
    activeUser: Selectors.activeUserSelector,
};
export const enhance = compose(withStyles(styles), connect(selectors));
const Tabs = enhance(function Tabs({ routes, id, activePath, activeUser, classes, }) {
    const basePath = getBasePath(activePath);
    const activeRoute = routes.findIndex(({ path }) => basePath === path.substring(1));
    return (<MaterialTabs id={`tabs--${id}`} value={activeRoute === -1 ? 0 : activeRoute} onChange={null} TabIndicatorProps={{ className: classes.indicator }}>
      {routes
        .filter(({ roles }) => roles.every((role) => activeUser.allowedAccess.includes(role)))
        .map(({ path, label, onClick }) => (<Tab classes={{ root: classes.tab }} label={label} {...(onClick ? { onClick } : { href: path, target: '_blank', rel: 'noopener' })} key={path}/>))}
    </MaterialTabs>);
});
export default Tabs;
