import { call, fork, put } from 'redux-saga/effects';
import * as ActionCreators from '@/store/actions/creators';
import * as Tasks from '../tasks';
export function* loadBeaconHealthcheckTask() {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        yield call(refreshBeaconHealthchecks);
    }
    catch (e) {
        yield Tasks.simpleError('load beacon healthcheck', e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* runBeaconQuickcheckTask({ payload: quickcheckId }) {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const analyticsClient = yield call(Tasks.authClient);
        const requestPayload = yield call(Tasks.createQuickcheckRequest, quickcheckId);
        yield call(analyticsClient.analyticsV2.runQuickcheck, requestPayload);
        yield call(refreshBeaconHealthchecks);
    }
    catch (e) {
        yield Tasks.simpleError('run beacon quickcheck', e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* refreshBeaconHealthchecks() {
    const analyticsClient = yield call(Tasks.authClient);
    const requestPayload = yield call(Tasks.createHealthcheckRequest);
    const { result } = yield call(analyticsClient.analyticsV2.healthcheck, requestPayload);
    yield put(ActionCreators.refreshBeaconHealthcheck(result.checks));
}
