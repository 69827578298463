import * as ConnectedReactRouter from 'connected-react-router';
import { Routing } from '@/constants';
import * as Actions from '../types/router';
import { createAction } from '../utils';
export const goToNewArea = (area) => createAction(Actions.GO_TO_NEW_AREA, area);
export const logout = () => createAction(Actions.LOGOUT);
export const goToLoginWithNext = (pathname, search) => ConnectedReactRouter.replace(`${Routing.LOGIN_PATH}?next=${pathname}${search ? encodeURIComponent(search) : ''}`);
export const goToManager = (modelType = null, opts = {}, state = {}) => createAction(Actions.GO_TO_MANAGER, Object.assign(Object.assign({ modelType }, opts), { state }));
export const goToAnalytics = (chartType) => createAction(Actions.GO_TO_ANALYTICS, chartType);
export const goToEnrich = (path) => createAction(Actions.GO_TO_ENRICH, path);
export const goToCreate = (modelType = null, defaultValues = {}, action = 'create') => createAction(Actions.GO_TO_CREATE, { modelType, defaultValues, action });
export const goToEdit = (modelType, id, area) => createAction(Actions.GO_TO_EDIT, { modelType, area, id });
