import * as ConnectedReactRouter from 'connected-react-router';
import { call, delay, put, race, select, spawn, take, } from 'redux-saga/effects';
import { NetworkException } from '@/client/fetch';
import { ModelType } from '@/constants';
import { RESOURCE_LOCK_DURATIONS } from '@/merchandising/model-manager/resource-lock-monitor';
import * as ActionCreators from '@/store/actions/creators';
import { creators as merchActionCreators, withArea } from '@/store/actions/merchandising';
import * as Actions from '@/store/actions/types';
import * as Tasks from '@/store/sagas/tasks';
import * as UtilTasks from '@/store/sagas/utils';
import * as Selectors from '@/store/selectors';
import * as ResourceLocking from './locking';
export const LOCK_POLL_TIMEOUT = 30 * 1000;
export function* getBulkLockStatus(modelType, area) {
    var _a;
    const client = yield call(Tasks.crudClient, modelType, area);
    try {
        const result = yield call(Tasks.authCall, client.bulkLockStatus);
        return (_a = result === null || result === void 0 ? void 0 : result.ids) !== null && _a !== void 0 ? _a : [];
    }
    catch (e) {
        yield Tasks.error('Fetching locks failed.', e);
        return [];
    }
}
export function* checkLockStatus(id, modelType, area) {
    const client = yield call(Tasks.crudClient, modelType, area);
    try {
        return yield call(Tasks.authCall, client.lockStatus, id);
    }
    catch (e) {
        return { locked: false, lockOwner: null };
    }
}
export function* unlockModel(modelId, modelType, area) {
    const client = yield call(Tasks.crudClient, modelType, area);
    try {
        return yield call(Tasks.authCall, client.unlock, modelId);
    }
    catch (e) {
        return null;
    }
}
export function* modelLockManager(modelType, id, area) {
    const result = yield call(checkLockStatus, id, modelType, area);
    const { locked: isLocked, owner: lockOwner } = result;
    const setLockStatus = (locked, owner) => withArea(area)(merchActionCreators.setLockStatus(modelType, locked, owner));
    const activeUser = yield select(Selectors.activeUserSelector);
    yield spawn(UtilTasks.wrapContinuousSaga, listenForUnlockOnLocationChange, [modelType, id, area]);
    if (isLocked) {
        yield put(setLockStatus(isLocked, lockOwner));
        const isOwnedByActiveUser = yield select(Selectors.isModelLockOwnerSelector);
        if (!isOwnedByActiveUser) {
            return yield spawn(UtilTasks.wrapContinuousSaga, lockPoll, [id, modelType], ConnectedReactRouter.LOCATION_CHANGE);
        }
        return yield spawn(UtilTasks.wrapContinuousSaga, lockHeartbeat, [modelType, id, area, lockOwner], Actions.STOP_HEARTBEAT);
    }
    const lockedSuccesfully = yield call(lockModel, modelType, id, area);
    if (lockedSuccesfully) {
        yield put(setLockStatus(true, activeUser.email));
        const unlockAction = withArea(area)(merchActionCreators.unlockModel(modelType, id));
        yield spawn(unlockOnLogout, unlockAction);
        return yield spawn(UtilTasks.wrapContinuousSaga, lockHeartbeat, [modelType, id, area, lockOwner], Actions.STOP_HEARTBEAT);
    }
    yield delay(1000);
    return yield call(modelLockManager, modelType);
}
export function* unlockOnLogout(unlockAction) {
    yield race([
        call(function* () {
            yield take(Actions.UNLOCK_MODEL);
        }),
        call(UtilTasks.spawnOnTake, Actions.LOGOUT, unlockModelTask, unlockAction),
        call(UtilTasks.spawnOnTake, Actions.EXPIRE_TOKEN, unlockModelTask, unlockAction),
    ]);
}
export function* bulkLockManager(modelType, area) {
    const result = yield call(getBulkLockStatus, modelType, area);
    const unlockInProgressId = yield select(Selectors.unlockInProgressSelector);
    yield put(merchActionCreators.setLocksById(modelType, result.filter((v) => v !== unlockInProgressId)));
}
export function* lockTaskManager(modelType, id, area) {
    if (yield select(Selectors.isLockableFormSelector)) {
        return yield call(modelLockManager, modelType, id, area);
    }
    const activeModelType = yield select(Selectors.activeModelTypeSelector);
    const isNoLockModel = activeModelType === ModelType.KEY || activeModelType === ModelType.CHANGE_LOG;
    if (!isNoLockModel && (yield select(Selectors.isLockableListSelector))) {
        return yield call(bulkLockManager, modelType, area);
    }
}
export function* lockPoll(id, modelType) {
    const area = yield select(Selectors.activeAreaSelector);
    while (true) {
        const result = yield call(checkLockStatus, id, modelType, area);
        const { locked: isLocked } = result;
        if (!isLocked) {
            yield put(ActionCreators.updateLockBanner(true));
            return;
        }
        yield delay(RESOURCE_LOCK_DURATIONS.POLL_DEBOUNCE);
    }
}
export function* lockHeartbeat(modelType, id, area) {
    while (true) {
        yield take(Actions.REFRESH_LOCK);
        yield call(lockModel, modelType, id, area);
    }
}
export function* lockModel(modelType, id, area) {
    const client = yield call(Tasks.crudClient, modelType, area);
    try {
        yield call(Tasks.authCall, client.lock, id);
        return true;
    }
    catch (e) {
        if (e instanceof NetworkException && e.statusCode === 404) {
            return false;
        }
        return null;
    }
}
export function* listenForUnlockOnLocationChange(modelType, id, area) {
    yield take(ConnectedReactRouter.LOCATION_CHANGE);
    const isLocked = yield select(Selectors.modelLockStatusSelector);
    const isOwnedByActiveUser = yield select(Selectors.isModelLockOwnerSelector);
    if (isLocked && isOwnedByActiveUser) {
        const unlockModelAction = withArea(area)(merchActionCreators.unlockModel(modelType, id));
        yield call(unlockModelTask, unlockModelAction);
        const locksByIds = yield select(Selectors.locksByIdsSelector);
        return yield put(merchActionCreators.setLocksById(modelType, locksByIds.filter((v) => v !== id)));
    }
    yield put(ActionCreators.updateLockBanner(false));
    yield call(clearLockState, modelType, area);
}
export function* clearLockState(modelType, area) {
    yield put(withArea(area)(merchActionCreators.setLockStatus(modelType, false, null)));
}
export function* lockModelTask({ payload: id, meta: { modelType, area } }) {
    yield call(lockModel, modelType, id, area);
}
export function* unlockModelTask({ payload: id, meta: { modelType, area } }) {
    yield put(merchActionCreators.setUnlockInProgress(modelType, id));
    yield call(unlockModel, id, modelType, area);
    yield put(merchActionCreators.stopHeartbeat(modelType));
    // setTimeout is used to prevent the race condition
    // where the API request for bulkLockStatus returns this model as being locked incorrectly
    setTimeout(ResourceLocking.clearUnlockInProgress(id, modelType), 5000);
    yield call(clearLockState, modelType, area);
}
export function clearUnlockInProgress(currentUnlockInProgressId, modelType) {
    return function* () {
        const newUnlockInProgress = yield select(Selectors.unlockInProgressSelector);
        if (currentUnlockInProgressId === newUnlockInProgress) {
            yield put(merchActionCreators.setUnlockInProgress(modelType, null));
        }
    };
}
