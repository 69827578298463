import { __decorate } from "tslib";
import React from 'react';
import List from '@material-ui/core/List';
import { disabling } from '@/components/disabling';
import { DraggableListItem } from '@/components/drag/item';
import Link from '@/components/link';
import { withStyles } from '@/decorators';
import DisplayItem from './item/display';
import FormItem from './item/form';
import ListItemWrapper from './item/wrapper';
export const styles = ({ spacing }) => ({
    listActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: `${spacing(1 / 2)}px 0 ${spacing(2)}px 0`,
    },
});
let FormManagerList = class FormManagerList extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { showAll: false };
        this.handleRemove = (index) => () => {
            const { fields: { remove, getAll }, limit, } = this.props;
            remove(index);
            if (getAll().length - 1 <= limit) {
                this.setState({ showAll: false });
            }
        };
        this.handleEdit = (index) => () => this.props.onEdit(index);
        this.onToggleShowAll = () => this.setState({ showAll: !this.state.showAll });
    }
    render() {
        const { fields, editingIndex, onReorder, onSave, reorderable, formComponent: FormComponent, displayComponent: DisplayComponent, listClassName, sort, limit, disabled, classes, } = this.props;
        const { showAll } = this.state;
        const hasLimit = limit > 1;
        if (fields.length === 0) {
            return null;
        }
        const fieldValues = fields.getAll().map((value, index) => ({ index, value }));
        const sorted = sort ? sort(fieldValues) : fieldValues;
        const subset = hasLimit && !showAll ? sorted.slice(0, limit) : sorted;
        return (<React.Fragment>
        <List className={listClassName}>
          {subset.map(({ index }) => {
            const section = `${fields.name}[${index}]`;
            const itemProps = (reorderable && editingIndex === -1 && !disabled
                ? {
                    index,
                    component: DraggableListItem,
                    reorder: onReorder,
                }
                : {});
            return (<ListItemWrapper {...itemProps} key={index}>
                {editingIndex === index ? (<FormItem onSave={onSave} onRemove={this.handleRemove(index)}>
                    <FormComponent member={section}/>
                  </FormItem>) : (<DisplayItem onEdit={this.handleEdit(index)} onRemove={this.handleRemove(index)} key={index}>
                    <DisplayComponent value={fields.get(index)}/>
                  </DisplayItem>)}
              </ListItemWrapper>);
        })}
        </List>
        {hasLimit && sorted.length > limit && (<div className={classes.listActions}>
            <Link onClick={this.onToggleShowAll}>{showAll ? 'Show Fewer' : 'Show More'}</Link>
          </div>)}
      </React.Fragment>);
    }
};
FormManagerList = __decorate([
    disabling,
    (withStyles(styles))
], FormManagerList);
export default FormManagerList;
