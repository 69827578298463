import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles } from '@/decorators';
export const styles = {
    highlighted: {
        fontWeight: 300,
    },
    regular: {
        fontWeight: 500,
    },
};
const Suggestion = withStyles(styles)(function Suggestion({ suggestion: { label }, query, isHighlighted, classes, }) {
    const matches = match(label, query);
    const parts = parse(label, matches);
    return (<MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (<strong key={`${part.text}-${index}`} className={part.highlight ? classes.highlighted : classes.regular}>
              {part.text}
            </strong>))}
      </div>
    </MenuItem>);
});
export default Suggestion;
