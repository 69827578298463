import { popularSearchesLabelCallback, popularSearchesTitleCallback } from './utils';
const PADDING = 40;
const GRID_LINE_BACKGROUND = 'rgba(0,0,0,0.04)';
const GRID_LINE = 'rgba(0,0,0,0.3)';
const GRID_LINE_CONFIG = [
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
    GRID_LINE_BACKGROUND,
];
// eslint-disable-next-line import/prefer-default-export
export const OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
        mode: 'nearest',
    },
    layout: {
        padding: PADDING,
    },
    legend: {
        display: false,
        labels: {
            fontSize: 14,
        },
    },
    scales: {
        yAxes: [
            {
                id: 'y-axis',
                scaleLabel: {
                    display: true,
                    labelString: 'Conversion Score',
                },
                ticks: {
                    beginAtZero: true,
                    max: 100,
                },
                gridLines: {
                    color: GRID_LINE_CONFIG,
                    drawOnChartArea: true,
                },
            },
        ],
        xAxes: [
            {
                id: 'x-axis',
                scaleLabel: {
                    labelString: 'Exploration Score',
                    display: true,
                },
                ticks: {
                    beginAtZero: true,
                    max: 100,
                },
                gridLines: {
                    color: GRID_LINE_CONFIG,
                    drawOnChartArea: true,
                },
            },
        ],
    },
    tooltips: {
        displayColors: false,
        titleFontSize: 14,
        callbacks: {
            label: popularSearchesLabelCallback,
            title: popularSearchesTitleCallback,
        },
    },
};
