import { destroy } from 'redux-form';
import * as Actions from '../types';
import { createAction } from '../utils';
export * from './analytics';
export * from './auth';
export * from './beaconing';
export * from './cache';
export * from './change-log';
export * from './collections';
export * from './glancer-analytics';
export * from './router';
export * from './search-preview';
export * from './session';
export * from './enrich';
export * from './ui';
export const setCopyToAreaTargetArea = (area) => createAction(Actions.SET_COPY_TO_AREA_TARGET, area);
export const setDuplicateModelId = (duplicateModelId) => createAction(Actions.SET_DUPLICATE_MODEL_ID, duplicateModelId);
export const promoteArea = (source, target) => createAction(Actions.PROMOTE_AREA, { source, target });
export const modelFormUpdate = (formName) => createAction(Actions.MODEL_FORM_UPDATE, formName);
export const openUnsavedFormModal = (action) => createAction(Actions.OPEN_UNSAVED_FORM_MODAL, action);
export const proxyAction = (attemptedAction) => createAction(Actions.PROXY_ACTION, attemptedAction);
export const dispatchAttemptedAction = (action) => createAction(Actions.DISPATCH_ATTEMPTED_ACTION, action);
export const cancelAttemptedAction = () => createAction(Actions.CANCEL_ATTEMPTED_ACTION);
export const destroyReduxForms = (...form) => (Object.assign(Object.assign({}, destroy(...form)), { payload: {} }));
export const receiveMessageEvent = (event) => createAction(Actions.RECEIVE_MESSAGE_EVENT, event);
