import * as Actions from '@/store/actions/types';
import { normalize } from '@/utils';
import * as GlancerAnalyticsReducers from './glancer-analytics';
export const DEFAULT = {
    dashboards: {
        allIds: [],
        byId: {},
    },
    embedUrl: undefined,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.UPDATE_GLANCER_DASHBOARD_LIST:
            return GlancerAnalyticsReducers.updateDashboards(state, action);
        case Actions.UPDATE_GLANCER_EMBED_URL:
            return GlancerAnalyticsReducers.updateEmbedUrl(state, action);
        default:
            return state;
    }
};
export function updateDashboards(state, { payload: dashboards }) {
    return Object.assign(Object.assign({}, state), { dashboards: normalize(dashboards) });
}
export function updateEmbedUrl(state, { payload: embedUrl }) {
    return Object.assign(Object.assign({}, state), { embedUrl });
}
