import collections from '@/store/sagas/collections';
import analytics from './analytics';
import auth from './auth';
import changeLog from './change-log';
import enrich from './enrich';
import keys from './keys';
import glancerAnalytics from './glancer-analytics';
import merchandising from './merchandising';
import promote from './promote';
import routing from './routing';
import searchPreview from './search-preview';
import ui from './ui';
export default [
    analytics,
    auth,
    changeLog,
    collections,
    enrich,
    keys,
    glancerAnalytics,
    merchandising,
    promote,
    routing,
    searchPreview,
    ui,
];
