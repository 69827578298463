/* eslint-disable import/prefer-default-export */
import { Routing } from '@/constants';
const defaultPageSettings = Routing.Page.Enrich.DEFAULT_PAGE_SETTINGS;
export function getEnrichDefaultSettingsPages() {
    const pageNames = [...new Set(Object.values(Routing.Page.Enrich.PATH_TO_PAGE))];
    return Object.fromEntries(pageNames.map((name) => {
        const pageType = Routing.Page.Enrich.PAGE_TO_TYPE[name];
        const settings = defaultPageSettings[pageType];
        return [name, settings];
    }));
}
