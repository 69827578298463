import React from 'react';
import { Route, Switch } from 'react-router';
import { Routing } from '@/constants';
import loadable from '@/components/loadable';
function LoggedOutRouter() {
    return (<Switch>
      <Route path={Routing.SSO_LOGIN_PATH} component={loadable(() => import('@/pages/sso-login' /* webpackChunkName: "sso-login" */))}/>
      <Route path={Routing.LOGIN_PATH} component={loadable(() => import('@/pages/login' /* webpackChunkName: "login" */))}/>
      <Route component={loadable(() => import('@/pages/logging-in' /* webpackChunkName: "logging-in" */))}/>
    </Switch>);
}
export default LoggedOutRouter;
