import React from 'react';
import { push } from 'connected-react-router';
import Alert from '@/components/alert';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const selectors = {
    location: Selectors.activeLocationSelector,
};
export const actionCreators = {
    refreshPage: push,
};
export const mergeProps = ({ location }, { refreshPage }) => ({
    refreshPage: () => refreshPage(location),
});
const TimedOutAlert = connect(selectors, actionCreators, mergeProps)(function TimedOutAlert({ refreshPage }) {
    return (<Alert open title="Edit Access Lost" primaryButton={{ onClick: refreshPage, label: 'REFRESH PAGE' }}>
      You lost edit access due to inactivity and lost all unsaved changes. Click Refresh to get edit access again.
    </Alert>);
});
export default TimedOutAlert;
