import GoogleAnalyticsGtag, { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'connected-react-router';
import { createMiddleware } from 'redux-beacon';
import { GOOGLE_ANALYTICS_ID } from '@/config/global';
import * as Actions from './actions/types';
export const VRB_CATEGORY = 'visual_rule_builder';
export const GA_EVENTS_MAP = {
    [LOCATION_CHANGE]: trackPageView(({ payload: { pathname } }) => ({
        path: pathname,
    })),
    [Actions.ACKNOWLEDGE_AUTHENTICATION]: trackEvent(({ payload: { user: { customerId } } }) => ({
        action: 'login',
        category: 'auth',
        label: customerId,
    })),
    [Actions.BEACON_VRB_EVENT]: trackEvent(({ payload: { action, label } }) => (Object.assign({ action, category: VRB_CATEGORY }, (label ? { label } : {})))),
};
export function createGaMiddleware() {
    const ga = GoogleAnalyticsGtag(GOOGLE_ANALYTICS_ID);
    return createMiddleware(GA_EVENTS_MAP, ga);
}
export default [createGaMiddleware()];
