export const Y_AXIS_CONFIG = [
    { digits: 9, symbol: 'B' },
    { digits: 6, symbol: 'M' },
    { digits: 3, symbol: 'K' },
];
export const DATA_SET_PROPS = {
    type: 'line',
    fill: false,
    lineTension: 0,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 2,
    borderWidth: 2,
};
export const OPTIONS = {
    maintainAspectRatio: false,
    responsive: true,
    hover: {
        mode: 'nearest',
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        bodySpacing: 6,
        callbacks: {
            // otherwise labelColor will only appears if hovered on the point
            labelColor: (tooltipItem, chart) => {
                const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                return {
                    borderColor: dataset.backgroundColor,
                    backgroundColor: dataset.backgroundColor,
                };
            },
        },
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
    },
    scales: {
        yAxes: {
            type: 'linear',
            position: 'left',
            gridLines: {
                drawBorder: false,
            },
            scaleLabel: { fontSize: 10 },
            ticks: {
                beginAtZero: true,
                fontSize: 9,
                callback: (value, _, values) => formatYAxis(value, values[0]),
            },
        },
        xAxes: {
            gridLines: { display: false },
            scaleLabel: { display: false, fontSize: 10 },
            ticks: {
                fontSize: 9,
            },
        },
    },
    legend: {
        labels: {
            boxWidth: 30,
            padding: 16,
            fontSize: 10,
        },
    },
    animation: {
        duration: 400,
        easing: 'linear',
    },
};
export function getDataSet(label, colour, length, dataSetProps = DATA_SET_PROPS) {
    return Object.assign(Object.assign({}, dataSetProps), { label, borderColor: colour, pointHoverBackgroundColor: colour, pointHoverBorderColor: colour, pointBorderColor: length > 1 ? 'transparent' : colour, pointBackgroundColor: length > 1 ? 'transparent' : colour });
}
export function formatter(value) {
    return value.toLocaleString();
}
export function getYAxisDividend(maxValue) {
    return Y_AXIS_CONFIG.find(({ digits }) => String(maxValue).length > digits) || null;
}
export function formatYAxis(currentValue, maxValue) {
    const dividend = getYAxisDividend(maxValue);
    if (maxValue <= 5) {
        return `${currentValue.toFixed(1)}`;
    }
    if (dividend === null || currentValue === 0) {
        return currentValue;
    }
    return `${currentValue / (10 ** dividend.digits)}${dividend.symbol}`;
}
