import { __rest } from "tslib";
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import { compose } from 'ramda';
import { withStyles } from '@/decorators';
import { disabling } from './disabling';
export const styles = ({ spacing }) => ({
    root: {
        padding: spacing(1),
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    left: {
        textAlign: 'left',
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    small: {
        width: spacing(12),
        '&:first-child': {
            padding: `${spacing(1)}px ${spacing(1)}px ${spacing(1)}px ${spacing(3)}px`,
        },
    },
    large: {
        width: 'auto',
        '&:first-child': {
            paddingLeft: spacing(3),
        },
    },
    medium: {
        width: spacing(24),
        padding: `0 ${spacing(2)}px`,
    },
});
const Cell = withStyles(styles)(function Cell(_a) {
    var { type, align, classes, children } = _a, props = __rest(_a, ["type", "align", "classes", "children"]);
    return (<TableCell classes={{
        root: classNames(classes.root, classes[type], classes[align]),
    }} {...props}>
      {children}
    </TableCell>);
});
export default Cell;
export const formCellStyles = ({ spacing, palette }) => ({
    root: {
        whiteSpace: 'nowrap',
        width: '5%',
        borderBottom: 0,
        padding: `${spacing(1)}px ${spacing(1)}px`,
        '&:first-child': {
            padding: `${spacing(1 / 2)}px ${spacing(1)}px ${spacing(1 / 2)}px ${spacing(2)}`,
        },
        '&:last-child': {
            padding: `${spacing(1 / 2)}px ${spacing(2)}px ${spacing(1 / 2)}px ${spacing(1)}`,
        },
        '&$disabled': {
            color: palette.disabledFont,
        },
    },
    small: { width: '25%' },
    medium: { width: '30%' },
    large: { width: '50%' },
    fullWidth: { width: '100%' },
    monospace: {
        fontFamily: '"Source Code Pro", "monospace"',
    },
    disabled: {},
});
export const enhance = compose(disabling, withStyles(formCellStyles));
export const FormCell = enhance(function FormCell({ size, monospace, classes, children, disabled, }) {
    return (<TableCell classes={{
        root: classNames(classes.root, classes[size], {
            [classes.monospace]: monospace,
            [classes.disabled]: disabled,
        }),
    }}>
      {children}
    </TableCell>);
});
