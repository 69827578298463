import React from 'react';
import Alert from '@/components/alert';
import { withStyles } from '@/decorators';
export const styles = {
    email: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
};
const LandingAlert = withStyles(styles)(function LandingAlert({ classes, onOkay, email }) {
    return (
    /* eslint-disable react/no-unescaped-entities */
    <Alert open title="Can’t Edit Resource Yet" primaryButton={{ onClick: onOkay, label: 'ENTER VIEW MODE' }}>
      You have read-only access as <span className={classes.email}>"{email}" </span> is currently editing this resource.
      We will display a message on this page as soon as the resource is open for editing.
    </Alert>
    /* eslint-enable react/no-unescaped-entities */
    );
});
export default LandingAlert;
