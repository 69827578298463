import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as Actions from '@/store/actions/types';
import refreshDashboard from './dashboards';
import * as Healthcheck from './healthcheck';
import * as Insights from './insights';
import * as Reports from './reports';
export default function* analyticsSaga() {
    yield takeLatest(Actions.REFRESH_DASHBOARD, refreshDashboard);
    yield takeLatest(Actions.LOAD_REPORT, Reports.loadReportTask);
    yield takeLatest(Actions.LOAD_INSIGHTS, Insights.loadInsightsTask);
    yield takeLatest(Actions.LOAD_BEACON_HEALTHCHECK, Healthcheck.loadBeaconHealthcheckTask);
    yield takeEvery(Actions.RUN_BEACON_QUICKCHECK, Healthcheck.runBeaconQuickcheckTask);
}
