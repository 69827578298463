import State from '@/store/state';
import apiClient from './api';
import inMemoryClient from './in-memory';
import { IN_MEMORY_DB } from '@/config/global';
export default (IN_MEMORY_DB ? inMemoryClient : apiClient);
export var Client;
(function (Client) {
    let Analytics;
    (function (Analytics) {
        let QuickcheckStatus;
        (function (QuickcheckStatus) {
            QuickcheckStatus["PASS"] = "pass";
            QuickcheckStatus["WARN"] = "warn";
            QuickcheckStatus["FAIL"] = "fail";
            QuickcheckStatus["NODATA"] = "nodata";
        })(QuickcheckStatus = Analytics.QuickcheckStatus || (Analytics.QuickcheckStatus = {}));
        let DerivedQuickcheckStatus;
        (function (DerivedQuickcheckStatus) {
            DerivedQuickcheckStatus["SUCCESS_UNHEALTHY"] = "successUnhealthy";
            DerivedQuickcheckStatus["SUCCESS_FAILURE"] = "successFailure";
        })(DerivedQuickcheckStatus = Analytics.DerivedQuickcheckStatus || (Analytics.DerivedQuickcheckStatus = {}));
    })(Analytics = Client.Analytics || (Client.Analytics = {}));
})(Client || (Client = {}));
