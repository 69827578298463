import React from 'react';
import 'brace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import './polyfills';
import './styles.css';
import { compose } from 'ramda';
import { hot } from 'react-hot-loader/root';
import AppFrame from '@/components/frame';
import FrameContent from '@/components/frame/content';
import ConfirmationAlert from './components/confirmation-alert';
import DragLayer from './components/drag/layer';
import Header from './components/header';
import InactivityMonitor from './components/inactivity-monitor';
import Notifications from './components/notifications';
import PreviewDrawer from './components/preview-drawer';
import Router from './components/router';
import LoggedOutRouter from './components/router/logged-out';
import * as Selectors from './store/selectors';
import { connect } from './utils';
export const selectors = {
    isAuthenticated: Selectors.isAuthenticatedSelector,
};
export const enhance = compose(hot, connect(selectors));
const App = enhance(function App({ isAuthenticated }) {
    return (<AppFrame>
      <Header />
      <Notifications />
      <FrameContent>
        {isAuthenticated ? (<React.Fragment>
            <Router />
            <InactivityMonitor />
          </React.Fragment>) : (<LoggedOutRouter />)}
      </FrameContent>
      <DragLayer />
      <PreviewDrawer />
      <ConfirmationAlert />
    </AppFrame>);
});
export default App;
