import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ palette, spacing, fontSize }) => ({
    root: {
        display: 'flex',
        padding: `${spacing(1 / 4)}px ${spacing(3)}px 0`,
        borderBottom: `1px solid ${palette.grey[300]}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: spacing(7.5),
        boxSizing: 'border-box',
        background: '#fff',
        '&$darkMode': {
            borderBottom: 'none',
        },
    },
    small: {
        fontSize: fontSize.pageHeaderContent,
    },
    darkMode: {},
});
const PageHeader = withStyles(styles)(function PageHeader({ small, className, darkMode, classes, children, }) {
    return (<div id="page__header" className={classNames(classes.root, { [classes.small]: small, [classes.darkMode]: darkMode }, className)}>
      {children}
    </div>);
});
export default PageHeader;
