import { __decorate, __rest } from "tslib";
import React from 'react';
import moize from 'moize';
import BaseAutocomplete from '@/components/controls/autocomplete/base';
import { ModelType } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import { withArea } from '@/store/area-context';
import DependencyWrapper from '@/store/containers/dependency';
import * as Selectors from '@/store/selectors';
import { connect2, formatId } from '@/utils';
import * as ProductAttributeModule from './product-attributes';
export const actionCreators = {
    fetchProductAttributes: ActionCreators.fetchProductAttributes,
};
export const selectors = {
    productAttributes: Selectors.productAttributesSelector,
};
let ProductAttributes = class ProductAttributes extends React.PureComponent {
    render() {
        const _a = this.props, { suggestions, productAttributes, fetchProductAttributes, area, numeric, refineable, sortable, form } = _a, props = __rest(_a, ["suggestions", "productAttributes", "fetchProductAttributes", "area", "numeric", "refineable", "sortable", "form"]);
        const filteredProductAttributes = ProductAttributeModule.filterProductAttributes(productAttributes, this.props);
        const filteredSuggestions = convertToSuggestions(filteredProductAttributes);
        return (<DependencyWrapper to={ModelType.NAVIGATION} overrideArea={area}>
        {(navigations) => (<BaseAutocomplete id={`${formatId(form)}-product-attributes`} options={ProductAttributeModule.addDisplayName(navigations)(filteredSuggestions)} {...props}/>)}
      </DependencyWrapper>);
    }
    componentDidMount() {
        this.props.fetchProductAttributes();
    }
};
ProductAttributes = __decorate([
    withArea,
    connect2(selectors, actionCreators)
], ProductAttributes);
/**
 * Inserts a label for all given suggestions.
 * The display name from the corresponding navigation is appended to the label as applicable.
 * This function is curried, accepting the navigations first, then the suggestions in the returned function.
 */
export const addDisplayName = moize((navigations) => {
    const navigationsByName = {};
    navigations.forEach((navigation) => {
        navigationsByName[navigation.name] = navigation;
    });
    return moize((suggestions) => (suggestions.map((_a) => {
        var { name } = _a, suggestion = __rest(_a, ["name"]);
        const navigation = navigationsByName[name];
        return Object.assign(Object.assign({}, suggestion), { name, label: navigation ? `${name} (${navigation.displayName})` : name });
    })));
});
const memoizedFilterProductAttributes = moize((productAttributes, isNumeric, isRefineable, isSortable) => productAttributes.filter(({ numeric, sort, raw }) => {
    let shouldInclude = true;
    if (isNumeric !== undefined) {
        shouldInclude = shouldInclude && numeric === isNumeric;
    }
    if (isRefineable !== undefined) {
        shouldInclude = shouldInclude && raw === isRefineable;
    }
    if (isSortable !== undefined) {
        shouldInclude = shouldInclude && sort === isSortable;
    }
    return shouldInclude;
}));
export function filterProductAttributes(productAttributes, { numeric, refineable, sortable }) {
    return memoizedFilterProductAttributes(productAttributes, numeric, refineable, sortable);
}
export const convertToSuggestions = moize((productAttributes) => (productAttributes.map((productAttribute) => ({
    name: productAttribute.name,
}))));
export default ProductAttributes;
