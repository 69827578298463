import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LegacyMuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as UnstatedProvider } from 'unstated';
import * as url from 'url';
import App from './app';
import { GTM_ID } from '@/config/global';
import createStore from './store';
import customTheme from './theme';
const history = createBrowserHistory({
    basename: url.parse(document.querySelector('base').href).path.slice(0, -1),
});
const store = createStore(history);
const theme = createMuiTheme(customTheme);
const rootEl = document.getElementById('app');
TagManager.initialize({
    gtmId: GTM_ID,
});
ReactDOM.render(<DndProvider backend={HTML5Backend}>
    <LegacyMuiThemeProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={theme}>
          <UnstatedProvider>
            <ReduxProvider store={store}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </ReduxProvider>
          </UnstatedProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </LegacyMuiThemeProvider>
  </DndProvider>, rootEl);
