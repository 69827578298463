import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { compose } from 'ramda';
import { createModelName } from '@/analytics/dashboards/shared/utils';
import Button from '@/components/controls/button';
import { ModelType } from '@/constants';
import { withStyles, } from '@/decorators';
import Rule from '@/merchandising/models/rule';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
export const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '1em',
        borderBottom: 'solid 1px silver',
    },
    button: {
        marginLeft: 'auto',
        fontSize: spacing(1.5),
        padding: `${spacing(1)}px ${spacing(1.5)}px`,
        minHeight: `${spacing(4)}px`,
    },
    title: {
        marginLeft: spacing(1 / 2),
    },
    closeIcon: {
        height: spacing(2.75),
        width: spacing(2.75),
        cursor: 'pointer',
        padding: '0 0.25em',
        marginLeft: '0.25em',
        color: 'grey',
        '&:hover': {
            color: 'black',
        },
    },
});
export const actionCreators = {
    createModel: ActionCreators.goToCreate,
};
export const mergeProps = (_, { createModel }, { title }) => ({
    createNewRule: () => createModel(ModelType.RULE, {
        name: createModelName('TopSearch', 'Query', title),
        triggerSets: [[{ value: title, type: Rule.Trigger.Type.SEARCH_MATCHES }]],
    }),
});
const enhance = compose(withStyles(styles), connect(null, actionCreators, mergeProps));
const Header = enhance(function Header({ title, classes, createNewRule, onClose, }) {
    return (
    /* eslint-disable react/no-unescaped-entities */
    <div className={classes.root}>
      <span>
        Insights for
        <strong className={classes.title}>'{title}'</strong>
      </span>
      <Button className={classes.button} onClick={createNewRule} variant="primary">
        Create A Rule
      </Button>
      <CloseIcon onClick={onClose} className={classes.closeIcon}/>
    </div>
    /* eslint-enable react/no-unescaped-entities */
    );
});
export default Header;
