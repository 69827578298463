import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, appHeader, breakpoints }) => ({
    root: {
        height: `calc(100vh - ${appHeader.height}px - ${spacing(19.5)}px)`,
        [breakpoints.down('sm')]: {
            height: `calc(100vh - ${appHeader.heightMobile}px - ${spacing(19.5)}px)`,
        },
    },
    icon: {
        fontSize: spacing(20),
    },
});
const PreviewError = withStyles(styles)(function PreviewError({ error, errorClassName, classes }) {
    return (<Grid container item direction="column" justify="center" alignItems="center" className={classNames(classes.root, errorClassName)}>
      <ErrorIcon color="secondary" className={classes.icon}/>
      <Typography variant="body1">{error}</Typography>
    </Grid>);
});
export default PreviewError;
