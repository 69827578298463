import { propEq } from 'ramda';
import { ModelType } from '@/constants';
import * as Utils from '@/merchandising/model-manager/utils';
import { filterByIdAnd } from '@/utils';
import { Sort, } from '.';
var Navigation;
(function (Navigation) {
    let Type;
    (function (Type) {
        Type["VALUE"] = "Value";
        Type["RANGE"] = "Range";
        Type["DYNAMIC_RANGE"] = "Dynamic_Range";
    })(Type = Navigation.Type || (Navigation.Type = {}));
    Navigation.TYPE_OPTIONS = [
        {
            value: Type.VALUE,
            label: Type.VALUE,
        },
        {
            value: Type.RANGE,
            label: Type.RANGE,
        },
        {
            value: Type.DYNAMIC_RANGE,
            label: 'Dynamic Range',
        },
    ];
    let SortField;
    (function (SortField) {
        SortField["COUNT"] = "Count";
        SortField["VALUE"] = "Value";
    })(SortField = Navigation.SortField || (Navigation.SortField = {}));
    Navigation.SORTS = [
        { value: { field: SortField.COUNT, order: Sort.Order.ASCENDING }, label: 'Count Ascending' },
        { value: { field: SortField.COUNT, order: Sort.Order.DESCENDING }, label: 'Count Descending' },
        { value: { field: SortField.VALUE, order: Sort.Order.ASCENDING }, label: 'Value Ascending' },
        { value: { field: SortField.VALUE, order: Sort.Order.DESCENDING }, label: 'Value Descending' },
    ];
    Navigation.DEFAULT = {
        type: Type.VALUE,
        sort: { field: SortField.COUNT, order: Sort.Order.DESCENDING },
        ranges: [],
        metadata: [],
        pinnedRefinements: [],
    };
    Navigation.CONFIG = {
        type: ModelType.NAVIGATION,
        defaults: Navigation.DEFAULT,
        filter: filterByIdAnd(['name', 'displayName']),
        sort: Utils.sortWithPush(Utils.PushSortDirection.DOWN, propEq('bulkImported', true), Utils.sortByPriority),
        isDisabled: disabledPredicate,
        prioritized: true,
    };
    // eslint-disable-next-line no-inner-declarations
    function disabledPredicate({ bulkImported }) {
        return bulkImported;
    }
})(Navigation || (Navigation = {}));
export default Navigation;
