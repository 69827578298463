import { __awaiter, __rest } from "tslib";
import { Routing } from '@/constants';
import { formatQueryParam } from '@/utils';
import * as API from './api';
import fetch from './fetch';
import AnalyticsFormatters from './formatters/analytics';
import ModelFormatters from './formatters/merchandising';
import Parsers from './parsers';
import * as Utils from './utils';
import { API_HOST, API_PATH, API_PORT, DYNAMIC_API, ENRICH_API_PREFIX, } from '@/config/global';
export const BASE_URL = `http${window.location.protocol === 'https:' ? 's' : ''}://${DYNAMIC_API ? window.location.host : API_HOST}${API_PORT ? `:${API_PORT}` : ''}`;
export const REFAPP_URL = 'http://refapp.groupbycloud.com';
export const TEMPLATE_IMAGES_URL = `${BASE_URL}/templateImages`;
export const JSON_HEADERS = { 'Content-Type': 'application/json' };
export const AUTH_TOKEN_HEADER = 'authentication';
export var StreamAdmin;
(function (StreamAdmin) {
    StreamAdmin.API_URL = `${BASE_URL}/api/v2`;
    StreamAdmin.COLLECTIONS_PATH = 'collections';
    StreamAdmin.GROVE_URL = `${BASE_URL}/admin/v2/grove`;
})(StreamAdmin || (StreamAdmin = {}));
export var Admin;
(function (Admin) {
    Admin.API_URL = `${BASE_URL}/admin/v2`;
    Admin.COLLECTIONS_PATH = 'collections';
    Admin.SSO_PROVIDERS_PATH = 'openidproviders';
})(Admin || (Admin = {}));
export var Images;
(function (Images) {
    Images.API_URL = `${BASE_URL}/templateImages`;
    Images.UPLOAD_PATH = 'upload';
})(Images || (Images = {}));
export var Merchandising;
(function (Merchandising) {
    Merchandising.API_URL = `${BASE_URL}${API_PATH || ''}`;
    Merchandising.ADMIN_PATH = 'admin';
    Merchandising.AREA_PATH = `${Merchandising.ADMIN_PATH}/area`;
    Merchandising.AREA_PROMOTE_PATH = `${Merchandising.AREA_PATH}/_promote`;
    Merchandising.USER_PATH = `${Merchandising.ADMIN_PATH}/user`;
    Merchandising.AUTH_VALIDATION_PATH = `${Merchandising.USER_PATH}/_validate`;
    Merchandising.PASSWORD_VALIDATION_PATH = 'password/validate';
    Merchandising.KEY_PATH = `${Merchandising.ADMIN_PATH}/key`;
    Merchandising.LOGIN_PATH = 'login';
    Merchandising.PRODUCT_ATTRIBUTES_PATH = 'autocomplete/fields';
    Merchandising.PRODUCT_ATTRIBUTE_VALUES_PATH = 'autocomplete/values';
    Merchandising.SEARCH_PREVIEW_PATH = 'proxy/search';
    Merchandising.CHANGE_LOG_PATH = 'changeLog';
    Merchandising.CHANGE_LOG_STATUS_PATH = 'changeLog/status';
})(Merchandising || (Merchandising = {}));
export var Analytics;
(function (Analytics) {
    Analytics.API_URL = `${BASE_URL}/wisdom/v2`;
    Analytics.API_V2_URL = `${BASE_URL}/api/analytics`;
    Analytics.OPERATIONS_URL = `${Analytics.API_V2_URL}/operations`;
    Analytics.PERFORMANCE_URL = `${Analytics.API_V2_URL}/performance`;
    Analytics.RECOMMENDATIONS_URL = `${Analytics.API_URL}/recommendations`;
    Analytics.TOP_REFINEMENTS_PATH = 'refinements/_getPopular';
    Analytics.CLICKTHROUGHS_PATH = 'search/metrics';
    Analytics.SERVICES_PATH = 'services';
    Analytics.TIMESERIES_PATH = 'queries/timeseries';
    Analytics.EVENT_HEALTHCHECK_URL = `${Analytics.API_URL}/events/healthchecks`;
    Analytics.RECORDS_PATH = 'records';
    Analytics.RECORDS_TIMESERIES_PATH = 'records/timeseries';
    Analytics.RESPONSE_TIME = 'queries/responseTime';
    Analytics.SEARCH_TERM_REPORTING_URL = `${Analytics.API_V2_URL}/v1/searches`;
    Analytics.PERFORMANCE_PATH = 'performance';
    Analytics.NULLS_PATH = 'nulls';
    Analytics.PRODUCTS_PATH = 'products';
    Analytics.NAVIGATIONS_PATH = 'navigations';
    Analytics.EVENT_QUICKCHECK_URL = `${Analytics.EVENT_HEALTHCHECK_URL}/quickcheck`;
    Analytics.RUN_QUICKCHECK_URL = `${Analytics.EVENT_QUICKCHECK_URL}/run`;
    Analytics.STATUS_QUICKCHECK_URL = `${Analytics.EVENT_QUICKCHECK_URL}/status`;
})(Analytics || (Analytics = {}));
export var GlancerAnalytics;
(function (GlancerAnalytics) {
    GlancerAnalytics.API_URL = `${BASE_URL}/admin/v2/analytics`;
    GlancerAnalytics.DASHBOARDS_PATH = 'folders/default';
    GlancerAnalytics.EMBED_PATH = 'embedSsoUrl';
})(GlancerAnalytics || (GlancerAnalytics = {}));
export var Enrich;
(function (Enrich) {
    Enrich.API_URL = 'https://console.edgecaseprod.com/api/v1';
    Enrich.CLIENT_PRODUCTS_REPORTS_PATH = 'clientProductsReports';
    Enrich.CUSTOMERS_PATH = 'customers';
    Enrich.AUTH_URL = `${BASE_URL}/admin/v2/enrichToken`;
    Enrich.CC_ENRICH_API_URL = ENRICH_API_PREFIX;
})(Enrich || (Enrich = {}));
export function models(token) {
    return (area, name) => {
        const path = `${area}/${name}`;
        const modelParser = Utils.arrayParser(Parsers[name]);
        const modelFormatter = ModelFormatters[name];
        return {
            get: Utils.authenticated(token, getModel(path, modelParser)),
            find: Utils.authenticated(token, findModels(path, modelParser)),
            create: (comment, parentId) => Utils.authenticated(token, createModel(path, modelFormatter, comment, parentId)),
            update: (comment, parentId) => Utils.authenticated(token, updateModel(path, modelFormatter, comment, parentId)),
            patch: (comment, parentId) => Utils.authenticated(token, patchModel(path, comment, parentId)),
            remove: Utils.authenticated(token, removeModel(path)),
            bulkLockStatus: Utils.authenticated(token, getBulkLockStatus(path)),
            lockStatus: Utils.authenticated(token, modelLockStatus(path)),
            lock: Utils.authenticated(token, lockModel(path)),
            unlock: Utils.authenticated(token, unlockModel(path)),
            bulkUpdate: (comment) => Utils.authenticated(token, bulkUpdateModel(path, modelFormatter, comment)),
        };
    };
}
export function area(token) {
    return {
        get: Utils.authenticated(token, getArea),
        find: Utils.authenticated(token, findAreas),
        create: (comment, parentId) => Utils.authenticated(token, createArea(comment, parentId)),
        update: (comment, parentId) => Utils.authenticated(token, updateArea(comment, parentId)),
        remove: Utils.authenticated(token, removeArea),
        bulkLockStatus: Utils.authenticated(token, getAreaBulkLockStatus),
        lockStatus: Utils.authenticated(token, areaLockStatus),
        lock: Utils.authenticated(token, lockArea),
        unlock: Utils.authenticated(token, unlockArea),
    };
}
export function user(token) {
    return {
        get: Utils.authenticated(token, getUser),
        find: Utils.authenticated(token, findUsers),
        create: (comment, parentId) => Utils.authenticated(token, createUser(comment, parentId)),
        update: (comment, parentId) => Utils.authenticated(token, updateUser(comment, parentId)),
        remove: Utils.authenticated(token, removeUser),
        bulkLockStatus: Utils.authenticated(token, getUserBulkLockStatus),
        lockStatus: Utils.authenticated(token, userLockStatus),
        lock: Utils.authenticated(token, lockUser),
        unlock: Utils.authenticated(token, unlockUser),
    };
}
export function getModel(path, parse) {
    return (id, skipCache) => get(path, id, parse, skipCache);
}
export function findModels(path, parse) {
    return (skipCache) => find(path, parse, skipCache);
}
export function createModel(path, format, comment, parentId) {
    return (model) => create(path, format ? format(model) : model, comment, parentId);
}
export function updateModel(path, format, comment, parentId) {
    return (id, model) => update(path, id, format ? format(model) : model, comment, parentId);
}
export function patchModel(path, comment, parentId) {
    return (id, updateFields) => patch(path, id, updateFields, comment, parentId);
}
export function removeModel(path) {
    return (id) => remove(path, id);
}
export function modelLockStatus(path) {
    return (id) => lockStatus(path, id);
}
export function getBulkLockStatus(path) {
    return () => bulkLockStatus(path);
}
export function lockModel(path) {
    return (id) => lock(path, id);
}
export function unlockModel(path) {
    return (id) => unlock(path, id);
}
export function bulkUpdateModel(path, format, comment) {
    return (models) => bulkUpdate(path, format ? models.map(format) : models, comment);
}
export function login(email, password) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch(`${Merchandising.API_URL}/${Merchandising.LOGIN_PATH}`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: JSON_HEADERS,
            expiry: false,
            skipCache: true,
        })
            .then(Utils.extractResponse())
            .then(Utils.extractToken);
    });
}
export function loginSSO(provider, queryString) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch(`${Admin.API_URL}${Routing.SSO_LOGIN_SEGMENT}/${provider}${queryString}`, {
            method: 'GET',
            headers: JSON_HEADERS,
            expiry: false,
            skipCache: true,
        })
            .then(Utils.extractResponse())
            .then(Utils.extractToken);
    });
}
export function fetchExternalAuthenticationProviders() {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch(`${Admin.API_URL}/${Admin.SSO_PROVIDERS_PATH}`, {
            method: 'GET',
        }).then(Utils.extractProvidersList);
    });
}
export function externalAuthLoginUrl(path) {
    return Admin.API_URL + path;
}
export function uploadImage(_area, image) {
    return {
        files: { image },
        url: `${Images.API_URL}/${Images.UPLOAD_PATH}`,
        method: 'POST',
    };
}
export function promote(source, target) {
    return {
        url: `${Merchandising.API_URL}/${Merchandising.AREA_PROMOTE_PATH}`,
        method: 'POST',
        body: { source, target },
    };
}
export function validate() {
    return {
        url: `${Merchandising.API_URL}/${Merchandising.AUTH_VALIDATION_PATH}`,
        expiry: false,
        skipCache: true,
    };
}
export function validatePassword(password) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch(`${Merchandising.API_URL}/${Merchandising.PASSWORD_VALIDATION_PATH}`, {
            method: 'POST',
            body: JSON.stringify({ argument: password }),
            headers: JSON_HEADERS,
            expiry: false,
            skipCache: true,
        }).then(Utils.extractResponse());
    });
}
export function productAttributes() {
    return { url: `${Merchandising.API_URL}/${Merchandising.PRODUCT_ATTRIBUTES_PATH}`, expiry: 300000 };
}
export function productAttributeValues(area, field) {
    return {
        method: 'POST',
        url: `${Merchandising.API_URL}/${Merchandising.PRODUCT_ATTRIBUTE_VALUES_PATH}`,
        body: { area, field },
        expiry: 300000,
    };
}
export function grove() {
    return {
        url: `${StreamAdmin.GROVE_URL}`,
    };
}
export function getArea(id, skipCache) {
    return get(Merchandising.AREA_PATH, id, Parsers.area, skipCache);
}
export function findAreas(skipCache) {
    return find(Merchandising.AREA_PATH, Utils.arrayParser(Parsers.area), skipCache);
}
export function createArea(comment, parentId) {
    return (area) => API.create(Merchandising.AREA_PATH, ModelFormatters.area(area), comment, parentId);
}
export function updateArea(comment, parentId) {
    return (id, area) => API.update(Merchandising.AREA_PATH, id, ModelFormatters.area(area), comment, parentId);
}
export function bulkUpdateArea(areas) {
    return API.bulkUpdate(Merchandising.AREA_PATH, areas.map(ModelFormatters.area));
}
export function removeArea(id) {
    return remove(Merchandising.AREA_PATH, id);
}
export function getAreaBulkLockStatus() {
    return bulkLockStatus(Merchandising.AREA_PATH);
}
export function areaLockStatus(id) {
    return lockStatus(Merchandising.AREA_PATH, id);
}
export function lockArea(id) {
    return lock(Merchandising.AREA_PATH, id);
}
export function unlockArea(id) {
    return unlock(Merchandising.AREA_PATH, id);
}
export function getUser(id, skipCache) {
    return get(Merchandising.USER_PATH, id, undefined, skipCache);
}
export function findUsers(skipCache) {
    return find(Merchandising.USER_PATH, undefined, skipCache);
}
export function createUser(comment, parentId) {
    return (user) => create(Merchandising.USER_PATH, user, comment, parentId);
}
export function updateUser(comment, parentId) {
    return (id, user) => update(Merchandising.USER_PATH, id, user, comment, parentId);
}
export function removeUser(id) {
    return remove(Merchandising.USER_PATH, id);
}
export function getUserBulkLockStatus() {
    return bulkLockStatus(Merchandising.USER_PATH);
}
export function userLockStatus(id) {
    return lockStatus(Merchandising.USER_PATH, id);
}
export function lockUser(id) {
    return lock(Merchandising.USER_PATH, id);
}
export function unlockUser(id) {
    return unlock(Merchandising.USER_PATH, id);
}
export function bulkUpdateUser(users) {
    return bulkUpdate(Merchandising.USER_PATH, users);
}
export function createKeyRequest(skipCache) {
    return {
        skipCache,
        url: `${Merchandising.API_URL}/${Merchandising.KEY_PATH}`,
    };
}
export function getKeys(skipCache) {
    return createKeyRequest(skipCache);
}
export function addPrimaryKey() {
    return Object.assign(Object.assign({}, createKeyRequest(true)), { method: 'POST', expiry: false });
}
export function removeSecondaryKey() {
    return Object.assign(Object.assign({}, createKeyRequest(true)), { method: 'DELETE', expiry: false });
}
export function searchPreview(body) {
    return {
        body,
        method: 'POST',
        url: `${Merchandising.API_URL}/${Merchandising.SEARCH_PREVIEW_PATH}`,
    };
}
export function changeLogStatus() {
    return {
        url: `${Merchandising.API_URL}/${Merchandising.CHANGE_LOG_STATUS_PATH}`,
        method: 'GET',
        skipCache: true,
    };
}
export function changeLog(queryParam) {
    return {
        url: `${Merchandising.API_URL}/${Merchandising.CHANGE_LOG_PATH}?${queryParam}`,
        skipCache: true,
    };
}
export function streamCollections() {
    return {
        url: `${StreamAdmin.API_URL}/${StreamAdmin.COLLECTIONS_PATH}`,
    };
}
export function adminCollections() {
    return {
        url: `${Admin.API_URL}/${Admin.COLLECTIONS_PATH}`,
    };
}
export function clickthroughs(body) {
    return {
        body: AnalyticsFormatters.analytics(body),
        method: 'POST',
        url: `${Analytics.PERFORMANCE_URL}/${Analytics.CLICKTHROUGHS_PATH}`,
    };
}
export function services(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.OPERATIONS_URL}/${Analytics.SERVICES_PATH}`,
    };
}
export function timeseries(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.OPERATIONS_URL}/${Analytics.TIMESERIES_PATH}`,
    };
}
export function topRefinements(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.RECOMMENDATIONS_URL}/${Analytics.TOP_REFINEMENTS_PATH}`,
    };
}
export function records(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.OPERATIONS_URL}/${Analytics.RECORDS_PATH}`,
    };
}
export function recordsTimeseries(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.OPERATIONS_URL}/${Analytics.RECORDS_TIMESERIES_PATH}`,
    };
}
export function responseTime(body) {
    return {
        body,
        method: 'POST',
        url: `${Analytics.OPERATIONS_URL}/${Analytics.RESPONSE_TIME}`,
    };
}
export function eventHealthcheck(body) {
    return {
        body,
        method: 'POST',
        url: Analytics.EVENT_HEALTHCHECK_URL,
        expiry: false,
        skipCache: true,
    };
}
export function runQuickcheck(body) {
    return {
        body,
        method: 'POST',
        url: Analytics.RUN_QUICKCHECK_URL,
        expiry: false,
        skipCache: true,
    };
}
export function statusQuickcheck(body) {
    return {
        body,
        method: 'POST',
        url: Analytics.STATUS_QUICKCHECK_URL,
        expiry: false,
        skipCache: true,
    };
}
export function performance(body) {
    return {
        body: AnalyticsFormatters.analytics(body),
        method: 'POST',
        url: `${Analytics.SEARCH_TERM_REPORTING_URL}/${Analytics.PERFORMANCE_PATH}`,
    };
}
export function nulls(body) {
    return {
        body: AnalyticsFormatters.analytics(body),
        method: 'POST',
        url: `${Analytics.SEARCH_TERM_REPORTING_URL}/${Analytics.NULLS_PATH}`,
    };
}
export function products(body) {
    return {
        body: AnalyticsFormatters.analytics(body),
        method: 'POST',
        url: `${Analytics.SEARCH_TERM_REPORTING_URL}/${Analytics.PRODUCTS_PATH}`,
    };
}
export function navigations(body) {
    return {
        body: AnalyticsFormatters.analytics(body),
        method: 'POST',
        url: `${Analytics.SEARCH_TERM_REPORTING_URL}/${Analytics.NAVIGATIONS_PATH}`,
    };
}
export function glancerDashboards() {
    return {
        method: 'GET',
        url: `${GlancerAnalytics.API_URL}/${GlancerAnalytics.DASHBOARDS_PATH}`,
    };
}
export function glancerEmbedUrl(area, path) {
    return {
        method: 'GET',
        url: `${GlancerAnalytics.API_URL}/${area}/${GlancerAnalytics.EMBED_PATH}?${formatQueryParam({ path })}`,
    };
}
export function clientProductsReports(params) {
    return {
        url: `${Enrich.API_URL}/${Enrich.CLIENT_PRODUCTS_REPORTS_PATH}?${formatQueryParam(params)}`,
    };
}
export function customersContract(params) {
    return {
        url: `${Enrich.API_URL}/${Enrich.CUSTOMERS_PATH}/${params.customerId}`,
    };
}
export function enrichTokens() {
    return {
        url: Enrich.AUTH_URL,
    };
}
export function auth(token) {
    return {
        models: models(token),
        upload: Utils.authenticated(token, uploadImage),
        promote: Utils.authenticated(token, promote),
        validate: Utils.authenticated(token, validate),
        grove: Utils.authenticated(token, grove),
        productAttributes: Utils.authenticated(token, productAttributes),
        productAttributeValues: Utils.authenticated(token, productAttributeValues),
        collections: Utils.authenticated(token, adminCollections),
        searchPreview: Utils.authenticated(token, searchPreview),
        enrichTokens: Utils.authenticated(token, enrichTokens),
        changeLog: Utils.authenticated(token, changeLog),
        changeLogStatus: Utils.authenticated(token, changeLogStatus),
        analyticsV2: {
            clickthroughs: Utils.authenticated(token, clickthroughs),
            services: Utils.authenticated(token, services),
            timeseries: Utils.authenticated(token, timeseries),
            records: Utils.authenticated(token, records),
            recordsTimeseries: Utils.authenticated(token, recordsTimeseries),
            performance: Utils.authenticated(token, performance),
            nulls: Utils.authenticated(token, nulls),
            products: Utils.authenticated(token, products),
            navigations: Utils.authenticated(token, navigations),
            responseTime: Utils.authenticated(token, responseTime),
            healthcheck: Utils.authenticated(token, eventHealthcheck),
            runQuickcheck: Utils.authenticated(token, runQuickcheck),
            statusQuickcheck: Utils.authenticated(token, statusQuickcheck),
        },
        glancerAnalytics: {
            dashboards: Utils.authenticated(token, glancerDashboards),
            embedUrl: Utils.authenticated(token, glancerEmbedUrl),
        },
        area: area(token),
        user: user(token),
        key: {
            addPrimary: Utils.authenticated(token, addPrimaryKey),
            removeSecondary: Utils.authenticated(token, removeSecondaryKey),
            get: Utils.authenticated(token, getKeys),
        },
        analytics: (clientKey) => ({
            topRefinements: Utils.analyticsAuthenticated(clientKey, topRefinements),
        }),
        admin: (clientKey) => ({
            collections: Utils.adminAuthenticated(clientKey, streamCollections),
        }),
    };
}
export function enrichAuth(token) {
    return {
        clientProductsReports: Utils.enrichAuthenticated(token, clientProductsReports),
        customersContract: Utils.enrichAuthenticated(token, customersContract),
    };
}
export function ccEnrichAuth(token) {
    return {
        url: Enrich.CC_ENRICH_API_URL,
        token,
    };
}
export default {
    login,
    loginSSO,
    auth,
    enrichAuth,
    validatePassword,
    ccEnrichAuth,
    fetchExternalAuthenticationProviders,
    externalAuthLoginUrl,
};
export function get(path, id, parse, skipCache) {
    return { parse, skipCache, url: `${Merchandising.API_URL}/${path}/${id}` };
}
export function find(path, parse, skipCache) {
    return { parse, skipCache, url: `${Merchandising.API_URL}/${path}` };
}
export function create(path, _a, comment, parentId) {
    var { id: _ } = _a, body = __rest(_a, ["id"]);
    return {
        body,
        url: `${Merchandising.API_URL}/${path}?${formatQueryParam({ comment, parentId })}`,
        method: 'POST',
        expiry: false,
        skipCache: true,
    };
}
export function update(path, id, body, comment, parentId) {
    return {
        body,
        url: `${Merchandising.API_URL}/${path}/${id}?${formatQueryParam({ comment, parentId })}`,
        method: 'PUT',
        expiry: false,
        skipCache: true,
    };
}
export function patch(path, id, body, comment, parentId) {
    return {
        body,
        url: `${Merchandising.API_URL}/${path}/${id}?${formatQueryParam({ comment, parentId })}`,
        method: 'PATCH',
    };
}
export function remove(path, id) {
    return {
        url: `${Merchandising.API_URL}/${path}/${id}`,
        method: 'DELETE',
        expiry: false,
        skipCache: true,
    };
}
export function bulkLockStatus(path) {
    return {
        url: `${Merchandising.API_URL}/${path}/_lock`,
        method: 'GET',
        expiry: false,
        skipCache: true,
    };
}
export function lockStatus(path, id) {
    return {
        url: `${Merchandising.API_URL}/${path}/${id}/_lock`,
        method: 'GET',
        expiry: false,
        skipCache: true,
    };
}
export function lock(path, id) {
    return {
        url: `${Merchandising.API_URL}/${path}/${id}/_lock`,
        method: 'POST',
        expiry: false,
        skipCache: true,
    };
}
export function unlock(path, id) {
    return {
        url: `${Merchandising.API_URL}/${path}/${id}/_unlock`,
        method: 'POST',
        expiry: false,
        skipCache: true,
    };
}
export function bulkUpdate(path, models, comment) {
    return {
        body: { models },
        url: `${Merchandising.API_URL}/${path}?${formatQueryParam({ comment })}`,
        method: 'PUT',
        expiry: false,
        skipCache: true,
    };
}
