import * as Actions from '../types';
import { createAction } from '../utils';
export const setChangeLogDataLimitReached = (dataLimitReached) => (createAction(Actions.SET_CHANGE_LOG_DATA_LIMIT_REACHED, { dataLimitReached }));
export const setChangeLogIsActive = (isActive) => (createAction(Actions.SET_CHANGE_LOG_IS_ACTIVE, { isActive }));
export const setChangeLogFilters = (filters) => (createAction(Actions.SET_CHANGE_LOG_FILTERS, filters));
export const updateChangeLogFilter = (field, value) => (createAction(Actions.UPDATE_CHANGE_LOG_FILTER, { field, value }));
export const updateChangeLogSearchBy = (searchBy) => (createAction(Actions.UPDATE_CHANGE_LOG_SEARCH_BY, searchBy));
export const resetChangeLogFilter = () => createAction(Actions.RESET_CHANGE_LOG_FILTER);
export const loadChangeLogData = (appendData = false, skip = 0) => (createAction(Actions.LOAD_CHANGE_LOG_DATA, { appendData, skip }));
export const initializeChangeLogQuickView = (dateRange) => (createAction(Actions.INITIALIZE_CHANGE_LOG_QUICK_VIEW, { dateRange }));
export const refreshChangeLogData = (data, keepPreviousData) => (createAction(Actions.REFRESH_CHANGE_LOG_DATA, { data, keepPreviousData }));
