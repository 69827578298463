import React from 'react';
import { withStyles } from '@/decorators';
import * as Utils from '../utils';
export const styles = ({ palette, spacing }) => ({
    scores: {
        width: spacing(3.75),
        height: spacing(3.75),
        textAlign: 'center',
        borderRadius: '50%',
        color: palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textShadow: '1px 2px #00000069',
        marginLeft: 'auto',
    },
});
const ScoreCell = withStyles(styles)(function ScoreCell({ classes, formatter, colorGradient, result = 0, }) {
    return (<span className={classes.scores} style={{
        background: Utils.colorPicker(colorGradient, Math.round(result)),
    }}>
      {formatter ? formatter(result) : result}
    </span>);
});
export default ScoreCell;
