import { __decorate } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { Bubble } from 'react-chartjs-2';
import NoDataError from '@/components/no-data-error';
import InsightsModal from '@/components/reports/table-v2/enhancements/insights-modal';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect } from '@/utils';
import { OPTIONS } from './config';
import { formatData } from './utils';
var ResultKey = State.Analytics.ResultKey;
var InsightsType = State.Analytics.InsightsType;
const TOP = 50;
const BOTTOM = 100;
const LEFT = '15%';
const RIGHT = '10%';
export const styles = ({ fontSize }) => ({
    root: {
        position: 'relative',
        zIndex: 0,
        height: '100%',
        overflow: 'initial',
    },
    quadrantLabel: {
        position: 'absolute',
        fontSize: fontSize.pageHeaderContent,
        zIndex: -1,
        '&$first': {
            top: TOP,
            left: LEFT,
        },
        '&$second': {
            top: TOP,
            right: RIGHT,
        },
        '&$third': {
            bottom: BOTTOM,
            left: LEFT,
        },
        '&$fourth': {
            bottom: BOTTOM,
            right: RIGHT,
        },
    },
    first: {},
    second: {},
    third: {},
    fourth: {},
});
export const selectors = {
    currentInsights: Selectors.currentInsightsSelector,
};
export const actionCreators = {
    loadInsights: ActionCreators.loadInsights,
    refreshInsights: ActionCreators.refreshInsights,
};
let PopularSearchesChart = class PopularSearchesChart extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { isModalOpen: false, modalData: null };
        this.openModal = (_, chartData) => {
            if (!chartData.length)
                return;
            const { loadInsights, currentInsights, refreshInsights } = this.props;
            const dataIndex = chartData[0]._index;
            // eslint-disable-next-line dot-notation
            const modalData = chartData[0]._chart.config.data.datasets[0]['rawGBData'][dataIndex];
            if (modalData !== this.state.modalData) {
                Object.values(InsightsType).map((insight) => refreshInsights(insight, null));
            }
            this.setState({ modalData, isModalOpen: true });
            loadInsights(currentInsights, modalData[ResultKey.TERM]);
        };
        this.closeModal = () => this.setState({ isModalOpen: false });
        // eslint-disable-next-line no-return-assign, dot-notation
        this.changeCursorStyle = (event, chartData) => (event.target['style'].cursor = chartData.length ? 'pointer' : 'default');
    }
    render() {
        const { data, classes } = this.props;
        const { isModalOpen, modalData } = this.state;
        if (!data) {
            return <NoDataError />;
        }
        const maxVal = data.reduce((acc, { uniqueSearches }) => (uniqueSearches > acc ? uniqueSearches : acc), data[0] ? data[0][ResultKey.UNIQUE_SEARCHES] : null);
        const formattedData = formatData(data, maxVal);
        const options = Object.assign(Object.assign({}, OPTIONS), { onClick: this.openModal, hover: Object.assign(Object.assign({}, OPTIONS.hover), { onHover: this.changeCursorStyle }) });
        const value = {
            labels: data.map((result) => result[ResultKey.TERM]),
            datasets: [
                {
                    label: 'formattedData',
                    backgroundColor: 'rgba(197, 237, 236, 0.4)',
                    borderColor: 'rgba(39, 198, 195)',
                    hoverBackgroundColor: 'rgba(245, 150, 80, 0.5)',
                    hoverBorderColor: 'rgba(245, 150, 80, 0.6)',
                    hoverBorderWidth: 2,
                    hoverRadius: 0,
                    data: formattedData,
                    rawGBData: data,
                },
            ],
        };
        return (<React.Fragment>
        <div className={classes.root}>
          <Bubble data={value} options={options}/>
          <span className={classNames(classes.quadrantLabel, classes.first)}>Niche Converters</span>
          <span className={classNames(classes.quadrantLabel, classes.second)}>High Performers</span>
          <span className={classNames(classes.quadrantLabel, classes.third)}>Underperformers</span>
          <span className={classNames(classes.quadrantLabel, classes.fourth)}>Conversion Opportunities</span>
        </div>
        <InsightsModal open={isModalOpen} onClose={this.closeModal} snapshot={modalData}/>
      </React.Fragment>);
    }
};
PopularSearchesChart = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators)
], PopularSearchesChart);
export default PopularSearchesChart;
