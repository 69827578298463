import React from 'react';
import { Redirect, Route, Switch, } from 'react-router';
import DrawerContainer from '@/components/drawer/container';
import EnrichDrawer from '@/components/drawer/enrich';
import { Routing } from '@/constants';
import Sections from '@/enrich';
import { extractRoutes, ENRICH_ROUTES } from '@/routes/drawer';
function EnrichRouter({ match }) {
    return (<DrawerContainer component={EnrichDrawer}>
      <Switch>
        {extractRoutes(ENRICH_ROUTES.sections).map((route) => (<Route path={`${match.path}/${route.path}`} component={Sections[route.path]} key={route.path}/>))}
        <Redirect to={`${Routing.ENRICH_PATH}/${Routing.Page.Enrich.BY_CATEGORY}`}/>
      </Switch>
    </DrawerContainer>);
}
export default EnrichRouter;
