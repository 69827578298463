import loadable from '@/components/loadable';
import { Routing } from '@/constants';
// CUE-120: hide enrich dashboard
// import ReportingType = State.Enrich.ReportingType;
var Page = Routing.Page.Enrich;
export default {
    // CUE-120: hide enrich dashboard
    // [ReportingType.DASHBOARD]: loadable(() => import('@/enrich/dashboard' /* webpackChunkName: "enrich-reporting-dashboard" */)),
    [Page.BY_CATEGORY]: loadable(() => import('@/enrich/pages/by-category' /* webpackChunkName: "enrich-by-category" */)),
    [Page.BY_TAXONOMY]: loadable(() => import('@/enrich/pages/by-taxonomy' /* webpackChunkName: "enrich-by-taxonomy" */)),
    // CUE-381: hide out of scope nav links
    // [Page.GLOBAL_TAXONOMY]: loadable(() => import('@/enrich/pages/global-taxonomy' /* webpackChunkName: "enrich-global-taxonomy" */)),
    [Page.RULE_LIBRARY]: loadable(() => import('@/enrich/pages/rule-library' /* webpackChunkName: "enrich-rule-library" */)),
};
