import * as Actions from '@/store/actions/types';
import * as ChartsDataReducers from './charts-data';
export const DEFAULT = {};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_DATA:
            return ChartsDataReducers.refreshDataReducer(state, action);
        default:
            return state;
    }
};
export function refreshDataReducer(state, { payload: { data, dashboardType, dataSetKey } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { [dataSetKey]: data }) });
}
