import React from 'react';
import { withStyles } from '@/decorators';
export const styles = ({ breakpoints, palette, spacing, footer, fontSize, }) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: footer.height,
        padding: `0 ${spacing(3)}px`,
        boxSizing: 'border-box',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: palette.secondary.main,
        [breakpoints.down('sm')]: {
            height: footer.heightMobile,
            justifyContent: 'center',
        },
    },
    copyright: {
        fontSize: fontSize.caption,
    },
});
const Footer = withStyles(styles)(function Footer({ classes }) {
    const year = new Date().getFullYear();
    const yearRange = year === 2018 ? year : `2018-${year}`;
    return (<footer className={classes.root}>
      <span className={classes.copyright}>©{yearRange} Copyright, GroupBy Inc.</span>
    </footer>);
});
export default Footer;
