import * as Actions from '@/store/actions/types/analytics';
import { createAction } from '@/store/actions/utils';
export const setDashboardV2DateRange = (start, end, dashboardType) => createAction(Actions.SET_DASHBOARD_V2_DATE_RANGE, { start, end, dashboardType });
export const setDeviceType = (devices, dashboardType) => createAction(Actions.SET_DEVICE_TYPE, { devices, dashboardType });
export const setServiceType = (service, dashboardType) => createAction(Actions.SET_SERVICE_TYPE, { service, dashboardType });
export const setTimeRange = (timeRange, dashboardType) => createAction(Actions.SET_TIME_RANGE, { timeRange, dashboardType });
export const refreshSnapshot = (snapshot, dashboardType, dataSetKey) => createAction(Actions.REFRESH_SNAPSHOT, { snapshot, dashboardType, dataSetKey });
export const refreshData = (data, dashboardType, dataSetKey) => createAction(Actions.REFRESH_DATA, { data, dashboardType, dataSetKey });
export const refreshDashboard = (dashboard) => createAction(Actions.REFRESH_DASHBOARD, dashboard);
export const stopRefreshDashboard = () => createAction(Actions.STOP_REFRESH_DASHBOARD);
