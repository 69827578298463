import * as Actions from '@/store/actions/types';
import * as SnapshotsDataReducers from './snapshots-data';
export const DEFAULT = {};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_SNAPSHOT:
            return SnapshotsDataReducers.refreshSnapshotReducer(state, action);
        default:
            return state;
    }
};
export function refreshSnapshotReducer(state, { payload: { snapshot, dashboardType, dataSetKey } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { [dataSetKey]: snapshot }) });
}
