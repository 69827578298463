import { __decorate, __rest } from "tslib";
import React from 'react';
import BaseAutocomplete, { normalizeSuggestions } from '@/components/controls/autocomplete/base';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect2, formatId } from '@/utils';
export const selectors = {
    suggestions: Selectors.collectionsSelector,
};
export const actionCreators = {
    fetchSuggestions: ActionCreators.fetchCollections,
};
let CollectionsAutocomplete = class CollectionsAutocomplete extends React.PureComponent {
    render() {
        const _a = this.props, { suggestions, fetchSuggestions, form } = _a, props = __rest(_a, ["suggestions", "fetchSuggestions", "form"]);
        return (<BaseAutocomplete options={normalizeSuggestions(suggestions)} id={`${formatId(form)}-collections-autocomplete`} {...props}/>);
    }
    componentDidMount() {
        this.props.fetchSuggestions();
    }
};
CollectionsAutocomplete = __decorate([
    connect2(selectors, actionCreators)
], CollectionsAutocomplete);
export default CollectionsAutocomplete;
