import * as Actions from '@/store/actions/types';
import * as DashboardFiltersReducer from './dashboard-filters';
export const DEFAULT = {};
export default function (state = DEFAULT, action) {
    switch (action.type) {
        case Actions.SET_DASHBOARD_V2_DATE_RANGE:
            return DashboardFiltersReducer.setDateRangeReducer(state, action);
        case Actions.SET_DEVICE_TYPE:
            return DashboardFiltersReducer.refreshDeviceTypeReducer(state, action);
        case Actions.SET_SERVICE_TYPE:
            return DashboardFiltersReducer.setServiceTypeReducer(state, action);
        case Actions.SET_TIME_RANGE:
            return DashboardFiltersReducer.setTimeRangeReducer(state, action);
        default:
            return state;
    }
}
export function setDateRangeReducer(state, { payload: { start, end, dashboardType } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { dateRange: { start, end } }) });
}
export function refreshDeviceTypeReducer(state, { payload: { devices, dashboardType } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { devices }) });
}
export function setServiceTypeReducer(state, { payload: { service, dashboardType } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { service }) });
}
export function setTimeRangeReducer(state, { payload: { timeRange, dashboardType } }) {
    return Object.assign(Object.assign({}, state), { [dashboardType]: Object.assign(Object.assign({}, state[dashboardType]), { timeRange }) });
}
