import React from 'react';
import { Redirect, Route, Switch, } from 'react-router';
import Sections from '@/analytics';
import AnalyticsDrawer from '@/components/drawer/analytics';
import DrawerContainer from '@/components/drawer/container';
import { Routing } from '@/constants';
import { extractRoutes, ANALYTICS_ROUTES } from '@/routes/drawer';
import AreaContext from '@/store/area-context';
import State from '@/store/state';
const AnalyticsRouter = function AnalyticsRouter({ match, area }) {
    return (<DrawerContainer component={AnalyticsDrawer}>
      <AreaContext.Provider value={area}>
        <Switch>
          {extractRoutes(ANALYTICS_ROUTES.sections).map((route) => (<Route path={`${match.path}/${route.path}`} component={Sections[route.path]} key={route.path}/>))}
          <Redirect to={`${Routing.ANALYTICS_PATH}/${State.Analytics.DashboardType.SEARCH_PERFORMANCE}`}/>
        </Switch>
      </AreaContext.Provider>
    </DrawerContainer>);
};
export default AnalyticsRouter;
