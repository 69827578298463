import { createSelector } from 'reselect';
import { Routing } from '@/constants';
import { parseSearch } from '@/utils';
export function routerSelector({ router }) {
    return router;
}
export const activeLocationSelector = createSelector(routerSelector, ({ location }) => location || window.location);
export const activeRouteSelector = createSelector(activeLocationSelector, (location) => (location ? location.pathname : '/'));
export const rawActiveQuerySelector = createSelector(activeLocationSelector, ({ search }) => search);
export const activeQuerySelector = createSelector(activeLocationSelector, ({ search }) => parseSearch(search));
export const activePathSelector = createSelector(activeLocationSelector, ({ pathname }) => pathname);
export const nextPathSelector = createSelector(activeLocationSelector, ({ search }) => (parseSearch(search).next || '/').replace('%3F', '?'));
export const locationStateSelector = createSelector(activeLocationSelector, ({ state = window.history.state ? window.history.state.state : {} }) => state);
export const onHomePageSelector = createSelector(activePathSelector, (path) => path === Routing.MERCHANDISING_PATH || path === '/');
