import { __decorate } from "tslib";
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import TableCell from '@/components/cell';
import { Align, Type } from '@/components/reports/table-v2/types';
import ActionMenu from '@/components/reports/table/action-menu';
import { ModelType } from '@/constants';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
export const styles = ({ palette, spacing }) => ({
    tableRow: {
        '&>*': {
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.07)',
            },
        },
        '&>:nth-child(even)': {
            background: palette.primary.contrastText,
        },
    },
    tableCell: {
        fontSize: spacing(1.75),
        color: palette.mainFont,
        '&$highlight': {
            fontWeight: 'bold',
        },
    },
    denseCell: {
        padding: `${spacing(1 / 2)}px ${spacing(1)}px`,
    },
    highlight: {},
    onClickAvailable: {
        cursor: 'pointer',
    },
});
export const actionCreators = {
    createModel: ActionCreators.goToCreate,
};
export const mergeProps = (_, { createModel }) => ({
    actions: [
        {
            label: 'Add a one-way synonym',
            callback: ({ term }) => createModel(ModelType.SYNONYM, { type: 'One_Way', searchTerm: term }),
        },
        {
            label: 'Add a redirect',
            callback: ({ term }) => createModel(ModelType.REDIRECT, { names: [term], name: term }),
        },
    ],
});
let TopNullsBody = class TopNullsBody extends React.PureComponent {
    render() {
        const { columns, orderBy, data, actions, classes, } = this.props;
        return (<React.Fragment>
        <TableBody className={classes.tableRow}>
          {data.map((reportingResult, index) => (<TableRow key={`row${index}`}>
              {columns.map(({ id, type = Type.SMALL, align = Align.LEFT, formatter, }, cellIndex) => (<TableCell type={type} align={align} key={`row${index}-cell${cellIndex}`} classes={{
            root: classNames(orderBy === id && classes.highlight, classes.tableCell),
        }}>
                    <span>{formatter ? formatter(reportingResult[id]) : reportingResult[id]}</span>
                  </TableCell>))}
              <TableCell type={Type.SMALL} align={Align.RIGHT} classes={{ root: classes.denseCell }} key={`row${index}-cell${columns.length}`}>
                <ActionMenu actions={actions} value={reportingResult} dense/>
              </TableCell>
            </TableRow>))}
        </TableBody>
      </React.Fragment>);
    }
};
TopNullsBody = __decorate([
    (withStyles(styles)),
    connect(null, actionCreators, mergeProps)
], TopNullsBody);
export default TopNullsBody;
