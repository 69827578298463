import React from 'react';
import classNames from 'classnames';
import Tooltip from '@/components/tooltip';
import { withStyles } from '@/decorators';
import Label from './label';
export const styles = {
    root: {
        display: 'flex',
        alignItems: 'baseline',
        '&$alignCenter': {
            alignItems: 'center',
        },
    },
    labelWrapper: {
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    alignCenter: {},
};
const LabeledInline = withStyles(styles)(function LabeledInline({ label, for: forId, tooltip, size, labelDecorator, wrapperClassName, alignCenter, tooltipClassName, classes, children, }) {
    return (<div className={classNames(classes.root, {
        [classes.alignCenter]: alignCenter,
    })}>
      <div className={classNames(classes.labelWrapper, wrapperClassName)}>
        <div>
          {tooltip && <Tooltip title={tooltip} tooltipClassName={tooltipClassName}/>}
          {label && (<Label for={forId} size={size}>
              {label}
            </Label>)}
        </div>
        <div>{labelDecorator}</div>
      </div>
      {children}
    </div>);
});
export default LabeledInline;
