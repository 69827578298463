export const SET_INACTIVE = 'SET_INACTIVE';
export const LOAD_KEYS = 'LOAD_KEYS';
export const REFRESH_KEYS = 'REFRESH_KEYS';
export const ADD_PRIMARY_KEY = 'ADD_PRIMARY_KEY';
export const REMOVE_SECONDARY_KEY = 'REMOVE_SECONDARY_KEY';
export const SWITCH_AREA = 'SWITCH_AREA';
export const REFRESH_AREA_ACCESS = 'REFRESH_AREA_ACCESS';
export const SWITCH_MODEL_TYPE = 'SWITCH_MODEL_TYPE';
export const ROUTING_LOADED = 'ROUTING_LOADED';
export const SET_LOADING = 'SET_LOADING';
export const LOAD_GROVE = 'LOAD_GROVE';
export const REFRESH_GROVE = 'REFRESH_GROVE';
