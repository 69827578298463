import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@/components/controls/select';
import SearchInput from '@/components/visual-search/query';
import State from '@/store/state';
var MatchType = State.Analytics.MatchType;
export const TERM_PLACEHOLDER = '_';
export const QUERY_OPTIONS = [
    { label: 'Matches', value: MatchType.EXACT },
    { label: 'Starts With', value: MatchType.PREFIX },
    { label: 'Ends With', value: MatchType.SUFFIX },
    { label: 'Contains', value: MatchType.CONTAINS },
];
class SearchQuery extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handleFormatChange = (matchType) => {
            const { reportType, updateReportQuery, loadReport, query, } = this.props;
            updateReportQuery(reportType, query, matchType);
            if (query) {
                loadReport();
            }
        };
        this.onQueryUpdate = (value) => this.props.updateReportQuery(this.props.reportType, value, this.props.matchType);
        this.onQueryClear = () => {
            const { updateReportQuery, reportType, matchType, loadReport, } = this.props;
            updateReportQuery(reportType, null, matchType);
            loadReport();
        };
        this.onEnterSubmit = ({ key }) => key === 'Enter' && this.props.loadReport();
    }
    render() {
        const { query, matchType, loadReport } = this.props;
        return (<React.Fragment>
        <Grid item xs={4}>
          <Select selected={matchType} onChange={this.handleFormatChange} options={QUERY_OPTIONS} belowHeader outlined/>
        </Grid>
        <Grid item xs={8}>
          <SearchInput updateQuery={this.onQueryUpdate} clearQuery={this.onQueryClear} queryValue={query} placeholder="Search" onIconClick={loadReport} onKeyPress={this.onEnterSubmit} outlined/>
        </Grid>
      </React.Fragment>);
    }
}
export default SearchQuery;
