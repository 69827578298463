import { Sort, ZoneContent } from '@/merchandising/models';
import Filter from '@/merchandising/models/filter';
import Navigation from '@/merchandising/models/navigation';
import Rule from '@/merchandising/models/rule';
import Synonym from '@/merchandising/models/synonym';
export const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjAsImVtYWlsIjoiam9obmRvZUBleGFtcGxlLmNvbSIsImV4cCI6MjE0NzQ4MzY0N30.kLOZhDp3UzqArFIYyv6Twhqhs20x-EqZ6yu3cJc4mJM';
export const IMAGE_PLACEHOLDER = '//via.placeholder.com/200x200';
export const CUSTOMER = {
    companyName: 'fakecustomer',
    activeKey1: '123-456-789-XXX',
    activeKey2: 'ABC-DEF-GHI-000',
};
export const AREAS = addIds([
    {
        name: 'Production',
        biasingProfile: null,
        matchStrategy: null,
        wildcardSearchEnabled: true,
        relaxMatchStrategyName: 'abc',
        suggestionStrength: 7.3,
        metadata: [],
        engineIds: ['mock'],
        intelligentNavigationEnabled: false,
    },
    {
        name: 'development',
        biasingProfile: null,
        matchStrategy: null,
        wildcardSearchEnabled: false,
        relaxMatchStrategyName: false,
        suggestionStrength: 2.6,
        metadata: [{ key: 'brand', value: 'Nike' }],
        engineIds: ['mock'],
        intelligentNavigationEnabled: true,
    },
]);
export const USERS = addIds([
    {
        email: 'marketer@company.com',
        password: 'phooey',
        commandCenterAccess: ['queryRewrite', 'admin'],
        areas: [],
    },
    {
        email: 'merchandiser@company.com',
        password: 'chutzpah',
        commandCenterAccess: ['merchandising', 'queryRewrite'],
        areas: [],
    },
    {
        email: 'mock@test.com',
        password: 'password123',
        commandCenterAccess: ['merchandising', 'queryRewrite', 'engineConfiguration', 'admin'],
        areas: [],
    },
]);
export const STORE_COLLECTIONS = [
    {
        name: 'price',
        numeric: true,
        raw: true,
        sort: true,
    },
    { name: 'sale', raw: true },
    { name: 'description' },
    { name: 'color', raw: true, sort: true },
    { name: 'date_added', numeric: true, sort: true },
];
export const CLICKTHROUGHS = [
    {
        datetime: '2019-01-20 00:00:00 UTC',
        totalAddToCartRatio: 0.26,
        totalOrderRatio: 0.14,
        totalViewProductRatio: 0.49,
        uniqueSearches: 846,
    },
    {
        datetime: '2019-01-20 01:00:00 UTC',
        totalAddToCartRatio: 0.24,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.5,
        uniqueSearches: 787,
    },
    {
        datetime: '2019-01-20 02:00:00 UTC',
        totalAddToCartRatio: 0.39,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.59,
        uniqueSearches: 615,
    },
    {
        datetime: '2019-01-20 03:00:00 UTC',
        totalAddToCartRatio: 0.22,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.42,
        uniqueSearches: 579,
    },
    {
        datetime: '2019-01-20 04:00:00 UTC',
        totalAddToCartRatio: 0.25,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.5,
        uniqueSearches: 443,
    },
    {
        datetime: '2019-01-20 05:00:00 UTC',
        totalAddToCartRatio: 0.24,
        totalOrderRatio: 0.04,
        totalViewProductRatio: 0.55,
        uniqueSearches: 335,
    },
    {
        datetime: '2019-01-20 06:00:00 UTC',
        totalAddToCartRatio: 0.32,
        totalOrderRatio: 0.02,
        totalViewProductRatio: 0.56,
        uniqueSearches: 194,
    },
    {
        datetime: '2019-01-20 07:00:00 UTC',
        totalAddToCartRatio: 0.28,
        totalOrderRatio: 0.02,
        totalViewProductRatio: 0.51,
        uniqueSearches: 144,
    },
    {
        datetime: '2019-01-20 08:00:00 UTC',
        totalAddToCartRatio: 0.32,
        totalOrderRatio: 0.01,
        totalViewProductRatio: 0.5,
        uniqueSearches: 111,
    },
    {
        datetime: '2019-01-20 09:00:00 UTC',
        totalAddToCartRatio: 0.3,
        totalOrderRatio: 0.01,
        totalViewProductRatio: 0.55,
        uniqueSearches: 155,
    },
    {
        datetime: '2019-01-20 10:00:00 UTC',
        totalAddToCartRatio: 0.18,
        totalOrderRatio: 0.11,
        totalViewProductRatio: 0.57,
        uniqueSearches: 96,
    },
    {
        datetime: '2019-01-20 11:00:00 UTC',
        totalAddToCartRatio: 0.11,
        totalOrderRatio: 0.03,
        totalViewProductRatio: 0.64,
        uniqueSearches: 72,
    },
    {
        datetime: '2019-01-20 12:00:00 UTC',
        totalAddToCartRatio: 0.34,
        totalOrderRatio: 0.05,
        totalViewProductRatio: 0.49,
        uniqueSearches: 232,
    },
    {
        datetime: '2019-01-20 13:00:00 UTC',
        totalAddToCartRatio: 0.36,
        totalOrderRatio: 0.15,
        totalViewProductRatio: 0.49,
        uniqueSearches: 371,
    },
    {
        datetime: '2019-01-20 14:00:00 UTC',
        totalAddToCartRatio: 5.59,
        totalOrderRatio: 0.08,
        totalViewProductRatio: 0.53,
        uniqueSearches: 580,
    },
    {
        datetime: '2019-01-20 15:00:00 UTC',
        totalAddToCartRatio: 1.08,
        totalOrderRatio: 0.08,
        totalViewProductRatio: 0.5,
        uniqueSearches: 860,
    },
    {
        datetime: '2019-01-20 16:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.09,
        totalViewProductRatio: 0.47,
        uniqueSearches: 901,
    },
    {
        datetime: '2019-01-20 17:00:00 UTC',
        totalAddToCartRatio: 0.34,
        totalOrderRatio: 0.09,
        totalViewProductRatio: 0.43,
        uniqueSearches: 919,
    },
    {
        datetime: '2019-01-20 18:00:00 UTC',
        totalAddToCartRatio: 0.5,
        totalOrderRatio: 0.08,
        totalViewProductRatio: 0.51,
        uniqueSearches: 920,
    },
    {
        datetime: '2019-01-20 19:00:00 UTC',
        totalAddToCartRatio: 0.3,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.48,
        uniqueSearches: 1187,
    },
    {
        datetime: '2019-01-20 20:00:00 UTC',
        totalAddToCartRatio: 0.33,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.47,
        uniqueSearches: 1150,
    },
    {
        datetime: '2019-01-20 21:00:00 UTC',
        totalAddToCartRatio: 0.22,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.56,
        uniqueSearches: 1199,
    },
    {
        datetime: '2019-01-20 22:00:00 UTC',
        totalAddToCartRatio: 0.19,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.51,
        uniqueSearches: 1160,
    },
    {
        datetime: '2019-01-20 23:00:00 UTC',
        totalAddToCartRatio: 0.19,
        totalOrderRatio: 0.05,
        totalViewProductRatio: 0.54,
        uniqueSearches: 923,
    },
    {
        datetime: '2019-01-21 00:00:00 UTC',
        totalAddToCartRatio: 0.16,
        totalOrderRatio: 0.05,
        totalViewProductRatio: 0.49,
        uniqueSearches: 1054,
    },
    {
        datetime: '2019-01-21 01:00:00 UTC',
        totalAddToCartRatio: 0.23,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.53,
        uniqueSearches: 957,
    },
    {
        datetime: '2019-01-21 02:00:00 UTC',
        totalAddToCartRatio: 0.23,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.46,
        uniqueSearches: 686,
    },
    {
        datetime: '2019-01-21 03:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.09,
        totalViewProductRatio: 0.43,
        uniqueSearches: 570,
    },
    {
        datetime: '2019-01-21 04:00:00 UTC',
        totalAddToCartRatio: 0.26,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.48,
        uniqueSearches: 461,
    },
    {
        datetime: '2019-01-21 05:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.55,
        uniqueSearches: 222,
    },
    {
        datetime: '2019-01-21 06:00:00 UTC',
        totalAddToCartRatio: 0.2,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.46,
        uniqueSearches: 199,
    },
    {
        datetime: '2019-01-21 07:00:00 UTC',
        totalAddToCartRatio: 0.55,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.45,
        uniqueSearches: 179,
    },
    {
        datetime: '2019-01-21 08:00:00 UTC',
        totalAddToCartRatio: 0.16,
        totalOrderRatio: 0.14,
        totalViewProductRatio: 0.49,
        uniqueSearches: 95,
    },
    {
        datetime: '2019-01-21 09:00:00 UTC',
        totalAddToCartRatio: 0.15,
        totalOrderRatio: 0.02,
        totalViewProductRatio: 0.36,
        uniqueSearches: 168,
    },
    {
        datetime: '2019-01-21 10:00:00 UTC',
        totalAddToCartRatio: 1.21,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.56,
        uniqueSearches: 131,
    },
    {
        datetime: '2019-01-21 11:00:00 UTC',
        totalAddToCartRatio: 0.42,
        totalOrderRatio: 0.18,
        totalViewProductRatio: 0.59,
        uniqueSearches: 172,
    },
    {
        datetime: '2019-01-21 12:00:00 UTC',
        totalAddToCartRatio: 0.38,
        totalOrderRatio: 0.16,
        totalViewProductRatio: 0.57,
        uniqueSearches: 294,
    },
    {
        datetime: '2019-01-21 13:00:00 UTC',
        totalAddToCartRatio: 0.44,
        totalOrderRatio: 0.2,
        totalViewProductRatio: 0.59,
        uniqueSearches: 438,
    },
    {
        datetime: '2019-01-21 14:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.12,
        totalViewProductRatio: 0.54,
        uniqueSearches: 595,
    },
    {
        datetime: '2019-01-21 15:00:00 UTC',
        totalAddToCartRatio: 0.29,
        totalOrderRatio: 0.12,
        totalViewProductRatio: 0.53,
        uniqueSearches: 662,
    },
    {
        datetime: '2019-01-21 16:00:00 UTC',
        totalAddToCartRatio: 0.3,
        totalOrderRatio: 0.14,
        totalViewProductRatio: 0.46,
        uniqueSearches: 740,
    },
    {
        datetime: '2019-01-21 17:00:00 UTC',
        totalAddToCartRatio: 0.26,
        totalOrderRatio: 0.11,
        totalViewProductRatio: 0.47,
        uniqueSearches: 725,
    },
    {
        datetime: '2019-01-21 18:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.13,
        totalViewProductRatio: 0.49,
        uniqueSearches: 714,
    },
    {
        datetime: '2019-01-21 19:00:00 UTC',
        totalAddToCartRatio: 0.37,
        totalOrderRatio: 0.14,
        totalViewProductRatio: 0.52,
        uniqueSearches: 714,
    },
    {
        datetime: '2019-01-21 20:00:00 UTC',
        totalAddToCartRatio: 0.23,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.59,
        uniqueSearches: 799,
    },
    {
        datetime: '2019-01-21 21:00:00 UTC',
        totalAddToCartRatio: 0.21,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.52,
        uniqueSearches: 867,
    },
    {
        datetime: '2019-01-21 22:00:00 UTC',
        totalAddToCartRatio: 0.21,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.52,
        uniqueSearches: 867,
    },
    {
        datetime: '2019-01-21 23:00:00 UTC',
        totalAddToCartRatio: 0.21,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.52,
        uniqueSearches: 867,
    },
    {
        datetime: '2019-01-22 00:00:00 UTC',
        totalAddToCartRatio: 0.21,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.52,
        uniqueSearches: 867,
    },
    {
        datetime: '2019-01-22 01:00:00 UTC',
        totalAddToCartRatio: 0.23,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.46,
        uniqueSearches: 686,
    },
    {
        datetime: '2019-01-22 02:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.09,
        totalViewProductRatio: 0.43,
        uniqueSearches: 570,
    },
    {
        datetime: '2019-01-22 03:00:00 UTC',
        totalAddToCartRatio: 0.26,
        totalOrderRatio: 0.06,
        totalViewProductRatio: 0.48,
        uniqueSearches: 461,
    },
    {
        datetime: '2019-01-22 04:00:00 UTC',
        totalAddToCartRatio: 0.31,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.55,
        uniqueSearches: 222,
    },
    {
        datetime: '2019-01-22 05:00:00 UTC',
        totalAddToCartRatio: 0.2,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.46,
        uniqueSearches: 199,
    },
    {
        datetime: '2019-01-22 06:00:00 UTC',
        totalAddToCartRatio: 0.55,
        totalOrderRatio: 0.1,
        totalViewProductRatio: 0.45,
        uniqueSearches: 179,
    },
    {
        datetime: '2019-01-22 07:00:00 UTC',
        totalAddToCartRatio: 0.16,
        totalOrderRatio: 0.14,
        totalViewProductRatio: 0.49,
        uniqueSearches: 95,
    },
    {
        datetime: '2019-01-22 08:00:00 UTC',
        totalAddToCartRatio: 0.15,
        totalOrderRatio: 0.02,
        totalViewProductRatio: 0.36,
        uniqueSearches: 168,
    },
    {
        datetime: '2019-01-22 09:00:00 UTC',
        totalAddToCartRatio: 0.19,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.51,
        uniqueSearches: 1160,
    },
    {
        datetime: '2019-01-22 10:00:00 UTC',
        totalAddToCartRatio: 0.19,
        totalOrderRatio: 0.05,
        totalViewProductRatio: 0.54,
        uniqueSearches: 923,
    },
    {
        datetime: '2019-01-22 11:00:00 UTC',
        totalAddToCartRatio: 0.16,
        totalOrderRatio: 0.05,
        totalViewProductRatio: 0.49,
        uniqueSearches: 1054,
    },
    {
        datetime: '2019-01-22 12:00:00 UTC',
        totalAddToCartRatio: 0.23,
        totalOrderRatio: 0.07,
        totalViewProductRatio: 0.53,
        uniqueSearches: 957,
    },
];
export const TRAFFIC = [
    {
        datetime: '2019-01-20 00:00:00 UTC',
        traffic: { searches: 846 },
    },
    {
        datetime: '2019-01-20 01:00:00 UTC',
        traffic: { searches: 787 },
    },
    {
        datetime: '2019-01-20 02:00:00 UTC',
        traffic: { searches: 615 },
    },
    {
        datetime: '2019-01-20 03:00:00 UTC',
        traffic: { searches: 579 },
    },
    {
        datetime: '2019-01-20 04:00:00 UTC',
        traffic: { searches: 443 },
    },
    {
        datetime: '2019-01-20 05:00:00 UTC',
        traffic: { searches: 335 },
    },
    {
        datetime: '2019-01-20 06:00:00 UTC',
        traffic: { searches: 194 },
    },
    {
        datetime: '2019-01-20 07:00:00 UTC',
        traffic: { searches: 144 },
    },
    {
        datetime: '2019-01-20 08:00:00 UTC',
        traffic: { searches: 111 },
    },
    {
        datetime: '2019-01-20 09:00:00 UTC',
        traffic: { searches: 155 },
    },
    {
        datetime: '2019-01-20 10:00:00 UTC',
        traffic: { searches: 96 },
    },
    {
        datetime: '2019-01-20 11:00:00 UTC',
        traffic: { searches: 72 },
    },
    {
        datetime: '2019-01-20 12:00:00 UTC',
        traffic: { searches: 232 },
    },
    {
        datetime: '2019-01-20 13:00:00 UTC',
        traffic: { searches: 371 },
    },
    {
        datetime: '2019-01-20 14:00:00 UTC',
        traffic: { searches: 580 },
    },
    {
        datetime: '2019-01-20 15:00:00 UTC',
        traffic: { searches: 860 },
    },
    {
        datetime: '2019-01-20 16:00:00 UTC',
        traffic: { searches: 901 },
    },
    {
        datetime: '2019-01-20 17:00:00 UTC',
        traffic: { searches: 919 },
    },
    {
        datetime: '2019-01-20 18:00:00 UTC',
        traffic: { searches: 920 },
    },
    {
        datetime: '2019-01-20 19:00:00 UTC',
        traffic: { searches: 1187 },
    },
    {
        datetime: '2019-01-20 20:00:00 UTC',
        traffic: { searches: 1150 },
    },
    {
        datetime: '2019-01-20 21:00:00 UTC',
        traffic: { searches: 1199 },
    },
    {
        datetime: '2019-01-20 22:00:00 UTC',
        traffic: { searches: 1160 },
    },
    {
        datetime: '2019-01-20 23:00:00 UTC',
        traffic: { searches: 923 },
    },
    {
        datetime: '2019-01-21 00:00:00 UTC',
        traffic: { searches: 1054 },
    },
    {
        datetime: '2019-01-21 01:00:00 UTC',
        traffic: { searches: 957 },
    },
    {
        datetime: '2019-01-21 02:00:00 UTC',
        traffic: { searches: 686 },
    },
    {
        datetime: '2019-01-21 03:00:00 UTC',
        traffic: { searches: 570 },
    },
    {
        datetime: '2019-01-21 04:00:00 UTC',
        traffic: { searches: 461 },
    },
    {
        datetime: '2019-01-21 05:00:00 UTC',
        traffic: { searches: 222 },
    },
    {
        datetime: '2019-01-21 06:00:00 UTC',
        traffic: { searches: 199 },
    },
    {
        datetime: '2019-01-21 07:00:00 UTC',
        traffic: { searches: 179 },
    },
    {
        datetime: '2019-01-21 08:00:00 UTC',
        traffic: { searches: 95 },
    },
    {
        datetime: '2019-01-21 09:00:00 UTC',
        traffic: { searches: 168 },
    },
    {
        datetime: '2019-01-21 10:00:00 UTC',
        traffic: { searches: 131 },
    },
    {
        datetime: '2019-01-21 11:00:00 UTC',
        traffic: { searches: 172 },
    },
    {
        datetime: '2019-01-21 12:00:00 UTC',
        traffic: { searches: 294 },
    },
    {
        datetime: '2019-01-21 13:00:00 UTC',
        traffic: { searches: 438 },
    },
    {
        datetime: '2019-01-21 14:00:00 UTC',
        traffic: { searches: 595 },
    },
    {
        datetime: '2019-01-21 15:00:00 UTC',
        traffic: { searches: 662 },
    },
    {
        datetime: '2019-01-21 16:00:00 UTC',
        traffic: { searches: 740 },
    },
    {
        datetime: '2019-01-21 17:00:00 UTC',
        traffic: { searches: 725 },
    },
    {
        datetime: '2019-01-21 18:00:00 UTC',
        traffic: { searches: 714 },
    },
    {
        datetime: '2019-01-21 19:00:00 UTC',
        traffic: { searches: 714 },
    },
    {
        datetime: '2019-01-21 20:00:00 UTC',
        traffic: { searches: 799 },
    },
    {
        datetime: '2019-01-21 21:00:00 UTC',
        traffic: { searches: 867 },
    },
];
export const COLLECTIONS = ['first', 'second', 'third'];
export const HEALTHCHECK = {
    customerId: 'mockCustomer',
    customerArea: 'mockArea',
    checks: [
        {
            title: 'Customer level healthchecks',
            statusMessage: 'status message',
            status: 'pass',
            hasNonPassingChild: true,
            quickcheck: {
                id: 'customer_level_healthchecks',
                enabled: true,
                running: false,
                disabledUntil: '2019-02-27T22:14:43Z',
            },
            lastRun: '2019-03-04T19:35:41Z',
            details: {
                results: [],
                causes: ['has a cause'],
                impacts: ['has an impact'],
                solutions: ['has a solution'],
            },
            checks: [
                {
                    title: 'Ratio of sessions resetting is too high',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'session_ids_resetting',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T22:14:43Z',
                    },
                    lastRun: '2019-03-04T05:01:52Z',
                    details: {
                        results: [{ key: 'Average sessions per visitor', value: '1.50%' }],
                        causes: ['The session cookie is being reset after every event is fired'],
                        impacts: [
                            'Analyzing historical user behaviour will not be possible',
                            'Negatively affects the Recommendations Service',
                        ],
                        solutions: ['Ensure session cookies are not being deleted or reset'],
                    },
                    checks: null,
                },
                {
                    title: 'Comparing unique session ids counts to unique logins ids',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'visitors_greater_logins',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T22:14:43Z',
                    },
                    lastRun: '2019-03-04T05:01:41Z',
                    details: {
                        results: [{ key: 'Total number of sessions', value: '12' }, { key: 'Total number of logins', value: '0' }],
                        causes: ['The login cookie is being reset after every event is fired'],
                        impacts: [
                            'Analyzing historical user behaviour will not be possible',
                            'Negatively affects the Recommendations Service',
                        ],
                        solutions: ['Ensure login cookies are not being deleted or reset'],
                    },
                    checks: null,
                },
                {
                    title: 'Comparing unique session ids counts to unique visitors ids',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'visitors_greater_sessions',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T22:14:43Z',
                    },
                    lastRun: '2019-03-04T05:01:56Z',
                    details: {
                        results: [{ key: 'Total number of sessions', value: '3' }, { key: 'Total number of visitors', value: '3' }],
                        causes: ['The visitor cookie is being reset after every event is fired'],
                        impacts: [
                            'Analyzing historical user behaviour will not be possible',
                            'Negatively affects the Recommendations Service',
                        ],
                        solutions: ['Ensure visitor cookies are not being deleted or reset'],
                    },
                    checks: null,
                },
            ],
        },
        {
            title: 'Checking for AddtoCart beacon',
            statusMessage: 'status message',
            status: 'warn',
            hasNonPassingChild: true,
            quickcheck: {
                id: 'add_to_cart_missing_beacon',
                enabled: true,
                running: false,
                disabledUntil: '2019-02-27T22:15:02Z',
            },
            lastRun: '2019-03-04T05:01:47Z',
            details: {
                results: [{ key: 'Total number of events', value: '1' }],
                causes: ['Event is not being beaconed', 'Beacons are invalid due to schema mismatch'],
                impacts: ['Any analysis that require these events will not be functional'],
                solutions: ['Properly implement beaconing for this event type'],
            },
            checks: [
                {
                    title: 'Add to cart events are missing important information',
                    statusMessage: 'status message',
                    status: 'fail',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'add_to_cart_missing_event_data',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T22:15:02Z',
                    },
                    lastRun: '2019-03-04T05:01:51Z',
                    details: {
                        results: [
                            { key: 'GBI Flag', value: 'true' },
                            { key: 'Empty ID Field Count', value: '0' },
                            { key: 'Empty IDs Ratio', value: '0.00%' },
                            { key: 'SKU Is Empty Count', value: '1' },
                            { key: 'SKUs Are Empty Ratio', value: '100.00%' },
                            { key: 'Price Is Empty Count', value: '0' },
                            { key: 'Price Is Empty Ratio', value: '0.00%' },
                            { key: 'Quantity Is Empty Count', value: '0' },
                            { key: 'Quantity Is Empty Ratio', value: '0.00%' },
                            { key: 'Total', value: '1' },
                        ],
                        causes: [
                            'Optional, but important information is missing in the beacons, such as product ID, SKU, price, and quantity',
                        ],
                        impacts: [
                            'Beacons may be ignored when generating recommendations and analytics',
                            'The result of data analysis will be inaccurate',
                        ],
                        solutions: ['Ensure optional fields are being sent, if the information is available'],
                    },
                    checks: null,
                },
            ],
        },
        {
            title: 'Checking for Remove From Cart beacon',
            statusMessage: 'status message',
            status: 'warn',
            hasNonPassingChild: true,
            quickcheck: {
                id: 'remove_from_cart_missing_beacon',
                enabled: true,
                running: false,
                disabledUntil: '2019-02-27T21:42:29Z',
            },
            lastRun: '2019-03-04T05:01:41Z',
            details: {
                results: [{ key: 'Total number of events', value: '1' }],
                causes: ['Event is not being beaconed', 'Beacons are invalid due to schema mismatch'],
                impacts: ['Any analysis that require these events will not be functional'],
                solutions: ['Properly implement beaconing for this event type'],
            },
            checks: [
                {
                    title: 'Checking the duplication rate of removeFromCart beacons',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'remove_from_cart_duplicate_beacon',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T21:42:29Z',
                    },
                    lastRun: '2019-03-04T05:02:31Z',
                    details: {
                        results: [
                            { key: 'Event Type', value: 'removeFromCart' },
                            { key: 'Event Count', value: '0' },
                            { key: 'Duplicate Count', value: '0' },
                            { key: 'Duplicate Rate', value: '0.00%' },
                        ],
                        causes: ['Event is not being beaconed', 'Beacons are invalid due to schema mismatch'],
                        impacts: ['Any analysis that require these events will not be functional'],
                        solutions: ['Properly implement beaconing for this event type'],
                    },
                    checks: null,
                },
                {
                    title: 'Checking the missing referrer rate on RemoveFromCart beacons',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'remove_from_cart_missing_referrer',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T21:42:29Z',
                    },
                    lastRun: '2019-03-04T05:02:07Z',
                    details: {
                        results: [
                            { key: 'EventType', value: 'removeFromCart' },
                            { key: 'Number of events with an empty referer', value: '0' },
                            { key: 'Total Events', value: '1' },
                            { key: 'Referrer Empty Percentage', value: '0.00%' },
                        ],
                        causes: ['Outdated tracker client (browser script)'],
                        impacts: ['Solutions that rely on historical user behaviour will not be fully functional'],
                        solutions: [
                            'Ensure the tracker client in the bundle is up to date (if being bundled)',
                            'Use the tracker client from our CDN, which is always up to date',
                        ],
                    },
                    checks: [
                        {
                            title: 'Checking the incorrect referrer rate on RemoveFromCart beacons',
                            statusMessage: 'status message',
                            status: 'pass',
                            hasNonPassingChild: false,
                            quickcheck: {
                                id: 'remove_from_cart_incorrect_referrer',
                                enabled: false,
                                running: false,
                                disabledUntil: '2019-02-27T21:42:29Z',
                            },
                            lastRun: '2019-03-04T05:02:12Z',
                            details: {
                                results: [
                                    { key: 'Event Type', value: 'removeFromCart' },
                                    { key: 'Event Count', value: '0' },
                                    { key: 'Duplicate Count', value: '0' },
                                    { key: 'Duplicate Rate', value: '0.00%' },
                                ],
                                causes: ['Outdated tracker client (browser script)'],
                                impacts: ['Solutions that rely on historical user behaviour will not be fully functional'],
                                solutions: [
                                    'Ensure the tracker client in the bundle is up to date (if being bundled)',
                                    'Use the tracker client from our CDN, which is always up to date',
                                ],
                            },
                            checks: null,
                        },
                    ],
                },
                {
                    title: 'Checking the multiple referrer rate on RemoveFromCart beacons',
                    statusMessage: 'status message',
                    status: 'pass',
                    hasNonPassingChild: false,
                    quickcheck: {
                        id: 'remove_from_cart_multiple_items',
                        enabled: false,
                        running: false,
                        disabledUntil: '2019-02-27T21:42:29Z',
                    },
                    lastRun: '2019-03-04T05:02:52Z',
                    details: {
                        results: [
                            { key: 'GBI Flag', value: 'true' },
                            { key: 'Total events', value: '1' },
                            { key: 'Count of items on cart', value: '1' },
                        ],
                        causes: ['The entire cart is being sent in the beacon instead of the difference (newly added products)'],
                        impacts: [
                            'Results for recommendations and analytics will be inaccurate',
                            "Determining the impact of user behaviour on the user's shopping cart will not be possible",
                        ],
                        solutions: ['Ensure only the products that were added to the cart are sent in the beacon'],
                    },
                    checks: null,
                },
            ],
        },
    ],
};
export const QUICKCHECK = {
    customerId: 'mockCustomer',
    customerArea: 'mockArea',
    quickcheck: {
        id: 'remove_from_cart_multiple_items',
        enabled: false,
        running: false,
        disabledUntil: '2019-02-27T21:42:29Z',
    },
};
export const BULK_LOCK_STATUS = {
    customerId: 'mockCustomer',
    area: 'mockArea',
    type: 'Rule',
    ids: [],
};
export const LOCK_STATUS = {
    locked: false,
};
export const CLIENT_PRODUCT_REPORTS = [
    {
        id: 60,
        customerId: 87,
        timestamp: '2019-01-01T01:00:00.000Z',
        period: 'DAY',
        totalProducts: 28623,
        newProducts: 8623,
        activeProducts: 18000,
        inactiveProducts: 2000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 61,
        customerId: 87,
        timestamp: '2019-01-02T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40623,
        newProducts: 5000,
        activeProducts: 34623,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 62,
        customerId: 87,
        timestamp: '2019-01-03T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 82623,
        newProducts: 15000,
        activeProducts: 60623,
        inactiveProducts: 7000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 63,
        customerId: 87,
        timestamp: '2019-01-04T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 30000,
        activeProducts: 40000,
        inactiveProducts: 20000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 64,
        customerId: 87,
        timestamp: '2019-01-05T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40000,
        newProducts: 16000,
        activeProducts: 25000,
        inactiveProducts: 9000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 65,
        customerId: 87,
        timestamp: '2019-01-06T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 29000,
        newProducts: 9000,
        activeProducts: 15000,
        inactiveProducts: 5000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 66,
        customerId: 87,
        timestamp: '2019-01-07T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 36000,
        newProducts: 6000,
        activeProducts: 19000,
        inactiveProducts: 11000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 67,
        customerId: 87,
        timestamp: '2019-01-08T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 11000,
        activeProducts: 55000,
        inactiveProducts: 4000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 68,
        customerId: 87,
        timestamp: '2019-01-09T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 20000,
        activeProducts: 40000,
        inactiveProducts: 10000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 69,
        customerId: 87,
        timestamp: '2019-01-10T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 35000,
        newProducts: 5000,
        activeProducts: 29000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 70,
        customerId: 87,
        timestamp: '2019-01-11T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 28623,
        newProducts: 8623,
        activeProducts: 18000,
        inactiveProducts: 2000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 71,
        customerId: 87,
        timestamp: '2019-01-12T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40623,
        newProducts: 5000,
        activeProducts: 34623,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 72,
        customerId: 87,
        timestamp: '2019-01-13T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 82623,
        newProducts: 15000,
        activeProducts: 60623,
        inactiveProducts: 7000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 73,
        customerId: 87,
        timestamp: '2019-01-14T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 30000,
        activeProducts: 40000,
        inactiveProducts: 20000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 74,
        customerId: 87,
        timestamp: '2019-01-15T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40000,
        newProducts: 16000,
        activeProducts: 25000,
        inactiveProducts: 9000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 75,
        customerId: 87,
        timestamp: '2019-01-16T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 29000,
        newProducts: 9000,
        activeProducts: 15000,
        inactiveProducts: 5000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 76,
        customerId: 87,
        timestamp: '2019-01-17T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 36000,
        newProducts: 6000,
        activeProducts: 19000,
        inactiveProducts: 11000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 77,
        customerId: 87,
        timestamp: '2019-01-18T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 11000,
        activeProducts: 55000,
        inactiveProducts: 4000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 78,
        customerId: 87,
        timestamp: '2019-01-19T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 20000,
        activeProducts: 40000,
        inactiveProducts: 10000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 79,
        customerId: 87,
        timestamp: '2019-01-20T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 35000,
        newProducts: 5000,
        activeProducts: 29000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 80,
        customerId: 87,
        timestamp: '2019-01-21T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 28623,
        newProducts: 8623,
        activeProducts: 18000,
        inactiveProducts: 2000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 81,
        customerId: 87,
        timestamp: '2019-01-22T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40623,
        newProducts: 5000,
        activeProducts: 34623,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 82,
        customerId: 87,
        timestamp: '2019-01-23T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 82623,
        newProducts: 15000,
        activeProducts: 60623,
        inactiveProducts: 7000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 83,
        customerId: 87,
        timestamp: '2019-01-24T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 30000,
        activeProducts: 40000,
        inactiveProducts: 20000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 84,
        customerId: 87,
        timestamp: '2019-01-25T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 40000,
        newProducts: 16000,
        activeProducts: 25000,
        inactiveProducts: 9000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 85,
        customerId: 87,
        timestamp: '2019-01-26T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 29000,
        newProducts: 9000,
        activeProducts: 15000,
        inactiveProducts: 5000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 86,
        customerId: 87,
        timestamp: '2019-01-27T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 36000,
        newProducts: 6000,
        activeProducts: 19000,
        inactiveProducts: 11000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 87,
        customerId: 87,
        timestamp: '2019-01-28T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 11000,
        activeProducts: 55000,
        inactiveProducts: 4000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 88,
        customerId: 87,
        timestamp: '2019-01-29T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 70000,
        newProducts: 20000,
        activeProducts: 40000,
        inactiveProducts: 10000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 89,
        customerId: 87,
        timestamp: '2019-01-30T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 35000,
        newProducts: 5000,
        activeProducts: 29000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 90,
        customerId: 87,
        timestamp: '2019-01-31T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 30000,
        newProducts: 5000,
        activeProducts: 24000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 90,
        customerId: 87,
        timestamp: '2019-02-01T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 30000,
        newProducts: 5000,
        activeProducts: 24000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
    {
        id: 90,
        customerId: 87,
        timestamp: '2019-02-02T00:00:00.000Z',
        period: 'DAY',
        totalProducts: 30000,
        newProducts: 5000,
        activeProducts: 24000,
        inactiveProducts: 1000,
        averageAttributes: 0,
        totalAttributes: 20623,
    },
];
export const CUSTOMER_INFO = {
    id: 90,
    customerId: 87,
    timestamp: '2019-02-02T00:00:00.000Z',
    period: 'DAY',
    totalProducts: 30000,
    newProducts: 5000,
    activeProducts: 24000,
    inactiveProducts: 1000,
    averageAttributes: 89,
    totalAttributes: 20623,
    name: 'Globo Gym',
    active: true,
    searchName: 'Dodgeball',
    contract: {
        customerId: 87,
        contractStart: '20180301',
        contractEnd: '20191231',
        annualProductLimit: 60000,
    },
};
const DB = {
    Production: {
        spelling: addIds([
            { name: 'woah', correction: 'wish', promote: false },
            { name: 'other', correction: 'mother', promote: true },
        ]),
        filter: addIds([
            {
                name: 'filter-one',
                type: Filter.Type.NAVIGATION_VALUE,
                navigationName: 'myName',
                value: 'lolol',
                promote: false,
            },
        ]),
        redirect: addIds([
            {
                name: 'nike',
                location: '/nike.html',
                global: false,
                promote: true,
            },
            {
                name: 'apple',
                location: '/apple.html',
                global: true,
                promote: false,
            },
        ]),
        relatedQuery: addIds([
            { name: 'orange', relatedQueries: ['apple'], promote: true },
            { name: 'blue', relatedQueries: ['ocean', 'sea'], promote: false },
        ]),
        stopWord: addIds([{ name: 'a', promote: true }, { name: 'the', promote: false }]),
        phrase: addIds([
            { name: 'running with scissors', promote: false },
            { name: 'save the whales', promote: true },
        ]),
        navigation: addIds([
            {
                name: 'price',
                displayName: 'Price',
                type: Navigation.Type.RANGE,
                unionable: false,
                ranges: [{ low: 10, high: 23 }, { low: 53, high: 83 }],
                metadata: [],
                buckets: 0,
                sort: { field: Navigation.SortField.VALUE, order: Sort.Order.ASCENDING },
                precedence: [],
                pinnedRefinements: [],
                priority: 0,
                intelligentNavigationEnabled: false,
            },
            {
                name: 'brand',
                displayName: 'Brand',
                type: Navigation.Type.VALUE,
                unionable: true,
                ranges: [],
                metadata: [{ key: 'color', value: 'red' }],
                buckets: 0,
                sort: { field: Navigation.SortField.COUNT, order: Sort.Order.DESCENDING },
                precedence: [],
                pinnedRefinements: [],
                priority: 1,
                intelligentNavigationEnabled: true,
            },
        ]),
        synonym: addIds([
            {
                name: 'original',
                synonyms: ['classic'],
                type: Synonym.Type.ONE_WAY,
                promote: false,
            },
            {
                name: 'flaming,fiery',
                synonyms: [],
                type: Synonym.Type.TWO_WAY,
                promote: true,
            },
        ]),
        matchStrategy: addIds([
            {
                name: 'simple strategy',
                rules: [{ terms: 4, mustMatch: '2' }, { terms: 5, greaterThan: true, mustMatch: '3' }],
            },
            { name: 'complex strategy', rules: [{ terms: 6, mustMatch: '4' }] },
        ]),
        biasingProfile: addIds([
            {
                name: 'push popular',
                influence: 8,
                matchMultiple: false,
                biases: [{ name: 'popular', content: 'true', strength: 'Medium_Increase' }],
                numericBoosts: [{ name: 'hits', weight: 3.2, inverted: false }],
            },
            {
                name: 'push latest',
                influence: 2.4,
                matchMultiple: true,
                biases: [{ name: 'date', strength: 'Strong_Increase' }],
                numericBoosts: [{ name: 'timeDecay', weight: 1.7, inverted: true }],
            },
        ]),
        template: addIds([
            {
                name: 'banner',
                zones: [{ name: 'some content', type: ZoneContent.Type.CONTENT, value: 'some value' }],
                image: IMAGE_PLACEHOLDER,
                thumbnailImage: IMAGE_PLACEHOLDER,
            },
            {
                name: 'landing page',
                zones: [{ reusableZone: 'named query', type: ZoneContent.Type.QUERY }],
                image: IMAGE_PLACEHOLDER,
                thumbnailImage: IMAGE_PLACEHOLDER,
            },
        ]),
        rule: addIds([
            {
                sort: {
                    field: 'price',
                    order: Sort.Order.ASCENDING,
                },
                abTesting: null,
                isDisplayInactiveText: false,
                triggerSets: [
                    [
                        { type: Rule.Trigger.Type.SEARCH_CONTAINS, value: 'new, trigger' },
                        { type: Rule.Trigger.Type.SEARCH_MATCHES, value: 'this, trigger' },
                        { type: Rule.Trigger.Type.NO_RESULTS_EVENT },
                    ],
                    [
                        { type: Rule.Trigger.Type.NAVIGATION_VALUE, value: 'nike, shoes', navigationName: 'brand' },
                        { type: Rule.Trigger.Type.NO_NAVIGATION_EVENT },
                    ],
                    [{
                            type: Rule.Trigger.Type.NAVIGATION_RANGE,
                            navigationName: 'price',
                            low: 40,
                            high: 80,
                        }],
                    [{ type: Rule.Trigger.Type.CUSTOM_PARAM, customUrlParam: { key: 'nice', value: 'custom' } }],
                ],
                includedNavigations: [],
                intelligentNavigationEnabled: false,
                navigationOverrides: [],
                bringToTop: ['12', '23'],
                sortByIds: [],
                restrictToIds: [],
                filters: [],
                nested: true,
                zoneContext: false,
                promote: true,
                priority: 0,
                name: 'Fun Rule',
                biasingProfile: 'push popular',
                template: {
                    name: 'banner',
                    zones: [{ name: 'some content', type: ZoneContent.Type.CONTENT }],
                    image: IMAGE_PLACEHOLDER,
                    thumbnailImage: IMAGE_PLACEHOLDER,
                    id: '0',
                },
            },
        ]),
        zone: addIds([{ name: 'content_zone', zone: { type: ZoneContent.Type.CONTENT, value: 'Christmas Trees' } }]),
    },
};
export default DB;
function addIds(items) {
    return items.map((item, index) => (Object.assign(Object.assign({}, item), { id: String(index) })));
}
