import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import { compose } from 'ramda';
import TableCell from '@/components/cell';
import IconButton from '@/components/controls/icon-button';
import { Align, Type } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    rotated: {
        transform: 'rotate(180deg)',
    },
    icon: {
        transition: 'transform 0.5s',
        position: 'static',
        padding: `${spacing(0.5)}px`,
    },
});
export const enhance = compose(withStyles(styles));
export const ExpansionCell = enhance(function ExpansionCell({ onClick, classes, tooltip = '', rotated = false, showExpansionButton = true, }) {
    return (<TableCell type={Type.SMALL} align={Align.CENTER}>
      <Tooltip title={tooltip} placement="top-start">
        {showExpansionButton && (<IconButton onClick={onClick} className={classNames(classes.icon, { [classes.rotated]: rotated })} disableRipple>
            <ArrowDropDownIcon />
          </IconButton>)}
      </Tooltip>
    </TableCell>);
});
export default ExpansionCell;
