import * as Actions from '@/store/actions/types';
import * as CopyToAreaReducers from './copy-to-area';
export const DEFAULT = {};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.OPEN_COPY_TO_AREA_MODAL:
            return CopyToAreaReducers.openModalReducer(state, action);
        case Actions.SET_COPY_TO_AREA_TARGET:
            return CopyToAreaReducers.setAreaReducer(state, action);
        case Actions.SET_DUPLICATE_MODEL_ID:
            return CopyToAreaReducers.setDuplicateModelReducer(state, action);
        case Actions.CLOSE_COPY_TO_AREA_MODAL:
            return DEFAULT;
        default:
            return state;
    }
};
export function openModalReducer(state, { payload: id }) {
    return Object.assign(Object.assign({}, state), { id });
}
export function setAreaReducer(state, { payload: targetArea }) {
    return Object.assign(Object.assign({}, state), { targetArea });
}
export function setDuplicateModelReducer(state, { payload: duplicateId }) {
    return Object.assign(Object.assign({}, state), { duplicateId });
}
