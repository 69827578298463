import { __decorate } from "tslib";
import React from 'react';
import MaterialDrawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import classNames from 'classnames';
import Button from '@/components/controls/button';
import { withStyles } from '@/decorators';
import { PREVIEW_CONFIG } from '@/preview-drawer-config';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const styles = ({ appHeader, spacing, fontSize, palette, breakpoints, }) => ({
    drawer: {
        display: 'initial',
        width: '50vw',
        top: appHeader.height,
        boxShadow: '-1px 0 10px 0 rgba(0,0,0,0.16)',
        [breakpoints.down('sm')]: {
            top: appHeader.heightMobile,
        },
        '&$hidden': {
            display: 'none',
        },
    },
    container: {
        margin: 0,
    },
    header: {
        height: spacing(7.5),
        margin: 0,
        borderBottom: `1px solid ${palette.grey[300]}`,
        padding: `${spacing(1 / 4)}px ${spacing(3)}px 0`,
        boxShadow: '0 3px 10px 0 rgba(0,0,0,0.16)',
    },
    title: {
        fontSize: fontSize.itemSubHeader,
        color: palette.mainFont,
        fontWeight: 500,
    },
    button: {
        minHeight: 0,
        padding: `0 ${spacing(1)}px`,
        fontSize: fontSize.caption,
        display: 'flex',
        alignItems: 'center',
    },
    arrowIcon: {
        marginTop: '-1px',
    },
    hidden: {},
});
export const selectors = {
    isOpen: Selectors.previewDrawerOpenSelector,
    activeModelType: Selectors.activeModelTypeSelector,
    path: Selectors.activePathSelector,
    query: Selectors.rawActiveQuerySelector,
};
export const actionCreators = {
    closeDrawer: ActionCreators.closePreviewDrawer,
};
export const mergeProps = ({ activeModelType, path, query, }) => {
    const previewConfig = PREVIEW_CONFIG[activeModelType] || {};
    return {
        title: previewConfig.title,
        previewComponent: previewConfig.component,
        locationWithQuery: path + query,
    };
};
let PreviewDrawer = class PreviewDrawer extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { transitionComplete: true };
        this.closeDrawer = () => {
            this.props.closeDrawer();
            this.startTransition();
        };
        this.startTransition = () => this.setState({ transitionComplete: false });
        this.endTransition = () => this.setState({ transitionComplete: true });
    }
    render() {
        const { transitionComplete } = this.state;
        const { isOpen, classes, title, previewComponent: PreviewComponent, } = this.props;
        return (<MaterialDrawer classes={{ paper: classNames(classes.drawer, { [classes.hidden]: !isOpen && transitionComplete }) }} variant="persistent" anchor="right" open={isOpen} SlideProps={{
            onEnter: this.startTransition,
            onEntered: this.endTransition,
            onExited: this.endTransition,
        }} transitionDuration={500}>
        <Grid container classes={{ container: classes.container }}>
          <Grid container item xs={12} justify="space-between" alignItems="center" classes={{ container: classes.header }}>
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                {title} Preview
              </Typography>
            </Grid>
            <Grid item>
              <Button className={classes.button} variant="tertiary" onClick={this.closeDrawer}>
                <ArrowIcon classes={{ root: classes.arrowIcon }}/>
                <span>CLOSE PREVIEW</span>
              </Button>
            </Grid>
          </Grid>
          {PreviewComponent && isOpen && transitionComplete && <PreviewComponent />}
        </Grid>
      </MaterialDrawer>);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.isOpen && prevProps.locationWithQuery !== this.props.locationWithQuery) {
            this.props.closeDrawer();
        }
    }
};
PreviewDrawer = __decorate([
    connect(selectors, actionCreators, mergeProps),
    (withStyles(styles))
], PreviewDrawer);
export default PreviewDrawer;
