import { __rest } from "tslib";
import { equals, isEmpty } from 'ramda';
import { getFormValues } from 'redux-form';
import { call, delay, fork, put, select, takeEvery, takeLatest, } from 'redux-saga/effects';
import { DEFAULT_AREA, ModelType, SEARCH_QUERY_UPDATE_DELAY } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as Actions from '@/store/actions/types';
import * as Selectors from '@/store/selectors';
import { convertToElasticPathFormat, dot, getManagerName, isValidField, renameProp, transformCSV, } from '@/utils';
import State from '../state';
import * as Tasks from './tasks';
var PinType = State.SearchPreview.PinType;
export const DEFAULT_COLLECTION = 'default';
export const BIASING_FORM_UPDATE_DELAY = 1000;
export function forceStringy(value) {
    return ['string', 'number', 'boolean'].includes(typeof value) ? value : '';
}
export function transformProductFields(records, configuration) {
    const { title, url, imageUrl, imageUrlPrefix, imageUrlSuffix, } = configuration;
    return records.map(({ allMeta }) => {
        const imageUrlValue = forceStringy(dot.get(allMeta, imageUrl) || '').trim();
        return Object.assign({ title: forceStringy(dot.get(allMeta, title)), url: forceStringy(dot.get(allMeta, url)), id: allMeta.id }, (imageUrlValue ? { imageUrl: `${imageUrlPrefix || ''}${imageUrlValue}${imageUrlSuffix || ''}` } : {}));
    });
}
export function generateFieldsList(configuration) {
    const keys = Object.keys(trimFields(configuration)).filter((field) => !['imageUrlSuffix', 'imageUrlPrefix'].includes(field));
    return keys.length ? ['id', ...keys.map((field) => convertToElasticPathFormat(configuration[field]))] : ['*'];
}
export function transformNumericBoostsField({ numericBoosts, biases, influence }) {
    return {
        biases,
        influence,
        numericBoosts: numericBoosts.map((numericBoost) => renameProp('weight', 'strength', numericBoost)),
    };
}
export function trimFields(configuration) {
    return Object.keys(configuration).reduce((acc, curr) => (isValidField(configuration[curr]) ? Object.assign(Object.assign({}, acc), { [curr]: configuration[curr].trim() }) : acc), {});
}
export function* modelFormUpdate({ payload: formName }) {
    const drawerOpen = yield select(Selectors.previewDrawerOpenSelector);
    const activeModelType = yield select(Selectors.activeModelTypeSelector);
    if (drawerOpen && activeModelType === ModelType.BIASING_PROFILE) {
        yield call(delayFetchProducts, BIASING_FORM_UPDATE_DELAY);
    }
    else if (activeModelType === ModelType.RULE) {
        const rule = yield select(getFormValues(formName));
        const { bringToTop = [] } = rule || {};
        const { pushToTopProducts } = yield select(Selectors.searchPreviewSelector);
        const pushToTopIds = pushToTopProducts.map(({ id }) => id);
        if (!equals(bringToTop, pushToTopIds)) {
            yield call(fetchPushToTopProducts, ActionCreators.loadPushToTopProducts(formName, true));
        }
    }
}
export function* delayFetchProducts(timeInterval) {
    yield delay(timeInterval);
    yield call(fetchProducts);
}
export function* fetchProducts() {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        const { query, queryType, pageSize, configurationByArea, } = yield select(Selectors.searchPreviewSelector);
        const activeArea = yield select(Selectors.activeAreaSelector);
        const _a = configurationByArea[activeArea]
            || configurationByArea[DEFAULT_AREA] || { collection: null }, { collection } = _a, configuration = __rest(_a, ["collection"]);
        const activeModelType = yield select(Selectors.activeModelTypeSelector);
        const biasing = activeModelType === ModelType.BIASING_PROFILE
            ? yield select(getFormValues(getManagerName(ModelType.BIASING_PROFILE)))
            : {};
        const isQuerySearch = queryType === State.SearchPreview.QueryType.QUERY;
        const trimmedQuery = query.trim();
        const transformedBiasing = isEmpty(biasing) ? biasing : transformNumericBoostsField(biasing);
        // this is to prevent an API error if there is an array with only an empty string
        const productIdSearchBiasingArray = isEmpty(trimmedQuery) ? [] : transformCSV(trimmedQuery);
        const productIdBiasing = {
            bringToTop: productIdSearchBiasingArray,
            restrictToIds: productIdSearchBiasingArray,
        };
        const queryTypeOptions = isQuerySearch && {
            query,
            biasing: transformedBiasing,
        };
        const baseOptions = {
            pageSize,
            collection: collection || DEFAULT_COLLECTION,
            fields: generateFieldsList(configuration),
            area: activeArea,
            biasing: productIdBiasing,
        };
        const { records } = yield call(Tasks.authCall, client.searchPreview, Object.assign(Object.assign({}, baseOptions), queryTypeOptions));
        if (!records.length) {
            yield put(ActionCreators.updateSearchPreviewError('No products found for your search'));
        }
        else {
            const products = yield call(transformProductFields, records, configuration);
            yield put(ActionCreators.updateSearchPreviewProducts(products));
        }
    }
    catch ({ errorMessage }) {
        yield put(ActionCreators.updateSearchPreviewError(errorMessage));
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* fetchPushToTopProducts({ payload: { isBringToTop } }) {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        const { configurationByArea } = yield select(Selectors.searchPreviewSelector);
        const activeArea = yield select(Selectors.activeAreaSelector);
        const _a = configurationByArea[activeArea]
            || configurationByArea[DEFAULT_AREA] || { collection: null }, { collection } = _a, configuration = __rest(_a, ["collection"]);
        const rule = yield select(getFormValues(getManagerName(ModelType.RULE)));
        const { bringToTop = [] } = rule || {};
        const { pushToTopProducts } = yield select(Selectors.searchPreviewSelector);
        const pushToTopIds = pushToTopProducts.length === 0 ? bringToTop : pushToTopProducts.map(({ id }) => id);
        const productIds = isBringToTop ? bringToTop : pushToTopIds;
        if (productIds.length > 0) {
            const { records } = yield call(Tasks.authCall, client.searchPreview, {
                biasing: { bringToTop: productIds, restrictToIds: productIds },
                collection: collection || DEFAULT_COLLECTION,
                fields: generateFieldsList(configuration),
                pageSize: productIds.length,
                area: activeArea,
            });
            const foundPinnedProducts = yield call(transformProductFields, records, configuration);
            const allPinnedProducts = productIds.map((id) => foundPinnedProducts.find((foundProduct) => id === foundProduct.id) || { id, title: id, notInCollection: true });
            yield put(ActionCreators.updatePushToTopProducts(allPinnedProducts));
        }
        else {
            yield put(ActionCreators.updatePushToTopProducts([]));
        }
    }
    catch ({ errorMessage }) {
        yield put(ActionCreators.updateSearchPreviewError(errorMessage));
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* fetchResultManagementProducts({ payload: { productIds, restrictToIds = false, formName }, }) {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        const { configurationByArea } = yield select(Selectors.searchPreviewSelector);
        const activeArea = yield select(Selectors.activeAreaSelector);
        const _a = configurationByArea[activeArea]
            || configurationByArea[DEFAULT_AREA] || { collection: null }, { collection } = _a, configuration = __rest(_a, ["collection"]);
        const rule = yield select(getFormValues(formName));
        if (productIds.length > 0) {
            const { records } = yield call(Tasks.authCall, client.searchPreview, {
                collection,
                biasing: restrictToIds ? { restrictToIds: productIds } : { bringToTop: productIds, restrictToIds: productIds },
                fields: generateFieldsList(configuration),
                pageSize: restrictToIds ? productIds.length + rule.bringToTop.length : productIds.length,
                area: activeArea,
            });
            const foundPinnedProducts = yield call(transformProductFields, records, configuration);
            const allProducts = productIds.map((id) => foundPinnedProducts.find((foundProduct) => id === foundProduct.id) || { id, title: id, notInCollection: true });
            const resultManagementFormValues = {
                [PinType.PUSH_TO_TOP]: rule.bringToTop,
                [PinType.RESTRICT_TO_IDS]: rule.restrictToIds,
                [PinType.SORT_BY_IDS]: rule.sortByIds,
            };
            const allPinnedProducts = allProducts.map((product) => {
                const includedPins = [];
                Object.keys(resultManagementFormValues).forEach((mode) => resultManagementFormValues[mode].includes(product.id) && includedPins.push(mode));
                return Object.assign(Object.assign({}, product), { includedPins });
            });
            if (restrictToIds) {
                yield put(ActionCreators.updateRestrictToIdsProductIds(foundPinnedProducts.map(({ id }) => id)));
            }
            else {
                yield put(ActionCreators.updateResultManagementProducts(allPinnedProducts));
            }
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            restrictToIds
                ? yield put(ActionCreators.updateRestrictToIdsProductIds([]))
                : yield put(ActionCreators.updateResultManagementProducts([]));
        }
    }
    catch ({ errorMessage }) {
        yield put(ActionCreators.updateSearchPreviewError(errorMessage));
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* updateSearchPreviewQuery() {
    yield call(delayFetchProducts, SEARCH_QUERY_UPDATE_DELAY);
}
export default function* searchPreviewSaga() {
    yield takeLatest(Actions.UPDATE_SEARCH_PREVIEW_QUERY, updateSearchPreviewQuery);
    yield takeLatest(Actions.LOAD_SEARCH_PREVIEW_PRODUCTS, fetchProducts);
    yield takeLatest(Actions.LOAD_PUSH_TO_TOP_PRODUCTS, fetchPushToTopProducts);
    yield takeEvery(Actions.LOAD_RESULT_MANAGEMENT_PRODUCTS, fetchResultManagementProducts);
    yield takeLatest(Actions.MODEL_FORM_UPDATE, modelFormUpdate);
}
