import { __rest } from "tslib";
import { HttpClient } from '@groupby/enrich-client';
import { replace } from 'connected-react-router';
import { difference } from 'ramda';
import { destroy, getFormNames, isDirty } from 'redux-form';
import { all, call, cancel, delay, fork, put, select, } from 'redux-saga/effects';
import * as voca from 'voca';
import API from '@/client';
import { IS_TEST_ENV, SKIP_AUTHENTICATION } from '@/config/global';
import { DEFAULT_AREA, JSON_EDITOR_FORM_NAMESPACE, ModelType, PROTECTED_FORM_NAMESPACE, Role, Routing, SSO_POPUP_NAME, } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import * as Utils from '@/utils';
import * as Tasks from './tasks';
import { buildJobCriteria, buildProductCriteria, parseCriteriaString, buildRuleCriteria, } from '@/utils/enrich-criteria-translator';
import { getPageNameFromPath } from '@/enrich/routing/utils/index';
var ModelCommentAction = State.ChangeLog.ModelCommentAction;
export const LOAD_DELAY = 500;
export const REPORT_SIZE = 100;
export const USER_HAS_NO_ACCESS_ERROR = 'User has no area access.';
export const EXPIRED_TOKEN_ERROR = 'Attempted to use an expired token.';
export const HYPHEN_PATTERN = /-/g;
export function* startLoading() {
    yield delay(LOAD_DELAY);
    yield put(ActionCreators.setLoading(true));
}
export function stopLoading(loadingTask) {
    return all([cancel(loadingTask), put(ActionCreators.setLoading(false))]);
}
export function error(message, error) {
    const errorMessage = error && error.errors && error.errors.join(' ');
    if (!IS_TEST_ENV && errorMessage) {
        console.error(errorMessage);
    }
    return put(ActionCreators.addError(message, errorMessage));
}
export function detailedError(task, error) {
    return put(ActionCreators.addError(task, error));
}
export function simpleError(task, error) {
    return Tasks.error(`Task "${Tasks.taskName(task)}" failed.`, error);
}
/* eslint-enable no-shadow */
export function success(message) {
    return put(ActionCreators.addSuccess(message));
}
export function simpleSuccess(task) {
    return Tasks.success(`Task "${Tasks.taskName(task)}" completed!`);
}
export function taskName(task) {
    return voca
        .kebabCase(task)
        .replace(HYPHEN_PATTERN, ' ')
        .trim()
        .toUpperCase();
}
export function* getAuthToken() {
    const token = yield select(Selectors.authTokenSelector);
    if (SKIP_AUTHENTICATION) {
        return token;
    }
    const expiry = Utils.decodeJWT(token).exp * 1000;
    if (expiry > Date.now()) {
        return token;
    }
    yield put(ActionCreators.expireToken());
    throw new Error(EXPIRED_TOKEN_ERROR);
}
export function* authClient() {
    const token = yield call(getAuthToken);
    return API.auth(token);
}
export function* enrichClient() {
    const customerId = yield select(Selectors.enrichCustomerIdSelector);
    const tokens = yield select(Selectors.enrichTokensSelector);
    const { authToken } = tokens.find((t) => t.enrichId === customerId);
    return API.enrichAuth(authToken);
}
export function* ccEnrichClient() {
    const token = yield select(Selectors.authTokenSelector);
    const auth = yield call(API.ccEnrichAuth, token);
    return auth;
}
export function createEnrichHttpClient(config) {
    return new HttpClient(config);
}
export function* loadKeys({ payload: skipCache }) {
    const loadingTask = yield fork(Tasks.startLoading);
    try {
        const client = yield call(Tasks.authClient);
        const [primaryKey, secondaryKey] = yield call(Tasks.authCall, client.key.get, skipCache);
        // feature flagging mechanism used to toggle to change log feature on a per-customer basis.
        const resp = yield call(Tasks.authCall, client.changeLogStatus);
        const isActive = resp.status ? resp.result : resp;
        yield put(ActionCreators.setChangeLogIsActive(isActive));
        yield put(ActionCreators.refreshKeys({ primaryKey, secondaryKey }));
    }
    catch (e) {
        yield Tasks.simpleError('load keys', e);
    }
    finally {
        yield Tasks.stopLoading(loadingTask);
    }
}
export function* adminKeyLoad(user) {
    if (user.commandCenterAccess.includes(Role.ADMIN)) {
        yield call(loadKeys, ActionCreators.loadKeys(true));
    }
}
export function* crudClient(model, area) {
    const authenticatedClient = yield call(Tasks.authClient);
    let activeArea = area;
    switch (model) {
        case ModelType.AREA:
        case ModelType.USER:
            return authenticatedClient[model];
        default:
            if (!activeArea) {
                activeArea = yield select(Selectors.activeAreaSelector);
            }
            return authenticatedClient.models(activeArea, model);
    }
}
export function* authAnalyticsClient() {
    const keys = yield select(Selectors.keysSelector);
    const client = yield call(Tasks.authClient);
    return client.analytics(keys.primaryKey);
}
export function* authAdminClient() {
    const keys = yield select(Selectors.keysSelector);
    const client = yield call(Tasks.authClient);
    return client.admin(keys.primaryKey);
}
export function* authCall(api, ...args) {
    const token = yield call(Tasks.getAuthToken);
    const { result, token: newToken } = yield call(api, ...args);
    if (newToken && newToken !== token) {
        yield put(ActionCreators.updateToken(newToken));
    }
    return result;
}
export function* login(token, user, next) {
    yield call(Tasks.refreshActiveArea, user.areas, token);
    yield put(ActionCreators.acknowledgeAuthentication(token, user));
    yield call(Tasks.adminKeyLoad, user);
    const isOnHomePage = yield select(Selectors.onHomePageSelector);
    if (!(isOnHomePage && next === '/')) {
        yield put(replace(next));
    }
    yield put(ActionCreators.loadGrove());
}
export function* openSSOPrompt(provider) {
    var _a;
    const externalAuthProviders = yield select(Selectors.externalAuthenticationProvidersSelector);
    const loginUrlSegment = (_a = externalAuthProviders.find((p) => p.name.toLowerCase() === provider)) === null || _a === void 0 ? void 0 : _a.loginUrl;
    if (loginUrlSegment) {
        const loginUrl = yield call(API.externalAuthLoginUrl, loginUrlSegment);
        yield call(window.open, loginUrl, SSO_POPUP_NAME);
    }
    else {
        throw new Error(`no provider ${provider} found`);
    }
}
export function* refreshActiveArea(allowedAreas, token) {
    let activeArea = yield select(Selectors.activeAreaSelector);
    if (!allowedAreas.includes(activeArea)) {
        if (allowedAreas.includes(DEFAULT_AREA)) {
            activeArea = DEFAULT_AREA;
        }
        else {
            const { result: areas } = yield call(API.auth(token).area.find);
            activeArea = areas.map(({ name }) => name).find((area) => allowedAreas.includes(area));
        }
    }
    if (!activeArea) {
        yield Tasks.error(USER_HAS_NO_ACCESS_ERROR);
        throw new Error(USER_HAS_NO_ACCESS_ERROR);
    }
    yield put(ActionCreators.switchArea(activeArea, true));
}
export function* goTo(createAction, modelTypeOverride) {
    const activeArea = yield select(Selectors.activeAreaSelector);
    const modelType = modelTypeOverride || (yield select(Selectors.activeModelTypeSelector));
    yield call(Tasks.destroyActiveForms);
    yield put(createAction(modelType, activeArea));
}
export function generateMatchPartial(query) {
    return query
        ? {
            and: [
                {
                    search: {
                        query,
                    },
                },
            ],
        }
        : {};
}
export function generateMatchExact(devices, metadata, area) {
    const metaDataFilter = {
        and: metadata.map(({ key, value }) => ({
            key,
            value,
        })),
    };
    const deviceFilters = devices.map((device) => ({ visit: { generated: { deviceType: device } } }));
    return Object.assign({ and: [
            { area },
            Object.assign({ metadata: metaDataFilter }, (devices.length === 1 && deviceFilters[0])),
        ] }, (devices.length > 1 && { or: deviceFilters }));
}
export function* createReportRequest(reportType) {
    const window = yield select(Selectors.reportWindowSelector);
    const { devices = [], metadata = [] } = yield select(Selectors.reportFiltersSelector(reportType));
    const query = yield select(Selectors.reportQuerySelector(reportType));
    const customerArea = yield select(Selectors.activeAreaSelector);
    return {
        window,
        matchPartial: Tasks.generateMatchPartial(query),
        matchExact: Tasks.generateMatchExact(devices, metadata, customerArea),
        size: REPORT_SIZE,
    };
}
export function* createHealthcheckRequest() {
    const area = yield select(Selectors.activeAreaSelector);
    return {
        customerArea: area,
    };
}
export function* createQuickcheckRequest(quickcheckId) {
    const area = yield select(Selectors.activeAreaSelector);
    return {
        quickcheckId,
        customerArea: area,
    };
}
export function* findDuplicate(modelType, area, modelName) {
    const client = yield call(Tasks.crudClient, modelType, area);
    const models = yield call(Tasks.authCall, client.find, true);
    if (modelType === ModelType.SYNONYM) {
        return;
    }
    return models.find(({ name }) => name === modelName);
}
export function* anyFormsDirty() {
    const names = yield select(getFormNames());
    let anyDirty = false;
    for (const name of names) {
        anyDirty = anyDirty || (name.startsWith(`${PROTECTED_FORM_NAMESPACE}/`) && (yield select(isDirty(name))));
    }
    return anyDirty;
}
export function* destroyActiveForms() {
    const modelType = yield select(Selectors.activeModelTypeSelector);
    const formNames = yield select(getFormNames());
    const managerFormName = Utils.getManagerName(modelType);
    const toDestroy = [];
    for (const formName of formNames) {
        if (formName.startsWith(managerFormName) || formName.startsWith(JSON_EDITOR_FORM_NAMESPACE)) {
            toDestroy.push(formName);
        }
    }
    if (toDestroy.length) {
        yield put(destroy(...toDestroy));
    }
}
export function* asyncSystemAction(msg, callback, args = []) {
    try {
        yield call(callback, ...args);
    }
    catch (e) {
        yield Tasks.simpleError(msg, e);
    }
}
export function* asyncUserAction(msg, callback, args = []) {
    yield call(asyncSystemAction, msg, function* () {
        const loadingTask = yield fork(Tasks.startLoading);
        const shouldNotify = yield call(callback, ...args);
        if (shouldNotify !== false) {
            yield Tasks.simpleSuccess(msg);
        }
        yield Tasks.stopLoading(loadingTask);
    });
}
export function* getFormDiff(modelType, model) {
    const state = yield select();
    const formNames = yield select(getFormNames());
    const formName = formNames.find((name) => name.includes(modelType));
    const initialFormValues = Selectors.formInitialValuesSelector(state)(formName);
    const newValues = Object.entries(model);
    const oldValues = Object.entries(initialFormValues);
    return difference(newValues, oldValues).map((entry) => entry[0]);
}
export function generateComment(action, _a) {
    var args = __rest(_a, []);
    const { formDiff, sourceIndex, targetIndex, targetArea, sourceArea, sourceName, targetName, modelType, } = args;
    switch (action) {
        case ModelCommentAction.CREATE:
            return `Created with: ${Tasks.generateUserFriendlyComments(formDiff, modelType)}`;
        case ModelCommentAction.UPDATE:
            return formDiff.length === 0
                ? 'Nothing was changed'
                : `Updated: ${Tasks.generateUserFriendlyComments(formDiff, modelType)}`;
        case ModelCommentAction.REORDER:
            return `Reordered from priority ${sourceIndex + 1} to priority ${targetIndex + 1}`;
        case ModelCommentAction.SINGLE_PROMOTE_SOURCE:
            return `Promoted ${targetName} to ${targetArea}`;
        case ModelCommentAction.SINGLE_PROMOTE_TARGET:
            return `Promoted from ${sourceName} in ${sourceArea}`;
    }
}
export const REPLACEMENTS = {
    [ModelType.RULE]: [
        ['sort', 'Sort By'],
        ['sortByIds', 'Sort By IDs'],
        ['restrictToIds', 'Restrict To IDs'],
        ['bringToTop', 'Push To Top'],
        ['navigationOverrides', 'Pinned Refinements'],
        ['filters', 'Inject Additional Filters'],
        ['abTesting', 'A/B Testing'],
        ['intelligentNavigationEnabled', 'Intelligent Navigation'],
    ],
    [ModelType.RELATED_QUERY]: [['name', 'Search Term']],
    [ModelType.MATCH_STRATEGY]: [['rules', 'Match Strategy Definitions']],
    [ModelType.SPELLING]: [['name', 'Trigger Term'], ['correction', 'Replacement Spelling']],
    [ModelType.STOP_WORD]: [['name', 'Stop Word']],
    [ModelType.PHRASE]: [['name', 'Phrase']],
    [ModelType.REDIRECT]: [
        ['name', 'Trigger Terms'],
        ['location', 'Redirect URL'],
        ['global', 'Trigger when the search term is accompanied by refinements'],
    ],
    [ModelType.NAVIGATION]: [
        ['unionable', 'Toggle OR Queries'],
        ['intelligentNavigationEnabled', 'Intelligent Navigation'],
    ],
    [ModelType.AREA]: [
        ['suggestionStrength', 'Volume of Did-You-Mean Spelling Suggestions'],
        ['intelligentNavigationEnabled', 'Intelligent Navigation'],
    ],
};
export function replaceMany(list, replacements) {
    if (!replacements) {
        return list;
    }
    return list.map((v) => {
        const replacementIndex = replacements.findIndex((r) => r[0] === v);
        if (replacementIndex !== -1) {
            return replacements[replacementIndex][1];
        }
        return v;
    });
}
export function generateUserFriendlyComments(formDiff, modelType) {
    // replace a subset of formDiffs to match user-facing headings
    const partialFormat = Tasks.replaceMany(formDiff, REPLACEMENTS[modelType]);
    // format the remaining diffs into Title Case
    const titleCaseFormat = partialFormat.map((diff) => (diff[0] === diff[0].toLowerCase() ? Utils.formatTitles(diff) : diff));
    return String(titleCaseFormat).replace(/,/g, ', ');
}
export function transformEnrichMLModels(models) {
    return models.map((model) => ({
        label: model.name,
        value: model.id,
    }));
}
export function transformEnrichClassPredictions(classPredictions, classes) {
    const optionTransformer = (option) => (Object.assign(Object.assign({}, option), { classTitle: classes.byId[option.classId].title, custom: false }));
    return classPredictions.map((p) => (Object.assign(Object.assign({}, p), { options: p.options.map(optionTransformer) })));
}
export function generateMultipleQueryOffsets(totalItems, pageSize) {
    const numberOfQueriesRequired = Math.ceil(totalItems / pageSize);
    return Array(numberOfQueriesRequired).fill(0).map((_, index) => index * pageSize);
}
export function getClassifiedFromEnrichProductsFilter(filter) {
    const classifiedValues = {
        [Routing.Page.Enrich.Products.Option.TOTAL]: null,
        [Routing.Page.Enrich.Products.Option.CLASSIFIED]: true,
        [Routing.Page.Enrich.Products.Option.UNCLASSIFIED]: false,
    };
    return classifiedValues[filter];
}
/**
 * Builds an Enrich Criteria for the appropriate resource.
 * Builds job criteria if the criteria object contains a job kind.
 * Builds rule criteria if the criteria object contains a ruleQuery property.
 * Otherwise, builds product criteria.
 */
export function createEnrichCriteria(criteria) {
    var _a;
    if ((_a = criteria) === null || _a === void 0 ? void 0 : _a.jobKind)
        return buildJobCriteria(Object.assign({}, criteria));
    if ('ruleQuery' in criteria)
        return buildRuleCriteria(Object.assign({}, criteria));
    return buildProductCriteria(Object.assign({}, criteria));
}
export function parseEnrichCriteriaString(criteria) {
    return parseCriteriaString(criteria);
}
export function* getSelectedClass() {
    var _a;
    const taxonomyNodesById = yield select(Selectors.enrichTaxonomyNodesByIdSelector);
    const selectedTaxonomyNode = yield select(Selectors.enrichSelectedTaxonomyNodeSelector);
    return (_a = taxonomyNodesById === null || taxonomyNodesById === void 0 ? void 0 : taxonomyNodesById[selectedTaxonomyNode]) === null || _a === void 0 ? void 0 : _a.classId;
}
export function normalizeEnrichResources(resource) {
    return {
        allIds: resource.map((x) => x.id),
        byId: resource.reduce((all, current) => Object.assign(all, { [current.id]: current }), {}),
    };
}
export function* setPageName() {
    try {
        const location = yield select(Selectors.activeRouteSelector);
        const pageName = yield getPageNameFromPath(location);
        yield put(ActionCreators.updateEnrichPageName(pageName));
    }
    catch (e) {
        yield Tasks.simpleError('Set Page Name', e);
    }
}
export function* setPageSettings() {
    try {
        const pageName = yield select(Selectors.enrichCurrentPageSelector);
        if (!Routing.Page.Enrich.PAGE_TO_TYPE[pageName])
            return;
        const mergedSettings = yield select(Selectors.enrichMergedPageSettingsSelector);
        yield put(ActionCreators.updateEnrichPageSettings(pageName, mergedSettings));
    }
    catch (e) {
        yield Tasks.simpleError('Set Page Settings', e);
    }
}
export function* getSupplementalDefaultValues(modelType, action) {
    if (modelType === ModelType.RULE && action === 'create') {
        const activeAreaModel = yield call(Tasks.getActiveAreaModel);
        if (activeAreaModel.intelligentNavigationEnabled) {
            return { intelligentNavigationEnabled: activeAreaModel.intelligentNavigationEnabled };
        }
    }
    return {};
}
export function* getActiveAreaModel() {
    try {
        const activeArea = yield select(Selectors.activeAreaSelector);
        const areaModels = yield select(Selectors.managerModelsSelector('area', activeArea));
        return areaModels.filter((model) => model.name === activeArea)[0];
    }
    catch (e) {
        console.error(e);
        return {};
    }
}
export function throwConfidenceThresholdError(confidence) {
    if (Utils.isValidConfidenceValue(confidence)) {
        throw new Error('No predictions found for confidence threshold');
    }
    throw new Error('Invalid confidence threshold input');
}
export function* generateEnrichJobRequestFilter(jobKind) {
    const [dateRange, models, predictionState, users,] = yield all([
        select(Selectors.enrichDateRangeSelector),
        select(Selectors.enrichJobManagementSelectedModelsIdsSelector),
        select(Selectors.enrichJobsPredictionStateSelector),
        select(Selectors.enrichSubmittersSelector),
    ]);
    // To be replaced when the Organization filter is added: https://groupby.atlassian.net/browse/CUE-718
    const organizations = [];
    const filter = yield call(Tasks.createEnrichCriteria, {
        jobKind,
        dateRange,
        models,
        users,
        organizations,
        predictionState,
    });
    return filter;
}
/**
 * Clears the resource loading status object if Enrich has been visited.
 * Sets all resource loading statuses to false; this is default resource loading state.
 * Does nothing if this is the first page visit to Enrich.
 */
export function* resetEnrichLoadingStatus() {
    const enrichVisited = yield select(Selectors.enrichVisitedSelector);
    if (enrichVisited) {
        yield put(ActionCreators.clearEnrichResourceLoadingStatus());
    }
}
export function* generateRuleFilter() {
    const query = yield select(Selectors.enrichQuery);
    const organizations = yield select(Selectors.enrichRuleLibrarySelectedOrganizationsSelector);
    if (query || organizations.length) {
        return yield call(Tasks.createEnrichCriteria, { ruleQuery: query, organizations });
    }
}
