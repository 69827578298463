var State;
(function (State) {
    let ChangeLog;
    (function (ChangeLog) {
        let FilterField;
        (function (FilterField) {
            FilterField["DATE_RANGE"] = "dateRange";
            FilterField["AREA"] = "area";
            FilterField["ACTION"] = "action";
            FilterField["TYPE"] = "type";
            FilterField["NAME"] = "name";
            FilterField["ID"] = "id";
            FilterField["SKIP"] = "skip";
            FilterField["EMAIL"] = "email";
        })(FilterField = ChangeLog.FilterField || (ChangeLog.FilterField = {}));
        let ModelAction;
        (function (ModelAction) {
            ModelAction["CREATE"] = "Create";
            ModelAction["PARTIAL_UPDATE"] = "PartialUpdate";
            ModelAction["STRICT_UPDATE"] = "StrictUpdate";
            ModelAction["COPY"] = "Copy";
            ModelAction["DELETE"] = "Delete";
            ModelAction["SINGLE_PROMOTE"] = "SinglePromote";
            ModelAction["PROMOTE"] = "Promote";
        })(ModelAction = ChangeLog.ModelAction || (ChangeLog.ModelAction = {}));
        let ModelCommentAction;
        (function (ModelCommentAction) {
            ModelCommentAction["CREATE"] = "create";
            ModelCommentAction["UPDATE"] = "update";
            ModelCommentAction["REORDER"] = "reorder";
            ModelCommentAction["SINGLE_PROMOTE_SOURCE"] = "singlePromoteSource";
            ModelCommentAction["SINGLE_PROMOTE_TARGET"] = "singlePromoteTarget";
        })(ModelCommentAction = ChangeLog.ModelCommentAction || (ChangeLog.ModelCommentAction = {}));
        let Key;
        (function (Key) {
            Key["ID"] = "id";
            Key["AREA"] = "area";
            Key["CUSTOMER"] = "customer";
            Key["MESSAGE"] = "message";
            Key["METHOD"] = "method";
            Key["MODEL_ID"] = "modelId";
            Key["MODEL_NAME"] = "modelName";
            Key["TIMESTAMP"] = "timestamp";
            Key["TYPE"] = "type";
            Key["USERNAME"] = "username";
            Key["PARENT"] = "parent";
        })(Key = ChangeLog.Key || (ChangeLog.Key = {}));
    })(ChangeLog = State.ChangeLog || (State.ChangeLog = {}));
    let SearchPreview;
    (function (SearchPreview) {
        let QueryType;
        (function (QueryType) {
            QueryType["PRODUCT_ID"] = "PRODUCT_ID";
            QueryType["QUERY"] = "QUERY";
        })(QueryType = SearchPreview.QueryType || (SearchPreview.QueryType = {}));
        let ConfigurationFields;
        (function (ConfigurationFields) {
            ConfigurationFields["IMAGE_URL"] = "imageUrl";
            ConfigurationFields["IMAGE_URL_PREFIX"] = "imageUrlPrefix";
            ConfigurationFields["IMAGE_URL_SUFFIX"] = "imageUrlSuffix";
            ConfigurationFields["URL"] = "url";
            ConfigurationFields["TITLE"] = "title";
            ConfigurationFields["COLLECTION"] = "collection";
        })(ConfigurationFields = SearchPreview.ConfigurationFields || (SearchPreview.ConfigurationFields = {}));
        let PinType;
        (function (PinType) {
            PinType["PUSH_TO_TOP"] = "bringToTop";
            PinType["RESTRICT_TO_IDS"] = "restrictToIds";
            PinType["SORT_BY_IDS"] = "sortByIds";
        })(PinType = SearchPreview.PinType || (SearchPreview.PinType = {}));
    })(SearchPreview = State.SearchPreview || (State.SearchPreview = {}));
    let Analytics;
    (function (Analytics) {
        let Window;
        (function (Window) {
            Window["DAY"] = "day";
            Window["WEEK"] = "week";
            Window["MONTH"] = "month";
        })(Window = Analytics.Window || (Analytics.Window = {}));
        let MatchType;
        (function (MatchType) {
            MatchType["EXACT"] = "exact";
            MatchType["PREFIX"] = "prefix";
            MatchType["SUFFIX"] = "suffix";
            MatchType["CONTAINS"] = "contains";
        })(MatchType = Analytics.MatchType || (Analytics.MatchType = {}));
        let DataSetKey;
        (function (DataSetKey) {
            DataSetKey["CLICKTHROUGHS"] = "clickthroughs";
            DataSetKey["SERVICES"] = "services";
            DataSetKey["TIMESERIES"] = "timeseries";
            DataSetKey["RECORDS"] = "records";
            DataSetKey["RESPONSE_TIME"] = "responseTime";
        })(DataSetKey = Analytics.DataSetKey || (Analytics.DataSetKey = {}));
        let ServiceKey;
        (function (ServiceKey) {
            ServiceKey["SEARCH"] = "search";
            ServiceKey["RECOMMENDATIONS"] = "recommendations";
            ServiceKey["SAYT"] = "sayt";
            ServiceKey["UPLOAD"] = "upload";
            ServiceKey["ORDERS"] = "orders";
        })(ServiceKey = Analytics.ServiceKey || (Analytics.ServiceKey = {}));
        let DashboardType;
        (function (DashboardType) {
            DashboardType["SEARCH_PERFORMANCE"] = "searchPerformance";
            DashboardType["API_USAGE"] = "apiUsage";
            DashboardType["API_RESPONSE_TIME"] = "apiResponseTime";
            DashboardType["RECORDS"] = "records";
            DashboardType["SEARCH_TERM"] = "searchTerm";
        })(DashboardType = Analytics.DashboardType || (Analytics.DashboardType = {}));
        let ReportType;
        (function (ReportType) {
            ReportType["TOP_REFINEMENTS"] = "topRefinements";
            ReportType["PERFORMANCE"] = "performance";
            ReportType["NULLS"] = "nulls";
            ReportType["NAVIGATIONS"] = "navigations";
        })(ReportType = Analytics.ReportType || (Analytics.ReportType = {}));
        let InsightsType;
        (function (InsightsType) {
            InsightsType["METRICS"] = "metrics";
            InsightsType["PRODUCTS"] = "products";
            InsightsType["NAVIGATIONS"] = "navigations";
        })(InsightsType = Analytics.InsightsType || (Analytics.InsightsType = {}));
        let ResultKey;
        (function (ResultKey) {
            ResultKey["ADD_TO_CARTS"] = "addToCarts";
            ResultKey["AVG_SEARCH_POSITION"] = "avgSearchPosition";
            ResultKey["COLLECTION"] = "collection";
            ResultKey["COLLECTIONS"] = "collections";
            ResultKey["CONVERSION_SCORE"] = "conversionScore";
            ResultKey["COUNT"] = "count";
            ResultKey["EXPLORATION_SCORE"] = "explorationScore";
            ResultKey["LAST_UPDATED"] = "lastUpdated";
            ResultKey["MAX_COUNT"] = "minCount";
            ResultKey["MAX_HOUR"] = "maxHour";
            ResultKey["MIN_COUNT"] = "maxCount";
            ResultKey["NAVIGATION"] = "navigation";
            ResultKey["ORDERS"] = "orders";
            ResultKey["PRODUCT"] = "product";
            ResultKey["REFINEMENT"] = "refinement";
            ResultKey["REFINEMENTS"] = "refinements";
            ResultKey["ROW_NUM"] = "rowNum";
            ResultKey["SKU"] = "sku";
            ResultKey["SKUS"] = "skus";
            ResultKey["TERM"] = "term";
            ResultKey["TITLE"] = "title";
            ResultKey["UNIQUE_ADD_TO_CART_RATIO"] = "uniqueAddToCartRatio";
            ResultKey["UNIQUE_NAVIGATIONS"] = "uniqueNavigations";
            ResultKey["UNIQUE_ORDER_RATIO"] = "uniqueOrderRatio";
            ResultKey["UNIQUE_REFINEMENTS"] = "uniqueRefinements";
            ResultKey["UNIQUE_SEARCHES"] = "uniqueSearches";
            ResultKey["UNIQUE_VIEW_PRODUCT_RATIO"] = "uniqueViewProductRatio";
            ResultKey["VIEW_PRODUCTS"] = "viewProducts";
        })(ResultKey = Analytics.ResultKey || (Analytics.ResultKey = {}));
        let ReportFilters;
        (function (ReportFilters) {
            let Type;
            (function (Type) {
                Type["DEVICE"] = "DEVICE";
                Type["METADATA"] = "METADATA";
                Type["NAVIGATION_NULLS"] = "NAVIGATION_NULLS";
            })(Type = ReportFilters.Type || (ReportFilters.Type = {}));
            let Device;
            (function (Device) {
                Device["DESKTOP"] = "desktop";
                Device["MOBILE"] = "phone";
                Device["TABLET"] = "tablet";
            })(Device = ReportFilters.Device || (ReportFilters.Device = {}));
            (function (Device) {
                Device.LABELS = {
                    [Device.DESKTOP]: 'desktop',
                    [Device.MOBILE]: 'mobile',
                    [Device.TABLET]: 'tablet',
                };
            })(Device = ReportFilters.Device || (ReportFilters.Device = {}));
        })(ReportFilters = Analytics.ReportFilters || (Analytics.ReportFilters = {}));
    })(Analytics = State.Analytics || (State.Analytics = {}));
    let Enrich;
    (function (Enrich) {
        let JobProgress;
        (function (JobProgress) {
            JobProgress[JobProgress["inProgress"] = 0] = "inProgress";
            JobProgress[JobProgress["complete"] = 1] = "complete";
            JobProgress[JobProgress["all"] = 2] = "all";
        })(JobProgress = Enrich.JobProgress || (Enrich.JobProgress = {}));
        let ClassificationRuleTarget;
        (function (ClassificationRuleTarget) {
            ClassificationRuleTarget["Global"] = "global";
            ClassificationRuleTarget["Client"] = "client";
        })(ClassificationRuleTarget = Enrich.ClassificationRuleTarget || (Enrich.ClassificationRuleTarget = {}));
        let Period;
        (function (Period) {
            Period["Day"] = "DAY";
            Period["Month"] = "MONTH";
        })(Period = Enrich.Period || (Enrich.Period = {}));
        let ReportingType;
        (function (ReportingType) {
            ReportingType["DASHBOARD"] = "dashboard";
        })(ReportingType = Enrich.ReportingType || (Enrich.ReportingType = {}));
    })(Enrich = State.Enrich || (State.Enrich = {}));
    let Notification;
    (function (Notification) {
        let Type;
        (function (Type) {
            Type["ERROR"] = "error";
            Type["SUCCESS"] = "success";
        })(Type = Notification.Type || (Notification.Type = {}));
    })(Notification = State.Notification || (State.Notification = {}));
    let UI;
    (function (UI) {
        let LockModalType;
        (function (LockModalType) {
            LockModalType["LANDING"] = "landing";
            LockModalType["INACTIVITY_WARNING"] = "inactivity-warning";
            LockModalType["TIMED_OUT"] = "timed-out";
        })(LockModalType = UI.LockModalType || (UI.LockModalType = {}));
        let Position;
        (function (Position) {
            let Type;
            (function (Type) {
                Type["MOVE_TO"] = "MOVE_TO";
                Type["MOVE_FROM"] = "MOVE_FROM";
            })(Type = Position.Type || (Position.Type = {}));
            let Relationship;
            (function (Relationship) {
                Relationship["ABOVE"] = "above";
                Relationship["BELOW"] = "below";
            })(Relationship = Position.Relationship || (Position.Relationship = {}));
        })(Position = UI.Position || (UI.Position = {}));
    })(UI = State.UI || (State.UI = {}));
})(State || (State = {}));
export default State;
