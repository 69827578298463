import React from 'react';
import SupportIcon from 'mdi-react/CommentQuestionIcon';
import { withStyles } from '@/decorators';
import MenuItem from '../menu-item';
export const styles = {
    icon: {
        fill: 'currentColor',
    },
};
const SupportMenuItem = withStyles(styles)(function SupportMenuItem({ classes }) {
    const url = '//support.groupbyinc.com';
    return (<MenuItem onClick={() => window.open(url, '_blank')}>
      <SupportIcon className={classes.icon}/>
      Support
    </MenuItem>);
});
export default SupportMenuItem;
