import React from 'react';
import SnapshotCard from '@/components/controls/snapshot-card';
import { withStyles } from '@/decorators';
import State from '@/store/state';
var IDs = State.Analytics.ResultKey;
const SNAPSHOT_CARDS = [
    {
        label: 'Unique Searches',
        tooltip: 'A unique search term queried within a session. If one session contains two searches with the same term, this is counted as one unique search. If two sessions have the same search term, this is counted as two unique searches',
        id: IDs.UNIQUE_SEARCHES,
    },
    {
        label: 'Clickthrough Rate',
        tooltip: 'The % of unique searches that had at least one clickthrough. A clickthrough is a search query that has at least one view product. If a unique search query has two product views, this would be counted as one clickthrough.',
        id: IDs.UNIQUE_VIEW_PRODUCT_RATIO,
    },
    {
        label: 'Add To Cart Rate',
        tooltip: 'The % of unique searches that directly resulted in at least one product being added to cart. If two products are added to cart from one search query, this is still counted as one add to cart.',
        id: IDs.UNIQUE_ADD_TO_CART_RATIO,
    },
    {
        label: 'Conversion Rate',
        tooltip: 'The % of unique searches that resulted in at least one product being ordered. If two products are ordered from one search query, this is still counted as one order.',
        id: IDs.UNIQUE_ORDER_RATIO,
    },
];
export const styles = ({ palette }) => ({
    button: {
        width: '25%',
        boxSizing: 'border-box',
    },
    [IDs.UNIQUE_SEARCHES]: {
        color: palette.analytics[IDs.UNIQUE_SEARCHES],
    },
    [IDs.UNIQUE_VIEW_PRODUCT_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_VIEW_PRODUCT_RATIO],
    },
    [IDs.UNIQUE_ADD_TO_CART_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_ADD_TO_CART_RATIO],
    },
    [IDs.UNIQUE_ORDER_RATIO]: {
        color: palette.analytics[IDs.UNIQUE_ORDER_RATIO],
    },
});
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const SearchPerformanceCards = withStyles(styles)(function SearchPerformanceCards({ data, classes }) {
    return (<React.Fragment>
        {SNAPSHOT_CARDS.map(({ id, label, tooltip }, index) => (<SnapshotCard value={getFormat(data, id).value} formatter={getFormat(data, id).formatter} tooltip={tooltip} className={classes.button} label={label} labelClassName={classes[id]} key={index}/>))}
      </React.Fragment>);
});
export default SearchPerformanceCards;
export function getFormat(value, id) {
    if (!value[id]) {
        return { value: 0, formatter: createZeroFormatterById(id) };
    }
    return {
        value: value[id],
        formatter: createFormatterById(id),
    };
}
export function createFormatterById(id) {
    switch (id) {
        case IDs.UNIQUE_SEARCHES:
            return (value) => value.toLocaleString();
        case IDs.CONVERSION_SCORE:
        case IDs.EXPLORATION_SCORE:
            return (value) => Math.round(value);
        default:
            return (value) => `${value.toFixed(1)}%`;
    }
}
export function createZeroFormatterById(id) {
    switch (id) {
        case IDs.UNIQUE_ADD_TO_CART_RATIO:
        case IDs.UNIQUE_ORDER_RATIO:
        case IDs.UNIQUE_VIEW_PRODUCT_RATIO:
            return (value) => `${value.toFixed(1)}%`;
        default:
            return (value) => String(value);
    }
}
