import * as Actions from '@/store/actions/types';
import * as UIReducers from './ui';
export const DEFAULT = {
    drawer: { open: false, collapsed: false },
    previewDrawer: { open: false },
    positionModal: { open: false },
    changeLogModal: { open: false },
    copyToAreaModal: { open: false },
    lockBanner: { open: false },
    unsavedFormModal: { attemptedAction: null },
    errors: {},
};
// eslint-disable-next-line complexity
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SWITCH_AREA:
        case Actions.CLOSE_DRAWER:
            return UIReducers.closeDrawerReducer(state);
        case Actions.OPEN_DRAWER:
            return UIReducers.openDrawerReducer(state);
        case Actions.COLLAPSE_DRAWER:
            return UIReducers.collapseDrawerReducer(state);
        case Actions.EXPAND_DRAWER:
            return UIReducers.expandDrawerReducer(state);
        case Actions.OPEN_POSITION_MODAL:
            return UIReducers.openPositionModalReducer(state, action);
        case Actions.CLOSE_POSITION_MODAL:
            return UIReducers.closePositionModalReducer(state);
        case Actions.OPEN_COPY_TO_AREA_MODAL:
            return UIReducers.openCopyToAreaModalReducer(state);
        case Actions.CLOSE_COPY_TO_AREA_MODAL:
            return UIReducers.closeCopyToAreaModalReducer(state);
        case Actions.SHOW_ERROR:
            return UIReducers.showErrorReducer(state, action);
        case Actions.CLEAR_ERROR:
            return UIReducers.clearErrorReducer(state, action);
        case Actions.OPEN_CHANGELOG_MODAL:
            return UIReducers.openChangeLogModalReducer(state);
        case Actions.CLOSE_CHANGELOG_MODAL:
            return UIReducers.closeChangeLogModalReducer(state);
        case Actions.OPEN_PREVIEW_DRAWER:
            return UIReducers.openPreviewDrawerReducer(state);
        case Actions.CLOSE_PREVIEW_DRAWER:
            return UIReducers.closePreviewDrawerReducer(state);
        case Actions.OPEN_UNSAVED_FORM_MODAL:
            return UIReducers.openUnsavedFormModalReducer(state, action);
        case Actions.DISPATCH_ATTEMPTED_ACTION:
        case Actions.CANCEL_ATTEMPTED_ACTION:
            return UIReducers.closeUnsavedFormModalReducer(state);
        case Actions.SHOW_ALERT:
            return UIReducers.showAlertReducer(state, action);
        case Actions.HIDE_ALERT:
            return UIReducers.hideAlertReducer(state);
        case Actions.UPDATE_LOCK_BANNER:
            return UIReducers.updateLockBanner(state, action);
        default:
            return state;
    }
};
export function closeDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { drawer: Object.assign(Object.assign({}, state.drawer), { open: false }) });
}
export function openDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { drawer: Object.assign(Object.assign({}, state.drawer), { open: true }) });
}
export function collapseDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { drawer: Object.assign(Object.assign({}, state.drawer), { collapsed: true }) });
}
export function expandDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { drawer: Object.assign(Object.assign({}, state.drawer), { collapsed: false }) });
}
export function closePreviewDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { previewDrawer: Object.assign(Object.assign({}, state.previewDrawer), { open: false }) });
}
export function openPreviewDrawerReducer(state) {
    return Object.assign(Object.assign({}, state), { previewDrawer: Object.assign(Object.assign({}, state.previewDrawer), { open: true }) });
}
export function openChangeLogModalReducer(state) {
    return Object.assign(Object.assign({}, state), { changeLogModal: Object.assign(Object.assign({}, state.changeLogModal), { open: true }) });
}
export function closeChangeLogModalReducer(state) {
    return Object.assign(Object.assign({}, state), { changeLogModal: Object.assign(Object.assign({}, state.changeLogModal), { open: false }) });
}
export function openPositionModalReducer(state, { payload: value }) {
    return Object.assign(Object.assign({}, state), { positionModal: { value, open: true } });
}
export function closePositionModalReducer(state) {
    return Object.assign(Object.assign({}, state), { positionModal: { open: false } });
}
export function openCopyToAreaModalReducer(state) {
    return Object.assign(Object.assign({}, state), { copyToAreaModal: { open: true } });
}
export function closeCopyToAreaModalReducer(state) {
    return Object.assign(Object.assign({}, state), { copyToAreaModal: { open: false } });
}
export function showErrorReducer(state, { payload: { id, message } }) {
    return Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { [id]: message }) });
}
export function clearErrorReducer(state, { payload: id }) {
    return Object.assign(Object.assign({}, state), { errors: Object.keys(state.errors)
            .filter((key) => key !== id)
            .reduce((errors, key) => {
            errors[key] = state.errors[key];
            return errors;
        }, {}) });
}
export function openUnsavedFormModalReducer(state, { payload: attemptedAction }) {
    return Object.assign(Object.assign({}, state), { unsavedFormModal: Object.assign(Object.assign({}, state.unsavedFormModal), { attemptedAction }) });
}
export function closeUnsavedFormModalReducer(state) {
    return Object.assign(Object.assign({}, state), { unsavedFormModal: Object.assign(Object.assign({}, state.unsavedFormModal), { attemptedAction: null }) });
}
export function showAlertReducer(state, { payload: { text, action } }) {
    return Object.assign(Object.assign({}, state), { alert: {
            text,
            action,
        } });
}
export function hideAlertReducer(state) {
    return Object.assign(Object.assign({}, state), { alert: null });
}
export function updateLockBanner(state, { payload: { open } }) {
    return Object.assign(Object.assign({}, state), { lockBanner: Object.assign(Object.assign({}, state.lockBanner), { open }) });
}
