import React from 'react';
import ExportIcon from '@material-ui/icons/SaveAlt';
import classNames from 'classnames';
import { compose } from 'ramda';
import { CSVLink } from 'react-csv';
import { capitalize } from 'voca';
import Button from '@/components/controls/button';
import { withStyles } from '@/decorators';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect, filterOutLastTwoChar } from '@/utils';
var DashboardType = State.Analytics.DashboardType;
export const styles = ({ spacing, palette }) => ({
    button: {
        minHeight: spacing(4),
        minWidth: spacing(5),
        marginRight: spacing(2),
        padding: 0,
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -2px rgba(0, 0, 0, 0.12)',
        '&:hover': {
            background: '#5e5d5d',
        },
    },
    download: {
        width: '100%',
    },
    icon: {
        height: spacing(3),
        width: spacing(3),
        color: palette.primary.contrastText,
    },
});
export const searchTermFilter = Selectors.filterSelector(DashboardType.SEARCH_TERM);
export const selectors = {
    filters: searchTermFilter,
    activeQuery: Selectors.reportQueryActiveQuerySelector,
    area: Selectors.activeAreaSelector,
    reportFilters: Selectors.reportFiltersSelectorV2,
};
export const enhance = compose(withStyles(styles), connect(selectors));
const ExportButton = enhance(function ExportButton({ filters: { dateRange }, activeQuery, area, reportFilters: { devices }, reportName, headers, data, className, classes, }) {
    if (!data) {
        return null;
    }
    return (<Button variant="secondary" className={classNames(classes.button, className)} disabled={data.length === 0} tooltip="Save as .CSV">
      <CSVLink className={classes.download} data={data} filename={generateFilename(reportName, area, activeQuery, devices, dateRange)} headers={headers}>
        <ExportIcon className={classes.icon}/>
      </CSVLink>
    </Button>);
});
export default ExportButton;
export function generateFilename(reportName, area, activeQuery, devices, dateRange) {
    const reportNameString = generateReportName(reportName);
    const activeQueryString = generateActiveQueryString(activeQuery);
    const devicesString = generateDevicesString(devices);
    const dateRangeString = generateDateRangeString(dateRange);
    return `${reportNameString}_${area}_${activeQueryString}_${devicesString}_${dateRangeString}.csv`;
}
export function generateReportName(reportName) {
    return capitalize(reportName.replace(/\s+/g, '-'), true);
}
export function generateDevicesString(devices) {
    return devices.toString().replace(/,/g, '-');
}
export function generateActiveQueryString(activeQuery) {
    if (!activeQuery.query) {
        return 'query-none';
    }
    return `${activeQuery.matchType}-${activeQuery.query}`;
}
export function generateDateRangeString(dateRange) {
    return `${filterOutLastTwoChar(dateRange.start)}-${filterOutLastTwoChar(dateRange.end)}`;
}
export function escapeDoubleQuotes(data) {
    return data.map((record) => (Object.assign(Object.assign({}, record), { term: record.term.replace(/"/g, '""') })));
}
