import { __decorate } from "tslib";
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import TableCell from '@/components/cell';
import ExpansionCell from '@/components/reports/table-v2/cells/expansion';
import ExpandableRow from '@/components/reports/table-v2/expandable-row';
import { Align, Type } from '@/components/reports/table-v2/types';
import { withStyles } from '@/decorators';
import State from '@/store/state';
var ReportKey = State.Analytics.ResultKey;
export const styles = ({ palette, table }) => ({
    tableRow: {
        '&>*': {
            '&:hover': {
                background: 'rgba(0, 0, 0, 0`.07)',
            },
        },
        '&>:nth-child(even)': {
            background: palette.primary.contrastText,
        },
    },
    content: {
        '&>*': {
            background: table.background,
        },
    },
});
export const COLUMNS_INNER = [
    {
        id: ReportKey.REFINEMENT,
        label: 'Refinement',
        type: Type.LARGE,
        align: Align.LEFT,
        formatter: null,
        tooltip: 'Refinement',
        csvLabel: 'refinement',
    },
    {
        id: ReportKey.UNIQUE_REFINEMENTS,
        label: 'Unique refinement',
        type: Type.SMALL,
        align: Align.CENTER,
        formatter: null,
        tooltip: 'Unique Refinements',
        csvLabel: 'unique refinements',
    },
    {
        id: null,
        label: null,
        type: null,
        align: null,
        formatter: null,
        tooltip: null,
        csvLabel: null,
    },
];
let NavigationsTableBody = class NavigationsTableBody extends React.PureComponent {
    render() {
        const { classes, columns, data, onExpansionClick, selectedRow, } = this.props;
        return (<React.Fragment key={'table-body'}>
        <TableBody className={classNames(classes.tableRow)}>
          {data.map((result, index) => {
            const isSelectedRow = index === selectedRow;
            return (<React.Fragment key={index}>
                <TableRow>
                  {columns.map(({ id, type, align, formatter, }, cellIndex) => (<TableCell align={align} type={type} key={`${index}-${cellIndex}`}>
                      <span>{formatter ? formatter(result[id]) : result[id]}</span>
                    </TableCell>))}
                  <ExpansionCell onClick={() => onExpansionClick(index)} rotated={isSelectedRow} showExpansionButton={!!result[ReportKey.REFINEMENTS]} tooltip={'View Refinements'}/>
                </TableRow>
                {isSelectedRow && (<ExpandableRow className={classes.content} columns={COLUMNS_INNER} data={result[ReportKey.REFINEMENTS]} parentRowIndex={index}/>)}
              </React.Fragment>);
        })}
        </TableBody>
      </React.Fragment>);
    }
};
NavigationsTableBody = __decorate([
    (withStyles(styles))
], NavigationsTableBody);
export default NavigationsTableBody;
