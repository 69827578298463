import { __rest } from "tslib";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ spacing }) => ({
    root: {
        padding: `${spacing(2)}px 0`,
        '&$labeled': {
            paddingBottom: 0,
        },
    },
    labeled: {},
    inline: {
        padding: 0,
    },
});
const FormSection = withStyles(styles)(function FormSection(_a) {
    var { labeled, container = true, inline, className, classes, column } = _a, props = __rest(_a, ["labeled", "container", "inline", "className", "classes", "column"]);
    return (<Grid item xs={12} className={classNames({ [classes.root]: !inline, [classes.labeled]: !inline && labeled }, className)} {...(container ? { container: true, direction: column ? 'column' : 'row' } : {})} {...(inline
        ? {
            className: classNames(classes.inline, className), alignItems: 'center', wrap: 'nowrap', spacing: 3,
        }
        : { className: classNames(classes.root, { [classes.labeled]: labeled }, className) })} {...props}/>);
});
export default FormSection;
