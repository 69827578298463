import * as FeatureFlags from './feature-flags';
import { IS_PRODUCTION_ENV, IS_STAGING_ENV, IS_DEV_ENV } from './global';
export function hasBetaAccess() {
    return !IS_PRODUCTION_ENV || IS_STAGING_ENV || IS_DEV_ENV;
}
export function isCustomer(name) {
    return window.location.hostname.split('.')[0] === name;
}
export function isFilterManagementCustomer() {
    return !IS_PRODUCTION_ENV || isCustomer('dicks');
}
export function isAnalyticsChartCustomer() {
    return (hasBetaAccess()
        || isCustomer('swansonhealth')
        || isCustomer('bestbuyca')
        || isCustomer('vitaminshoppe')
        || isCustomer('buildcom'));
}
export function isAnalyticsInsightsCustomer() {
    return hasBetaAccess() || isCustomer('vitaminshoppe');
}
export function isINavCustomer() {
    return (FeatureFlags.hasBetaAccess()
        || FeatureFlags.isCustomer('bedbathbeyond')
        || FeatureFlags.isCustomer('cvshealth')
        || FeatureFlags.isCustomer('lovelyskin')
        || FeatureFlags.isCustomer('connection')
        || FeatureFlags.isCustomer('signet')
        || FeatureFlags.isCustomer('signeteu')
        || FeatureFlags.isCustomer('bjswholesale')
        || FeatureFlags.isCustomer('liverpool'));
}
export function hasEnrichAccess() {
    return FeatureFlags.hasBetaAccess() || FeatureFlags.isCustomer('enrich');
}
