import loadable from '@/components/loadable';
import { APIType, ChartType } from '@/constants';
import State from '@/store/state';
var DashboardType = State.Analytics.DashboardType;
var ReportType = State.Analytics.ReportType;
export default {
    [DashboardType.API_USAGE]: loadable(() => import('./dashboards/api-usage' /* webpackChunkName: "api-usage-chart" */)),
    [DashboardType.API_RESPONSE_TIME]: loadable(() => import('./dashboards/api-response-time' /* webpackChunkName: "api-response-time" */)),
    [DashboardType.SEARCH_PERFORMANCE]: loadable(() => import('./dashboards/search-performance' /* webpackChunkName: "search-performance-chart" */)),
    [ReportType.TOP_REFINEMENTS]: loadable(() => import('./reports/refinements' /* webpackChunkName: "top-refinements-report" */)),
    [ChartType.RECORD_COUNT]: loadable(() => import('./dashboards/record-count' /* webpackChunkName: "record-count" */)),
    [APIType.BEACON_HEALTH]: loadable(() => import('./api-analytics/health-checks' /* webpackChunkName: "healthchecks" */)),
    [DashboardType.SEARCH_TERM]: loadable(() => import('./dashboards/search-term' /* webpackChunkName: "search-term" */)),
};
