import React from 'react';
import moize from 'moize';
import { Redirect, Route, Switch, } from 'react-router';
import { withProps } from 'recompose';
import DrawerContainer from '@/components/drawer/container';
import MerchandisingDrawer from '@/components/drawer/merchandising';
import FooterContainer from '@/components/footer/container';
import loadable from '@/components/loadable';
import Managers from '@/merchandising/managers';
import { isGlobalModelType } from '@/merchandising/models/utils';
import { extractRoutes, MERCHANDISING_ROUTES } from '@/routes/drawer';
import AreaContext from '@/store/area-context';
import authorized from './authorized';
const MerchandisingRouter = withProps(({ area }) => ({
    routes: createRoutesFromArea(area),
}))(function MerchandisingRouter({ routes, match, area }) {
    return (<DrawerContainer component={MerchandisingDrawer}>
      <FooterContainer>
        <AreaContext.Provider value={area}>
          <Switch>
            <Route exact path={match.path} component={loadable(() => import('@/pages/home' /* webpackChunkName: "home" */))}/>
            {routes.map(({ path, name, component: Manager }) => (<Route path={`${match.path}/${path}`} component={Manager} key={name}/>))}
            {routes
        .filter(({ name }) => !isGlobalModelType(name))
        .map(({ basePath, path, name }) => (<Route exact path={`${match.path}/${basePath}`} component={withProps({ to: { pathname: `${match.path}/${path}`, state: { modelType: name } } })(Redirect)} key={name}/>))}
            <Redirect to={match.path}/>
          </Switch>
        </AreaContext.Provider>
      </FooterContainer>
    </DrawerContainer>);
});
export default MerchandisingRouter;
export const createRoutesFromArea = moize((area) => extractRoutes(MERCHANDISING_ROUTES.sections).map((route) => (Object.assign(Object.assign({}, route), { basePath: route.path, path: `${route.path}${isGlobalModelType(route.name) ? '' : `/${area}`}`, component: authorized(route.roles)(Managers[route.name]) }))));
