import React from 'react';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import IconButton from '@/components/controls/icon-button';
import TextField from '@/components/controls/textfield';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette, thumbnailColor, table, }) => ({
    container: {
        position: 'relative',
    },
    searchIcon: {
        position: 'absolute',
        right: 0,
        bottom: spacing(1 / 3.5),
        padding: `${spacing(1 / 2)}px`,
    },
    clearIcon: {
        position: 'absolute',
        right: 0,
        bottom: spacing(1 / 3.5),
        padding: `${spacing(1 / 2)}px`,
    },
    input: {
        marginTop: spacing(1),
        '&$outlinedInput': {
            margin: 0,
            width: `calc(100% - ${spacing(3.5)}px)`,
            fontSize: spacing(1.5),
        },
    },
    outlined: {
        border: `1px solid ${table.border}`,
        padding: `3px ${spacing(1 / 2)}px ${spacing(1 / 4)}px ${spacing(1 / 2)}px`,
        transition: '250ms',
        '&:focus-within': {
            border: `1px solid ${palette.mainFont}`,
        },
    },
    outlinedInput: {},
    iconClick: {
        cursor: 'pointer',
        bottom: 0,
        top: 0,
        '&:hover': {
            background: `radial-gradient(${thumbnailColor.hover} 56%, transparent 44%)`,
        },
    },
    buttonMode: {
        left: `calc(100% + ${spacing(1 / 8)}px)`,
        background: table.border,
        paddingTop: '5px',
        borderRadius: `0px ${spacing(1 / 2)}px ${spacing(1 / 2)}px 0px`,
        top: '-1px',
        bottom: '-1px',
        transition: '250ms',
        '&:hover': {
            background: '#d5d5d5',
        },
    },
});
const SearchPreviewQuery = withStyles(styles)(function SearchPreviewQuery({ placeholder, queryValue, updateQuery, clearQuery, onFocus, outlined = false, excludeSearchButton = false, onIconClick, onKeyPress, classes, }) {
    return (<Grid className={classNames(classes.container, { [classes.outlined]: outlined })}>
      <TextField onChange={updateQuery} onFocus={onFocus} value={queryValue} className={classNames(classes.input, { [classes.outlinedInput]: outlined })} disableUnderline={outlined} placeholder={placeholder || 'Search Query'} onKeyPress={onKeyPress} simple fullWidth/>
      {clearQuery && (<IconButton className={classNames(classes.clearIcon, { [classes.iconClick]: clearQuery })} onClick={clearQuery}>
          <ClearIcon color="secondary"/>
        </IconButton>)}
      {!excludeSearchButton && (<IconButton className={classNames(classes.searchIcon, { [classes.iconClick]: onIconClick }, { [classes.buttonMode]: clearQuery })} onClick={onIconClick}>
          <SearchIcon color="secondary"/>
        </IconButton>)}
    </Grid>);
});
export default SearchPreviewQuery;
