import { __decorate } from "tslib";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import Select from '@/components/controls/select';
import Loading from '@/components/loading';
import SmallIconButton from '@/components/small-icon-button';
import { ModelType } from '@/constants';
import { withStyles } from '@/decorators';
import * as ActionCreators from '@/store/actions/creators';
import DependencyWrapper from '@/store/containers/dependency';
import * as Selectors from '@/store/selectors';
import { connect, formatId } from '@/utils';
import AreaInfo from './area-info';
export const styles = ({ label, palette, spacing, zIndex, }) => ({
    root: {
        maxWidth: '100%',
        '&:hover': {
            backgroundColor: palette.grey[200],
        },
        '&$darkMode': {
            background: 'transparent',
            color: '#e0e0e0',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    selectMenu: {
        zIndex: zIndex.drawerModal,
    },
    label: {
        marginRight: spacing(1),
    },
    popoverContainer: {
        pointerEvents: 'none',
        transform: 'translate(-50px, 20px)',
        zIndex: zIndex.drawerModal,
    },
    popoverContent: {
        backgroundColor: palette.grey[100],
        overflowY: 'visible',
        '&:after': {
            content: '""',
            borderWidth: 10,
            borderStyle: 'solid',
            borderColor: `transparent transparent ${label.color} transparent`,
            position: 'absolute',
            top: -20,
            left: 50,
        },
    },
    icon: {
        color: '#e0e0e0',
    },
    darkMode: {},
});
export const selectors = {
    activeUser: Selectors.activeUserSelector,
    activeArea: Selectors.activeAreaSelector,
};
export const actionCreators = {
    switchArea: switchAreaActionCreator,
};
let AreaSwitcher = class AreaSwitcher extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { popoverOpen: false };
        this.buttonRef = React.createRef();
        this.renderValue = (value) => (<React.Fragment>
      <span className={this.props.classes.label}>Area:</span>
      <strong>{value}</strong>
    </React.Fragment>);
        this.handlePopoverOpen = () => this.setState({ popoverOpen: true });
        this.handlePopoverClose = () => this.setState({ popoverOpen: false });
        this.handlePopoverExited = () => {
            this.buttonRef.current.focus();
        };
        this.handleKeyDown = (event) => {
            switch (event.key) {
                case 'Enter':
                    this.handlePopoverOpen();
                    break;
                case 'Escape':
                case 'Esc': // IE
                    this.handlePopoverClose();
                    break;
                default: // Do nothing
            }
        };
    }
    render() {
        const { activeArea, activeUser, switchArea, darkMode, classes, } = this.props;
        return (<DependencyWrapper to={ModelType.AREA}>
        {(areas, loaded) => {
            if (!loaded) {
                return <Loading isLoading/>;
            }
            return (<Grid container alignItems="center" wrap="nowrap" spacing={2}>
              <Select selected={activeArea} onChange={switchArea} options={areas
                .map(({ name }) => name)
                .filter((area) => activeUser.allowedAreas.includes(area))
                .sort((lhs, rhs) => lhs.localeCompare(rhs))} renderValue={this.renderValue} id="area-switcher" disableUnderline primaryColor belowHeader fullWidth className={classNames(classes.root, { [classes.darkMode]: darkMode })} menuClassName={classes.selectMenu} xs={10}/>
              <SmallIconButton aria-label="Area Info" aria-haspopup="true" aria-expanded={this.state.popoverOpen} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} onKeyDown={this.handleKeyDown} buttonRef={this.buttonRef}>
                <InfoIcon color={darkMode ? 'inherit' : 'action'} classes={{ root: classNames({ [classes.icon]: darkMode }) }}/>
              </SmallIconButton>
              <Popover aria-describedby={`area-info-${formatId(this.props.activeArea)}`} aria-expanded={this.state.popoverOpen} anchorEl={this.buttonRef.current} className={classes.popoverContainer} classes={{ paper: classes.popoverContent }} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} onKeyDown={this.handleKeyDown} open={this.state.popoverOpen} onExited={this.handlePopoverExited}>
                <AreaInfo id={`area-info-${formatId(this.props.activeArea)}`} activeArea={areas.find(({ name }) => name === activeArea)} activeUser={activeUser}/>
              </Popover>
            </Grid>);
        }}
      </DependencyWrapper>);
    }
};
AreaSwitcher = __decorate([
    (withStyles(styles)),
    connect(selectors, actionCreators)
], AreaSwitcher);
export default AreaSwitcher;
export function switchAreaActionCreator(area) {
    return ActionCreators.proxyAction(ActionCreators.goToNewArea(area));
}
