import React from 'react';
import { analyticsRoute } from '@/routes';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import RouteLink from '../route-link';
export const selectors = {
    activeRoute: Selectors.activeRouteSelector,
};
export const actionCreators = {
    goToAnalytics: ActionCreators.goToAnalytics,
};
export const mergeProps = (_, { goToAnalytics }, { route }) => ({
    goToAnalytics: () => goToAnalytics(route.path),
});
const AnalyticsRoute = connect(selectors, actionCreators, mergeProps)(function AnalyticsRoute({ goToAnalytics, route }) {
    const path = analyticsRoute(route.path);
    return <RouteLink label={route.label} path={path} onClick={goToAnalytics}/>;
});
export default AnalyticsRoute;
