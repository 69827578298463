import loadable from '@/components/loadable';
import { ModelType } from '@/constants';
const Managers = {
    [ModelType.KEY]: loadable(() => import('./key' /* webpackChunkName: "key-manager" */)),
    [ModelType.CHANGE_LOG]: loadable(() => import('./change-log' /* webpackChunkName: "change-log-manager" */)),
    [ModelType.AREA]: loadable(() => import('./area' /* webpackChunkName: "area-manager" */)),
    [ModelType.USER]: loadable(() => import('./user' /* webpackChunkName: "user-manager" */)),
    [ModelType.BIASING_PROFILE]: loadable(() => import('./biasing-profile' /* webpackChunkName: "biasing-profile-manager" */)),
    [ModelType.MATCH_STRATEGY]: loadable(() => import('./match-strategy' /* webpackChunkName: "match-strategy-manager" */)),
    [ModelType.NAVIGATION]: loadable(() => import('./navigation' /* webpackChunkName: "navigation-manager" */)),
    [ModelType.PHRASE]: loadable(() => import('./phrase' /* webpackChunkName: "phrase-manager" */)),
    [ModelType.REDIRECT]: loadable(() => import('./redirect' /* webpackChunkName: "redirect-manager" */)),
    [ModelType.RELATED_QUERY]: loadable(() => import('./related-query' /* webpackChunkName: "related-query-manager" */)),
    [ModelType.RULE]: loadable(() => import('./rule' /* webpackChunkName: "rule-manager" */)),
    [ModelType.SPELLING]: loadable(() => import('./spelling' /* webpackChunkName: "spelling-manager" */)),
    [ModelType.STOP_WORD]: loadable(() => import('./stop-word' /* webpackChunkName: "stop-word-manager" */)),
    [ModelType.SYNONYM]: loadable(() => import('./synonym' /* webpackChunkName: "synonym-manager" */)),
    [ModelType.TEMPLATE]: loadable(() => import('./template' /* webpackChunkName: "template-manager" */)),
    [ModelType.ZONE]: loadable(() => import('./zone' /* webpackChunkName: "zone-manager" */)),
    [ModelType.FILTER]: loadable(() => import('./filter' /* webpackChunkName: "filter-manager" */)),
};
export default Managers;
