export const CREATE_MODEL = 'MODELS:CREATE';
export const UPDATE_MODEL = 'MODELS:UPDATE';
export const PATCH_MODEL = 'MODELS:PATCH';
export const SAVE_MODEL = 'MODELS:SAVE';
export const REMOVE_MODEL = 'MODELS:REMOVE';
export const STORE_ALL_MODELS = 'MODELS:STORE_ALL';
export const STORE_ONE_MODEL = 'MODELS:STORE_ONE';
export const LOAD_ALL_MODELS = 'MODELS:LOAD_ALL';
export const LOAD_ONE_MODEL = 'MODELS:LOAD_ONE';
export const SET_MODEL_LOCK_STATUS = 'MODELS:LOCK_STATUS:SET';
export const LOCK_MODEL = 'MODELS:LOCK';
export const UNLOCK_MODEL = 'MODELS:UNLOCK';
export const REFRESH_LOCK = 'MODELS:REFRESH_LOCK';
export const STOP_HEARTBEAT = 'MODELS:STOP_HEARTBEAT';
export const SET_LOCKS_BY_ID = 'MODELS:SET_LOCKS_BY_ID';
export const SET_UNLOCK_IN_PROGRESS = 'MODELS:SET_UNLOCK_IN_PROGRESS';
export const ENABLE_FORM = 'MODELS:ENABLE_FORM';
export const DISABLE_FORM = 'MODELS:DISABLE_FORM';
export const TOGGLE_PROMOTION = 'MODELS:TOGGLE_PROMOTION';
export const COPY_TO_AREA = 'MODELS:COPY_TO_AREA';
export const REPLACE_IN_AREA = 'MODELS:REPLACE_IN_AREA';
export const REORDER_BY_ID = 'MODELS:REORDER_BY_ID';
export const REORDER_BY_INDEX = 'MODELS:REORDER_BY_INDEX';
export const LISTEN_FOR_UNLOCK = 'MODELS:LOCK_STATUS:LISTEN_FOR_UNLOCK';
