import React from 'react';
import AreaSwitcher from '@/components/drawer/area-switcher';
import * as Page from '@/components/page';
import { ChartType } from '@/constants';
import { ANALYTICS_ROUTES } from '@/routes/drawer';
import * as Selectors from '@/store/selectors';
import State from '@/store/state';
import { connect, getSectionPath } from '@/utils';
import RouteList from '../route-list';
import RouteSection from '../route-section';
import RouteLink from './link';
var DashboardType = State.Analytics.DashboardType;
export const DISABLED_AREA_SWITCHER_PATHS = [
    // represents the root path
    null,
    DashboardType.API_USAGE,
    DashboardType.API_RESPONSE_TIME,
    ChartType.RECORD_COUNT,
];
export const selectors = {
    path: Selectors.activePathSelector,
};
const AnalyticsDrawer = connect(selectors)(function AnalyticsDrawer({ path }) {
    return (<React.Fragment>
      <Page.Wrapper>
        <Page.Header>{!DISABLED_AREA_SWITCHER_PATHS.includes(getSectionPath(path)) && <AreaSwitcher />}</Page.Header>
      </Page.Wrapper>
      <RouteList>
        {ANALYTICS_ROUTES.sections.map((section) => (<RouteSection path={ANALYTICS_ROUTES.path} section={section} component={RouteLink} key={section.path}/>))}
      </RouteList>
    </React.Fragment>);
});
export default AnalyticsDrawer;
