import React from 'react';
import ToggleButtons from '@/components/controls/toggle-buttons';
import { withStyles } from '@/decorators';
import State from '@/store/state';
var QueryType = State.SearchPreview.QueryType;
export const styles = ({ palette, fontSize, spacing, toggleButton, }) => ({
    root: {
        paddingLeft: spacing(1),
        marginTop: spacing(1 / 2),
    },
    button: {
        fontSize: fontSize.caption,
        fontWeight: 400,
        border: `1px solid ${toggleButton.border}`,
        '&$selected': {
            backgroundColor: palette.primary.dark,
            color: palette.primary.contrastText,
            border: `1px solid ${palette.primary.dark}`,
            '&:hover': {
                border: `1px solid ${toggleButton.border}`,
            },
            '&:after': {
                opacity: 0,
            },
        },
    },
    selected: {},
});
const QueryToggle = withStyles(styles)(function QueryToggle({ classes, queryType, onSwitchQuery }) {
    return (<ToggleButtons className={classes.root} buttonsList={[
        {
            value: QueryType.QUERY,
            classes: { root: classes.button, selected: classes.selected },
            selected: queryType === QueryType.QUERY,
            content: 'Queries',
            onClick: () => onSwitchQuery(State.SearchPreview.QueryType.QUERY),
        },
        {
            value: QueryType.PRODUCT_ID,
            classes: { root: classes.button, selected: classes.selected },
            selected: queryType === QueryType.PRODUCT_ID,
            content: 'Product Id',
            onClick: () => onSwitchQuery(State.SearchPreview.QueryType.PRODUCT_ID),
        },
    ]}/>);
});
export default QueryToggle;
