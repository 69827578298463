import React from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import AnimatedNumber from 'react-animated-number';
import { colorPicker } from '@/components/reports/table-v2/utils';
import Tooltip from '@/components/tooltip';
import { withStyles } from '@/decorators';
export const SCORE_GRADIENT = [
    { rangeIndex: 0, color: [209, 13, 13] },
    { rangeIndex: 50, color: [226, 196, 39] },
    { rangeIndex: 100, color: [63, 148, 45] },
];
export const styles = ({ spacing, breakpoints, palette }) => ({
    row: {
        padding: spacing(1),
        flexWrap: 'nowrap',
        color: palette.mainFont,
    },
    tooltip: {
        color: 'inherit',
        height: '0.7em',
        width: '0.7em',
    },
    top: {
        fontSize: spacing(3.5),
        [breakpoints.down('md')]: {
            fontSize: spacing(3.5),
        },
    },
    bottom: {
        fontSize: spacing(1.75),
        fontWeight: 400,
        textAlign: 'center',
        [breakpoints.down('md')]: {
            fontSize: spacing(1.5),
        },
    },
});
const SnapshotCard = withStyles(styles)(function SnapshotCard({ value, formatter, label, tooltip, className, numberClassName, labelClassName, classes, isCircle, }) {
    return (<Grid container item className={className} direction="column" alignItems="center" justify="center">
      <AnimatedNumber className={classNames(classes.row, classes.top, numberClassName)} duration={500} value={value || 0} stepPrecision={1} formatValue={formatter} style={{
        background: isCircle ? colorPicker(SCORE_GRADIENT, Math.round(value)) : 'initial',
    }}/>

      <div className={classNames(classes.row, classes.bottom, labelClassName)}>
        {tooltip && <Tooltip title={tooltip} iconStyle={classes.tooltip} placement="bottom-start"/>}
        {label}
      </div>
    </Grid>);
});
export default SnapshotCard;
