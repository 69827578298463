import * as Actions from '@/store/actions/types';
import * as HealthChecksReducers from './health-checks';
export const DEFAULT = [];
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.REFRESH_BEACON_HEALTHCHECK:
            return HealthChecksReducers.refreshBeaconHealthcheckReducer(action);
        default:
            return state;
    }
};
export function refreshBeaconHealthcheckReducer({ payload: healthchecks, }) {
    return healthchecks;
}
