import * as Actions from '@/store/actions/types';
import State from '@/store/state';
import { DEFAULT_CHANGE_LOG_SELECT_RANGE } from '@/constants';
import { getLastNumberOfDays } from '@/utils/time';
import * as ChangeLogReducers from './change-log';
export const DEFAULT = {
    filters: {
        dateRange: { start: null, end: null },
        action: null,
        type: null,
        email: [],
        name: null,
        area: null,
        id: null,
    },
    data: null,
    dataLimitReached: false,
    isActive: false,
    searchBy: State.ChangeLog.FilterField.NAME,
};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SET_CHANGE_LOG_FILTERS:
            return ChangeLogReducers.setChangeLogFilterReducer(state, action);
        case Actions.UPDATE_CHANGE_LOG_FILTER:
            return ChangeLogReducers.updateChangeLogFilterReducer(state, action);
        case Actions.UPDATE_CHANGE_LOG_SEARCH_BY:
            return ChangeLogReducers.updateChangeLogSearchByReducer(state, action);
        case Actions.RESET_CHANGE_LOG_FILTER:
            return ChangeLogReducers.resetChangeLogFilterReducer(state);
        case Actions.REFRESH_CHANGE_LOG_DATA:
            return ChangeLogReducers.refreshChangeLogDataReducer(state, action);
        case Actions.SET_CHANGE_LOG_IS_ACTIVE:
            return ChangeLogReducers.setChangeLogIsActive(state, action);
        case Actions.SET_CHANGE_LOG_DATA_LIMIT_REACHED:
            return ChangeLogReducers.setChangeLogDataLimitReached(state, action);
        default:
            return state;
    }
};
export function setChangeLogDataLimitReached(state, { payload: { dataLimitReached } }) {
    return Object.assign(Object.assign({}, state), { dataLimitReached });
}
export function setChangeLogIsActive(state, { payload: { isActive } }) {
    return Object.assign(Object.assign({}, state), { isActive });
}
export function setChangeLogFilterReducer(state, { payload: filters }) {
    return Object.assign(Object.assign({}, state), { filters: Object.assign(Object.assign({}, state.filters), filters) });
}
export function updateChangeLogSearchByReducer(state, { payload: searchBy }) {
    return Object.assign(Object.assign({}, state), { searchBy });
}
export function updateChangeLogFilterReducer(state, { payload: { field, value } }) {
    return Object.assign(Object.assign({}, state), { filters: Object.assign(Object.assign({}, state.filters), { [field]: value }) });
}
export function resetChangeLogFilterReducer(state) {
    return Object.assign(Object.assign({}, state), { filters: Object.assign(Object.assign({}, ChangeLogReducers.DEFAULT.filters), { dateRange: { start: getLastNumberOfDays(DEFAULT_CHANGE_LOG_SELECT_RANGE), end: getLastNumberOfDays(0) } }), searchBy: ChangeLogReducers.DEFAULT.searchBy });
}
export function refreshChangeLogDataReducer(state, { payload: { data, keepPreviousData = false } }) {
    return keepPreviousData
        ? Object.assign(Object.assign({}, state), { data: [...state.data, ...data] }) : Object.assign(Object.assign({}, state), { data });
}
