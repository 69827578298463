import { ModelType } from '@/constants';
import Area from '@/merchandising/models/area';
import BiasingProfile from '@/merchandising/models/biasing-profile';
import Filter from '@/merchandising/models/filter';
import MatchStrategy from '@/merchandising/models/match-strategy';
import Navigation from '@/merchandising/models/navigation';
import Phrase from '@/merchandising/models/phrase';
import Redirect from '@/merchandising/models/redirect';
import RelatedQuery from '@/merchandising/models/related-query';
import Rule from '@/merchandising/models/rule';
import Spelling from '@/merchandising/models/spelling';
import StopWord from '@/merchandising/models/stop-word';
import Synonym from '@/merchandising/models/synonym';
import Template from '@/merchandising/models/template';
import User from '@/merchandising/models/user';
import Zone from '@/merchandising/models/zone';
const MerchandisingConfig = {
    [ModelType.AREA]: Area.CONFIG,
    [ModelType.USER]: User.CONFIG,
    [ModelType.BIASING_PROFILE]: BiasingProfile.CONFIG,
    [ModelType.FILTER]: Filter.CONFIG,
    [ModelType.MATCH_STRATEGY]: MatchStrategy.CONFIG,
    [ModelType.NAVIGATION]: Navigation.CONFIG,
    [ModelType.PHRASE]: Phrase.CONFIG,
    [ModelType.REDIRECT]: Redirect.CONFIG,
    [ModelType.RELATED_QUERY]: RelatedQuery.CONFIG,
    [ModelType.RULE]: Rule.CONFIG,
    [ModelType.SPELLING]: Spelling.CONFIG,
    [ModelType.STOP_WORD]: StopWord.CONFIG,
    [ModelType.SYNONYM]: Synonym.CONFIG,
    [ModelType.TEMPLATE]: Template.CONFIG,
    [ModelType.ZONE]: Zone.CONFIG,
    [ModelType.KEY]: { global: true },
    [ModelType.CHANGE_LOG]: { global: true },
};
export default MerchandisingConfig;
