import { __rest } from "tslib";
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames';
import { compose } from 'ramda';
import { disabling } from '@/components/disabling';
import LabeledInline from '@/components/labeled/inline';
import LabeledSection from '@/components/labeled/section';
import { responsive } from '@/components/responsive';
import { withStyles, } from '@/decorators';
import Input from './input';
export const styles = ({ label, fontSize, spacing, breakpoints, }) => ({
    formControl: {
        position: 'relative',
        marginBottom: spacing(2),
    },
    hint: {
        position: 'absolute',
        fontSize: fontSize.formControl,
        color: label.color,
        '&$focused': {
            color: label.color,
        },
    },
    focused: {},
    hintOffset: {
        marginTop: spacing(2),
    },
    error: {
        position: 'absolute',
        bottom: spacing(-2),
        [breakpoints.down('md')]: {
            bottom: spacing(-3),
        },
    },
});
export const enhance = compose(responsive, disabling, withStyles(styles));
const TextField = enhance(function TextField(_a) {
    var { fullWidth, error, id, hint, value, onChange, label, tooltip, simple, labeledSection, inputClassName, inputFieldClassName, formControlClassName, formErrorClassName, classes } = _a, props = __rest(_a, ["fullWidth", "error", "id", "hint", "value", "onChange", "label", "tooltip", "simple", "labeledSection", "inputClassName", "inputFieldClassName", "formControlClassName", "formErrorClassName", "classes"]);
    const helperTextId = id && `${id}-helper-text`;
    const LabelComponent = (labeledSection ? LabeledSection : LabeledInline);
    const inputElement = (<Input value={value} id={id} className={inputClassName} inputFieldClass={inputFieldClassName} fullWidth={fullWidth} onChange={({ target: { value } }) => onChange(value)} {...props}/>);
    return simple ? (inputElement) : (<LabelComponent {...(id ? { 'for': id } : '')} label={label} tooltip={tooltip}>
      <FormControl {...(error ? { 'aria-discribed-by': helperTextId } : '')} className={classNames(classes.formControl, { [classes.hintOffset]: !hint }, formControlClassName)} error={!!error} fullWidth={fullWidth}>
        {hint && (<InputLabel shrink={!!(hint && props.placeholder) || (typeof value === 'string' ? !!value.trim() : value != null)} htmlFor={id} className={classes.hint} classes={{ focused: classes.focused }}>
            {hint}
          </InputLabel>)}
        {inputElement}
        {typeof error === 'string' && error && (<FormHelperText id={helperTextId} error className={classNames(classes.error, formErrorClassName)}>
            {error}
          </FormHelperText>)}
      </FormControl>
    </LabelComponent>);
});
export default TextField;
