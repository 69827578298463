import { ModelType } from '@/constants';
import { filterByNameAndId } from '@/utils';
var Area;
(function (Area) {
    Area.DEFAULT = {
        intelligentNavigationEnabled: false,
        suggestionStrength: 2,
        wildcardSearchEnabled: false,
        relaxMatchStrategyName: false,
        metadata: [],
        engineIds: ['cloud'],
    };
    Area.CONFIG = {
        type: ModelType.AREA,
        global: true,
        defaults: Area.DEFAULT,
        filter: filterByNameAndId,
    };
})(Area || (Area = {}));
export default Area;
