import React from 'react';
import { withStyles } from '@/decorators';
export const styles = {
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
};
const Container = withStyles(styles)(function Container({ classes, children }) {
    return <div className={classes.root}>{children}</div>;
});
export default Container;
