import { __decorate } from "tslib";
import React from 'react';
import MaterialCheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { disabling } from '@/components/disabling';
import { labeled } from '@/components/labeled';
import { responsive } from '@/components/responsive';
import { withStyles } from '@/decorators';
export const styles = ({ palette, fontSize, spacing }) => ({
    root: {
        padding: spacing(1),
        width: '100%',
    },
    label: {
        fontSize: fontSize.formControl,
        display: 'initial',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
    checkBox: {
        height: spacing(2),
        width: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1),
        '&$dense': {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    tooltip: {
        fontSize: fontSize.formControl,
        margin: `0 ${spacing(5)}px`,
    },
    formText: {
        margin: `auto ${spacing(1)}px`,
        whiteSpace: 'nowrap',
        color: palette.mainFont,
    },
    dense: {},
    disabled: {
        color: palette.disabledFont,
    },
    disabledCursor: {
        cursor: 'default',
    },
});
let CheckBox = class CheckBox extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.inputRef = React.createRef();
        this.onChange = (e) => this.props.onChange(e.target.checked);
    }
    render() {
        const { checked, disabled, description, formText, dense, className, classes, } = this.props;
        const { current: inputRef } = this.inputRef;
        const { clientWidth, scrollWidth } = inputRef
            ? inputRef.labels[0].lastElementChild
            : { clientWidth: 0, scrollWidth: 0 };
        const checkbox = (<React.Fragment>
        <MaterialCheckBox color="primary" checked={checked} onChange={this.onChange} disabled={disabled} className={classNames(classes.checkBox, { [classes.dense]: dense })}/>
        {formText && <span className={classNames(classes.formText, { [classes.disabled]: disabled })}>{formText}</span>}
      </React.Fragment>);
        const formControl = (<FormControlLabel inputRef={this.inputRef} control={checkbox} label={description} classes={{
            root: classNames(classes.root, { [classes.disabledCursor]: disabled }, className),
            label: classNames(classes.label, { [classes.disabled]: disabled }),
        }}/>);
        return description ? (scrollWidth > clientWidth ? (<Tooltip placement="bottom-start" title={description} className={classes.tooltip} classes={{ tooltip: classes.tooltip }}>
          {formControl}
        </Tooltip>) : (formControl)) : (checkbox);
    }
};
CheckBox = __decorate([
    responsive,
    labeled,
    disabling,
    (withStyles(styles))
], CheckBox);
export default CheckBox;
