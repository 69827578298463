import React from 'react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { withLinkStyles } from './link';
const Page = withLinkStyles(function Page({ value, isActive, onClick, classes, }) {
    return (<Button classes={{
        root: classes.root,
        label: classNames({ [classes.inactive]: !isActive, [classes.active]: isActive }),
    }} onClick={onClick} aria-label={`Go to page ${value}`}>
      {value}
    </Button>);
});
export default Page;
