import { __rest } from "tslib";
import React from 'react';
import { replace } from 'connected-react-router';
import { compose } from 'ramda';
import { setDisplayName, wrapDisplayName } from 'recompose';
import { Routing } from '@/constants';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
export const selectors = {
    activeUser: Selectors.activeUserSelector,
};
export const actionCreators = {
    redirect: replace,
};
export const enhance = (Component) => compose(setDisplayName(wrapDisplayName(Component, 'authorized')), connect(selectors, actionCreators));
export default function authorized(roles) {
    return (Component) => (enhance(Component)(function AuthorizedComponent(_a) {
        var { activeUser, redirect } = _a, props = __rest(_a, ["activeUser", "redirect"]);
        if (roles && !roles.every((role) => activeUser.allowedAccess.includes(role))) {
            redirect(Routing.MERCHANDISING_PATH);
            return null;
        }
        return <Component {...props}/>;
    }));
}
