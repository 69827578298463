export default {
    API_HOST: 'groupbycloud.com',
    API_PATH: '/admin/v2',
    DYNAMIC_API: false,
    ENRICH_API_PREFIX: 'https://enrich-api.dev.groupbycloud.com',
    GOOGLE_ANALYTICS_ID: 'UA-111076827-2',
    GTM_ID: 'GTM-PZ36WLJ',
    IN_MEMORY_DB: false,
    LOCAL_ENRICH_API_PREFIX: 'http://localhost:8080',
    QA_API_SUBDOMAIN: 'cc-qa2',
    SR_QA_API_SUBDOMAIN: 'sr-qa1',
};
