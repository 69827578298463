export function cacheSelector({ cache }) {
    return cache;
}
// this selector is not memoized using createSelector since it relies on Date.now()
export const cacheEntry = (key) => (state) => {
    const cache = cacheSelector(state);
    const entry = cache[key];
    return !entry || Date.now() >= entry.expires
        ? undefined
        : entry.data;
};
