import moment from 'moment';
import { DATE_FORMAT_DASH, DATE_FORMAT_HOUR, LIMIT_FOR_PERIOD_IN_DAY } from '@/constants';
import { convertToISO, getDuration } from '@/utils';
const TWENTY_FIVE_DAYS_DST = 601;
export function getToday() {
    return moment()
        .hours(0)
        .utc()
        .format(DATE_FORMAT_HOUR);
}
export function getLastNumberOfDays(days) {
    return moment()
        .hours(0)
        .subtract(days, 'days')
        .utc()
        .format(DATE_FORMAT_HOUR);
}
export function createDateRangeEnding(day = 14) {
    return {
        start: moment()
            .startOf('day')
            .subtract(day, 'days')
            .utc()
            .format(DATE_FORMAT_HOUR),
        end: moment()
            .startOf('day')
            .utc()
            .format(DATE_FORMAT_HOUR),
    };
}
export function getStartOfMonth() {
    return moment()
        .startOf('month')
        .utc()
        .format(DATE_FORMAT_HOUR);
}
export function getYesterdayDate() {
    return getPreviousDate(1);
}
export function getPreviousDate(daysAgo) {
    return moment()
        .hours(0)
        .subtract(daysAgo, 'day')
        .toDate();
}
export function getTimeFormat(start, end, datetime) {
    const duration = getDuration(start, end);
    return moment(datetime)
        .local()
        .format(duration <= TWENTY_FIVE_DAYS_DST ? 'Do hA' : 'MMM DD');
}
export function getMinutesCurrentHour() {
    return moment().minutes();
}
export function isDst(datetime) {
    return moment(convertToISO(datetime)).isDST();
}
export function formatDateToDash(date) {
    return moment(convertToISO(date)).format(DATE_FORMAT_DASH);
}
export function getEnrichTimeFormat(start, end, datetime) {
    const duration = getDuration(start, end);
    return moment(datetime)
        .local()
        .add(1, 'day')
        .format(duration > LIMIT_FOR_PERIOD_IN_DAY ? 'MMM' : 'MMM DD');
}
