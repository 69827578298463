import { createSelector } from 'reselect';
export function sessionSelector({ session }) {
    return session || {};
}
export const activeAreaSelector = createSelector(sessionSelector, ({ area }) => area);
export const activeModelTypeSelector = createSelector(sessionSelector, ({ modelType }) => modelType);
export const keysSelector = createSelector(sessionSelector, ({ keys }) => keys);
export const routingLoadedSelector = createSelector(sessionSelector, ({ routingLoaded }) => routingLoaded);
export const inactiveSessionSelector = createSelector(sessionSelector, ({ isInactive }) => isInactive);
export const groveSelector = createSelector(sessionSelector, ({ grove }) => grove);
export const isLoadingSelector = createSelector(sessionSelector, ({ isLoading }) => isLoading);
