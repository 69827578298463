import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import PixelsLogo from 'PixelsLogo';
import { withStyles } from '@/decorators';
import { preventDefault } from '@/utils';
import { APP_VERSION } from '@/config/global';
export const styles = ({ frame, spacing, breakpoints }) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        [breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    title: {
        marginLeft: spacing(2),
        color: frame.color,
        whiteSpace: 'nowrap',
    },
    superText: {
        fontSize: '0.6em',
        padding: spacing(1 / 2),
        fontWeight: 100,
    },
});
const HeaderTitle = withStyles(styles)(function HeaderTitle({ goHome, classes }) {
    return (<div className={classes.root}>
      <a href="/" onClick={preventDefault(goHome)} className={classes.link}>
        <SvgIcon>
          <PixelsLogo />
        </SvgIcon>
        <Typography className={classes.title} variant="h6">
          COMMAND Center
          <sup className={classes.superText}>
            <em>{APP_VERSION}</em>
          </sup>
        </Typography>
      </a>
    </div>);
});
export default HeaderTitle;
