import { __decorate } from "tslib";
import React from 'react';
import Popover from '@material-ui/core/Popover';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import UserButton from '@material-ui/icons/Person';
import IconButton from '@/components/controls/icon-button';
import { Role } from '@/constants';
import { withStyles } from '@/decorators';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import DocumentationMenuItem from './items/documentation';
import LogoutMenuItem from './items/logout';
import RefAppMenuItem from './items/refapp';
import StatusMenuItem from './items/status';
import SupportMenuItem from './items/support';
export const styles = ({ spacing, zIndex, frame, breakpoints, }) => ({
    userIcon: {
        height: spacing(8),
        color: frame.color,
    },
    paper: {
        borderRadius: 0,
        borderBottomLeftRadius: spacing(1 / 4),
        borderBottomRightRadius: spacing(1 / 4),
    },
    popover: {
        zIndex: zIndex.header,
        [breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
});
export const selectors = {
    activeUser: Selectors.activeUserSelector,
};
let UserMenu = class UserMenu extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { menuOpen: false };
        this.buttonRef = React.createRef();
        this.toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });
        this.menuClose = () => this.setState({ menuOpen: false });
    }
    render() {
        const { classes, activeUser } = this.props;
        return (<React.Fragment>
        <IconButton id="menu--user-action" className={classes.userIcon} onClick={this.toggleMenu} buttonRef={this.buttonRef} aria-label="User">
          <UserButton />
          <ArrowIcon style={{ fontSize: '0.8em' }}/>
        </IconButton>
        <Popover id="menu--user-action__list" className={classes.popover} classes={{
            paper: classes.paper,
        }} open={this.state.menuOpen} anchorEl={this.buttonRef.current} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }} onClose={this.menuClose}>
          <DocumentationMenuItem />
          {activeUser.allowedAccess.includes(Role.ADMIN) ? <RefAppMenuItem activeUser={activeUser}/> : null}
          <StatusMenuItem />
          <SupportMenuItem />
          <LogoutMenuItem />
        </Popover>
      </React.Fragment>);
    }
};
UserMenu = __decorate([
    (withStyles(styles)),
    connect(selectors)
], UserMenu);
export default UserMenu;
