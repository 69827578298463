import * as ConnectedReactRouter from 'connected-react-router';
import { debounce } from 'debounce';
import createPathMatcher from 'path-match';
import * as Redux from 'redux';
import persistState from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import createStorageAdapter from 'redux-localstorage/lib/adapters/localStorage';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import { Routing, DEFAULT_CACHE_TTL, SEARCH_QUERY_UPDATE_DELAY } from '@/constants';
import { isGlobalModelType } from '@/merchandising/models/utils';
import * as ActionCreators from './actions/creators';
import { isModelAction } from './actions/merchandising';
import * as Actions from './actions/types';
import BeaconingMiddleware from './beaconing';
import * as Selectors from './selectors';
import * as Middleware from './middleware';
import { onEnrichPath } from '@/enrich/routing/utils/index';
import { SKIP_AUTHENTICATION } from '@/config/global';
export const STORAGE_KEY = 'cc-persisted-state';
export const REQUIRED_AREA_MODEL_ACTIONS = [Actions.STORE_ONE_MODEL, Actions.STORE_ALL_MODELS];
export const INVALID_GLOBAL_MODEL_ACTIONS = [Actions.COPY_TO_AREA, Actions.REPLACE_IN_AREA];
export const modelManagerPathMatcher = createPathMatcher({ end: false })(Routing.MODEL_MANAGER_PATH);
export const ssoLoginPathMatcher = createPathMatcher({ end: false })(Routing.SSO_LOGIN_PATH);
export const glancerAnalyticsPathMatcher = createPathMatcher({ end: false })(Routing.GLANCER_ANALYTICS_PATH);
export const glancerAnalyticsDashboardPathMatcher = createPathMatcher({ end: false })(Routing.GLANCER_ANALYTICS_DASHBOARD_PATH);
export function messageEventMiddleware(store) {
    return (next) => (action) => {
        if (action.type === Actions.RECEIVE_MESSAGE_EVENT) {
            return store.dispatch(action.payload.data);
        }
        return next(action);
    };
}
export function reauthenticationMiddleware(store) {
    return (next) => (action) => {
        if (action.type === ConnectedReactRouter.LOCATION_CHANGE) {
            const isDirtyAuthenticated = Selectors.isDirtyAuthenticatedSelector(store.getState());
            if (isDirtyAuthenticated) {
                return store.dispatch(ActionCreators.validateToken(action));
            }
        }
        return next(action);
    };
}
export function authenticationMiddleware(store) {
    return (next) => (action) => {
        if (action.type === ConnectedReactRouter.LOCATION_CHANGE) {
            const { location: { pathname, search }, } = action.payload;
            const isAuthenticated = Selectors.isAuthenticatedSelector(store.getState());
            const onSSOLoginPage = ssoLoginPathMatcher(pathname);
            const onLoginPage = pathname === Routing.LOGIN_PATH || onSSOLoginPage;
            if (!isAuthenticated && !onLoginPage) {
                // redirect to login page if not authenticated
                return store.dispatch(ActionCreators.goToLoginWithNext(pathname, search));
            }
            if (isAuthenticated && onLoginPage) {
                // disallow access to the login page while authenticated
                return store.dispatch(ConnectedReactRouter.replace('/'));
            }
            if (onSSOLoginPage) {
                store.dispatch(ActionCreators.exchangeSSOCode(onSSOLoginPage.provider));
            }
        }
        return next(action);
    };
}
export function areaUpdateMiddleware(store) {
    return (next) => (action) => {
        if (action.type === ConnectedReactRouter.LOCATION_CHANGE) {
            const { pathname } = action.payload;
            const match = modelManagerPathMatcher(pathname);
            if (match && match.area) {
                store.dispatch(ActionCreators.switchArea(match.area));
            }
        }
        return next(action);
    };
}
export function allowedAreasMiddleware(store) {
    return (next) => (action) => {
        if (action.type === Actions.SWITCH_AREA && (!action.meta || !action.meta.force)) {
            const { allowedAreas } = Selectors.activeUserSelector(store.getState());
            if (!allowedAreas.includes(action.payload)) {
                return;
            }
        }
        return next(action);
    };
}
/* eslint-disable no-console */
export function modelActionValidationMiddleware() {
    return (next) => (action) => {
        if (isModelAction(action)) {
            if (!action.meta) {
                console.error('model action should define a "meta" property');
                console.dir(action);
                return;
            }
            if (!action.meta.modelType) {
                console.error('model action should define a "meta.modelType" property');
                console.dir(action);
                return;
            }
            const isGlobal = isGlobalModelType(action.meta.modelType);
            if (!isGlobal && REQUIRED_AREA_MODEL_ACTIONS.includes(action.type) && !action.meta.area) {
                console.error('model action should define a "meta.area" property');
                console.dir(action);
                return;
            }
            if (isGlobal && INVALID_GLOBAL_MODEL_ACTIONS.includes(action.type)) {
                console.error(`invalid model action type "${action.type}" for global model type "${action.meta.modelType}"`);
                console.dir(action);
                return;
            }
        }
        return next(action);
    };
}
/* eslint-enable no-console */
export function glancerAnalyticsMiddleware(store) {
    return (next) => (action) => {
        if (action.type === ConnectedReactRouter.LOCATION_CHANGE) {
            const { location: { pathname } } = action.payload;
            const onGlancerPath = glancerAnalyticsPathMatcher(pathname);
            const state = store.getState();
            const hasGlancerAccess = Selectors.glancerAccessSelector(state);
            // The Glancer Analytics path is the same as the Analytics v2 path,
            // so we must also check that the Glancer Analytics page is the one being loaded.
            if (onGlancerPath && hasGlancerAccess) {
                store.dispatch(ActionCreators.updateGlancerEmbedUrl(''));
                if (!Selectors.glancerDashboardsSelector(state).allIds.length) {
                    store.dispatch(ActionCreators.getGlancerDashboardList());
                }
            }
        }
        return next(action);
    };
}
export const debouncedSearch = debounce((store, loadAction) => store.dispatch(loadAction()), SEARCH_QUERY_UPDATE_DELAY);
export function enrichDebounceSearchMiddleware(store) {
    return (next) => (action) => {
        if (action.type === Actions.UPDATE_ENRICH_QUERY) {
            debouncedSearch(store, ActionCreators.loadEnrichPageData);
        }
        else if (action.type === Actions.UPDATE_ENRICH_CONFIDENCE_THRESHOLD) {
            debouncedSearch(store, ActionCreators.loadEnrichMLClassPredictions);
        }
        return next(action);
    };
}
export function purgeExpiredCacheMiddleware(firstPurgeTime = 0) {
    let nextPurgeTime = firstPurgeTime;
    return (store) => (next) => (action) => {
        const now = Date.now();
        if (now >= nextPurgeTime) {
            nextPurgeTime = Date.now() + DEFAULT_CACHE_TTL;
            store.dispatch(ActionCreators.purgeExpiredCacheEntries(now));
        }
        return next(action);
    };
}
export function enrichPageVisitMiddleware(store) {
    return (next) => (action) => {
        const retVal = next(action);
        if (action.type === ConnectedReactRouter.LOCATION_CHANGE) {
            const { location: { pathname } } = action.payload;
            if (onEnrichPath(pathname)) {
                store.dispatch(ActionCreators.notifyEnrichPageVisited());
            }
        }
        return retVal;
    };
}
export default function createMiddleware(history, sagaMiddleware) {
    const routerMiddleware = ConnectedReactRouter.routerMiddleware(history);
    const storageAdapter = filter([
        'auth.token',
        'auth.user',
        'enrich.pages',
        'enrich.userSettings',
        'session.area',
        'searchPreview.configurationByArea',
        'ui.drawer.collapsed',
    ])(createStorageAdapter(window.localStorage));
    const persistMiddleware = persistState(storageAdapter, STORAGE_KEY);
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 300 }))
        || Redux.compose;
    const authMiddleware = [reauthenticationMiddleware, authenticationMiddleware];
    return composeEnhancers(Redux.applyMiddleware(messageEventMiddleware, ...(SKIP_AUTHENTICATION ? [] : authMiddleware), Middleware.purgeExpiredCacheMiddleware(), areaUpdateMiddleware, allowedAreasMiddleware, modelActionValidationMiddleware, glancerAnalyticsMiddleware, enrichDebounceSearchMiddleware, enrichPageVisitMiddleware, thunkMiddleware, sagaMiddleware, ...BeaconingMiddleware, routerMiddleware), ...(SKIP_AUTHENTICATION ? [] : [persistMiddleware]));
}
