import { __rest } from "tslib";
import React from 'react';
import { compose } from 'ramda';
import IconButton from '@/components/controls/icon-button';
import { disabling } from '@/components/disabling';
import { withStyles, } from '@/decorators';
export const styles = {
    icon: {
        padding: 0,
    },
};
export const enhance = compose(withStyles(styles), disabling);
const SmallIconButton = enhance(function SmallIconButton(_a) {
    var { onClick, onKeyDown, classes, labelClassName, children } = _a, props = __rest(_a, ["onClick", "onKeyDown", "classes", "labelClassName", "children"]);
    return (<IconButton onClick={onClick} onKeyDown={onKeyDown} className={classes.icon} classes={{ label: labelClassName }} {...props}>
      {children}
    </IconButton>);
});
export default SmallIconButton;
