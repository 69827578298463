import { __rest } from "tslib";
import React from 'react';
import { CriteriaBuilder } from '@groupby/enrich-criteria';
import { format as formatCriteria } from '@groupby/enrich-criteria/cel';
import csvParse from 'csv-parse/lib/sync';
import cuid from 'cuid';
import stringify from 'safe-stable-stringify';
import decodeJWT from 'jwt-decode';
import moize from 'moize';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import * as qs from 'qs';
import { compose } from 'ramda';
import { connect as reduxConnect } from 'react-redux';
import { getContext, getDisplayName, mapProps, pure, withContext, } from 'recompose';
import { bindActionCreators } from 'redux';
import { formValues, FormName, FormSection } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { shallowEqual } from 'shallow-equal-object';
import StringNaturalCompare from 'string-natural-compare';
import { capitalize } from 'voca';
import { IS_PRODUCTION_ENV } from '@/config/global';
import { DATE_FORMAT_HOUR, ModelType, PAGE_SIZE, PROTECTED_FORM_NAMESPACE, WRAPPED_COMPONENT_KEY, } from '@/constants';
import * as utils from '.';
export { cuid, decodeJWT, StringNaturalCompare, };
export const ARRAY_ACCESS_KEY_PATTERN = /\[(\d+)\]/g;
export const ARRAY_ACCESS_PATTERN = /(\[\d+\])/g;
export const exposeWrapped = (hoc) => (Component) => {
    const wrapped = hoc(Component);
    wrapped[WRAPPED_COMPONENT_KEY] = Component;
    return wrapped;
};
export function bindActions(actionCreators, component) {
    return (dispatch) => bindActionCreators(Object.keys(actionCreators).reduce((acc, key) => {
        acc[key] = (...args) => {
            const action = actionCreators[key](...args);
            return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { source: getDisplayName(component) }) });
        };
        return acc;
    }, {}), dispatch);
}
export function wrapMerger(merger) {
    return (stateProps, dispatchProps, props) => (Object.assign(Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), props), merger(stateProps, dispatchProps, props)));
}
export function connect(selectors, actionCreators, mergeProps, _a = {
    merge: true,
    injectDispatch: false,
}) {
    var { merge, injectDispatch } = _a, opts = __rest(_a, ["merge", "injectDispatch"]);
    return ((component) => reduxConnect(selectors ? createStructuredSelector(selectors) : selectors, actionCreators ? utils.bindActions(actionCreators, component) : injectDispatch ? null : {}, mergeProps && merge ? utils.wrapMerger(mergeProps) : mergeProps, opts)(component));
}
export function connect2(selectors, actionCreators, mergeProps, _a = {
    merge: true,
    injectDispatch: false,
}) {
    var { merge, injectDispatch } = _a, opts = __rest(_a, ["merge", "injectDispatch"]);
    return (component) => reduxConnect(selectors ? createStructuredSelector(selectors) : null, actionCreators ? utils.bindActions(actionCreators, component) : injectDispatch ? null : {}, mergeProps && merge ? utils.wrapMerger(mergeProps) : null, opts)(component);
}
export const withFormName = exposeWrapped((Component) => (props) => (<FormName>{({ form }) => <Component formName={form} {...props}/>}</FormName>));
export const withFormValues = (arg) => exposeWrapped(compose(formValues(arg), mapProps((_a) => {
    var { _reduxForm } = _a, props = __rest(_a, ["_reduxForm"]);
    return props;
})));
export function safeIncludes(value, pattern) {
    return value && value.toLowerCase().includes(pattern.toLowerCase());
}
export function sortAlphabetically(lhs, rhs) {
    return lhs.localeCompare(rhs);
}
export function parseSearch(search) {
    return qs.parse((search || '?').substr(1));
}
export const getModelType = exposeWrapped(compose(getContext({ modelType: PropTypes.string }), pure));
export const wrapFormSection = exposeWrapped((Component) => function WrappedFormSection(_a) {
    var { member } = _a, props = __rest(_a, ["member"]);
    return (<FormSection name={member}>
          <Component {...props}/>
        </FormSection>);
});
export function transformCSV(value) {
    return csvParse(value, { trim: true })[0].filter(Boolean);
}
export function totalPages(items, pageSize = PAGE_SIZE) {
    return Math.max(Math.ceil((Array.isArray(items) ? items.length : items) / pageSize), 1);
}
export function formatDate(datetime) {
    return moment(datetime)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('MMM DD YYYY');
}
export function renameProp(oldProp, newProp, _a) {
    var _b = oldProp, old = _a[_b], others = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    return Object.assign({ [newProp]: old }, others);
}
export function isValidField(value) {
    return value != null ? value.trim().length !== 0 : false;
}
export function convertFieldArrayAccessSyntax(path) {
    return path.replace(ARRAY_ACCESS_KEY_PATTERN, '.$1');
}
export function convertToElasticPathFormat(path) {
    return path.replace(ARRAY_ACCESS_PATTERN, '');
}
export const dot = {
    get(target, path, defaultValue) {
        try {
            const normalizedPath = convertFieldArrayAccessSyntax(path);
            const dotIndex = normalizedPath.indexOf('.');
            if (dotIndex === -1) {
                return normalizedPath in target ? target[normalizedPath] : defaultValue;
            }
            const key = normalizedPath.substr(0, dotIndex);
            return key in target ? dot.get(target[key], normalizedPath.substr(dotIndex + 1), defaultValue) : defaultValue;
        }
        catch (_a) {
            return defaultValue;
        }
    },
};
export function trimValue(value) {
    switch (typeof value) {
        case 'string':
            return value.trim();
        default:
            return value;
    }
}
export function getDuration(start, end) {
    return moment.duration(moment(end, DATE_FORMAT_HOUR).diff(moment(start, DATE_FORMAT_HOUR))).asHours();
}
export const preventDefault = moize((handler) => (e) => {
    e.preventDefault();
    if (handler) {
        handler();
    }
});
export function normalize(models) {
    return {
        allIds: models.map(({ id }) => id),
        byId: models.reduce((byId, model) => Object.assign(byId, { [model.id]: model }), {}),
    };
}
export const denormalize = moize((models) => {
    if (!models) {
        return [];
    }
    return models.allIds.map((id) => models.byId[id]);
});
export function getManagerName(modelType) {
    return `${PROTECTED_FORM_NAMESPACE}/${modelType}-manager`;
}
export function getFormManagerName(parentForm, name) {
    return `${parentForm}/form/${name.replace('.', '/').replace(/\[(\d+)\]/g, '--$1')}`;
}
export const filterByIdAnd = (keys) => (filter) => (_a) => {
    var { id } = _a, model = __rest(_a, ["id"]);
    return id.includes(filter) || keys.some((key) => utils.safeIncludes(model[key], filter));
};
export const filterByNameAndId = filterByIdAnd(['name']);
export function sortBy(key) {
    return ({ [key]: lhs }, { [key]: rhs }) => (typeof lhs === 'string' && typeof rhs === 'string' ? lhs.localeCompare(rhs) : 0);
}
export const sortByName = sortBy('name');
export function sortByPriority({ priority: lhs }, { priority: rhs }) {
    return lhs - rhs;
}
/**
 * note: only to be used for debugging components
 * only works for functional components
 */
export const log = (Component) => {
    if (IS_PRODUCTION_ENV) {
        return Component;
    }
    const displayName = getDisplayName(Component);
    let prevProps = null;
    // eslint-disable-next-line react/display-name
    return (props) => {
        if (prevProps !== null) {
            console.warn(`re-rendering ${displayName}`);
            shallowEqual(prevProps, props, { debug: true });
        }
        else {
            console.warn(`rendering ${displayName}`);
        }
        prevProps = props;
        return <Component {...props}/>;
    };
};
export function filterOutLastTwoChar(value) {
    return value ? value.slice(0, -2) : null;
}
export function extractLastTwoChar(value) {
    return value ? value.slice(-2) : null;
}
export function formatTo12H(time) {
    return moment(time, 'hh').format('ha');
}
export function formatTo24H(time) {
    return moment(time, 'ha').format('HH');
}
export function convertToUtc(datetime, keepHour) {
    return keepHour
        ? moment(convertToISO(datetime), DATE_FORMAT_HOUR)
            .utc()
            .format(DATE_FORMAT_HOUR)
        : moment(convertToISO(datetime), DATE_FORMAT_HOUR)
            .hours(0)
            .utc()
            .format(DATE_FORMAT_HOUR);
}
export function convertToLocal(datetime) {
    return moment
        .utc(convertToISO(datetime), DATE_FORMAT_HOUR)
        .local()
        .format(DATE_FORMAT_HOUR);
}
export function convertToDate(date) {
    return moment(date).toDate();
}
export function convertToISO(datetime) {
    return `${filterOutLastTwoChar(datetime)}T${extractLastTwoChar(datetime)}`;
}
export function getBasePath(activePath) {
    return activePath.split('/')[1];
}
export function getSectionPath(activePath) {
    const parts = activePath.split('/');
    return parts.length > 2 ? parts[2] : null;
}
export function formatTitles(value) {
    return capitalize(value.replace(/([A-Z])/g, ' $1'));
}
export function toPercent(value) {
    return `${(value * 100).toFixed()}%`;
}
export const withModelType = exposeWrapped(withContext({ modelType: PropTypes.string }, ({ title }) => ({
    modelType: title,
})));
export function getDocsURL(docsAutoLoginBlob, customerId) {
    // This function's parameters are an artifact of the previous docs platform supporting autologin.
    // They have been kept for when the new docs platform eventually supports SSO.
    return 'https://docs.groupbycloud.com/';
}
export function formatQueryParam(params) {
    const builtQueryParam = Object.entries(params)
        .filter((param) => param[1] && param[1].length)
        .map((param, index) => `${index === 0 ? '' : '&'}${param[0]}=${Array.isArray(param[1]) ? param[1].join(',') : param[1]}`);
    return encodeURI(`${builtQueryParam.join('')}`);
}
export function mapToApiModelType(modelType) {
    switch (modelType) {
        case ModelType.KEY:
            return 'Customer';
        case ModelType.ZONE:
            return 'ReusableZone';
        default:
            return capitalize(modelType);
    }
}
export function mapFromApiToChangeLogModelType(modelType) {
    switch (modelType) {
        case 'Customer':
            return capitalize(ModelType.KEY);
        case 'ReusableZone':
            return capitalize(ModelType.ZONE);
        default:
            return capitalize(modelType);
    }
}
export function formatId(str) {
    const replaceMap = {
        ' ': '',
        '/': '-',
    };
    const re = new RegExp(Object.keys(replaceMap).join('|'), 'g');
    return str.toLowerCase().replace(re, (matched) => replaceMap[matched]);
}
export function generateEnrichCacheKey(resource, method, ...args) {
    // Combine the arguments to a string. For the additional args,
    // stringify them so that if two objects are deeply equal, their resulting strings are also equal.
    // CriteriaBuilder objects are stringified using their formatted representation.
    const stringifiedArgs = stringify(args, (_, value) => (value instanceof CriteriaBuilder ? formatCriteria(value) : value));
    return `${resource}|${method}|${stringifiedArgs}`;
}
export const enrichClassesToSelectionOptions = moize((classes) => (classes.allIds.map((id) => ({
    label: classes.byId[id].title,
    value: classes.byId[id].id,
}))));
/**
 * Rounds and then abbreviates a long number by using words.
 *
 * @param number The number to abbreviate.
 * @param decimals The number of desired decimals if the number is abbreviated.
 */
export function abbreviateNumber(number, decimals) {
    const numberWords = ['K', 'M', 'B'];
    let abbreviation;
    for (let i = numberWords.length - 1; i >= 0; i--) {
        const breakpoint = 10 ** ((i + 1) * 3);
        if (Math.abs(number) >= breakpoint) {
            abbreviation = (number / breakpoint).toFixed(decimals).toString() + numberWords[i];
            break;
        }
    }
    return abbreviation || number.toString();
}
/**
 * Determines if value passed in is an accepted probabilty confidence value.
 * Returns true if value is a number between 0 and 99.
 *
 * @param threshold The string number to check for validity.
 */
export const isValidConfidenceValue = (threshold) => {
    const numberThreshold = Number(threshold);
    return /^\d+(\.\d+)?$/.test(threshold) && numberThreshold >= 0 && numberThreshold <= 100;
};
/**
 * Generates a valid probabilty confidence value.
 * Returns the passed in confidence if passed in confidence threshold is valid.
 * Returns a modified, valid, confidence value if passed in confidence threshold is not valid.
 *
 * @param string The confidence filter.
 */
export function getValidConfidenceThreshold(confidence) {
    const numberConfidence = Number(confidence);
    if (isValidConfidenceValue(confidence)) {
        return confidence;
    }
    if (numberConfidence > 100) {
        return '100';
    }
    if (numberConfidence < 0) {
        return '0';
    }
    return '';
}
