import { format } from '@groupby/enrich-criteria/cel';
export function getOrgNamesFromJobsFilter(filter, orgsById) {
    // NOTE: This is a hacky approach. See https://groupby.atlassian.net/browse/CUE-768
    // for details and assumptions.
    const filterString = format(filter);
    const search = /organizationId\s+@<\s+\[([^\]]*)\]/;
    const match = search.exec(filterString);
    if (match === null)
        return [];
    const orgIds = match[1].split(',');
    return orgIds.map((orgId) => { var _a; return (_a = orgsById[orgId.trim()]) === null || _a === void 0 ? void 0 : _a.name; }).filter((orgName) => !!orgName).sort();
}
export function getValidatedPredictions(job, remainingPredictions) {
    var _a;
    const predictionCount = (_a = job.result) === null || _a === void 0 ? void 0 : _a.predictionCount;
    return predictionCount ? predictionCount - remainingPredictions : 0;
}
