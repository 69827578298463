import { __rest } from "tslib";
import React, { useState } from 'react';
import { compose } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { usePopper } from 'react-popper';
import Portal from 'react-travel';
import { labeled } from '@/components/labeled';
import { disabling } from '@/components/disabling';
import { responsive } from '@/components/responsive';
import { PassthroughInput } from '@/components/controls/input';
import Suggestion from './suggestion';
export const MAX_SUGGESTIONS = 10;
var ChangeMethod;
(function (ChangeMethod) {
    ChangeMethod["TYPE"] = "type";
})(ChangeMethod || (ChangeMethod = {}));
export const useStyles = makeStyles(({ spacing, palette, zIndex, shadows, fontSize, breakpoints, }) => ({
    root: { flexGrow: 1 },
    input: {
        fontSize: fontSize.formControl,
    },
    container: {
        position: 'relative',
    },
    containerOpen: {
        position: 'absolute',
        top: '100%',
        marginTop: spacing(1 / 2),
        marginBottom: spacing(3),
        backgroundColor: palette.background.paper,
        zIndex: zIndex.modal,
        boxShadow: shadows[8],
    },
    list: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    listItem: {
        display: 'block',
    },
    fullWidth: {
        flexGrow: 1,
    },
    containerOpenScroll: {
        overflowY: 'scroll',
        minWidth: '100%',
        maxHeight: spacing(40),
        '&$condenseContainerHeight': {
            maxHeight: spacing(17),
        },
        '&$rightAligned': {
            right: 0,
        },
    },
    errorMessage: {
        position: 'absolute',
        bottom: spacing(-2),
        [breakpoints.down('sm')]: {
            bottom: spacing(-3),
        },
    },
    condenseContainerHeight: {},
    rightAligned: {},
}));
const Autocomplete = compose(responsive, labeled, disabling)((_a) => {
    var { value, onChange, max = MAX_SUGGESTIONS, fullWidth, options, portal = true, className, size, condenseHeight, rightAligned, suggestAllOnFocus, id } = _a, props = __rest(_a, ["value", "onChange", "max", "fullWidth", "options", "portal", "className", "size", "condenseHeight", "rightAligned", "suggestAllOnFocus", "id"]);
    const classes = useStyles();
    const [suggestions, setSuggestions] = useState([]);
    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-start' });
    const clearSuggestions = () => setSuggestions([]);
    const suggestAllOnFocusMethods = {
        onSuggestionSelected: clearSuggestions,
        shouldRenderSuggestions: () => true,
    };
    const autosuggestComponent = (<Autosuggest id={id} inputProps={Object.assign(Object.assign({ value,
        fullWidth,
        className,
        id }, props), { onChange: (_, { newValue, method }) => onChange(newValue, method) })} suggestions={suggestions} renderInputComponent={(_a) => {
        var { ref, error } = _a, props = __rest(_a, ["ref", "error"]);
        return (<div>
          <PassthroughInput ref={setReferenceElement} inputRef={ref} {...props} max={props.max} min={props.min} onChange={props.onChange} className={classNames(classes.input, className)}/>

          {error && (<FormHelperText className={classes.errorMessage} error>
              {error}
            </FormHelperText>)}
        </div>);
    }} renderSuggestionsContainer={({ containerProps, children, }) => {
        const container = <div {...containerProps}>{children}</div>;
        return portal ? (<Portal>
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              {container}
            </div>
          </Portal>) : (container);
    }} renderSuggestion={renderSuggestion} onSuggestionsFetchRequested={({ value }) => {
        const normalizedOptions = normalizeSuggestions(options);
        setSuggestions(normalizedOptions
            .filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()))
            .sort(({ label: lhs }, { label: rhs }) => lhs.localeCompare(rhs))
            .slice(0, suggestAllOnFocus ? undefined : max));
    }} onSuggestionsClearRequested={clearSuggestions} getSuggestionValue={({ name }) => name} {...(suggestAllOnFocus ? suggestAllOnFocusMethods : {})} theme={{
        container: classNames(classes.container, { [classes.fullWidth]: fullWidth }),
        suggestionsContainerOpen: classNames(classes.containerOpen, { [classes.containerOpenScroll]: !portal || suggestAllOnFocus }, { [classes.condenseContainerHeight]: condenseHeight }, { [classes.rightAligned]: rightAligned }),
        suggestionsList: classes.list,
        suggestion: classes.listItem,
    }}/>);
    return portal ? <div className={classes.root}>{autosuggestComponent}</div> : autosuggestComponent;
});
export default Autocomplete;
export function renderSuggestion(suggestion, { query, isHighlighted }) {
    return <Suggestion suggestion={suggestion} query={query} isHighlighted={isHighlighted}/>;
}
export function normalizeSuggestions(options) {
    return options
        ? options.map((option) => (typeof option === 'string' ? { label: option, name: option } : option))
        : [];
}
