import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { FormCell } from '@/components/cell';
function TableHeader({ columns, sortable = true, ascending, sortBy, handleClick, }) {
    return (<TableHead>
      <TableRow>
        <FormCell />
        {columns.map(({ label, size, key }) => (<FormCell size={size} key={key}>
            {sortable ? (<Tooltip title="Sort" enterDelay={300} leaveDelay={300} placement="bottom-start">
                <TableSortLabel active={sortBy === key} direction={ascending ? 'asc' : 'desc'} onClick={handleClick(key)}>
                  {label}
                </TableSortLabel>
              </Tooltip>) : (label)}
          </FormCell>))}
        <FormCell />
      </TableRow>
    </TableHead>);
}
export default TableHeader;
