import React from 'react';
import Modal from '@/components/modal';
import { withStyles } from '@/decorators';
import Insights from './content';
export const styles = ({ drawer, appHeader, fontSize, breakpoints, spacing, }) => ({
    paper: {
        position: 'absolute',
        top: `${appHeader.height + spacing(5)}px`,
        left: 'initial',
        right: 0,
        height: '100%',
        maxHeight: `calc(100vh - ${appHeader.heightMobile}px - ${spacing(1)}px)`,
        width: `calc(100vw - ${drawer.width}px - ${spacing(4)}px)`,
        transform: 'inherit',
        marginRight: spacing(2),
        fontSize: fontSize.formControl,
        borderRadius: spacing(1 / 2),
        padding: 0,
        [breakpoints.down('md')]: {
            left: 0,
            margin: 'auto',
            width: '90%',
        },
        [breakpoints.down('sm')]: {
            top: `${appHeader.heightMobile + spacing(5)}px`,
            maxHeight: `calc(100vh - ${appHeader.heightMobile}px - ${spacing(8)}px)`,
        },
    },
    container: {
        padding: 0,
        height: '100%',
    },
    content: {
        padding: 0,
        maxHeight: '100%',
        overflowY: 'initial',
    },
});
const InsightsModal = withStyles(styles)(function InsightsModal({ snapshot, onClose, open, classes, }) {
    return (<Modal paperClassName={classes.paper} contentClassName={classes.content} containerClassName={classes.container} showFooter={false} open={open} onClose={onClose}>
      <Insights snapshot={snapshot} onClose={onClose}/>
    </Modal>);
});
export default InsightsModal;
