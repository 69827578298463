import { __decorate } from "tslib";
import React from 'react';
import * as MerchandisingActions from '@/store/actions/merchandising';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import { withArea } from '../area-context';
export const loadContextualModels = (modelType, area) => MerchandisingActions.withArea(area)(MerchandisingActions.creators.loadAllModels(modelType));
export const selectors = {
    models: Selectors.managerModelsSelectorCreator,
};
export const actionCreators = {
    loadModels: loadContextualModels,
};
export const mergeProps = ({ models }, { loadModels }, { to: modelType, overrideArea, area }) => ({
    models: models(modelType, overrideArea || area),
    loadModels: () => loadModels(modelType, overrideArea || area),
});
let DependencyWrapper = class DependencyWrapper extends React.PureComponent {
    render() {
        const { models } = this.props;
        return this.props.children(models || [], !!models);
    }
    componentDidMount() {
        this.props.loadModels();
    }
};
DependencyWrapper = __decorate([
    withArea,
    connect(selectors, actionCreators, mergeProps)
], DependencyWrapper);
export default DependencyWrapper;
