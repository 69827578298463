import { __decorate } from "tslib";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@/decorators';
import ErrorBoundaryComponent from './error-boundary';
export const styles = ({ spacing }) => ({
    root: {
        padding: `${spacing(2)}px 0`,
    },
});
let ErrorBoundary = class ErrorBoundary extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { hasError: false };
    }
    render() {
        const { classes } = this.props;
        if (this.state.hasError) {
            return (<Grid container alignItems="center" justify="center" spacing={2} classes={{ container: classes.root }}>
          <WarningIcon color="action"/>
          <Typography variant="caption">Something went wrong!</Typography>
        </Grid>);
        }
        return this.props.children;
    }
    componentDidCatch() {
        this.setState({ hasError: true });
    }
};
ErrorBoundary = __decorate([
    (withStyles(styles))
], ErrorBoundary);
export default ErrorBoundary;
export const errorBoundary = (Component) => function ErrorSafeComponent(props) {
    return (<ErrorBoundaryComponent>
      <Component {...props}/>
    </ErrorBoundaryComponent>);
};
