import { __decorate } from "tslib";
import React from 'react';
import Table from '@material-ui/core/Table';
import moize from 'moize';
import { PAGE_SIZE } from '@/constants';
import { withStyles } from '@/decorators';
import Pagination from '@/merchandising/model-manager/table/pagination';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import { Direction } from './types';
export const styles = {
    table: {
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    tableWrapper: {
        width: '100%',
    },
};
export const selectors = {
    activeQuery: Selectors.reportQueryActiveQuerySelector,
};
let ReportTable = class ReportTable extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            orderBy: this.props.defaultSort.orderBy,
            order: this.props.defaultSort.order,
            currentPage: 1,
            selectedRow: null,
        };
        this.tableRef = React.createRef();
        this.clearSelectedRow = () => this.setState({ selectedRow: null });
        this.onCurrentPageChange = (currentPage) => {
            if (this.props.onPageChange) {
                this.props.onPageChange(currentPage);
            }
            this.tableRef.current.parentElement.scrollTop = 0;
            this.setState({ currentPage });
        };
        this.onExpansionClick = (index) => (index === this.state.selectedRow ? this.clearSelectedRow() : this.selectRow(index));
        this.onSortClick = (orderBy) => () => {
            let order = Direction.DESC;
            if (this.state.orderBy === orderBy && this.state.order === 'desc') {
                order = Direction.ASC;
            }
            this.clearSelectedRow();
            this.setState({ order, orderBy });
        };
        this.selectRow = (selectedRow) => this.setState({ selectedRow });
    }
    componentDidUpdate(prevProps) {
        const { filterSize: prevFilterSize, activeQuery: prevActiveQuery } = prevProps;
        const { filterSize, activeQuery } = this.props;
        const reportLengthChanged = filterSize !== prevFilterSize || activeQuery !== prevActiveQuery;
        if (reportLengthChanged) {
            this.setState({ currentPage: 1 });
        }
    }
    render() {
        const { selectedRow, orderBy, order, currentPage, } = this.state;
        const { classes, data, filterSize = Number.MAX_VALUE, columns, onRowClick, head: Head, body: Body, hidePagination = false, } = this.props;
        const sortedData = data.slice().sort(sortTable(order, orderBy));
        return (<div ref={this.tableRef} className={classes.tableWrapper}>
        <Table className={classes.table}>
          {Head && <Head columns={columns} order={order} orderBy={orderBy} onClick={this.onSortClick}/>}
          {Body && (<Body columns={columns} currentPage={currentPage} data={selectPage(sortedData, currentPage)} onExpansionClick={this.onExpansionClick} onRowClick={onRowClick} orderBy={orderBy} selectedRow={selectedRow}/>)}
        </Table>

        {!hidePagination && (<Pagination totalResults={Math.min(data.length, filterSize)} currentPage={currentPage} updatePage={this.onCurrentPageChange} stickyPosition/>)}
      </div>);
    }
};
ReportTable = __decorate([
    (withStyles(styles)),
    connect(selectors)
], ReportTable);
export default ReportTable;
export function genericSort(lhs, rhs, order, orderBy) {
    let sort = 0;
    if (rhs[orderBy] < lhs[orderBy]) {
        sort = 1;
    }
    if (rhs[orderBy] > lhs[orderBy]) {
        sort = -1;
    }
    return order === Direction.ASC ? sort : -sort;
}
export function sortTable(order, orderBy) {
    return (lhs, rhs) => genericSort(lhs, rhs, order, orderBy);
}
export function indexData(data) {
    return data.map((record, index) => (Object.assign({ index: index + 1 }, record)));
}
export const selectPage = moize((results = [], currentPage) => {
    let pageOffset = (currentPage - 1) * PAGE_SIZE;
    if (pageOffset === results.length) {
        pageOffset = Math.max(0, pageOffset - PAGE_SIZE);
    }
    return results.slice(pageOffset, pageOffset + PAGE_SIZE);
});
