import { __rest } from "tslib";
import React from 'react';
import MaterialInput from '@material-ui/core/Input';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ fontSize }) => ({
    default: {
        fontSize: fontSize.formControl,
    },
    disabled: {
        fontSize: fontSize.itemHeader,
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
});
const Input = withStyles(styles)(function Input(_a) {
    var { value, disabled, float: _float, min, max, className, classes, inputFieldClass, errorClass } = _a, props = __rest(_a, ["value", "disabled", "float", "min", "max", "className", "classes", "inputFieldClass", "errorClass"]);
    return (<MaterialInput value={typeof value === 'number' ? value : value || ''} disabled={disabled} className={classNames(disabled ? classes.disabled : classes.default, classes.nowrap, className)} inputProps={{ min, max }} classes={{ input: inputFieldClass, error: errorClass }} {...props}/>);
});
// eslint-disable-next-line react/display-name
export const PassthroughInput = withStyles(styles)(React.forwardRef((_a, ref) => {
    var { value, disabled, float: _float, min, max, className, classes, inputFieldClass, errorClass } = _a, props = __rest(_a, ["value", "disabled", "float", "min", "max", "className", "classes", "inputFieldClass", "errorClass"]);
    return (<MaterialInput ref={ref} value={typeof value === 'number' ? value : value || ''} disabled={disabled} className={classNames(disabled ? classes.disabled : classes.default, classes.nowrap, className)} inputProps={{ min, max }} classes={{ input: inputFieldClass, error: errorClass }} {...props}/>);
}));
export default Input;
